import Axios from "axios";
import {storageService} from "./";


const baseURL = process.env.REACT_APP_API_ENDPOINT;
export const client = Axios.create({
  baseURL: baseURL,
});

export const HttpMethod = {
  POST: "POST",
  GET: "GET",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
};

export const HttpStatus = {
  RefreshRequired: 422,
  RefreshExpired: 424,
  KycRequired: 426,
  BadRequest: 400,
  Created: 201,
  Ok: 200,
  Forbidden: 403,
  Unauthorized: 401,
  NotFound: 404,
  Timeout: 100,
};

function addAuthorizationHeader(headers) {
  const {access_token} = storageService.getAuthData();
  if (access_token) {
    if (headers) {
      headers["Authorization"] = `Bearer ${access_token}`;
    } else {
      headers = {
        Authorization: `Bearer ${access_token}`,
      };
    }
  }
  return headers;
}

export async function fireRequest(fireRequestProps, authenticate = false) {
  try {
    if (authenticate) {
      fireRequestProps.headers = addAuthorizationHeader(
        fireRequestProps.headers || {}
      );
    }
    const response = await client.request({
      url: fireRequestProps.url,
      data: fireRequestProps.body,
      method: fireRequestProps.method,
      headers: fireRequestProps.headers,
      responseType: fireRequestProps.responseType || "json",
    });

    if (fireRequestProps.responseType) {
      return response;
    }
    return response.data;
  } catch (e) {
    const axiosError = e;
    console.error("Axios Error occurred: ", axiosError.response?.data);

    if (axiosError.response) {
      throw new HttpError(axiosError.response.status, axiosError.response.data);
    } else {
      throw new HttpError(HttpStatus.Timeout, {
        error: {
          detail:
            "An error occurred while processing request. Check your internet connection",
        },
      });
    }
  }
}

export class HttpError extends Error {
  constructor(statusCode, data) {
    super("Http Error occurred");
    this.statusCode = statusCode;
    this.data = data;
  }

  getStatusCode() {
    return this.statusCode;
  }

  getData() {
    return this.data;
  }
}
