import React, { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import { Tab, Tabs, Box } from "@mui/material";
import "./Styles/ListProjects.css";
import ActiveTab from "./ActiveTab";
import AdsTab from "./AdsTab";
import BidsTab from "./BidsTab";
import CompletedTab from "./CompletedTab";
import DraftsTab from "./DraftsTab";
import { useDispatch, useSelector } from "react-redux";
import EmptyProject from "./EmptyProject";
import { listAllProjects, listProjects } from "services/projectService";
import { listBids } from "services/bidService";
import FundEscrowBanner from "./FundEscrowBanner";
import FundContract from "./FundContract";
import AcceptCollaborationInvite from "./AcceptCollaborationInvite";
import { listCollaborators } from "services/collaboratorService";
import Loader from "common/Loader";
import HorizontalMain from "components/Tabs/HorizontalMain";
import { initProjectAsync } from "store/project/actions";

const ListProjects = () => {
  const {
    bids_projects: bids,
    draft_projects,
    all_active_projects,
    ads_projects,
    projects,
    loading,
  } = useSelector((state) => state.project);
  const [value, setValue] = useState(0);
  const { active } = useSelector((state) => state.account);
  const [isFundEscrow, setIsFundEscrow] = useState(false);
  const [inviteAccepted, setInviteAccepted] = useState(false);
  const [showAcceptBanner, setShowAcceptBanner] = useState(false);
  const [showFundEscrowBanner, setShowFundEscrowBanner] = useState(false);
  const [groupProjects, setGroupProjects] = useState([]);
  const dispatch = useDispatch();

  const tabChange = (newValue) => {
    setIsFundEscrow(false);
    setValue(newValue);
    console.log();
  };

  const handleFundEscrow = (fund) => {
    setIsFundEscrow(fund);
    setInviteAccepted(false);
    setValue(0);
  };

  const handleAcceptInvite = (is_accepted) => {
    setInviteAccepted(is_accepted);
    setIsFundEscrow(false);
    setValue(0);
    setShowAcceptBanner(false);
    setShowFundEscrowBanner(false);
  };

  const handleSelectedProject = (value) => {
    if (value.project_type === "group" && value.created_by.id !== active.id) {
      setShowAcceptBanner(true);
      setShowFundEscrowBanner(false);
    } else if (value.status === "ongoing" && value.is_onhold) {
      setShowFundEscrowBanner(true);
      setShowAcceptBanner(false);
    } else {
      setShowAcceptBanner(false);
      setShowFundEscrowBanner(false);
    }
  };

  useEffect(() => {
    dispatch(initProjectAsync());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log(projects, loading);
  if (loading) return <Loader />;

  return (
    <>
      {projects.length > 0 && (
        <div className="tw-relative ">
          <div className="tw-bg-dashboard-banner tw-w-full tw-h-[120px] tw-bg-no-repeat tw-bg-cover tw-relative">
            <div className="tw-px-[32px] tw-h-full tw-flex tw-flex-col tw-justify-center">
              {showAcceptBanner && (
                <AcceptCollaborationInvite
                  onAcceptInvite={handleAcceptInvite}
                />
              )}
              {showFundEscrowBanner && (
                <FundEscrowBanner onFundEscrow={handleFundEscrow} />
              )}
              <h3 className="tw-text-xs-bold !tw-text-[24px]">
                {" "}
                Welcome, {active && <span>{active.first_name}</span>}{" "}
              </h3>
            </div>
          </div>

          {/* <div className="lg:tw-px-[32px]">
            <HorizontalMain
              tabs={[]}
              activeTab={value}
              setActiveTab={setValue}
            />
          </div> */}
          <div className="tw-px-[32px] d-flex flex-column">
            <div className="tw-w-full tw-border-b tw-border-[#EAECF0]">
              <div aria-label="tab" className="tw-flex tw-gap-[16px]">
                <button
                  onClick={() => tabChange(0)}
                  className={`${
                    value === 0 && `tw-border-b tw-border-[#EAAA08] `
                  } tw-pb-[4px]`}
                >
                  <div className={`tw-flex`}>
                    <span
                      className={`tw-text-md-semi-bold tw-text-[#667085] ${
                        value === 0 && "!tw-text-[#EAAA08]"
                      }`}
                    >
                      Active
                    </span>
                    <span className="tw-ml-[8px] tw-bg-[#FEFBE8] tw-px-[10px] tw-py-[2px] tw-rounded-full tw-text-[#EAAA08] tw-flex tw-items-center tw-justify-center tw-text-sm-medium">
                      {all_active_projects.length}
                    </span>
                  </div>
                </button>
                <button
                  onClick={() => tabChange(1)}
                  className={`${
                    value === 1 && `tw-border-b tw-border-[#EAAA08]`
                  } tw-pb-[4px] `}
                >
                  <div className={`tw-flex`}>
                    <span
                      className={`tw-text-md-semi-bold tw-text-[#667085] ${
                        value === 1 && "!tw-text-[#EAAA08]"
                      }`}
                    >
                      Ads
                    </span>
                    <span className="tw-ml-[8px] tw-bg-[#FEFBE8] tw-px-[10px] tw-py-[2px] tw-rounded-full tw-text-[#EAAA08] tw-flex tw-items-center tw-justify-center tw-text-sm-medium">
                      {ads_projects.length}
                    </span>
                  </div>
                </button>
                <button
                  onClick={() => tabChange(2)}
                  className={`${
                    value === 2 && `tw-border-b tw-border-[#EAAA08] `
                  } tw-pb-[4px]`}
                >
                  <div className="tw-flex">
                    <span
                      className={`tw-text-md-semi-bold tw-mr-[8px] tw-text-[#667085] ${
                        value === 2 && "!tw-text-[#EAAA08]"
                      }`}
                    >
                      Bids
                    </span>
                    <span className="tw-bg-[#F2F4F7] tw-text-sm-medium  tw-flex tw-items-center tw-justify-center tw-py-[2px] tw-px-[10px] tw-text-red-500 tw-rounded-full">
                      {bids.filter((bid) => bid.status !== "draft").length}
                    </span>
                  </div>
                </button>
                <button
                  onClick={() => tabChange(3)}
                  className={`${
                    value === 3 && `tw-border-b tw-border-[#EAAA08] `
                  } tw-pb-[4px]`}
                >
                  <span
                    className={`tw-text-md-semi-bold tw-text-[#667085] ${
                      value === 3 && "!tw-text-[#EAAA08]"
                    }`}
                  >
                    Drafts
                  </span>
                </button>
                <button
                  onClick={() => tabChange(4)}
                  className={`${
                    value === 4 && `tw-border-b tw-border-[#EAAA08]`
                  } tw-pb-[4px]`}
                >
                  <span
                    className={`tw-text-md-semi-bold tw-text-[#667085] ${
                      value === 4 && "!tw-text-[#EAAA08]"
                    }`}
                  >
                    Completed
                  </span>
                </button>
              </div>
            </div>
            {value === 0 && !isFundEscrow && !inviteAccepted && (
              <ActiveTab
                onSelectedProject={(e) => handleSelectedProject(e)}
                projects={all_active_projects}
              />
            )}
            {/* {value === 0 && isFundEscrow && (<FundContract projects={projects} />)} */}
            {value === 1 && <AdsTab projects={ads_projects} />}
            {value === 2 && (
              <BidsTab bids={bids.filter((bid) => bid.status !== "draft")} />
            )}
            {value === 3 && <DraftsTab projects={draft_projects} />}
            {/* {value === 4 && <CompletedTab projects={completedProjects} />} */}
          </div>
        </div>
      )}
      {projects.length === 0 && <EmptyProject />}
    </>
  );
};

export default ListProjects;
