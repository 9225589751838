import React from "react";
import SettingsCard from "components/SettingsCard";

const settingsRoute = [
  {
    icon: (
      <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M37.3333 6.05176V17.0665C37.3333 18.56 37.3333 19.3067 37.6239 19.8772C37.8796 20.3789 38.2875 20.7869 38.7893 21.0425C39.3597 21.3332 40.1065 21.3332 41.6 21.3332H52.6147M42.6666 34.6663H21.3333M42.6666 45.333H21.3333M26.6666 23.9997H21.3333M37.3333 5.33301H23.4666C18.9862 5.33301 16.746 5.33301 15.0347 6.20496C13.5294 6.97194 12.3056 8.19579 11.5386 9.70108C10.6666 11.4124 10.6666 13.6526 10.6666 18.133V45.8663C10.6666 50.3468 10.6666 52.587 11.5386 54.2983C12.3056 55.8036 13.5294 57.0274 15.0347 57.7944C16.746 58.6663 18.9862 58.6663 23.4666 58.6663H40.5333C45.0137 58.6663 47.2539 58.6663 48.9652 57.7944C50.4705 57.0274 51.6944 55.8036 52.4613 54.2983C53.3333 52.587 53.3333 50.3468 53.3333 45.8663V21.333L37.3333 5.33301Z"
          stroke="#EAAA08"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
    name: "Profile",
    description:
      "Your name, contacts and other relevant information about yourself",
    path: "/workspace/settings/profile",
  },

  {
    icon: (
      <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M24 30.6666L29.3334 36L41.3334 24M53.3334 32C53.3334 45.0892 39.0561 54.609 33.8613 57.6397C33.2709 57.9841 32.9758 58.1563 32.5592 58.2456C32.2359 58.315 31.7642 58.315 31.4409 58.2456C31.0243 58.1563 30.7291 57.9841 30.1387 57.6397C24.9439 54.609 10.6667 45.0892 10.6667 32V19.2469C10.6667 17.1149 10.6667 16.0489 11.0154 15.1325C11.3234 14.323 11.824 13.6007 12.4738 13.0281C13.2093 12.3798 14.2075 12.0055 16.2038 11.2569L30.5019 5.89511C31.0563 5.68722 31.3335 5.58327 31.6186 5.54206C31.8716 5.50551 32.1285 5.50551 32.3814 5.54206C32.6666 5.58327 32.9438 5.68722 33.4981 5.89511L47.7963 11.2569C49.7926 12.0055 50.7907 12.3798 51.5263 13.0281C52.1761 13.6007 52.6766 14.323 52.9847 15.1325C53.3334 16.0489 53.3334 17.1149 53.3334 19.2469V32Z"
          stroke="#EAAA08"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
    name: "Security",
    description: "",
    path: "/workspace/settings/security",
  },

  {
    icon: (
      <svg
        width="57"
        height="53"
        viewBox="0 0 57 53"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M26.3333 34H18.3333C13.3633 34 10.8782 34 8.91802 34.812C6.30439 35.8946 4.22787 37.9711 3.14526 40.5847C2.33331 42.5449 2.33331 45.03 2.33331 50M38.3333 2.77536C42.2424 4.35772 45 8.19016 45 12.6667M28.9996 51.3333L34.3999 50.2533C34.8707 50.1592 35.1062 50.1121 35.3257 50.026C35.5205 49.9496 35.7057 49.8505 35.8774 49.7307C36.0708 49.5958 36.2406 49.4261 36.5801 49.0865L54.3334 31.3335C55.8063 29.8606 55.8062 27.4727 54.3334 25.9999C52.8606 24.5272 50.4728 24.5272 49 26L31.2467 43.7533C30.9072 44.0928 30.7375 44.2625 30.6026 44.4559C30.4828 44.6276 30.3837 44.8127 30.3073 45.0076C30.2212 45.2271 30.1741 45.4625 30.0799 45.9333L28.9996 51.3333ZM33 12.6667C33 18.5577 28.2244 23.3333 22.3333 23.3333C16.4423 23.3333 11.6666 18.5577 11.6666 12.6667C11.6666 6.77563 16.4423 2 22.3333 2C28.2244 2 33 6.77563 33 12.6667Z"
          stroke="#EAAA08"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
    name: "Control & Permissions",
    description: "Manage users access rights",
    path: "/workspace/settings/permissions",
  },

  {
    icon: (
      <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M24.9451 55.9997C26.8255 57.6593 29.2955 58.6663 32.0007 58.6663C34.7059 58.6663 37.1758 57.6593 39.0562 55.9997M6.1177 15.5194C6.07945 11.6491 8.16606 8.035 11.5369 6.133M57.873 15.5194C57.9113 11.6491 55.8247 8.03501 52.4538 6.13302M48.0007 21.333C48.0007 17.0895 46.315 13.0199 43.3144 10.0193C40.3138 7.01872 36.2441 5.33301 32.0007 5.33301C27.7572 5.33301 23.6875 7.01872 20.687 10.0193C17.6864 13.0199 16.0007 17.0895 16.0007 21.333C16.0007 29.5735 13.9219 35.2156 11.5998 38.9474C9.641 42.0953 8.66162 43.6693 8.69753 44.1084C8.73729 44.5945 8.84029 44.7799 9.23207 45.0706C9.58589 45.333 11.1809 45.333 14.3709 45.333H49.6304C52.8204 45.333 54.4154 45.333 54.7693 45.0706C55.161 44.7799 55.264 44.5945 55.3038 44.1084C55.3397 43.6693 54.3603 42.0953 52.4016 38.9474C50.0794 35.2156 48.0007 29.5735 48.0007 21.333Z"
          stroke="#EAAA08"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
    name: "Notifications",
    description: "Notification and alert preference",
    path: "/workspace/settings/notifications",
  },

  {
    icon: (
      <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M50.6666 55.9997V39.9997M42.6666 47.9997H58.6666M58.6666 26.6663H5.33331M58.6666 31.9997V21.8663C58.6666 18.8794 58.6666 17.3859 58.0853 16.2451C57.574 15.2415 56.7581 14.4256 55.7546 13.9143C54.6137 13.333 53.1203 13.333 50.1333 13.333H13.8666C10.8797 13.333 9.38623 13.333 8.24536 13.9143C7.24183 14.4256 6.42594 15.2415 5.91461 16.2451C5.33331 17.3859 5.33331 18.8794 5.33331 21.8663V42.133C5.33331 45.12 5.33331 46.6134 5.91461 47.7543C6.42594 48.7578 7.24183 49.5737 8.24536 50.085C9.38623 50.6663 10.8797 50.6663 13.8666 50.6663H32"
          stroke="#EAAA08"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
    name: "Payment",
    description: "Add and update your payment methods",
    path: "/workspace/settings/payment",
  },
];
const Settings = () => {
  return (
    <div className="tw-relative">
      <div className="tw-bg-dashboard-banner tw-w-full tw-h-[120px] tw-bg-no-repeat tw-bg-cover">
        <div className="tw-p-[32px] tw-h-full tw-flex tw-flex-col tw-justify-center">
          <h3 className="tw-text-xs-bold !tw-text-[24px]">Settings</h3>
        </div>
      </div>

      <div className="tw-p-2 lg:tw-p-[32px]">
        <div className="tw-grid lg:tw-grid-cols-3 tw-grid-cols-1  tw-gap-[30px]  mx-auto">
          {settingsRoute.map((setting, index) => {
            return (
              <SettingsCard
                key={index}
                path={setting.path}
                name={setting.name}
                description={setting.description}
                icon={setting.icon}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Settings;
