/* eslint-disable no-script-url */
import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import styled from "styled-components";
import { Button } from "react-bootstrap";
import { getCurrencySymbol } from "constants/Utils";
import { useSelector } from "react-redux";
import { details } from "services/projectService";
import { listMilestones } from "services/milestoneService";

const itemsPerPage = 5;

const PaidOutTab = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedProject, setSelectedProject] = useState(
    props.activeProjects.length > 0
      ? props.activeProjects[0]
      : props.activeBids[0].project
  );
  const buyer = useSelector((state) => state.buyer.buyer);
  const designer = useSelector((state) => state.designer.designer);
  const [, setTotalFundedAmount] = useState();
  const [escrowBalance, setEscrowBalance] = useState();
  const [totalPaidout, setTotalPaidout] = useState();
  const [paidOutMilestones, setPaidOutMilestones] = useState([]);
  const token = localStorage.getItem("auth_token");
  const totalPages = Math.ceil(paidOutMilestones.length / itemsPerPage);
  const [projectDetails, setProjectDetails] = useState({});

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const format_date = (dateTime) => {
    const options = { month: "short", day: "numeric", year: "numeric" };
    const formattedDate = new Date(dateTime).toLocaleDateString(
      undefined,
      options
    );
    return formattedDate;
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  // const handleProjectChange = (proj) => {
  //   const acceptedBid = proj.bids.find((bid) => bid.is_accepted);
  //   calculateEscrowBalance(acceptedBid);
  //   const fundedMilestones = acceptedBid.milestones.filter(
  //     (milestone) => milestone.is_funded
  //   );

  //   setTotalFundedAmount(
  //     fundedMilestones
  //       .reduce((sum, milestone) => sum + parseFloat(milestone.amount), 0)
  //       .toFixed(2)
  //   );
  //   setSelectedProject(proj);
  // };

  const handleDesignerProjectChange = async (proj) => {
    const resp = await details(proj.id, token);
    const acceptedBid = resp.bids.find((bid) => bid.is_accepted);

    calculateEscrowBalance(acceptedBid);
    const fundedMilestones = acceptedBid.milestones.filter(
      (milestone) => milestone.is_funded
    );

    setTotalFundedAmount(
      fundedMilestones
        .reduce((sum, milestone) => sum + parseFloat(milestone.amount), 0)
        .toFixed(2)
    );
    setSelectedProject(resp);
  };

  const calculateEscrowBalance = async (acceptedBid) => {
    const fundedMilestones = acceptedBid.milestones.filter(
      (milestone) => milestone.is_funded
    );
    const fundedAndCompletedMilestones = acceptedBid.milestones.filter(
      (milestone) => milestone.is_funded && milestone.status === "completed"
    );

    const total_funded = fundedMilestones
      .reduce((sum, milestone) => sum + parseFloat(milestone.amount), 0)
      .toFixed(2);

    const total_funded_and_completed = fundedAndCompletedMilestones
      .reduce((sum, milestone) => sum + parseFloat(milestone.amount), 0)
      .toFixed(2);

    setTotalPaidout(total_funded_and_completed);
    setEscrowBalance((total_funded - total_funded_and_completed).toFixed(2));
  };

  const loadMilestones = async () => {
    if (designer) {
      const resp = await listMilestones("", designer.id, token);
      setPaidOutMilestones(
        resp.filter((milestone) => milestone.status === "completed")
      );
    } else if (buyer) {
      const projectsWithAcceptedBids = props.activeProjects.filter((project) =>
        project.bids.some((bid) => bid.is_accepted)
      );

      const completedMilestones = projectsWithAcceptedBids.map((project) =>
        project.bids
          .filter((bid) => bid.is_accepted)
          .map((acceptedBid) =>
            acceptedBid.milestones.filter(
              (milestone) => milestone.status === "completed"
            )
          )
          .flat()
      );

      const allCompletedMilestones = completedMilestones.flat();
      setPaidOutMilestones(allCompletedMilestones);
    }
  };

  const fetchProjectDetails = async (projectId) => {
    const resp = await details(projectId, token);
    return resp;
  };

  useEffect(() => {
    if (designer) {
      handleDesignerProjectChange(selectedProject);
    }
    loadMilestones();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchProjectDetailsById = async (projectId) => {
      try {
        const project = await fetchProjectDetails(projectId);
        setProjectDetails((prevDetails) => ({
          ...prevDetails,
          [projectId]: project,
        }));
      } catch (error) {
        console.error(
          `Error fetching project details for ID ${projectId}:`,
          error
        );
      }
    };

    paidOutMilestones.forEach((data) => {
      const projectId = data.project;

      if (!projectDetails[projectId]) {
        fetchProjectDetailsById(projectId);
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paidOutMilestones, projectDetails]);

  return (
    <>
      <Div6>
        <Div7>
          <BackImg
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/b0a8e14b-d416-40b2-b3fc-4fa758208f38?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/b0a8e14b-d416-40b2-b3fc-4fa758208f38?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b0a8e14b-d416-40b2-b3fc-4fa758208f38?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/b0a8e14b-d416-40b2-b3fc-4fa758208f38?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/b0a8e14b-d416-40b2-b3fc-4fa758208f38?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b0a8e14b-d416-40b2-b3fc-4fa758208f38?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/b0a8e14b-d416-40b2-b3fc-4fa758208f38?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/b0a8e14b-d416-40b2-b3fc-4fa758208f38?"
          />
          <Div8>
            <A2 href="javascript:void(0)">Escrow Balance</A2>
            <A3 href="javascript:void(0)">
              {`${getCurrencySymbol(
                JSON.parse(selectedProject?.budget)?.currency
              )}`}
              {escrowBalance}
            </A3>
          </Div8>
          <Div9>
            <Div10>
              <A4 href="javascript:void(0)">Active Projects</A4>
              <A5 href="javascript:void(0)">0</A5>
            </Div10>
            {/* <Div11>
              <A6 href="javascript:void(0)">Pending Payments</A6>
              <A7 href="javascript:void(0)">0</A7>
            </Div11> */}
            <Div12>
              <A8 href="javascript:void(0)">Paid Out</A8>
              <A9 href="javascript:void(0)">
                {`${getCurrencySymbol(
                  JSON.parse(selectedProject?.budget)?.currency
                )}`}
                {totalPaidout}
              </A9>
            </Div12>
          </Div9>
        </Div7>
      </Div6>
      <Div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Project Name</TableCell>
                <TableCell align="center">Amount</TableCell>
                <TableCell align="center">Date</TableCell>
                <TableCell align="center">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {buyer && (
                <>
                  {paidOutMilestones.map((data, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <ProjectInfo>
                          <Img
                            loading="lazy"
                            srcSet={
                              props.activeProjects.find(
                                (p) => p.id === data.project
                              ).cover_image || "..."
                            }
                            alt="Project Image"
                          />
                          <ProjectDetails>
                            <ProjectTitle>
                              {
                                props.activeProjects.find(
                                  (p) => p.id === data.project
                                ).title
                              }
                            </ProjectTitle>
                            <ProjectID>
                              @
                              {
                                props.activeProjects.find(
                                  (p) => p.id === data.project
                                ).created_by.slug
                              }
                            </ProjectID>
                          </ProjectDetails>
                        </ProjectInfo>
                      </TableCell>
                      <TableCell align="center">
                        {`${JSON.parse(
                            props.activeProjects.find(
                              (p) => p.id === data.project
                            )?.budget
                          )?.currency}`}
                        {data.amount}
                      </TableCell>
                      <TableCell align="center">{format_date(data.created_at)}</TableCell>
                      <TableCell align="center">
                        <StatusInfo>
                          <StatusImage
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/30c25467-8bb1-4c40-9ac0-0311917018b9?"
                            alt="Paid out"
                          />
                          <StatusText>Paid out</StatusText>
                        </StatusInfo>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              )}
              {designer && (
                <>
                  {paidOutMilestones.map((data, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <ProjectInfo>
                          <Img
                            loading="lazy"
                            srcSet={
                              data.project.cover_image
                                ? data.project.cover_image
                                : "..."
                            }
                            alt="Project Image"
                          />
                          <ProjectDetails>
                            <ProjectTitle>{data.project.title}</ProjectTitle>
                            <ProjectID>
                              @{data.project.created_by.slug}
                            </ProjectID>
                          </ProjectDetails>
                        </ProjectInfo>
                      </TableCell>
                      <TableCell align="center">
                        {`${JSON.parse(data.project?.budget)?.currency}`}
                        {data.amount}
                      </TableCell>
                      <TableCell align="center">{format_date(data.created_at)}</TableCell>
                      <TableCell align="center">
                        <StatusInfo>
                          <StatusImage
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/30c25467-8bb1-4c40-9ac0-0311917018b9?"
                            alt="Paid out"
                          />
                          <StatusText>Paid out</StatusText>
                        </StatusInfo>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              )}
            </TableBody>
          </Table>
          <PaginationContainer>
            <div>
              Page {currentPage} of {totalPages}
            </div>
            <div style={{ display: "flex", gap: 12 }}>
              <Button
                variant="outline-light"
                className="btn-lg rounded-0 text-dark"
                onClick={handlePrevPage}
                disabled={currentPage === 1}
              >
                Previous
              </Button>
              <Button
                variant="outline-light"
                className="btn-lg rounded-0 mx-2 text-dark"
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                Next
              </Button>
            </div>
          </PaginationContainer>
        </TableContainer>
      </Div>
    </>
  );
};

export default PaidOutTab;

const Div6 = styled.div`
  // padding-top: 32px;
  padding-left: 0;
  padding-right: 20px;
  padding-bottom: 32px;
  align-self: stretch;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 206px;
  @media (max-width: 991px) {
    min-height: 113px;
  }
`;

const Div7 = styled.div`
  disply: flex;
  flex-direction: column;
  // padding-top: 32px;
  padding-left: 0;
  padding-right: 20px;
  padding-bottom: 32px;
  align-self: stretch;
  width: 100%;
  display: flex;
  min-height: 206px;
  position: relative;
  @media (max-width: 991px) {
    min-height: 113px;
  }
`;

const BackImg = styled.img`
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const Div8 = styled.div`
  position: relative;
  align-items: center;
  background: var(--gray-25, #fcfcfd);
  width: 100%;
  max-width: 167px;
  margin-left: 2px;
  padding-top: 9px;
  padding-left: 9px;
  padding-right: 9px;
  padding-bottom: 9px;
  align-self: center;
  display: flex;
  flex-direction: column;
`;

const A2 = styled.a`
  color: var(--gray-800, #1d2939);
  text-align: center;
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  margin-top: -3px;
  margin-left: 0px;
  align-self: center;
`;

const A3 = styled.a`
  color: var(--gray-800, #1d2939);
  text-align: center;
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 27px;
  margin-top: 19px;
  margin-bottom: 2px;
  align-self: stretch;
`;

const Div9 = styled.div`
  position: relative;
  justify-content: center;
  align-items: flex-start;
  border-radius: 32px;
  border: 1px solid var(--gray-100, #f2f4f7);
  background: var(--gray-50, #f9fafb);
  width: 50%;
  margin-top: 8px;
  margin-left: 2px;
  padding-top: 6px;
  padding-left: 6px;
  padding-right: 20px;
  padding-bottom: 6px;
  align-self: center;
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
  @media (max-width: 991px) {
    justify-content: center;
  }
`;

const Div10 = styled.div`
  justify-content: center;
  align-items: flex-start;
  border-radius: 6px;
  padding-top: 16px;
  padding-left: 14px;
  padding-right: 20px;
  padding-bottom: 16px;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: 11.6875px;
  flex-wrap: wrap;
`;

const A4 = styled.a`
  color: var(--gray-400, #98a2b3);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  margin-top: 1px;
  align-self: stretch;
`;

const A5 = styled.a`
  color: var(--gray-500, #667085);
  text-align: center;
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  margin-top: 1px;
  align-self: stretch;
`;


const Div12 = styled.div`
  justify-content: center;
  align-items: flex-start;
  border-radius: 6px;
  padding-top: 17px;
  padding-left: 15px;
  padding-right: 20px;
  padding-bottom: 17px;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: 12.0546875px;
  flex-wrap: wrap;
`;

const A8 = styled.a`
  color: var(--gray-400, #98a2b3);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  margin-top: -0px;
  align-self: stretch;
`;

const A9 = styled.a`
  color: var(--gray-500, #667085);
  text-align: center;
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  margin-top: -0px;
  align-self: stretch;
`;

const Div = styled.div`
  align-self: stretch;
  margin-top: 20px;
  border: 1px solid var(--gray-200, #eaecf0);
  background-color: var(--color-neutrals-00, #fff);
  display: flex;
  flex-direction: column;
`;

const ProjectInfo = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  padding: 16px 80px 16px 24px;
`;

const Img = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 40px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
`;

const ProjectDetails = styled.div`
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
`;

const ProjectTitle = styled.div`
  color: var(--gray-900, #101828);
  white-space: nowrap;
  font: 500 14px/20px Inter, sans-serif;
`;

const ProjectID = styled.div`
  color: var(--gray-600, #475467);
  white-space: nowrap;
  font: 400 14px/20px Inter, sans-serif;
`;

const StatusInfo = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 25px 20px;
`;

const StatusImage = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 12px;
  overflow: hidden;
  margin: auto 0;
`;

const StatusText = styled.div`
  color: var(--success-700, #027a48);
  text-align: center;
  align-self: stretch;
  flex-grow: 1;
  white-space: nowrap;
  font: 500 12px/18px Inter, sans-serif;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 14px 24px;
  border-top: 1px solid var(--gray-200, #eaecf0);
`;
