import { storageService } from "services";


export function signOutAsync() {
  return async function (dispatch) {
    try {
      storageService.clearAuthData();
    } catch (e) {}
  };
}
