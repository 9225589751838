import React from "react";

// import { Container } from './styles';

function SelectableCard({ project, setSelected, selected }) {
  return (
    <label htmlFor={project?.label} className={`${selected  === project?.label ? 'tw-bg-yellow-50 tw-border tw-border-yellow-600 ': "tw-border tw-border-[#EAECF0]"} tw-py-[20px] tw-px-[20px] tw-w-full lg:tw-w-[436px]  tw-border-[2px] lg:tw-h-[291px] tw-cursor-pointer`} >
      <div className="tw-flex tw-justify-between">
        <h4 className={`tw-text-[#667085] tw-text-md-semi-bold ${selected  === project?.label  && 'tw-text-yellow-600'}`}>{project?.name}</h4>
        <input
          type="radio"
          name="options"
          value={project?.label}
          id={project.label}
          checked={selected === project?.label}
          
          onChange={setSelected}
          className="  tw-peer
    tw-col-start-1 tw-row-start-1
    tw-appearance-none tw-shrink-0
    tw-w-4 tw-h-4 tw-border-2 tw-border-yellow-500 tw-rounded-full
    focus:tw-outline-none focus:tw-ring-offset-0 focus:tw-ring-2 focus:tw-ring-yellow-400
    disabled:tw-border-gray-400"
        />
      </div>
      <p className={`tw-w-[267px] tw-mt-[16px] tw-text-[#667085] ${selected  === project?.label  && 'tw-text-yellow-600'}`}>{project?.subHeading}</p>
    </label>
  );
}

export default SelectableCard;
