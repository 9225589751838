import Loader from "common/Loader";
import PortfolioCard from "components/PortfolioCard";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { listDesignerPortfolio } from "services/designerService";

export default function MyPortfolioTab() {
  const [portfolios, setPortfolios] = useState([]);
  const { active } = useSelector((state) => state.account);
  const [loading, isLoading] = useState(false);

  useEffect(() => {
    async function fetchDesignerPortfolio() {
      try {
        isLoading(true);
        const response = await listDesignerPortfolio(active.id);
        setPortfolios(response);
      } catch (err) {
      } finally {
        isLoading(false);
      }
    }
    fetchDesignerPortfolio();
  }, [active.id]);
  // fetch portfolio details
  if (loading) return <Loader />;

  return (
    <div className="lg:tw-grid-cols-2 xl:tw-grid-cols-3 tw-grid-cols-1 tw-grid tw-gap-[24px]">
      {portfolios.map((portfolio, i) => {
        return (
          <PortfolioCard
            image={portfolio?.image_url}
            tags={portfolio.tags}
            description={portfolio.description}
          />
        );
      })}
    </div>
  );
}
