function SideNavUser({
  img = "https://ui-avatars.com/api/?background=ccf3ff&color=001F3F&name=Aimes",
  name = "Ukpai Emeka",
  email = "speak2c.emeka@gmail.com",
  logout,
}) {
  //TODO: handle Logout
  return (
    <div className="tw-flex tw-gap-[12px] tw-mb-[32px] tw-mt-[24px]">
      <img
        src={img}
        alt="user"
        className="tw-w-[40px] tw-h-[40px] tw-rounded-[200px] tw-object-cover"
      />
      <div className="tw-flex-col">
        <div className="tw-flex tw-justify-between tw-items-center">
          <h4 className="tw-text-sm-semi-bold">{name}</h4>
          <button onClick={logout}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.3333 14.1667L17.5 10M17.5 10L13.3333 5.83333M17.5 10H7.5M7.5 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V13.5C2.5 14.9001 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9878 3.86502 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5H7.5"
                stroke="#667085"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
        <p className="tw-break-all">{email}</p>
      </div>
    </div>
  );
}

export default SideNavUser;
