import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// import { useAuth } from '../../context/useAuth';

const DropdownUser = ({ img, logout }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  //   const { authData, signOut } = useAuth();
  const navigate = useNavigate();

  const trigger = useRef(null);
  const dropdown = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <div className="tw-relative">
      <Link
        ref={trigger}
        onClick={() => setDropdownOpen(!dropdownOpen)}
        onMouseOver={() => setDropdownOpen(true)}
        className="tw-flex tw-items-center tw-gap-[8px]"
        to="#"
      >
        <span className="tw-h-[32px] tw-w-[32px] tw-rounded-full ">
          <img src={img} alt="User" className="tw-rounded-full tw-h-[32px] tw-w-[32px] tw-object-cover" />
        </span>
        <svg
          className="tw-hidden  sm:tw-block"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 7.5L10 12.5L15 7.5"
            stroke="#344054"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </Link>

      {/* <!-- Dropdown Start --> */}
      <div
        ref={dropdown}
        onFocus={() => setDropdownOpen(true)}
        onBlur={() => setDropdownOpen(false)}
        onMouseLeave={() => setDropdownOpen(false)}
        className={`tw-absolute tw-right-0 tw-mt-4 tw-flex tw-w-62.5 tw-flex-col tw-rounded-sm tw-border tw-border-stroke tw-bg-white tw-shadow-default  ${
          dropdownOpen === true ? "tw-block" : "tw-hidden"
        }`}
      >
        <ul className="tw-flex tw-flex-col tw-gap-5 tw-border-b tw-border-stroke tw-px-6 tw-py-7.5 ">
          <li>
            <Link
              to="/workspace/settings/profile"
              className="tw-flex tw-items-center tw-gap-3.5 tw-text-sm tw-font-medium tw-duration-300 tw-ease-in-out hover:tw-text-primary lg:tw-text-base"
            >
              {/* <svg
                className="fill-current"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 9.62499C8.42188 9.62499 6.35938 7.59687 6.35938 5.12187C6.35938 2.64687 8.42188 0.618744 11 0.618744C13.5781 0.618744 15.6406 2.64687 15.6406 5.12187C15.6406 7.59687 13.5781 9.62499 11 9.62499ZM11 2.16562C9.28125 2.16562 7.90625 3.50624 7.90625 5.12187C7.90625 6.73749 9.28125 8.07812 11 8.07812C12.7188 8.07812 14.0938 6.73749 14.0938 5.12187C14.0938 3.50624 12.7188 2.16562 11 2.16562Z"
                  fill=""
                />
                <path
                  d="M17.7719 21.4156H4.2281C3.5406 21.4156 2.9906 20.8656 2.9906 20.1781V17.0844C2.9906 13.7156 5.7406 10.9656 9.10935 10.9656H12.925C16.2937 10.9656 19.0437 13.7156 19.0437 17.0844V20.1781C19.0094 20.8312 18.4594 21.4156 17.7719 21.4156ZM4.53748 19.8687H17.4969V17.0844C17.4969 14.575 15.4344 12.5125 12.925 12.5125H9.07498C6.5656 12.5125 4.5031 14.575 4.5031 17.0844V19.8687H4.53748Z"
                  fill=""
                />
              </svg> */}
              My Profile
            </Link>
          </li>
        </ul>
        <button
          className="tw-flex tw-items-center tw-gap-3.5 tw-px-6 tw-py-4 tw-text-sm tw-font-medium tw-duration-300 tw-ease-in-out hover:tw-text-primary lg:tw-text-base"
          onClick={logout}
        >
          Log Out
        </button>
      </div>
      {/* <!-- Dropdown End --> */}
    </div>
  );
};

export default DropdownUser;
