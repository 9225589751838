import React from "react";
import styled from "styled-components";

const FundEscrowBanner = (props) => {
  const handleFundEscrow = () => {
    props.onFundEscrow(true);

    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };
  return (
    <Div>
      <Div2>
        <Div3>
          <Img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/4ef309b487dfd5465d0da1ff76fb41de7029ac52ea196322da85224fd90c6ec6?"
          />
          <Span>
            <Div4>This project is on hold </Div4>
            <Div5>fund your escrow to keep the project running</Div5>
          </Span>
        </Div3>
        <Span2 style={{ cursor: "pointer" }} onClick={handleFundEscrow}>
          Fund Escrow
        </Span2>
      </Div2>
    </Div>
  );
};

export default FundEscrowBanner;

const Div = styled.div`
  border: 1px solid var(--Error-300, #fda29b);
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  background-color: var(--Error-50, #fef3f2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px;
`;

const Div2 = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Div3 = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Img = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 48px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
`;

const Span = styled.span`
  align-self: center;
  display: flex;
  padding-right: 80px;
  gap: 6px;
  margin: auto 0;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
    padding-right: 20px;
  }
`;

const Div4 = styled.div`
  color: var(--Primary-700, var(--Primary-700, #a15c07));
  flex-grow: 1;
  white-space: nowrap;
  font: 600 16px/150% Inter, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div5 = styled.div`
  color: var(--Primary-600, #ca8504);
  flex-grow: 1;
  white-space: nowrap;
  font: 400 16px/150% Inter, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Span2 = styled.span`
  color: var(--Base-White, #fff);
  white-space: nowrap;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid var(--Primary-500, #eaaa08);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  background-color: var(--Primary-500, #eaaa08);
  align-self: center;
  margin: auto 0;
  padding: 10px 18px;
  font: 600 16px/150% Inter, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;
