import { format, parse } from "date-fns";
import * as React from "react";
import styled from "styled-components";

const ProjectDetailsTab = ({project}) => {
  // const originalEndDate = parse(project?.end_date, 'yyyy-MM-dd', new Date());
  // const formattedEndDate = format(originalEndDate, 'd MMM, yyyy');
  return (
    <>
      <Div75>
        {project?.title}
      </Div75>
      <Div76>
        <Div77>Delivery location</Div77>
        {/* <Div78>{`${project.city?.name}, ${project.country?.name}`}</Div78> */}
        <Div79>Language</Div79>
        <Div80>
          <Div81>{project?.language.name}</Div81>
          <Div82>{project?.language_level},</Div82>
        </Div80>
        <Div83>Total Jobs</Div83>
        <Div84>16 Jobs</Div84>
        <Div85>Project Deadline</Div85>
        {/* <Div86>{formattedEndDate}</Div86> */}
        <Div87>Years of Experience</Div87>
        {/* <Div88>{project.experience_level?.name}</Div88> */}
        <Div89>Language</Div89>
        <Div90>
          <Div91>
            {/* <Div92>{project.language?.name}</Div92> */}
            {/* <Div93>{project.language_level},</Div93> */}
          </Div91>
          <Div94>
            <Div95>Spanish</Div95>
            <Div96>Basic,</Div96>
          </Div94>
        </Div90>
        <Div97>Clothing types</Div97>
        {/* <Div98>
          <Div99>{project?.gender} wears</Div99>
          {project?.clothing_type.map((item, index) => (
            <Div100 key={index}>{item.name},</Div100>
          ))}
        </Div98> */}
      </Div76>
      <Div103>Additional note</Div103>
      <Div104>
        {/* {project.additional_note} */}
      </Div104>
      <Div105>Styling inspirations</Div105>
      <Div106>Snapshots of your works</Div106>
    </>
  );
};

export default ProjectDetailsTab;

const Div75 = styled.div`
  color: var(--gray-600, #475467);
  margin-top: 16px;
  font: 400 16px/24px Inter, sans-serif;
`;

const Div76 = styled.div`
  align-items: center;
  background-color: var(--gray-50, #f9fafb);
  display: flex;
  margin-top: 24px;
  width: 100%;
  flex-direction: column;
`;

const Div77 = styled.div`
  align-self: stretch;
  color: var(--gray-500, #667085);
  font: 500 14px/20px Inter, sans-serif;
`;

const Div78 = styled.div`
  color: var(--gray-700, #344054);
  align-self: stretch;
  margin-top: 8px;
  white-space: nowrap;
  font: 500 16px/24px Inter, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div79 = styled.div`
  align-self: stretch;
  color: var(--gray-500, #667085);
  margin-top: 24px;
  font: 500 14px/20px Inter, sans-serif;
`;

const Div80 = styled.div`
  align-self: start;
  display: flex;
  margin-top: 8px;
  gap: 8px;
`;

const Div81 = styled.div`
  color: var(--gray-700, #344054);
  white-space: nowrap;
  font: 500 16px/24px Inter, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div82 = styled.div`
  color: var(--gray-500, #667085);
  white-space: nowrap;
  font: 400 16px/24px Inter, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div83 = styled.div`
  align-self: stretch;
  color: var(--gray-500, #667085);
  margin-top: 24px;
  font: 500 14px/20px Inter, sans-serif;
`;

const Div84 = styled.div`
  color: var(--gray-700, #344054);
  align-self: stretch;
  margin-top: 8px;
  white-space: nowrap;
  font: 600 16px/24px Inter, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div85 = styled.div`
  align-self: stretch;
  color: var(--gray-500, #667085);
  margin-top: 24px;
  font: 500 14px/20px Inter, sans-serif;
`;

const Div86 = styled.div`
  color: var(--gray-700, #344054);
  align-self: stretch;
  margin-top: 8px;
  white-space: nowrap;
  font: 600 16px/24px Inter, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div87 = styled.div`
  align-self: stretch;
  color: var(--gray-500, #667085);
  margin-top: 24px;
  font: 500 14px/20px Inter, sans-serif;
`;

const Div88 = styled.div`
  color: var(--gray-700, #344054);
  align-self: stretch;
  margin-top: 8px;
  white-space: nowrap;
  font: 600 16px/24px Inter, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div89 = styled.div`
  color: var(--gray-500, #667085);
  align-self: stretch;
  margin-top: 24px;
  font: 500 14px/20px Inter, sans-serif;
`;

const Div90 = styled.div`
  align-self: stretch;
  display: flex;
  margin-top: 8px;
  justify-content: space-between;
  gap: 16px;
`;

const Div91 = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;

const Div92 = styled.div`
  color: var(--gray-700, #344054);
  white-space: nowrap;
  font: 500 16px/24px Inter, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div93 = styled.div`
  color: var(--gray-500, #667085);
  white-space: nowrap;
  font: 400 16px/24px Inter, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div94 = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;

const Div95 = styled.div`
  color: var(--gray-700, #344054);
  white-space: nowrap;
  font: 500 16px/24px Inter, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div96 = styled.div`
  color: var(--gray-500, #667085);
  white-space: nowrap;
  font: 400 16px/24px Inter, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div97 = styled.div`
  color: var(--gray-500, #667085);
  align-self: stretch;
  margin-top: 24px;
  font: 500 14px/20px Inter, sans-serif;
`;

const Div98 = styled.div`
  align-self: stretch;
  display: flex;
  margin-top: 8px;
  gap: 8px;
  @media (max-width: 991px) {
    justify-content: center;
  }
`;

const Div99 = styled.div`
  color: var(--gray-700, #344054);
  white-space: nowrap;
  font: 500 16px/24px Inter, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div100 = styled.div`
  color: var(--gray-700, #344054);
  font: 500 16px/24px Inter, sans-serif;
`;



const Div103 = styled.div`
  color: var(--gray-900, #101828);
  margin-top: 24px;
  font: 500 16px/24px Inter, sans-serif;
`;

const Div104 = styled.div`
  color: var(--gray-600, #475467);
  margin-top: 8px;
  font: 400 16px/24px Inter, sans-serif;
`;

const Div105 = styled.div`
  color: var(--gray-900, #101828);
  margin-top: 24px;
  font: 600 18px/28px Inter, sans-serif;
`;

const Div106 = styled.div`
  color: var(--gray-600, #475467);
  margin-top: 4px;
  font: 400 14px/20px Inter, sans-serif;
`;
