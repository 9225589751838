import React, { useState } from "react";
import "../Styles/projectType.css";
import WizardCreate from "./Wizard/WizardCreate";
import { useNavigate, useLocation } from "react-router-dom";
import SelectableCard from "components/SelectableCard";
import Wrapper from "./wrapper";

const projects = [
  {
    name: "Solo Project",
    label: "solo",
    subHeading:
      "Tailored to Your Vision: Collaborate with Your Preferred Designer for Personalized Perfection",
  },
  {
    name: "Group Project",
    label: "group",
    subHeading:
      "Unleash Your Collective Vision: Collaborate with Friends for Custom-Tailored Designs",
  },
];

const ProjectType = () => {
  const [selectedOption, setSelectedOption] = useState("solo");
  const [typeIsSelected, setIsTypeSelected] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleNext = () => {
    setIsTypeSelected(true);
  };

  const goToWorkspace = () => {
    navigate("/workspace/projects");
  };

  return (
    <div>
      {location.state ? (
        <WizardCreate
          project_type={location.state.projecttype}
          parent_project={location.state.group_project}
        />
      ) : (
        <>
          {typeIsSelected ? (
            <WizardCreate
              project_type={selectedOption}
              setIsTypeSelected={setIsTypeSelected}
            />
          ) : (
            <Wrapper
              header={
                <div className="lg:tw-p-[32px]">
                  <h2 className="tw-text-xs-bold !tw-text-[24px]">Create new project</h2>
                  <h4 className="tw-mt-[4px] tw-text-sm-regular">Choose Project type</h4>
                </div>
              }
            >
              <div className="tw-px-[10px] lg:tw-px-[32px]">
                <div className="tw-flex-wrap tw-flex  tw-gap-[48px]">
                  {projects.map((project, i) => {
                    return (
                      <SelectableCard
                        key={i}
                        setSelected={handleOptionChange}
                        selected={selectedOption}
                        project={project}
                      />
                    );
                  })}
                </div>
              </div>
              {/*  */}
              <div className="lg:tw-px-[32px]">
                <hr className="tw-mt-10 tw-text-[#EAECF0]" />
                <div className="tw-flex tw-justify-between tw-my-[20px]">
                  <button
                    className="tw-text-sm-semi-bold"
                    onClick={goToWorkspace}
                  >
                    Back
                  </button>
                  <div className="tw-flex tw-gap-[12px] ">
                    <button
                      className="tw-text-sm-semi-bold tw-py-[10px] tw-px-[16px] tw-bg-yellow-500 tw-text-white"
                      onClick={handleNext}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </Wrapper>
          )}
        </>
      )}
    </div>
  );
};

export default ProjectType;
