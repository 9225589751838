import { reducerMultiplexer } from "constants/Utils";
import { ProjectState, INIT_PROJECT, INIT_PROJECT_FAILED, INIT_PROJECT_SUCCESS, CREATE_PROJECT, CREATE_PROJECT_FAILED, CREATE_PROJECT_SUCCESS} from "./actions";

export default function accountReducer(state = ProjectState, action) {
  return reducerMultiplexer({
    [INIT_PROJECT]: (state, action) => {
      return {
        ...state,
        loading: action.payload
      }
    },
    [INIT_PROJECT_FAILED]: (state, action) => {
      return {
        ...state, 
        loading: false,
        error: action.payload
      }
    },
    [INIT_PROJECT_SUCCESS]: (state, action) => {
      return {
        ...state,
        loading: false,
        ...action.payload
      }
    },
    [CREATE_PROJECT]: (state, action) => {
      return {
        ...state,
        loading: action.payload
      }
    },
    [CREATE_PROJECT_FAILED]: (state, action) => {
      return {
        ...state, 
        loading: false,
        error: action.payload
      }
    },
    [CREATE_PROJECT_SUCCESS]: (state, action) => {
      console.log(action.payload)
      return {
        ...state,
        loading: false,
        ...action.payload
      }
    },
  })(state, action);
}
