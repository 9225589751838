import React, { Suspense } from "react";
import {
  Route,
  Routes,
  BrowserRouter as Router,
  useLocation,
  matchPath,
} from "react-router-dom";
import Error404 from "pages/Error404/Error404";
// import TopNav from "components/NavBars/TopNav";
import Loader from "common/Loader";

export const RouteWithSubRoutes = (route) => {
  console.log(route);
  const location = useLocation();

  function matchLoc(pathName) {
    return matchPath(location.pathname, {
      path: pathName,
      exact: false,
      strict: false,
    });
  }
  const excludeList = [
    matchLoc("/admin"),
    matchLoc("/user"),
    matchLoc("/signup"),
    matchLoc("/signin"),
    matchLoc("/invoice"),
    matchLoc("/forgot-password"),
    matchLoc("/check-email"),
    matchLoc("/reset-password"),
    matchLoc("/password-changed"),
    matchLoc("/confirm-email"),
    matchLoc("/email-verified"),
    matchLoc("/onboard"),
    matchLoc("/congratulations"),
    matchLoc("/create-account"),
    matchLoc("/jobs"),
    matchLoc("/designers"),
    matchLoc("/about"),
    matchLoc("/contact"),
    matchLoc("/blog"),
    matchLoc("/google-callback"),
    matchLoc("/facebook-callback"),
    matchLoc("/test"),
  ];
  const isPathActive = excludeList.some(function (v) {
    return v !== null;
  });
  const isRootPath = location.pathname === "/";

  return <Route path={route.path} component={route.component} />;
  // </Route>
};

const RenderRoutes = ({ routes }) => {
  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <Routes>
          {routes.map((route, i) => {
            return (
              <Route path={route.path} element={route.component} key={i}>
                {route.routes &&
                  route.routes.map((childRoute, i) => {
                    return (
                      <Route
                        key={i + childRoute.path}
                        path={childRoute.path}
                        element={childRoute.component}
                      />
                    );
                  })}
              </Route>
            );
          })}
          <Route path={'*'} element={<Error404 />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default RenderRoutes;
