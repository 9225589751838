import React, { useState } from "react";
import {
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  DeleteOutlineRounded,
} from "@mui/icons-material";
import { Button, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import ProfileHolder from "assets/images/icons/user-01.svg";
import styled from "styled-components";

const rowsPerPage = 5; // Number of rows per page

const DesignersTab = ({filteredConnections}) => {
  const [currentPage,] = useState(1);
  const [, setShow] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [, setUserToDelete] = useState(null);
  const {active} = useSelector(state=> state.acount)

  // const handleCloseModal = () => setShow(false);
  const handleShowModal = (objecttoDelete, usertype) => {
    if (objecttoDelete) {
      setUserToDelete({ objecttoDelete, usertype });
      setShow(true);
    }
  }

  const handleUserSelection = (userId) => {
    setSelectedUsers((prevSelected) => {
      if (prevSelected.includes(userId)) {
        return prevSelected.filter((id) => id !== userId);
      } else {
        return [...prevSelected, userId];
      }
    });
  };

  const handleSelectAll = () => {
    const allUserIds = filteredConnections.map((user) => user.id);
    setSelectedUsers(allUserIds);
  };

  // const handleChangePage = (event, newPage) => {
  //   setCurrentPage(newPage);
  // };

  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const displayedUsers = filteredConnections.slice(startIndex, endIndex);

  return (
    <div style={{ border: "1px solid #e0e0e0" }}>
      <TableContainer>
        <Table>
          <TableHead className="bg-secondary">
            <TableRow>
              <TableCell>
                <Checkbox
                  checked={selectedUsers.length === displayedUsers.length}
                  onChange={handleSelectAll}
                />
              </TableCell>
              <TableCell><Typography>Name</Typography></TableCell>
              <TableCell><Typography>Role</Typography></TableCell>
              <TableCell><Typography>Projects</Typography></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {displayedUsers.map((item, index) => (
              <>
                {item.buyer_designer && item.buyer_designer.id !== active?.id ? (
                  <TableRow key={index}>
                    <TableCell>
                      <Checkbox
                        checked={selectedUsers.includes(item.id)}
                        onChange={() => handleUserSelection(item.id)}
                      />
                    </TableCell>
                    <TableCell>
                      <div style={{ display: "flex", alignItems: "left" }}>
                        <CustomAvatar size="40px">
                          <AvatarImage alt={`${item.buyer_designer.first_name}${" "}${item.buyer_designer.last_name}`} src={item.buyer_designer.profile_image || ProfileHolder} />
                        </CustomAvatar>
                        <div style={{ marginLeft: "8px" }}>
                          <Typography>
                            {item.buyer_designer.first_name}{" "}
                            {item.buyer_designer.last_name}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            @{item.buyer_designer.slug}
                          </Typography>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>{item.buyer_designer.user.roles}</TableCell>
                    <TableCell>
                      {/* {item.projects.map((project, index) => (
                  <Chip
                    key={index}
                    label={project}
                    style={{ marginRight: "4px" }}
                  />
                ))} */}
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleShowModal(item, "buyer_designer")}>
                        <DeleteOutlineRounded />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow key={item.id}>
                    <TableCell>
                      <Checkbox
                        checked={selectedUsers.includes(item.id)}
                        onChange={() => handleUserSelection(item.id)}
                      />
                    </TableCell>
                    <TableCell>
                      <div style={{ display: "flex", alignItems: "left" }}>
                        <CustomAvatar size="40px">
                          <AvatarImage alt={`${item.created_by.first_name}${" "}${item.created_by.last_name}`} src={item.created_by.profile_image || ProfileHolder} />
                        </CustomAvatar>
                        <div style={{ marginLeft: "8px" }}>
                          <Typography>
                            {item.created_by.first_name}{" "}
                            {item.created_by.last_name}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            @{item.created_by.slug}
                          </Typography>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>{item.created_by.user.roles}</TableCell>
                    <TableCell>
                      {/* {item.projects.map((project, index) => (
                  <Chip
                    key={index}
                    label={project}
                    style={{ marginRight: "4px" }}
                  />
                ))} */}
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleShowModal(item, "created_by")}>
                        <DeleteOutlineRounded />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )}
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Row className="mb-3 mt-3">
        <Col>
        <div className="mx-2">
        Page {currentPage} of {Math.ceil(filteredConnections.length / rowsPerPage)}
        </div>
          {/* <Pagination
            count={Math.ceil(usersData.length / rowsPerPage)}
            page={currentPage}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
          /> */}
        </Col>
        <Col className="d-flex justify-content-end" style={{ right: 0 }}>
          <Button
            variant="outline-light"
            className="btn-lg rounded-0 text-dark"
          >
            Previous
          </Button>
          <Button
            variant="outline-light"
            className="btn-lg rounded-0 mx-2 text-dark"
          >
            Next
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default DesignersTab;

const CustomAvatar = styled.div`
  width: ${(props) => props.size || '40px'};
  height: ${(props) => props.size || '40px'};
  border-radius: 50%;
  overflow: hidden;
`;

const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;