/* eslint-disable no-script-url */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import ImagePlaceholder from "assets/images/workspace/image-placeholder.png";
import "./Escrow.css";
import { getCurrencySymbol } from "constants/Utils";
import { details } from "services/projectService";

const ActiveProjectsTab = (props) => {
  const [selectedProject, setSelectedProject] = useState(
    props.activeProjects.length > 0
      ? props.activeProjects[0]
      : props.activeBids[0]?.project
  );
  const token = localStorage.getItem("auth_token");
  const buyer = useSelector((state) => state.buyer.buyer);
  const designer = useSelector((state) => state.designer.designer);
  const [totalFundedAmount, setTotalFundedAmount] = useState();
  const [escrowBalance, setEscrowBalance] = useState();
  const [totalPaidout, setTotalPaidout] = useState();

  const openInvoiceWindow = () => {
    // window.navigate.pushState({ selectedProject }, '', '/invoice');
    window.open('/invoice', '_blank');
  };

  const handleProjectChange = (proj) => {
    const acceptedBid = proj.bids.find((bid) => bid.is_accepted);
    calculateEscrowBalance(acceptedBid);
    const fundedMilestones = acceptedBid.milestones.filter(
      (milestone) => milestone.is_funded
    );

    setTotalFundedAmount(
      fundedMilestones
        .reduce((sum, milestone) => sum + parseFloat(milestone.amount), 0)
        .toFixed(2)
    );
    setSelectedProject(proj);
  };

  const handleDesignerProjectChange = async (proj) => {
    const resp = await details(proj.id, token);
    const acceptedBid = resp.bids.find((bid) => bid.is_accepted);

    calculateEscrowBalance(acceptedBid);
    const fundedMilestones = acceptedBid.milestones.filter(
      (milestone) => milestone.is_funded
    );

    setTotalFundedAmount(
      fundedMilestones
        .reduce((sum, milestone) => sum + parseFloat(milestone.amount), 0)
        .toFixed(2)
    );
    setSelectedProject(resp);
  };

  const calculateEscrowBalance = async (acceptedBid) => {
    const fundedMilestones = acceptedBid.milestones.filter(
      (milestone) => milestone.is_funded
    );
    const fundedAndCompletedMilestones = acceptedBid.milestones.filter(
      (milestone) => milestone.is_funded && milestone.status === "completed"
    );

    const total_funded = fundedMilestones
      .reduce((sum, milestone) => sum + parseFloat(milestone.amount), 0)
      .toFixed(2);

    const total_funded_and_completed = fundedAndCompletedMilestones
      .reduce((sum, milestone) => sum + parseFloat(milestone.amount), 0)
      .toFixed(2);

    setTotalPaidout(total_funded_and_completed);
    setEscrowBalance((total_funded - total_funded_and_completed).toFixed(2));
  };

  useEffect(() => {
    if (designer) {
      handleDesignerProjectChange(selectedProject);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Div16>
        <Div17>
          {buyer && (
            <>
              {props.activeProjects.map((project, index) => (
                <Column key={index} style={{ cursor: "pointer" }}>
                  <Div18
                    className={`${selectedProject === project
                        ? "selected-col"
                        : "unselected-col"
                      }`}
                    onClick={() => handleProjectChange(project)}
                  >
                    <Img2
                      loading="lazy"
                      srcSet={
                        project.cover_image
                          ? project.cover_image
                          : ImagePlaceholder
                      }
                    />
                  </Div18>
                </Column>
              ))}
            </>
          )}
          {designer && (
            <>
              {props.activeBids.map((bid, index) => (
                <Column key={index} style={{ cursor: "pointer" }}>
                  <Div18
                    className={`${selectedProject === bid.project
                        ? "selected-col"
                        : "unselected-col"
                      }`}
                    onClick={() => handleDesignerProjectChange(bid.project)}
                  >
                    <Img2
                      loading="lazy"
                      srcSet={
                        bid.project.cover_image
                          ? bid.project.cover_image
                          : ImagePlaceholder
                      }
                    />
                  </Div18>
                </Column>
              ))}
            </>
          )}
        </Div17>
      </Div16>
      <Div24>
        <A14 href="javascript:void(0)">{selectedProject?.title} escrow</A14>
        <Div25>
          <span style={{ color: "rgba(208,213,221,1)" }}>with</span>
          <span style={{ color: "rgba(102,112,133,1)" }}> Prince Emanuel</span>
        </Div25>
      </Div24>
      <Div6>
        <Div7>
          <Img
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/b0a8e14b-d416-40b2-b3fc-4fa758208f38?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/b0a8e14b-d416-40b2-b3fc-4fa758208f38?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b0a8e14b-d416-40b2-b3fc-4fa758208f38?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/b0a8e14b-d416-40b2-b3fc-4fa758208f38?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/b0a8e14b-d416-40b2-b3fc-4fa758208f38?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b0a8e14b-d416-40b2-b3fc-4fa758208f38?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/b0a8e14b-d416-40b2-b3fc-4fa758208f38?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/b0a8e14b-d416-40b2-b3fc-4fa758208f38?"
          />
          <Div8>
            <A2 href="javascript:void(0)">Escrow Balance</A2>
            <A3 href="javascript:void(0)">
              {`${selectedProject && JSON.parse(selectedProject.budget)?.currency
                ? getCurrencySymbol(JSON.parse(selectedProject.budget).currency)
                : ''}`}
              {escrowBalance}
            </A3>
          </Div8>
          {/* <Div9>
            <Div10>
              <A4 href="javascript:void(0)">Active Projects</A4>
              <A5 href="javascript:void(0)">0</A5>
            </Div10>
            <Div11>
              <A6 href="javascript:void(0)">Pending Payments</A6>
              <A7 href="javascript:void(0)">0</A7>
            </Div11>
            <Div12>
              <A8 href="javascript:void(0)">Paid Out</A8>
              <A9 href="javascript:void(0)">{`${getCurrencySymbol(JSON.parse(selectedProject?.budget)?.currency)}`}{totalPaidout}</A9>
            </Div12>
          </Div9> */}
        </Div7>
      </Div6>
      <Div20>
        <Div21>
          <Div22>
            <Column3>
              <Div23>
                <Div26>
                  <Div27>
                    <Div28>
                      <Div29>
                        <Div30>
                          <A15 href="javascript:void(0)">Paid Out</A15>
                        </Div30>
                        <A16 href="javascript:void(0)">
                          {`${getCurrencySymbol(
                            JSON.parse(selectedProject?.budget)?.currency
                          )}`}
                          {totalPaidout}
                        </A16>
                      </Div29>
                      <Div31>
                        <>
                          {selectedProject.bids && (
                            <>
                              {selectedProject.bids
                                .filter((bid) => bid.is_accepted)
                                .map((acceptedBid) => (
                                  <>
                                    {acceptedBid.milestones
                                      .slice()
                                      .sort(
                                        (a, b) =>
                                          new Date(a.created_at) -
                                          new Date(b.created_at)
                                      )
                                      .map((milestone, index) => (
                                        <Div32 key={index}>
                                          <A17 href="javascript:void(0)">
                                            {milestone.name}
                                          </A17>
                                          <A18
                                            href="javascript:void(0)"
                                            className={
                                              milestone.status === "completed"
                                                ? "crossed-out-text"
                                                : ""
                                            }
                                          >
                                            {`${getCurrencySymbol(
                                              JSON.parse(
                                                selectedProject?.budget
                                              )?.currency
                                            )}`}
                                            {milestone.amount}
                                          </A18>
                                        </Div32>
                                      ))}
                                  </>
                                ))}
                            </>
                          )}
                        </>
                        <Div35>
                          <A23 href="javascript:void(0)">
                            Delivery Milestone
                          </A23>
                          <A24 href="javascript:void(0)">
                            {`${getCurrencySymbol(
                              JSON.parse(selectedProject?.budget)?.currency
                            )}`}
                            0.00
                          </A24>
                        </Div35>
                      </Div31>
                    </Div28>
                  </Div27>
                  <Div36>
                    <Div37>
                      <A25 href="javascript:void(0)">Funded Amount</A25>
                    </Div37>
                    <A26 href="javascript:void(0)">
                      {`${getCurrencySymbol(
                        JSON.parse(selectedProject?.budget)?.currency
                      )}`}
                      {totalFundedAmount}
                    </A26>
                  </Div36>
                  <Div39>
                    <Div40>
                      <A27 href="javascript:void(0)">Project Price</A27>
                    </Div40>
                    <A28 href="javascript:void(0)">
                      {`${getCurrencySymbol(
                        JSON.parse(selectedProject?.budget)?.currency
                      )}`}
                      {`${JSON.parse(selectedProject?.budget)?.amount}`}
                    </A28>
                  </Div39>
                </Div26>
              </Div23>
            </Column3>
            <Column4>
              <Img5
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/14d68c05-7b5c-49ac-8eb9-003fcc49e481?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/14d68c05-7b5c-49ac-8eb9-003fcc49e481?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/14d68c05-7b5c-49ac-8eb9-003fcc49e481?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/14d68c05-7b5c-49ac-8eb9-003fcc49e481?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/14d68c05-7b5c-49ac-8eb9-003fcc49e481?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/14d68c05-7b5c-49ac-8eb9-003fcc49e481?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/14d68c05-7b5c-49ac-8eb9-003fcc49e481?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/14d68c05-7b5c-49ac-8eb9-003fcc49e481?"
              />
            </Column4>
            <Column5>
              <Img6
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/75adb6ef-c6b7-474f-8d97-24a7d04a609c?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/75adb6ef-c6b7-474f-8d97-24a7d04a609c?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/75adb6ef-c6b7-474f-8d97-24a7d04a609c?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/75adb6ef-c6b7-474f-8d97-24a7d04a609c?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/75adb6ef-c6b7-474f-8d97-24a7d04a609c?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/75adb6ef-c6b7-474f-8d97-24a7d04a609c?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/75adb6ef-c6b7-474f-8d97-24a7d04a609c?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/75adb6ef-c6b7-474f-8d97-24a7d04a609c?"
              />
            </Column5>
            <Column6>
              <Div42>
                <Img7
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/fd0c6470-51cb-4764-a54a-d6c643fae8a1?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/fd0c6470-51cb-4764-a54a-d6c643fae8a1?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/fd0c6470-51cb-4764-a54a-d6c643fae8a1?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/fd0c6470-51cb-4764-a54a-d6c643fae8a1?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/fd0c6470-51cb-4764-a54a-d6c643fae8a1?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/fd0c6470-51cb-4764-a54a-d6c643fae8a1?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/fd0c6470-51cb-4764-a54a-d6c643fae8a1?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/fd0c6470-51cb-4764-a54a-d6c643fae8a1?"
                />
                <button
                  type="button"
                  onClick={openInvoiceWindow}
                  className="btn-print-invoice"
                >
                  Invoice
                </button>
              </Div42>
            </Column6>
          </Div22>
        </Div21>
      </Div20>
    </>
  );
};

export default ActiveProjectsTab;

const Div6 = styled.div`
  // padding-top: 32px;
  padding-left: 0;
  padding-right: 20px;
  padding-bottom: 32px;
  align-self: stretch;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 206px;
  @media (max-width: 991px) {
    min-height: 113px;
  }
`;

const Div7 = styled.div`
  disply: flex;
  flex-direction: column;
  // padding-top: 32px;
  padding-left: 0;
  padding-right: 20px;
  padding-bottom: 32px;
  align-self: stretch;
  width: 100%;
  display: flex;
  min-height: 206px;
  position: relative;
  @media (max-width: 991px) {
    min-height: 113px;
  }
`;

const Img = styled.img`
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const Div8 = styled.div`
  position: relative;
  align-items: center;
  background: var(--gray-25, #fcfcfd);
  width: 100%;
  max-width: 167px;
  margin-left: 2px;
  margin-top: 5%;
  padding-top: 9px;
  padding-left: 9px;
  padding-right: 9px;
  padding-bottom: 9px;
  align-self: center;
  display: flex;
  flex-direction: column;
`;

const A2 = styled.a`
  color: var(--gray-800, #1d2939);
  text-align: center;
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  margin-top: -3px;
  margin-left: 0px;
  align-self: center;
`;

const A3 = styled.a`
  color: var(--gray-800, #1d2939);
  text-align: center;
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 27px;
  margin-top: 19px;
  margin-bottom: 2px;
  align-self: stretch;
`;


const Div16 = styled.div`
  width: 100%;
  max-width: 701px;
  margin-top: 20px;
  padding-right: 20px;
  align-self: start;
  display: flex;
  flex-direction: column;
`;

const Div17 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 50%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div18 = styled.div`
  align-items: center;
  background: var(--gray-100, #f2f4f7);
  display: flex;
  flex-direction: column;
  @media (max-width: 991px) {
    margin-top: 29px;
  }
`;

const Img2 = styled.img`
  aspect-ratio: 16 / 9;
  object-fit: cover;
  object-position: center;
  width: 100%;
  align-self: center;
`;

const Div20 = styled.div`
  border: 1px solid var(--gray-200, #eaecf0);
  margin-top: 20px;
  padding-top: 1px;
  padding-left: 2px;
  padding-right: 16px;
  padding-bottom: 1px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
`;

const Div21 = styled.div`
  margin-top: -1px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
`;

const Div22 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column3 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 53%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div23 = styled.div`
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  @media (max-width: 991px) {
    margin-top: 50px;
  }
`;

const Div24 = styled.div`
  align-items: flex-start;
  width: 100%;
  max-width: 243px;
  padding-top: 14px;
  padding-left: 8px;
  padding-right: 20px;
  padding-bottom: 14px;
  align-self: start;
  display: flex;
  flex-direction: column;
`;

const A14 = styled.a`
  color: var(--gray-800, #1d2939);
  text-align: center;
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  margin-top: 0px;
  margin-left: 1px;
  align-self: start;
  @media (max-width: 991px) {
    margin-left: 1px;
  }
`;

const Div25 = styled.div`
  color: var(--gray-500, #667085);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-top: 16px;
  margin-bottom: -0px;
  align-self: start;
`;

const Div26 = styled.div`
  align-items: flex-start;
  margin-top: 20px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
`;

const Div27 = styled.div`
  align-items: flex-start;
  background: var(--gray-50, #f9fafb);
  padding-top: 11px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 11px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
`;

const Div28 = styled.div`
  align-items: flex-end;
  margin-top: 1px;
  margin-bottom: -1px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
`;

const Div29 = styled.div`
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
`;

const Div30 = styled.div`
  align-items: flex-start;
  padding-top: 2px;
  padding-right: 20px;
  padding-bottom: 2px;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: 12.4px;
  flex-wrap: wrap;
  min-width: 96px;
`;

const A15 = styled.a`
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-top: 0px;
  align-self: center;
  text-align: center;
`;

const A16 = styled.a`
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-top: 1px;
  align-self: center;
  text-align: center;
  min-width: 63px;
`;

const Div31 = styled.div`
  align-items: flex-start;
  width: 100%;
  padding-top: 4px;
  padding-bottom: 4px;
  align-self: start;
  display: flex;
  flex-direction: column;
`;

const Div32 = styled.div`
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 3px;
  padding-left: 1px;
  padding-right: 20px;
  padding-bottom: 3px;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
`;

const A17 = styled.a`
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  margin-top: 1.5px;
  align-self: stretch;
  min-width: 96px;
`;

const A18 = styled.a`
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  margin-top: 1px;
  align-self: stretch;
  min-width: 54px;
`;

const Div35 = styled.div`
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 4px;
  padding-top: 3px;
  padding-left: 1px;
  padding-right: 20px;
  padding-bottom: 3px;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
`;

const A23 = styled.a`
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  margin-top: 1.5px;
  align-self: stretch;
  min-width: 124px;
`;

const A24 = styled.a`
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  margin-top: 1px;
  align-self: start;
  min-width: 46px;
`;

const Div36 = styled.div`
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 16px;
  padding-right: 20px;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
`;

const Div37 = styled.div`
  align-items: flex-start;
  padding-top: 2px;
  padding-right: 20px;
  padding-bottom: 2px;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: 12.4px;
  flex-wrap: wrap;
  min-width: 151px;
`;

const A25 = styled.a`
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-top: 0px;
  align-self: center;
  text-align: center;
`;

const A26 = styled.a`
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-top: 1px;
  align-self: center;
  text-align: center;
  min-width: 63px;
`;

const Div39 = styled.div`
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 16px;
  padding-right: 20px;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
`;

const Div40 = styled.div`
  align-items: flex-start;
  padding-top: 2px;
  padding-right: 20px;
  padding-bottom: 2px;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: 12.40625px;
  flex-wrap: wrap;
  min-width: 127px;
`;


const A27 = styled.a`
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-top: 4px;
  align-self: start;
`;

const A28 = styled.a`
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-top: 1px;
  align-self: center;
  text-align: center;
  min-width: 63.5px;
`;

const Column4 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 1%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Img5 = styled.img`
  aspect-ratio: 0;
  object-fit: cover;
  object-position: center;
  width: 1px;
  @media (max-width: 991px) {
    margin-top: 36px;
  }
`;

const Column5 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 22%;
  margin-left: 20px;
  align-self: end;
  margin-bottom: 10px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Img6 = styled.img`
  aspect-ratio: 0.74;
  object-fit: cover;
  object-position: center;
  width: 204px;
  margin-top: 70px;
  @media (max-width: 991px) {
    margin-top: 50px;
  }
`;

const Column6 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 25%;
  margin-left: 20px;
  align-self: end;
  margin-bottom: 10px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div42 = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  @media (max-width: 991px) {
    margin-top: 50px;
  }
`;

const Img7 = styled.img`
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 100%;
  max-width: 24px;
  align-self: end;
`;
