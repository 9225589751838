
const Loader = () => {

  return (
    <div
      className={`tw-flex  tw-w-full tw-h-screen tw-items-center tw-justify-center tw-bg-white`} // Use opacity class based on adjusted value
    >
      <div className="tw-h-20 tw-w-20 tw-animate-spin tw-rounded-full tw-border-4 tw-border-solid tw-border-yellow-400 tw-border-t-transparent"></div>
    </div>
  );
};

export default Loader;