import React from "react";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import User011 from "assets/images/icons/user-01.svg";
import "./Styles/bidsTab.css";
import BuyerBidCard from "components/Bids/BuyerBidCard";

const BidsTab = ({ bids }) => {
  const format_date = (date) => {
    const mydatetime = new Date(date);
    const formattedDate = format(mydatetime, "dd MMM, yyyy");
    return formattedDate;
  };
  const navigate = useNavigate();

  const handleViewProposal = (bid) => {
    navigate(`/workspace/projects/${bid.project.id}/bid/${bid.id}`);
  };

  return (
    <div className="">
      {bids.map((bid, index) => {
        return (
          <BuyerBidCard bid={bid} onClick={handleViewProposal} key={index} />
        );
      })}
    </div>
  );
};

export default BidsTab;
