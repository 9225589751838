import * as React from "react";
import styled from "styled-components";

const FavoritesTab = () => {
  return (
    <Div>
      <Div2>
        No Favorites
        {/* <Div3>
          <Column>
            <Div4>
              <Div5>
                <Column2>
                  <Img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/883fe544-ef96-4b76-bbd1-c7d898da357a?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/883fe544-ef96-4b76-bbd1-c7d898da357a?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/883fe544-ef96-4b76-bbd1-c7d898da357a?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/883fe544-ef96-4b76-bbd1-c7d898da357a?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/883fe544-ef96-4b76-bbd1-c7d898da357a?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/883fe544-ef96-4b76-bbd1-c7d898da357a?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/883fe544-ef96-4b76-bbd1-c7d898da357a?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/883fe544-ef96-4b76-bbd1-c7d898da357a?"
                  />
                </Column2>
                <Column3>
                  <Div6>
                    <A href="#">Prince Emmanuel</A>
                    <Div7>
                      <A2 href="#">Nigeria </A2>
                      <Img2
                        loading="lazy"
                        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/0eefeae6-14a7-4286-89b7-6769d31bd717?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/0eefeae6-14a7-4286-89b7-6769d31bd717?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/0eefeae6-14a7-4286-89b7-6769d31bd717?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/0eefeae6-14a7-4286-89b7-6769d31bd717?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/0eefeae6-14a7-4286-89b7-6769d31bd717?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/0eefeae6-14a7-4286-89b7-6769d31bd717?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/0eefeae6-14a7-4286-89b7-6769d31bd717?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/0eefeae6-14a7-4286-89b7-6769d31bd717?"
                      />
                      <A3 href="#">$50 - 60k (USD)</A3>
                    </Div7>
                    <A4 href="#">Available</A4>
                    <Div8>
                      <Div9>
                        <span style={{fontFamily: "Inter, sans-serif", color: "rgba(52,64,84,1)"}}>
                          3
                        </span>
                        <span style={{fontFamily: "Inter, sans-serif", color: "rgba(52,64,84,1)"}}>
                          {" "}
                        </span>
                        <span style={{fontFamily: "Inter, sans-serif", color: "rgba(152,162,179,1)"}}>
                          Jobs
                        </span>
                      </Div9>
                      <Div10>
                        <span style={{fontFamily: "Inter, sans-serif", color: "rgba(52,64,84,1)"}}>
                          100%
                        </span>
                        <span style={{fontFamily: "Inter, sans-serif", color: "rgba(52,64,84,1)"}}>
                          {" "}
                        </span>
                        <span style={{fontFamily: "Inter, sans-serif", color: "rgba(152,162,179,1)"}}>
                          success rate
                        </span>
                      </Div10>
                      <Div11>
                        <span style={{fontFamily: "Inter, sans-serif", color: "rgba(52,64,84,1)"}}>
                          9
                        </span>
                        <span style={{fontFamily: "Inter, sans-serif", color: "rgba(52,64,84,1)"}}>
                          {" "}
                        </span>
                        <span style={{fontFamily: "Inter, sans-serif", color: "rgba(152,162,179,1)"}}>
                          reviews
                        </span>
                      </Div11>
                    </Div8>
                  </Div6>
                </Column3>
              </Div5>
            </Div4>
          </Column>
          <Column4>
            <Div12>
              <Div13>
                <Column5>
                  <Img3
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/80e039e8-5d56-4d00-a97f-f130f9cf7c71?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/80e039e8-5d56-4d00-a97f-f130f9cf7c71?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/80e039e8-5d56-4d00-a97f-f130f9cf7c71?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/80e039e8-5d56-4d00-a97f-f130f9cf7c71?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/80e039e8-5d56-4d00-a97f-f130f9cf7c71?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/80e039e8-5d56-4d00-a97f-f130f9cf7c71?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/80e039e8-5d56-4d00-a97f-f130f9cf7c71?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/80e039e8-5d56-4d00-a97f-f130f9cf7c71?"
                  />
                </Column5>
                <Column5>
                  <Img3
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/80e039e8-5d56-4d00-a97f-f130f9cf7c71?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/80e039e8-5d56-4d00-a97f-f130f9cf7c71?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/80e039e8-5d56-4d00-a97f-f130f9cf7c71?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/80e039e8-5d56-4d00-a97f-f130f9cf7c71?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/80e039e8-5d56-4d00-a97f-f130f9cf7c71?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/80e039e8-5d56-4d00-a97f-f130f9cf7c71?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/80e039e8-5d56-4d00-a97f-f130f9cf7c71?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/80e039e8-5d56-4d00-a97f-f130f9cf7c71?"
                  />
                </Column5>
                <Column5>
                  <Img3
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/80e039e8-5d56-4d00-a97f-f130f9cf7c71?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/80e039e8-5d56-4d00-a97f-f130f9cf7c71?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/80e039e8-5d56-4d00-a97f-f130f9cf7c71?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/80e039e8-5d56-4d00-a97f-f130f9cf7c71?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/80e039e8-5d56-4d00-a97f-f130f9cf7c71?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/80e039e8-5d56-4d00-a97f-f130f9cf7c71?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/80e039e8-5d56-4d00-a97f-f130f9cf7c71?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/80e039e8-5d56-4d00-a97f-f130f9cf7c71?"
                  />
                </Column5>
              </Div13>
            </Div12>
          </Column4>
        </Div3> */}
      </Div2>
    </Div>
  );
}
export default FavoritesTab;

const Div = styled.div`
  border: 2px solid var(--gray-200, #eaecf0);
  background: var(--gray-25, #fcfcfd);
  width: 100%;
  padding-top: 13px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  align-self: end;
  display: flex;
  flex-direction: column;
`;

const Div2 = styled.div`
  width: 100%;
  margin-left: 10px;
  align-self: start;
  display: flex;
  flex-direction: column;
  @media (max-width: 991px) {
    margin-left: 10px;
  }
`;
