import React from "react";
import useWindowSize from "hooks/useWindowSize";

export default function HorizontalTab({ tabs, activeTab, setActiveTab }) {
  const { width } = useWindowSize();
  const isMobile = width <= 768;

  return (
    <div className="tw-w-full">
      {isMobile ? (
        <select
          className="tw-w-full tw-py-[10px] tw-px-[14px] tw-outline-none tw-border tw-border-[#D0D5DD] tw-text-[#101828] tw-text-md-semi-bold"
          value={activeTab}
          onChange={(e) => setActiveTab(e.target.value)}
        >
          {tabs.map((tab, i) => (
            <option key={i} value={tab.key}>
              {tab.name}
            </option>
          ))}
        </select>
      ) : (
        <div className="tw-bg-[#F9FAFB] tw-border tw-border-[#F2F4F7] tw-p-[6px] tw-w-full tw-flex">
          {tabs.map((tab, i) => (
            <button
              key={i}
              type="button"
              onClick={() => setActiveTab(tab.key)}
              className={`tw-py-[10px] tw-px-[14px] tw-shadow-sm tw-flex-1 tw-bottom-0 tw-outline-none tw-text-[#667085] tw-text-md-semi-bold ${
                activeTab === tab.key && "tw-bg-white !tw-text-[#344054]"
              }`}
            >
              {tab.name}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}
