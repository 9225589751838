import React, { useState, useEffect, useCallback } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Form, Col, Button } from "react-bootstrap";
import { listBuyers } from "services/buyerService";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  connectionsFailure,
  connectionsSuccess,
} from "store/actions/connectionsActions";
import { listDesigners } from "services/designerService";
import CopyIcon from "assets/images/icons/copy-icon.svg";
import { createConnection } from "services/connectionsServices";

const validationSchema = Yup.object().shape({
  connections: Yup.array(),
});

const InviteToOnboard = ({ props, onHide }) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [, setAllUsers] = useState([]);
  const [newEmail, setNewEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const dispatch = useDispatch();
  const [, setIsLinkCopied] = useState(false);
  const { active } = useSelector((state) => state.account);

  const getBuyers = useCallback(async () => {
    try {
      const resp = await listBuyers();
      const filteredBuyers = resp.filter((buyer) => buyer?.id !== active?.id);
      setAllUsers((prevUsers) => [...prevUsers, ...filteredBuyers]);
    } catch (err) {
      console.error(err);
    }
  }, [active?.id]);

  const getDesigners = useCallback(async () => {
    try {
      const resp = await listDesigners();
      const filteredDesigners = resp.filter(
        (designer) => designer?.id !== active?.id
      );
      setAllUsers((prevUsers) => [...prevUsers, ...filteredDesigners]);
    } catch (err) {
      console.error(err);
    }
  }, [active?.id]);

  // const handleSelectedUser = (event, selectedOption) => {
  //   if (selectedOption) {
  //     setSelectedUsers([...selectedUsers, selectedOption]);
  //     setNewTag("");
  //     setAllUsers((prevUsers) =>
  //       prevUsers.filter((user) => user.id !== selectedOption.id)
  //     );
  //   }
  // };

  // const handleRemoveTag = (userId) => {
  //   const updatedSelectedUsers = selectedUsers.filter(
  //     (user) => user.id !== userId
  //   );
  //   setSelectedUsers(updatedSelectedUsers);
  // };

  // const handleAddTag = () => {
  //   if (newTag.trim() !== "") {
  //     setSelectedUsers([...selectedUsers, { id: Date.now(), slug: newTag }]);
  //     setNewTag("");
  //   }
  // };
  const formik = useFormik({
    initialValues: {
      created_by: "",
      connections: [],
    },
    validationSchema,
    onSubmit: async ({ resetForm }) => {
      const connections_data = {
        created_by: active.id,
        connections: selectedUsers.map((user) => user.id),
      };
      try {
        const resp = await createConnection(connections_data);
        dispatch(connectionsSuccess(resp));
        resetForm();
      } catch (err) {
        dispatch(connectionsFailure(err.message));
      }
    },
  });

  const handleRemoveEmail = (email) => {
    const updatedSelectedUsers = selectedUsers.filter(
      (user) => user.email !== email
    );
    setSelectedUsers(updatedSelectedUsers);
  };

  const handleAddEmail = () => {
    if (isValidEmail(newEmail)) {
      if (!selectedUsers.some((user) => user.email === newEmail)) {
        setSelectedUsers([...selectedUsers, { email: newEmail }]);
        setNewEmail("");
        setEmailError("");
      } else {
        setEmailError("This email is already in the list.");
      }
    } else {
      setEmailError("Please enter a valid email address.");
    }
  };

  const handleEnterKey = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAddEmail();
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleCopyLink = () => {
    const linkToCopy = "http://beta.umojaproduction.com";
    navigator.clipboard
      .writeText(linkToCopy)
      .then(() => {
        setIsLinkCopied(true);
        setTimeout(() => {
          setIsLinkCopied(false);
        }, 3000);
      })
      .catch((err) => {
        console.error("Unable to copy link: ", err);
      });
  };

  useEffect(() => {
    Promise.allSettled([getBuyers(), getDesigners()]);
  }, [getBuyers, getDesigners]);

  return (
    <div className="w-full">
      <Form onSubmit={formik.handleSubmit}>
        <Div2>
          <Div3>
            <Div4>
              <Img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/7de9c2ae-026a-4467-9809-63f572c5c4f0?"
              />
              <Img2
                style={{ cursor: "pointer" }}
                onClick={onHide}
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/55ef8dc5-44f2-4cc4-8cd1-3fdd9677d339?"
              />
            </Div4>
            <Div5>
              <Div6>Invite to onboard</Div6>
              <Div7>Invite family and friends to join you using umojalin</Div7>
            </Div5>
          </Div3>
        </Div2>
        <Div8>
          <Div9>
            <Div10>Email address</Div10>
            {selectedUsers.map((item, index) => (
              <Div11 key={index}>
                <Div12>
                  <Form.Control
                    type="text"
                    placeholder="you@umojalinn.com"
                    className="add-tag-field border-0"
                    value={item.email}
                  />
                  <Col>
                    <Button
                      variant="danger"
                      className="btn btn-small"
                      onClick={() => handleRemoveEmail(item.email)}
                    >
                      x
                    </Button>
                  </Col>
                </Div12>
              </Div11>
            ))}
            <Div11>
              <Div12>
                <Form.Control
                  type="text"
                  placeholder="you@umojalinn.com"
                  className={`add-tag-field form-control-md border-0  w-full ${
                    emailError && "is-invalid"
                  }`}
                  value={newEmail}
                  onChange={(e) => {
                    setNewEmail(e.target.value);
                    setEmailError("");
                  }}
                  onKeyDown={handleEnterKey}
                />
              </Div12>
              {emailError && (
                <div className="invalid-feedback">{emailError}</div>
              )}
            </Div11>
          </Div9>
          <Div17 className="tw-flex">
            <button
              className="add-another-btn tw-flex tw-items-center tw-gap-[8px]"
              onClick={handleCopyLink}
            >
              <img loading="lazy" src={CopyIcon} alt="umojalin" /> Copy link
            </button>
          </Div17>
        </Div8>
        <Div19>
          <button className="btn-send-invite">Send invites</button>
        </Div19>
      </Form>
    </div>
  );
};

export default InviteToOnboard;

const Div2 = styled.div`
  align-items: center;
  align-self: stretch;
  background-color: var(--base-white, #fff);
  display: flex;
  width: 100%;
  padding-bottom: 20px;
  flex-direction: column;
`;

const Div3 = styled.div`
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--color-neutrals-00, #fff);
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 24px 24px 0;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const Div4 = styled.div`
  align-self: stretch;
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
`;

const Img = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 48px;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  overflow: hidden;
  align-self: start;
  max-width: 100%;
`;

const Img2 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  overflow: hidden;
  align-self: start;
  max-width: 100%;
`;

const Div5 = styled.div`
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  margin-top: 16px;
  flex-grow: 1;
  flex-direction: column;
`;

const Div6 = styled.div`
  align-self: stretch;
  color: var(--gray-900, #101828);
  font: 600 18px/156% Inter, sans-serif;
`;

const Div7 = styled.div`
  align-self: stretch;
  color: var(--gray-600, #475467);
  margin-top: 4px;
  font: 400 14px/143% Inter, sans-serif;
`;

const Div8 = styled.div`
  align-items: flex-start;
  align-self: center;
  display: flex;
  max-width: 100%;
  flex-direction: column;
`;

const Div9 = styled.div`
  align-items: flex-start;
  align-self: start;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0 20px;
`;

const Div10 = styled.div`
  color: var(--gray-700, #344054);
  align-self: stretch;
  margin-right: -20px;
  white-space: nowrap;
  font: 500 14px/143% Inter, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div11 = styled.div`
  align-items: center;
  align-self: stretch;
  border: 1px solid var(--gray-300, #d0d5dd);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  background-color: var(--color-neutrals-00, #fff);
  display: flex;
  width: 100%;
  flex-grow: 1;
  flex-direction: column;
  margin: 6px -20px 0 0;
  padding: 10px 14px;
`;

const Div12 = styled.div`
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;

const Div17 = styled.div`
  justify-content: center;
  align-items: flex-start;
  align-self: start;
  display: flex;
  margin-top: 12px;
  max-width: 100%;
  gap: 8px;
  padding: 0 20px;
`;

const Div19 = styled.div`
  align-items: flex-start;
  align-self: center;
  display: flex;
  max-width: 100%;
  gap: 12px;
  margin: 32px 0 24px;
  padding: 0 20px;
`;
