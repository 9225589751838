const initialState = {
    teammates: null,
    error: null,
  };
  
  const teamMatesReducer = (state = initialState, action) => {
    switch (action.type) {
      case "TEAMMATE_SUCCESS":
        return {
          ...state,
          teammates: action.payload,
          error: null,
        };
      case "TEAMMATE_FAILURE":
        return {
          ...state,
          teammates: null,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default teamMatesReducer;
  