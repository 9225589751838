const initialState = {
    connections: null,
    error: null,
  };
  
  const connectionsReducer = (state = initialState, action) => {
    switch (action.type) {
      case "CONNECTIONS_SUCCESS":
        return {
          ...state,
          connections: action.payload,
          error: null,
        };
      case "CONNECTIONS_FAILURE":
        return {
          ...state,
          connections: null,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default connectionsReducer;
  