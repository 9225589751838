import React from "react";
import styled from "styled-components";
import { Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";

const validationSchema = Yup.object().shape({
  email: Yup.string().required("Email is required"),
  password: Yup.string().required("Password is required"),
});
const DisconnectAccountModal = (props) => {
  const buyer = useSelector((state) => state.buyer.buyer);
  const designer = useSelector((state) => state.designer.designer);
  const token = localStorage.getItem("auth_token");
  const selectedAccount = buyer ? buyer : designer;

  const formik = useFormik({
    initialValues: {
      email: selectedAccount.user.email,
      password: "password",
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        if (token) {
          props.onHide();         
        } else {
        }
      } catch (err) {
        // dispatch(buyerFailure(err.message));
      }
    },
  });
  return (
    <Div>
      <Form onSubmit={formik.handleSubmit}>
        <Div2>
          <Div3>
            <Div4>
              <Img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/54462003-ef07-462b-971b-f771c5ac11c5?"
              />
              <Img2
                style={{cursor: "pointer"}}
                loading="lazy"
                onClick={props.onRedirectToDash}
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/ce42d6c5-e7fa-47a5-929b-f8b5ccf6e707?"
              />
            </Div4>
            <Div5>Are you sure you want to disconnect?</Div5>
          </Div3>
        </Div2>
        <Div11>
          <button className="btn-cancel-withdrawal" type="button" onClick={props.onRedirectToDash}>Disconnect</button>
          <button className="btn-verify-withdrawal" type="submit">Update</button>
        </Div11>
      </Form>
    </Div>
  );
};

export default DisconnectAccountModal;

const Div = styled.div`
  align-items: center;
  background-color: var(--color-neutrals-00, #fff);
  display: flex;
  flex-direction: column;
`;

const Div2 = styled.div`
  align-self: stretch;
  background-color: var(--base-white, #fff);
  display: flex;
  width: 100%;
  padding-bottom: 20px;
  flex-direction: column;
`;

const Div3 = styled.div`
  align-items: end;
  background-color: var(--color-neutrals-00, #fff);
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 24px 24px 0;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const Div4 = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
`;

const Img = styled.img`
  aspect-ratio: 1;
  align-self: start;
  object-fit: contain;
  object-position: center;
  width: 48px;
  justify-content: left;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
`;

const Img2 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: right;
  overflow: hidden;
  align-self: start;
  max-width: 100%;
`;

const Div5 = styled.div`
  align-self: stretch;
  color: var(--gray-900, #101828);
  text-align: center;
  margin-top: 16px;
  font: 600 18px/28px Inter, sans-serif;
`;

// const Div6 = styled.div`
//   align-self: stretch;
//   color: var(--gray-600, #475467);
//   text-align: center;
//   margin-top: 4px;
//   font: 400 14px/20px Inter, sans-serif;
// `;

// const Div7 = styled.div`
//   color: var(--gray-700, #344054);
//   align-self: center;
//   white-space: nowrap;
//   font: 500 14px/20px Inter, sans-serif;
//   @media (max-width: 991px) {
//     white-space: initial;
//   }
// `;

// const Div9 = styled.div`
//   color: var(--gray-700, #344054);
//   align-self: center;
//   margin-top: 16px;
//   white-space: nowrap;
//   font: 500 14px/20px Inter, sans-serif;
//   @media (max-width: 991px) {
//     white-space: initial;
//   }
// `;

const Div11 = styled.div`
  align-self: center;
  display: flex;
  width: 100%;
  max-width: 352px;
  gap: 12px;
  margin: 32px 0 24px;
`;
