import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Col, Row, Button, Badge, Modal, Form } from "react-bootstrap";
import styled from "styled-components";
import "./Template.css";
import Save1 from "assets/images/templates/save 1.png";
import Save2 from "assets/images/templates/save 2.png";
import image4 from "assets/images/templates/image 4.png";
import image10 from "assets/images/templates/image 10.png";
import bg12 from "assets/images/templates/bg-12 3.png";
import plusIcon from "assets/images/templates/_Avatar_add_button.svg";
import VerticalDots from "assets/images/icons/dots-vertical.svg";
import MaleFullBodyHeight from "assets/images/templates/male_full_height.png";
import FemaleFullBodyNeck from "assets/images/templates/female_full_neck.png";
import FemaleUpperBody from "assets/images/templates/feamle_upper_body.png";
import FemaleLowerBody from "assets/images/templates/female_lower_body.png";
import { Tabs, Tab } from "@mui/material";
import UpperBody from "./Measurements/UpperBody";
import LowerBody from "./Measurements/LowerBody";
import {
  create,
  listSizingTemplates,
} from "services/sizingTemplateService";
import {
  sizingTemplateFailure,
  sizingTemplateSuccess,
} from "store/actions/sizingTemplateActions";
import { showToast } from "services/toastService";
import { showErrors } from "constants/Utils";

const validationSchema = Yup.object().shape({
  template_name: Yup.string().required("Name is required"),
  height: Yup.string().required("Height is required"),
  neck_circumference: Yup.string(),
});

const TemplatesList = ({ sizing_templates }) => {
  const [selectedTemplate, setSelectedTemplate] = useState(sizing_templates[0]);
  const [value, setValue] = useState(0);
  const [selectedUnit, setSelectedUnit] = useState("CM");
  const [selectedGender, setSelectedGender] = useState("male");
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const buyer = useSelector((state) => state.buyer.buyer);
  const designer = useSelector((state) => state.designer.designer);
  const selectedAccount = buyer ? buyer : designer;
  const [templates_list, setTemplatesList] = useState([]);

  const handleCloseModal = () => setShow(false);
  const handleShowModal = () => setShow(true);

  const handleTemplateChange = (template_obj) => {
    setSelectedTemplate(template_obj);
    setShow(true);
  };

  const tabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleUnitClick = (unitName) => {
    setSelectedUnit(unitName);
  };

  const handleGender = (tempGender) => {
    setSelectedGender(tempGender);
  };

  useEffect(() => {
      loadTemplates();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadTemplates = async () => {
    const resp = await listSizingTemplates(selectedAccount?.id);
    setTemplatesList(resp);
  };

  const formik = useFormik({
    initialValues: {
      template_name: selectedTemplate?.name ?? "",
      height: selectedTemplate?.height ?? 167,
      neck_circumference: selectedTemplate?.neck_circumference ?? 0,
      chest_circumference: selectedTemplate?.chest_circumference ?? 0,
      shoulder_width: selectedTemplate?.shoulder_width ?? 0,
      shoulder_diameter: selectedTemplate?.shoulder_width ?? 0,
      upper_arm_circumference: selectedTemplate?.upper_arm_circumference ?? 0,
      arm_length: selectedTemplate?.arm_length ?? 0,
      biceps: selectedTemplate?.biceps ?? 0,
      wrist_circumference: selectedTemplate?.wrist_circumference ?? 0,
      back_width: selectedTemplate?.back_width ?? 0,
      upper_body_length: selectedTemplate?.upper_body_length ?? 0,
      back_length: selectedTemplate?.back_length ?? 0,
      waist_circumference: selectedTemplate?.waist_circumference ?? 0,
      hips_circumference: selectedTemplate?.hips_circumference ?? 0,
      thigh_circumference: selectedTemplate?.thigh_circumference ?? 0,
      crochet_dept: selectedTemplate?.crochet_dept ?? 0,
      inseam: selectedTemplate?.inseam ?? 0,
      hips: selectedTemplate?.hips ?? 0,
      body_rise: selectedTemplate?.body_rise ?? 0,
      neck_to_waistline: selectedTemplate?.neck_to_waistline ?? 0,
      waist: selectedTemplate?.waist ?? 0,
      waist_to_knee: selectedTemplate?.waist_to_knee ?? 0,
      knee: selectedTemplate?.knee ?? 0,
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const template_data = {
          name: values.template_name,
          template_type: selectedGender,
          measurement_unit: selectedUnit,
          height: values.height,
          neck_circumference: values.neck_circumference,
          chest_circumference: values.chest_circumference,
          shoulder_width: values.shoulder_width,
          shoulder_diameter: values.shoulder_diameter,
          upper_arm_circumference: values.upper_arm_circumference,
          arm_length: values.arm_length,
          biceps: values.biceps,
          wrist_circumference: values.wrist_circumference,
          back_width: values.back_width,
          upper_body_length: values.upper_body_length,
          back_length: values.back_length,
          waist_circumference: values.waist_circumference,
          hips_circumference: values.hips_circumference,
          thigh_circumference: values.thigh_circumference,
          crochet_dept: values.crochet_dept,
          inseam: values.inseam,
          hips: values.hips,
          body_rise: values.body_rise,
          neck_to_waistline: values.neck_to_waistline,
          waist: values.waist,
          waist_to_knee: values.waist_to_knee,
          knee: values.knee,
          created_by: buyer.id,
        };

        if ( value === 0 || value === 1 || value === 2) {
          const resp = await create(template_data);
          //TODO: sizing template
          console.log(resp)
            dispatch(sizingTemplateSuccess(resp));
            resetForm();
           showToast('success', 'Operation Successful')
            loadTemplates();
            setShow(false);
        }
      } catch (err) {
        dispatch(sizingTemplateFailure(err.message));
        showErrors(err)
      }
    },
  });

  const disableAddMeasurement = templates_list.length >= 3 || designer;

  return (
    <>
      <Row>
        <Col xs={12} md={4}>
          <div className="frame">
            <div className="overlap-group-2">
              <img className="bg" alt="Bg" src={bg12} />
              <img className="save" alt="Save" src={Save2} />
              <img className="save-2" alt="Save" src={Save1} />
              <img className="image" alt="umojalin" src={image4} />
              <div className="attribution-card">
                <div
                  className="d-flex justify-content-center"
                  style={{ marginTop: "22%" }}
                >
                  <Button
                    disabled={disableAddMeasurement}
                    variant="warning"
                    onClick={handleShowModal}
                    className="rounded-0 bs-0"
                  >
                    Add Measurement{" "}
                    <img
                      alt="Icon"
                      className="avatar-add-button-icon"
                      src={plusIcon}
                    />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Col>
        {templates_list.map((template) => (
          <>
            <Col xs={12} md={4} key={template.id}>
              <div className="frame">
                <div className="overlap-group-2">
                  <img className="bg-2" alt="Bg" src={bg12} />
                  <img className="image-2" alt="umojalin" src={image4} />
                  <img
                    src={VerticalDots}
                    style={{ right: "2px", position: "absolute" }}
                    icon="icon"
                    alt="umojalin"
                  />
                  <div className="attribution-card-2">
                    <div className="text-2" style={{cursor: "pointer"}} onClick={() => handleTemplateChange(template)}>{template.name}</div>
                  </div>
                </div>
              </div>
            </Col>
            {template.is_active && (
              <Col xs={12} md={4} key={template.id}>
                <div className="frame">
                  <div className="overlap-3">
                    <img className="bg-3" alt="Bg" src={bg12} />
                    <img className="save-3" alt="Save" src={Save1} />
                    {/* <DotsVertical className="dots-vertical" color="#EAAA08" /> */}
                    <img
                      src={VerticalDots}
                      className="dots-vertical"
                      icon="icon"
                      alt="umojalin"
                    />
                    <div className="attribution-card-3">
                      <div className="overlap-group-3">
                        <div className="text-wrapper-3" style={{cursor: "pointer"}} onClick={() => handleTemplateChange(template)}>{template.name}</div>
                        <div className="div-3">In Use</div>
                      </div>
                      <Badge
                        className="badge-instance-3"
                        color="gray"
                        icon={false}
                        outline={false}
                        size="md"
                        text="My Agbada"
                        textClassName="design-component-instance-node-3"
                      />
                    </div>
                  </div>
                </div>
              </Col>
            )}
          </>
        ))}
        {sizing_templates.length >= 3 && (
          <Col xs={12} md={4} className="mt-3">
            <div className="frame">
              <div className="overlap-group-4">
                <img className="bg-4" alt="Bg" src={bg12} />
                <img className="save-4" alt="Save" src={Save2} />
                <img className="image-4" alt="umojalin" src={image10} />
                <img className="img-4" alt="Save" src={Save1} />
                <img className="image-4-1" alt="umojalin" src={image4} />
                <div className="attribution-card-4">
                  <div className="text-wrapper-4">$299</div>
                  <Button
                    size="md"
                    className="rounded-0 bs-0 button-instance-4"
                  >
                    Buy template
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        )}
      </Row>
      <Row>
        <Modal
          show={show}
          onHide={handleCloseModal}
          className="rounded-0"
          size="lg"
          centered
        >
          <Modal.Body>
            <Div>
              <Form onSubmit={formik.handleSubmit}>
                <Div2>
                  <Column>
                    <Div3>
                      <Div4>
                        <Div5>Measurement </Div5>
                        <Div6>
                          <Form.Group>
                            <Form.Control
                              name="template_name"
                              onChange={formik.handleChange}
                              value={formik.values.template_name}
                              className="form-control-lg rounded-0"
                              type="text"
                              placeholder="Template name"
                              required
                            />
                            {formik.errors.template_name &&
                              formik.touched.template_name && (
                                <Form.Text className="text-danger">
                                  {formik.errors.template_name}
                                </Form.Text>
                              )}
                          </Form.Group>
                          <Div8>
                            {/* <Div9>Male</Div9>
                          <Div10>Female</Div10> */}
                            <Div18
                              className={`${
                                selectedGender === "male"
                                  ? "selected-gender"
                                  : "unselected-gender border border-0"
                              }`}
                              onClick={() => handleGender("male")}
                              style={{ cursor: "pointer" }}
                            >
                              Male
                            </Div18>
                            <Div19
                              className={`${
                                selectedGender === "female"
                                  ? "selected-gender"
                                  : "unselected-gender border border-0"
                              }`}
                              onClick={() => handleGender("female")}
                              style={{ cursor: "pointer" }}
                            >
                              Female
                            </Div19>
                          </Div8>
                        </Div6>
                        <Div11>
                          <Div12>
                            <Tabs
                              value={value}
                              onChange={tabChange}
                              indicatorColor="primary"
                              textColor="primary"
                              aria-label="Project Tabs"
                            >
                              <Tab
                                label={
                                  <Div14 className="text-capitalize">
                                    Upper-Body
                                  </Div14>
                                }
                              />
                              <Tab
                                label={
                                  <Div14 className="text-capitalize">
                                    Lower-Body
                                  </Div14>
                                }
                              />
                              <Tab
                                label={
                                  <Div14 className="text-capitalize">
                                    Full-Body
                                  </Div14>
                                }
                              />
                            </Tabs>
                          </Div12>
                          <Div17>
                            <Div18
                              className={`${
                                selectedUnit === "INCH"
                                  ? "selected-measurement-unit"
                                  : "unselected-measurement-unit border border-0"
                              }`}
                              onClick={() => handleUnitClick("INCH")}
                              style={{ cursor: "pointer" }}
                            >
                              INCH
                            </Div18>
                            <Div19
                              className={`${
                                selectedUnit === "CM"
                                  ? "selected-measurement-unit"
                                  : "unselected-measurement-unit border border-0"
                              }`}
                              onClick={() => handleUnitClick("CM")}
                              style={{ cursor: "pointer" }}
                            >
                              CM
                            </Div19>
                          </Div17>
                        </Div11>
                      </Div4>
                      <Div20>
                        <Div21>
                          <Div22>Measurement Point</Div22>
                          <Div23>Measurement </Div23>
                        </Div21>
                        <Div25 style={{ width: "96%" }}>
                          <Div26>
                            <Div27>Height</Div27>
                            <Div28>
                              <div className="d-flex justify-content-end align-items-end">
                                <Form.Control
                                  style={{ textAlign: "right" }}
                                  type="number"
                                  onChange={formik.handleChange}
                                  value={formik.values.height}
                                  name="height"
                                  className="rounded-0 w-75"
                                  min="1"
                                  max="300"
                                />
                                <span
                                  className="pb-2"
                                  style={{ color: "rgba(102,112,133,1)" }}
                                >
                                  {" "}
                                  {selectedUnit}
                                </span>
                              </div>
                            </Div28>
                          </Div26>
                        </Div25>
                        {value === 0 && (
                          <UpperBody
                            values={formik.values}
                            errors={formik.errors}
                            touched={formik.touched}
                            handleChange={formik.handleChange}
                            setFieldValue={formik.setFieldValue}
                            selectedUnit={selectedUnit}
                            tabIndex={value}
                          />
                        )}
                        {value === 1 && (
                          <LowerBody
                            values={formik.values}
                            errors={formik.errors}
                            touched={formik.touched}
                            handleChange={formik.handleChange}
                            setFieldValue={formik.setFieldValue}
                            selectedUnit={selectedUnit}
                            tabIndex={value}
                          />
                        )}
                        {value === 2 && (
                          <div style={{ maxHeight: "400px", overflow: "auto", width: "100%", marginRight: "-25px" }}>
                            <UpperBody
                              values={formik.values}
                              errors={formik.errors}
                              touched={formik.touched}
                              handleChange={formik.handleChange}
                              setFieldValue={formik.setFieldValue}
                              selectedUnit={selectedUnit}
                              tabIndex={value}
                            />
                            <LowerBody
                              values={formik.values}
                              errors={formik.errors}
                              touched={formik.touched}
                              handleChange={formik.handleChange}
                              setFieldValue={formik.setFieldValue}
                              selectedUnit={selectedUnit}
                              tabIndex={value}
                            />
                          </div>
                        )}
                      </Div20>
                    </Div3>
                  </Column>
                  <Column3>
                    <Div60>
                      <Div61>
                        <Column4>
                          <Div62 />
                        </Column4>
                        <Column5>
                          <Div63>
                            <Div64>Preview</Div64>
                            {selectedGender === "male" && value === 0 && (
                              <Img2 loading="lazy" src={FemaleUpperBody} />
                            )}
                            {selectedGender === "female" && value === 0 && (
                              <Img2 loading="lazy" src={FemaleUpperBody} />
                            )}
                            {selectedGender === "male" && value === 1 && (
                              <Img2 loading="lazy" src={FemaleLowerBody} />
                            )}
                            {selectedGender === "female" && value === 1 && (
                              <Img2 loading="lazy" src={FemaleLowerBody} />
                            )}
                            {selectedGender === "male" && value === 2 && (
                              <Img2 loading="lazy" src={MaleFullBodyHeight} />
                            )}
                            {selectedGender === "female" && value === 2 && (
                              <Img2 loading="lazy" src={FemaleFullBodyNeck} />
                            )}
                            <Div65>
                              <button
                                type="button"
                                className="template-save-btn rounded-0"
                                onClick={handleCloseModal}
                              >
                                Save as draft
                              </button>
                              <button
                                type="submit"
                                className="template-submit-btn rounded-0"
                              >
                                Submit
                              </button>
                            </Div65>
                          </Div63>
                        </Column5>
                      </Div61>
                    </Div60>
                  </Column3>
                </Div2>
              </Form>
            </Div>
          </Modal.Body>
        </Modal>
      </Row>
    </>
  );
};

export default TemplatesList;

const Div = styled.div`
  background-color: var(--color-neutrals-00, #fff);
  padding: 0 33px 0 32px;
`;

const Div2 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 60%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div3 = styled.div`
  align-items: flex-start;
  display: flex;
  margin-top: 32px;
  flex-grow: 1;
  flex-direction: column;
  width: 509px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div4 = styled.div`
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div5 = styled.div`
  color: var(--gray-900, #101828);
  align-self: stretch;
  white-space: nowrap;
  font: 700 24px/133% Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    white-space: initial;
  }
`;

const Div6 = styled.div`
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  margin-top: 24px;
  width: 90%;
  padding-right: 5px;
  gap: 20px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const Div8 = styled.div`
  align-items: flex-start;
  border: 1px solid var(--gray-100, #f2f4f7);
  background-color: var(--gray-50, #f9fafb);
  align-self: stretch;
  display: flex;
  width: 146px;
  max-width: 100%;
  justify-content: space-between;
  gap: 20px;
  padding: 4px 16px 4px 4px;
`;

const Div11 = styled.div`
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  margin-top: 24px;
  width: 90%;
  padding-right: 5px;
  gap: 20px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const Div12 = styled.div`
  align-items: flex-start;
  border-bottom: 1px solid var(--gray-200, #eaecf0);
  align-self: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: auto;
  width: 326px;
  margin: auto 0;
  padding: 0 6px 0 4px;
`;

const Div14 = styled.div`
  color: var(--gray-500, #667085);
  align-self: start;
  font: 600 16px/150% Inter, sans-serif;
`;

const Div17 = styled.div`
  align-items: flex-start;
  border: 1px solid var(--gray-100, #f2f4f7);
  background-color: var(--gray-50, #f9fafb);
  align-self: stretch;
  display: flex;
  width: 122px;
  max-width: 100%;
  justify-content: space-between;
  gap: 20px;
  padding: 4px 2px 4px 16px;
`;

const Div18 = styled.div`
  align-self: stretch;
  margin: auto 0;
  font: 600 14px/143% Inter, sans-serif;
`;

const Div19 = styled.div`
  align-self: center;
  margin: auto 0;
  font: 600 14px/143% Inter, sans-serif;
`;

const Div20 = styled.div`
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  margin-top: 24px;
  flex-grow: 1;
  flex-direction: column;
  width: 90%;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div21 = styled.div`
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const Div22 = styled.div`
  color: var(--gray-500, #667085);
  font: 600 18px/156% Inter, sans-serif;
`;

const Div23 = styled.div`
  color: var(--gray-500, #667085);
  align-self: stretch;
  white-space: nowrap;
  font: 600 18px/156% Inter, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

// TODO: remove  
// const Div24 = styled.div`
//   align-items: flex-start;
//   align-self: stretch;
//   display: flex;
//   margin-top: 24px;
//   flex-grow: 1;
//   flex-direction: column;
//   max-height: 400px;
//   overflow-y: auto;
//   @media (max-width: 991px) {
//     max-width: 100%;
//   }
// `;

const Div25 = styled.div`
  align-items: flex-start;
  border-bottom: 1px solid var(--gray-100, #f2f4f7);
  align-self: stretch;
  display: flex;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div26 = styled.div`
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--color-neutrals-00, #fff);
  display: flex;
  justify-content: space-between;
  gap: 16px;
  padding: 10px 20px 10px 14px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Div27 = styled.div`
  color: var(--gray-500, #667085);
  align-self: stretch;
  max-width: 429px;
  flex-grow: 1;
  flex-basis: auto;
  font: 400 16px/150% Inter, sans-serif;
`;

const Div28 = styled.div`
  align-items: flex-start;
  border-radius: 16px;
  mix-blend-mode: multiply;
  align-self: center;
  display: flex;
  gap: 4px;
  margin: auto 0;
`;

const Column3 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 40%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div60 = styled.div`
  margin-top: 9px;
  flex-grow: 1;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div61 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column4 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 0%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div62 = styled.div`
  background-color: #eaecf0;
  width: 1px;
  height: 749px;
  margin: 0 auto;
  @media (max-width: 991px) {
    margin-top: 30px;
  }
`;

const Column5 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 100%;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div63 = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 0;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div64 = styled.div`
  color: var(--gray-900, #101828);
  align-self: stretch;
  white-space: nowrap;
  font: 700 24px/133% Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    white-space: initial;
  }
`;

const Img2 = styled.img`
  aspect-ratio: 0.45;
  object-fit: contain;
  object-position: center;
  width: 100%;
  overflow: hidden;
  align-self: end;
  margin-top: 87px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div65 = styled.div`
  justify-content: flex-end;
  align-items: flex-start;
  align-self: end;
  display: flex;
  margin-top: 32px;
  width: 157px;
  max-width: 100%;
  gap: 12px;
`;
