export const milestones = {
  milestones: [
    {
      name: "",
      is_approved: false,
      is_funded: false,
      is_delivery_milestone: false,
      description: "",
      amount: 0,
      currency: "",
      start_date: "",
      end_date: "",
      milestone_gallery: [{ image_url: "" }],
      saved: false,
    },
    {
        name: "",
        is_approved: false,
        is_funded: false,
        is_delivery_milestone: true,
        description: "",
        amount: 0,
        currency: "",
        start_date: "",
        end_date: "",
        milestone_gallery: [{ image_url: "" }],
        saved: false,
      },
  ],
  activeMilestones: -1,
};

export const ADD_MILESTONE = "@umojalinn/milestone/add";
export const SET_MILESTONE = "@umojalinn/milestone/set";
export const UPDATE_MILESTONE = "@umojalinn/milestone/update";
export const DELETE_MILESTONE = "@umojalinn/milestone/delete";
export const SET_ACTIVE_MILESTONE = "@umojalinn/milestone/set-active";
export const CREATE_MILESTONE = "@umojalinn/milestone/create";

export function addMilestone() {
  return {
    type: ADD_MILESTONE,
  };
}

export function setMileStone(milestones) {
  return {
    type: SET_MILESTONE,
    data: { milestones },
  };
}

export function updateMilestone(milestoneIndex, milestone) {
  return {
    type: UPDATE_MILESTONE,
    data: milestone,
    index: milestoneIndex,
  };
}

export function deleteMilestone(milestoneIndex) {
  return {
    type: DELETE_MILESTONE,
    data: milestoneIndex,
  };
}


export function createMilestoneSuccess (){
  return {
    type: CREATE_MILESTONE
  }
}