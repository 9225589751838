/* eslint-disable no-script-url */
import React, { useState, useEffect } from "react";
import { designerDetails } from "services/designerService";
import styled from "styled-components";
import profileHolderImg from "assets/images/icons/user-01.svg";
import { getCurrencySymbol } from "constants/Utils";

const ActivitiesTab = (props) => {
  const [designerInfo, setDesignerInfo] = useState();
  const auth_token = localStorage.getItem("auth_token");

  useEffect(() => {
    const fetchDesignerDetails = async (designerId) => {
      try {
        const resp = await designerDetails(designerId, auth_token);
        setDesignerInfo(resp);
      } catch (error) {
        console.error('Error fetching designer information:', error);
      }
    };
    if (props.project.bids.length > 0 && props.project.bids[0].milestones.length > 0) {
      fetchDesignerDetails(props.project.bids[0].milestones[0].created_by);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.project.bids]);

  const milestoneDate = (createdAt) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(new Date(createdAt));
    return formattedDate;
  }

  const milestoneTime = (createdAt) => {
    const dateObject = new Date(createdAt);
    const optionsTime = { hour: "numeric", minute: "2-digit", hour12: true };
    const formattedTime = new Intl.DateTimeFormat("en-US", optionsTime).format(dateObject);
    return formattedTime;
  };

  return (
    <>
      {props.project.bids
        .filter((bid) => bid.is_accepted)
        .map((acceptedBid) => (
          <Div>
            {acceptedBid.milestones.slice().sort((a, b) => new Date(a.created_at) - new Date(b.created_at)).map((milestone, index) => (
              <Div2 key={index}>
                <Div3>
                  <Column>
                    <Div4>
                      {milestone.status === "completed" && (
                        <>
                          <Img
                            loading="lazy"
                            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/3fcb77ea-5553-44f2-adb1-ae90d94c9f1b?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/3fcb77ea-5553-44f2-adb1-ae90d94c9f1b?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3fcb77ea-5553-44f2-adb1-ae90d94c9f1b?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/3fcb77ea-5553-44f2-adb1-ae90d94c9f1b?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/3fcb77ea-5553-44f2-adb1-ae90d94c9f1b?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3fcb77ea-5553-44f2-adb1-ae90d94c9f1b?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/3fcb77ea-5553-44f2-adb1-ae90d94c9f1b?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/3fcb77ea-5553-44f2-adb1-ae90d94c9f1b?"
                          />
                          <Div5 />
                        </>
                      )}
                      {milestone.status === "ongoing" && (
                        <>
                          <img
                            loading="lazy"
                            alt="umojalin"
                            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/d4a551d6-44d2-4bed-b74a-2fb2d81ad402?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4a551d6-44d2-4bed-b74a-2fb2d81ad402?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4a551d6-44d2-4bed-b74a-2fb2d81ad402?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4a551d6-44d2-4bed-b74a-2fb2d81ad402?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4a551d6-44d2-4bed-b74a-2fb2d81ad402?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4a551d6-44d2-4bed-b74a-2fb2d81ad402?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4a551d6-44d2-4bed-b74a-2fb2d81ad402?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4a551d6-44d2-4bed-b74a-2fb2d81ad402?"
                          />
                          <Div24 />
                        </>
                      )}
                      {milestone.status === "pending" && (
                        <>
                          <img
                            loading="lazy"
                            alt="umojalin"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/62aec2f9dd2f2b514af0247aa1c4ecc194dd262d369d05198f9cc09cd0804788?"
                          />
                          <Div24 />
                        </>
                      )}
                    </Div4>
                  </Column>
                  <Column2>
                    <Div6>
                      <A href="javascript:void(0)">{milestone.name}</A>
                      <Div7>
                        <Div8>{milestone.description}</Div8>
                        <Div9>
                          <Div10>
                            <Div11>
                              {designerInfo && (
                                <>
                                  <Img2 loading="lazy" srcSet={designerInfo.profile_image || profileHolderImg} alt={designerInfo?.first_name} />
                                  <A2>{`${designerInfo?.first_name} ${designerInfo?.last_name}`}</A2>
                                </>
                              )}
                            </Div11>
                            <Div12>
                              Attached to the file, is the content of the Design
                            </Div12>
                          </Div10>
                          <Div13>
                            <Div14>
                              <Column3>
                                <Img3
                                  loading="lazy"
                                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/98e941ca-dbf9-4601-8efa-61fc0543304a?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/98e941ca-dbf9-4601-8efa-61fc0543304a?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/98e941ca-dbf9-4601-8efa-61fc0543304a?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/98e941ca-dbf9-4601-8efa-61fc0543304a?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/98e941ca-dbf9-4601-8efa-61fc0543304a?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/98e941ca-dbf9-4601-8efa-61fc0543304a?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/98e941ca-dbf9-4601-8efa-61fc0543304a?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/98e941ca-dbf9-4601-8efa-61fc0543304a?"
                                />
                              </Column3>
                              <Column4>
                                <Img4
                                  loading="lazy"
                                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/b78aec93-dc7b-458f-bd15-b7cd9f41a93c?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/b78aec93-dc7b-458f-bd15-b7cd9f41a93c?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b78aec93-dc7b-458f-bd15-b7cd9f41a93c?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/b78aec93-dc7b-458f-bd15-b7cd9f41a93c?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/b78aec93-dc7b-458f-bd15-b7cd9f41a93c?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b78aec93-dc7b-458f-bd15-b7cd9f41a93c?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/b78aec93-dc7b-458f-bd15-b7cd9f41a93c?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/b78aec93-dc7b-458f-bd15-b7cd9f41a93c?"
                                />
                              </Column4>
                              <Column5>
                                <Img5
                                  loading="lazy"
                                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/80e5ab57-5abc-4d60-8223-1f9c8d664c92?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/80e5ab57-5abc-4d60-8223-1f9c8d664c92?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/80e5ab57-5abc-4d60-8223-1f9c8d664c92?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/80e5ab57-5abc-4d60-8223-1f9c8d664c92?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/80e5ab57-5abc-4d60-8223-1f9c8d664c92?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/80e5ab57-5abc-4d60-8223-1f9c8d664c92?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/80e5ab57-5abc-4d60-8223-1f9c8d664c92?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/80e5ab57-5abc-4d60-8223-1f9c8d664c92?"
                                />
                              </Column5>
                              <Column6>
                                <Div15 />
                              </Column6>
                            </Div14>
                          </Div13>
                        </Div9>
                        <Div16>
                          <Div17>
                            <Div18>
                              <A3 href="javascript:void(0)">{milestoneDate(milestone.created_at)}</A3>
                              <Img6
                                loading="lazy"
                                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/99312af4-534c-4d46-b4ed-3d7b38339e7f?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/99312af4-534c-4d46-b4ed-3d7b38339e7f?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/99312af4-534c-4d46-b4ed-3d7b38339e7f?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/99312af4-534c-4d46-b4ed-3d7b38339e7f?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/99312af4-534c-4d46-b4ed-3d7b38339e7f?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/99312af4-534c-4d46-b4ed-3d7b38339e7f?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/99312af4-534c-4d46-b4ed-3d7b38339e7f?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/99312af4-534c-4d46-b4ed-3d7b38339e7f?"
                              />
                              <A4 href="javascript:void(0)">{milestoneTime(milestone.created_at)}</A4>
                            </Div18>
                            <Div19>
                              <A5 href="javascript:void(0)">Paid</A5>
                              <A6 href="javascript:void(0)">{`${getCurrencySymbol(milestone.currency)}${milestone.amount}`}</A6>
                            </Div19>
                          </Div17>
                          <Div20>
                            <A7 href="javascript:void(0)">0</A7>
                            <A8 href="javascript:void(0)">retries</A8>
                          </Div20>
                        </Div16>
                      </Div7>
                    </Div6>
                  </Column2>
                </Div3>
              </Div2>
            ))}
          </Div>
        ))}
         <Div78>
              <Div79>
                <Column>
                  <Div4>
                    <img
                      loading="lazy"
                      alt="umojalin"
                      srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/d4a551d6-44d2-4bed-b74a-2fb2d81ad402?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4a551d6-44d2-4bed-b74a-2fb2d81ad402?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4a551d6-44d2-4bed-b74a-2fb2d81ad402?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4a551d6-44d2-4bed-b74a-2fb2d81ad402?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4a551d6-44d2-4bed-b74a-2fb2d81ad402?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4a551d6-44d2-4bed-b74a-2fb2d81ad402?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4a551d6-44d2-4bed-b74a-2fb2d81ad402?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4a551d6-44d2-4bed-b74a-2fb2d81ad402?"
                    />
                    <Div81 />
                  </Div4>
                </Column>
                <Column2>
                  <Div82>
                    <Div83>
                      <Div84>
                        <A href="javascript:void(0)">Delivery Milestone</A>
                        <Div85>
                          <A34 href="javascript:void(0)">Delivery method</A34>
                          <A35 href="javascript:void(0)">In person pick-up</A35>
                        </Div85>
                      </Div84>
                      <Img26
                        loading="lazy"
                        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/5019cf95-46b5-4046-8861-605988cb2b57?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/5019cf95-46b5-4046-8861-605988cb2b57?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/5019cf95-46b5-4046-8861-605988cb2b57?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/5019cf95-46b5-4046-8861-605988cb2b57?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/5019cf95-46b5-4046-8861-605988cb2b57?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/5019cf95-46b5-4046-8861-605988cb2b57?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/5019cf95-46b5-4046-8861-605988cb2b57?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/5019cf95-46b5-4046-8861-605988cb2b57?"
                      />
                    </Div83>
                    <Div86>
                      <Div87>
                        <A36 href="javascript:void(0)">Country</A36>
                        <Div88>
                          <Div89>
                            <Div90>
                              <Img27
                                loading="lazy"
                                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/27f88b1b-df0e-4064-913f-7bcb29dd26b3?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/27f88b1b-df0e-4064-913f-7bcb29dd26b3?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/27f88b1b-df0e-4064-913f-7bcb29dd26b3?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/27f88b1b-df0e-4064-913f-7bcb29dd26b3?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/27f88b1b-df0e-4064-913f-7bcb29dd26b3?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/27f88b1b-df0e-4064-913f-7bcb29dd26b3?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/27f88b1b-df0e-4064-913f-7bcb29dd26b3?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/27f88b1b-df0e-4064-913f-7bcb29dd26b3?"
                              />
                              <A37 href="javascript:void(0)">{props.project.country?.name}</A37>
                            </Div90>
                          </Div89>
                        </Div88>
                      </Div87>
                      <Div91>
                        <A38 href="javascript:void(0)">State /Province</A38>
                        <A39 href="javascript:void(0)">{props.project?.state}</A39>
                      </Div91>
                      <Div92>
                        <A40 href="javascript:void(0)">City</A40>
                        <A41 href="javascript:void(0)">{props.project.city?.name}</A41>
                      </Div92>
                      <Div93>
                        <Div94>Zip code / Postal code</Div94>
                        <A42 href="javascript:void(0)">{props.project?.zip_code}</A42>
                      </Div93>
                      <Div95>
                        <A43 href="javascript:void(0)">Street/Appartments</A43>
                        <A44 href="javascript:void(0)">{props.project?.address}</A44>
                      </Div95>
                    </Div86>
                  </Div82>
                </Column2>
              </Div79>
            </Div78>
    </>
  );
};

export default ActivitiesTab;

const Div = styled.div`
  align-items: flex-start;
  width: 100%;
  // max-width: 593px;
  align-self: end;
  display: flex;
  flex-direction: column;
`;

const Div2 = styled.div`
  width: 100%;
  max-width: 591px;
  padding-right: 20px;
  align-self: start;
  display: flex;
  flex-direction: column;
`;

const Div3 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;<img
  loading="lazy"
  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/d4a551d6-44d2-4bed-b74a-2fb2d81ad402?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4a551d6-44d2-4bed-b74a-2fb2d81ad402?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4a551d6-44d2-4bed-b74a-2fb2d81ad402?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4a551d6-44d2-4bed-b74a-2fb2d81ad402?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4a551d6-44d2-4bed-b74a-2fb2d81ad402?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4a551d6-44d2-4bed-b74a-2fb2d81ad402?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4a551d6-44d2-4bed-b74a-2fb2d81ad402?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/d4a551d6-44d2-4bed-b74a-2fb2d81ad402?"
/>
  width: 6%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div4 = styled.div`
  padding-bottom: 2px;
  align-items: center;
  padding-top: 2px;
  display: flex;
  flex-direction: column;
  @media (max-width: 991px) {
    margin-top: 13px;
  }
`;

const Img = styled.img`
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  // width: 100%;
  margin-top: -2px;
  max-width: 32px;
`;

const Div5 = styled.div`
  flex: 1 0 0;
  border-radius: 2px;
  background: var(--success-500, #12b76a);
  width: 100%;
  max-width: 2px;
  margin-top: 4px;
  margin-bottom: 2px;
  align-self: center;
  display: flex;
  flex-direction: column;
  min-height: 229px;
`;

const Column2 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 94%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div6 = styled.div`
  align-items: flex-start;
  padding-top: 6px;
  display: flex;
  flex-direction: column;
  @media (max-width: 991px) {
    margin-top: 13px;
  }
`;

const A = styled.a`
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  margin-left: 1px;
  align-self: start;
  @media (max-width: 991px) {
    margin-left: 1px;
  }
`;

const Div7 = styled.div`
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: 14px;
  padding-top: 6px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
`;

const Div8 = styled.div`
  webkit-box-orient: vertical;
  webkit-line-clamp: 1;
  align-self: start;
  overflow: hidden;
  color: var(--gray-700, #344054);
  text-overflow: ellipsis;
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-left: 1px;
  @media (max-width: 991px) {
    margin-left: 1px;
  }
`;

const Div9 = styled.div`
  justify-content: flex-end;
  align-items: flex-start;
  align-self: stretch;
  border: 1px solid var(--gray-100, #f2f4f7);
  margin-top: 7px;
  padding-top: 8px;
  padding-left: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
`;

const Div10 = styled.div`
  align-items: flex-start;
  align-self: start;
  width: 100%;
  max-width: 530px;
  padding-top: 1px;
  padding-right: 20px;
  padding-bottom: 1px;
  display: flex;
  flex-direction: column;
`;

const Div11 = styled.div`
  align-items: flex-start;
  width: 100%;
  margin-top: -1px;
  padding-right: 20px;
  align-self: start;
  display: flex;
  flex-direction: row;
  gap: 5.1171875px;
  flex-wrap: wrap;
`;

const Img2 = styled.img`
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 24px;
  border-radius: 100%;
  align-self: stretch;
`;

const A2 = styled.a`
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: -0px;
  align-self: center;
  text-align: center;
`;

const Div12 = styled.div`
  webkit-box-orient: vertical;
  webkit-line-clamp: 1;
  align-self: start;
  overflow: hidden;
  color: var(--gray-700, #344054);
  text-overflow: ellipsis;
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  margin-top: 6px;
  margin-left: 0px;
  margin-bottom: 1px;
  @media (max-width: 991px) {
    margin-left: 0px;
  }
`;

const Div13 = styled.div`
  margin-top: 4px;
  padding-right: 20px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
`;

const Div14 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column3 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 32%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Img3 = styled.img`
  aspect-ratio: 1.48;
  object-fit: cover;
  object-position: center;
  width: 167px;
  @media (max-width: 991px) {
    margin-top: 4px;
  }
`;

const Column4 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 32%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Img4 = styled.img`
  aspect-ratio: 1.48;
  object-fit: cover;
  object-position: center;
  width: 167px;
  @media (max-width: 991px) {
    margin-top: 4px;
  }
`;

const Column5 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 32%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Img5 = styled.img`
  aspect-ratio: 1.48;
  object-fit: cover;
  object-position: center;
  width: 167px;
  @media (max-width: 991px) {
    margin-top: 4px;
  }
`;

const Column6 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 5%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div15 = styled.div`
  align-items: flex-start;
  border-radius: 8px;
  background: var(--gray-50, #f9fafb);
  display: flex;
  flex-direction: column;
  min-height: 113px;
  @media (max-width: 991px) {
    margin-top: 4px;
  }
`;

const Div16 = styled.div`
  align-items: flex-start;
  align-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 4px;
  padding-right: 20px;
  align-self: start;
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

const Div17 = styled.div`
  justify-content: center;
  align-items: flex-start;
  padding-right: 20px;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: 4px;
  flex-wrap: wrap;
`;

const Div18 = styled.div`
  align-items: flex-start;
  padding-top: 3px;
  padding-left: 1px;
  padding-right: 20px;
  padding-bottom: 3px;
  align-self: center;
  display: flex;
  flex-direction: row;
  gap: 5.00390625px;
  flex-wrap: wrap;
  @media (max-width: 991px) {
    justify-content: center;
  }
`;

const A3 = styled.a`
  color: var(--gray-700, #344054);
  text-align: center;
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  margin-top: 1px;
  align-self: stretch;
`;

const Img6 = styled.img`
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 4px;
  align-self: center;
`;

const A4 = styled.a`
  color: var(--gray-700, #344054);
  text-align: center;
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  margin-top: 1px;
  align-self: stretch;
`;

const Div19 = styled.div`
  align-items: flex-start;
  border-radius: 16px;
  border: 1px solid var(--success-400, #32d583);
  padding-top: 2px;
  padding-left: 5px;
  padding-right: 20px;
  padding-bottom: 2px;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: 4.98828125px;
  flex-wrap: wrap;
`;

const A5 = styled.a`
  color: var(--success-500, #12b76a);
  text-align: center;
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  margin-top: -0px;
  align-self: center;
`;

const A6 = styled.a`
  align-items: center;
  border-radius: 16px;
  background: var(--success-400, #32d583);
  mix-blend-mode: multiply;
  padding-top: 5px;
  padding-left: 9px;
  padding-right: 9px;
  padding-bottom: 5px;
  align-self: stretch;
  color: var(--success-100, #d1fadf);
  text-align: center;
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  margin-top: 0px;
  margin-bottom: -0px;
`;

const Div20 = styled.div`
  align-items: flex-start;
  padding-top: 3px;
  padding-left: 1px;
  padding-right: 20px;
  padding-bottom: 3px;
  align-self: center;
  display: flex;
  flex-direction: row;
  gap: 4.8984375px;
  flex-wrap: wrap;
`;

const A7 = styled.a`
  color: var(--gray-700, #344054);
  text-align: center;
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  margin-top: 1px;
  align-self: stretch;
`;

const A8 = styled.a`
  color: var(--primary-500, var(--primary-500, #eaaa08));
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  text-decoration-line: underline;
  font-size: 11px;
  margin-top: 1px;
  align-self: stretch;
`;

const Div24 = styled.div`
  flex: 1 0 0;
  border-radius: 2px;
  background: var(--gray-500, #667085);
  width: 100%;
  max-width: 2px;
  margin-top: 4px;
  margin-bottom: 2px;
  align-self: center;
  display: flex;
  flex-direction: column;
  min-height: 229px;
`;

const Div78 = styled.div`
  margin-top: 4px;
  padding-right: 20px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
`;

const Div79 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Div81 = styled.div`
  flex: 1 0 0;
  border-radius: 2px;
  background: var(--gray-500, #667085);
  width: 100%;
  max-width: 2px;
  margin-top: 4px;
  align-self: center;
  display: flex;
  flex-direction: column;
  min-height: 516px;
`;

const Div82 = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  @media (max-width: 991px) {
    margin-top: 11px;
  }
`;

const Div83 = styled.div`
  justify-content: space-between;
  align-items: flex-start;
  align-self: start;
  width: 100%;
  max-width: 546px;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
`;

const Div84 = styled.div`
  align-items: flex-start;
  padding-top: 6px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  min-width: 236px;
`;

const Div85 = styled.div`
  align-items: flex-start;
  margin-top: 11px;
  padding-left: 1px;
  padding-right: 20px;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: 10.1171875px;
  flex-wrap: wrap;
`;

const A34 = styled.a`
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: 1px;
  align-self: center;
  text-align: center;
`;

const A35 = styled.a`
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid var(--gray-300, #d0d5dd);
  background: var(--base-white, #fff);
  padding-top: 6px;
  padding-left: 9px;
  padding-right: 9px;
  padding-bottom: 6px;
  align-self: stretch;
  color: var(--gray-700, #344054);
  text-align: center;
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  margin-top: 1px;
  margin-bottom: -0px;
`;

const Img26 = styled.img`
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 24px;
  align-self: center;
  min-width: 24px;
`;

const Div86 = styled.div`
  align-items: flex-start;
  margin-top: 8px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
`;

const Div87 = styled.div`
  align-items: flex-start;
  align-self: stretch;
  padding-top: 4px;
  padding-left: 2px;
  padding-right: 2px;
  padding-bottom: 4px;
  display: flex;
  flex-direction: column;
`;

const A36 = styled.a`
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: 1px;
  margin-left: 1px;
  align-self: start;
  @media (max-width: 991px) {
    margin-left: 1px;
  }
`;

const Div88 = styled.div`
  align-items: center;
  align-self: stretch;
  border: 1px solid var(--gray-300, #d0d5dd);
  background: var(--color-neutrals-00, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  margin-top: 8px;
  margin-bottom: -1px;
  padding-top: 10px;
  padding-left: 14px;
  padding-right: 14px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
`;

const Div89 = styled.div`
  align-items: center;
  flex: 1 0 0;
  padding-top: 2px;
  padding-right: 20px;
  padding-bottom: 2px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
`;

const Div90 = styled.div`
  width: 100%;
  align-self: start;
  display: flex;
  flex-direction: row;
  gap: 9.27734375px;
  align-items: flex-start;
  flex-wrap: wrap;
`;

const Img27 = styled.img`
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 20px;
  align-self: stretch;
`;

const A37 = styled.a`
  color: var(--gray-900, #101828);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-top: 4px;
  align-self: start;
`;

const Div91 = styled.div`
  align-items: flex-start;
  align-self: stretch;
  margin-top: 5px;
  padding-top: 4px;
  padding-left: 2px;
  padding-right: 2px;
  padding-bottom: 4px;
  display: flex;
  flex-direction: column;
`;

const A38 = styled.a`
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: 0px;
  margin-left: 1px;
  align-self: start;
  @media (max-width: 991px) {
    margin-left: 1px;
  }
`;

const A39 = styled.a`
  align-items: center;
  align-self: start;
  border: 1px solid var(--gray-300, #d0d5dd);
  background: var(--color-neutrals-00, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  margin-top: -0px;
  margin-bottom: -0px;
  padding-top: 14px;
  padding-left: 13px;
  padding-right: 20px;
  padding-bottom: 14px;
  flex: 1 0 0;
  color: var(--gray-900, #101828);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
`;

const Div92 = styled.div`
  align-items: flex-start;
  align-self: stretch;
  margin-top: 5px;
  padding-top: 4px;
  padding-left: 2px;
  padding-right: 2px;
  padding-bottom: 4px;
  display: flex;
  flex-direction: column;
`;

const A40 = styled.a`
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: 0px;
  margin-left: 1px;
  align-self: start;
  @media (max-width: 991px) {
    margin-left: 1px;
  }
`;

const A41 = styled.a`
  align-items: center;
  align-self: start;
  border: 1px solid var(--gray-300, #d0d5dd);
  background: var(--color-neutrals-00, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  margin-top: 2px;
  margin-bottom: -1px;
  padding-top: 14px;
  padding-left: 15px;
  padding-right: 20px;
  padding-bottom: 14px;
  flex: 1 0 0;
  color: var(--gray-900, #101828);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
`;

const Div93 = styled.div`
  align-items: flex-start;
  align-self: stretch;
  margin-top: 5px;
  padding-top: 4px;
  padding-left: 2px;
  padding-right: 2px;
  padding-bottom: 4px;
  display: flex;
  flex-direction: column;
`;

const Div94 = styled.div`
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: 0px;
  margin-left: 1px;
  align-self: start;
  @media (max-width: 991px) {
    margin-left: 1px;
  }
`;

const A42 = styled.a`
  align-items: center;
  align-self: start;
  border: 1px solid var(--gray-300, #d0d5dd);
  background: var(--color-neutrals-00, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  margin-top: 0px;
  margin-bottom: -0px;
  padding-top: 16px;
  padding-left: 15px;
  padding-right: 20px;
  padding-bottom: 16px;
  flex: 1 0 0;
  color: var(--gray-900, #101828);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
`;

const Div95 = styled.div`
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  margin-top: 5px;
  padding-top: 2px;
  padding-left: 2px;
  padding-right: 2px;
  padding-bottom: 2px;
  display: flex;
  flex-direction: column;
`;

const A43 = styled.a`
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: 2px;
  margin-left: 1px;
  align-self: start;
  @media (max-width: 991px) {
    margin-left: 1px;
  }
`;

const A44 = styled.a`
  align-items: flex-start;
  flex: 1 0 0;
  align-self: start;
  border: 1px solid var(--gray-300, #d0d5dd);
  background: var(--color-neutrals-00, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  margin-top: 8px;
  margin-bottom: 81px;
  padding-top: 18px;
  padding-left: 15px;
  padding-right: 20px;
  padding-bottom: 40px;
  color: var(--gray-900, #101828);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
`;
