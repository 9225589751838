/* eslint-disable no-script-url */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button, Form } from "react-bootstrap";
import { IconButton, TextareaAutosize } from "@mui/material";
import { AttachFile, Call } from "@mui/icons-material";
import { createMessage, listMessages } from "services/messageService";
import { useSelector } from "react-redux";



const ChatTab = ({ designerInfo, project }) => {
  const [message, setMessage] = useState("");
  const [connections_list] = useState([]);
  const [ chatHistory,  setChatHistory] = useState([]);
  const auth_token = localStorage.getItem("auth_token");
  const buyer = useSelector((state) => state.buyer.buyer);
  const designer = useSelector((state) => state.designer.designer);
  const [selectedAccount, setSelectedAccount] = useState(buyer ? buyer : designer);
  const [ setDynamicDate] = useState('');

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const loadChatHistoryByUserId = async (userId, selected_user) => {
    const data = {
      sender: selected_user,
      receiver: userId,
    };
    const resp = await listMessages(data, auth_token);
     setChatHistory(resp);
  };

  useEffect(() => {
    loadChatHistoryByUserId(buyer ? buyer.id : designer?.id, designerInfo.id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMessageSend = () => {
    if (message.trim() !== "") {
      const newMessage = {
        sender: buyer ? buyer.id : designer?.id,
        receiver: designerInfo.id,
        content: message,
      };
      createNewMessage(newMessage);
      setMessage("");
    }
  };

  const createNewMessage = async (values) => {
    const resp = await createMessage(values, auth_token);
    if (resp) {
      loadChatHistoryByUserId(buyer ? buyer.id : designer?.id, designerInfo.id);
    }
    return resp;
  };


  useEffect(() => {
    setSelectedAccount(buyer ? buyer : designer);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connections_list, selectedAccount?.id]);

  const messageDate = (createdAt) => {
    const dateObject = new Date(createdAt);
    const options = { weekday: "long", hour: "numeric", minute: "numeric", hour12: true };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(dateObject);
    return formattedDate;
  };

  const acceptedBids = project.bids.filter((bid) => bid.is_accepted).map((acceptedBid) => ({ ...acceptedBid, milestones: acceptedBid.milestones.slice().sort((a, b) => new Date(a.created_at) - new Date(b.created_at)), }));

  useEffect(() => {
    const targetDate = new Date('Jan 16, 2023');
    const currentDate = new Date();
    const timeDiff = currentDate - targetDate;
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

    if (daysDiff === 0) {
      setDynamicDate('Today');
    } else if (daysDiff === 1) {
      setDynamicDate('Yesterday');
    } else if (daysDiff >= 2 && daysDiff <= 6) {
      setDynamicDate(`Yesterday ${getCurrentDayOfWeek(currentDate)}`);
    } else {
      setDynamicDate(targetDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCurrentDayOfWeek = (date) => {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return daysOfWeek[date.getDay()];
  };

  return (
    <Div>
      {designerInfo && (
        <>
          <Div2>
            <Div3>
              <Div4 />
              <A href="javascript:void(0)">Today</A>
              <Div5 />
            </Div3>
            <Div6>
              {acceptedBids.map((milestone, index) => (
                <>
                  {milestone.is_approved && (
                    <Div7 key={index}>
                      <Div8>
                        <A2 href="javascript:void(0)">{`${designerInfo.first_name} ${designerInfo.last_name}`}</A2>
                        <A3 href="javascript:void(0)">{messageDate(milestone.created_at)}</A3>
                      </Div8>
                      <Div9>
                        <Div10>
                          <Img
                            loading="lazy"
                            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/1ce31ed4-76f1-4d32-86d0-61f25dbcfd38?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/1ce31ed4-76f1-4d32-86d0-61f25dbcfd38?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1ce31ed4-76f1-4d32-86d0-61f25dbcfd38?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/1ce31ed4-76f1-4d32-86d0-61f25dbcfd38?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/1ce31ed4-76f1-4d32-86d0-61f25dbcfd38?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1ce31ed4-76f1-4d32-86d0-61f25dbcfd38?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/1ce31ed4-76f1-4d32-86d0-61f25dbcfd38?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/1ce31ed4-76f1-4d32-86d0-61f25dbcfd38?"
                          />
                          <Div11>
                            <Div12>{milestone.name} approved</Div12>
                            <A4 href="javascript:void(0)">View details</A4>
                          </Div11>
                        </Div10>
                      </Div9>
                    </Div7>
                  )}
                </>
              ))}
              { chatHistory.length > 0 ? (
                <>
                  { chatHistory.map((item, index) => (
                    <>
                      {item.sender_object_id !== selectedAccount.id && (
                        <>
                          <Div13>
                            <Img2
                              loading="lazy"
                              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/62cc517a-a401-4739-9ed4-d503092cd84c?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/62cc517a-a401-4739-9ed4-d503092cd84c?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/62cc517a-a401-4739-9ed4-d503092cd84c?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/62cc517a-a401-4739-9ed4-d503092cd84c?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/62cc517a-a401-4739-9ed4-d503092cd84c?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/62cc517a-a401-4739-9ed4-d503092cd84c?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/62cc517a-a401-4739-9ed4-d503092cd84c?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/62cc517a-a401-4739-9ed4-d503092cd84c?" />
                            <Div14>
                              <Div15>
                                <A2 href="javascript:void(0)">{`${designerInfo.first_name} ${designerInfo.last_name}`}</A2>
                                <A6 href="javascript:void(0)">{messageDate(item.created_at)}</A6>
                              </Div15>
                              <Div16>
                                {item.content}
                              </Div16>
                            </Div14>
                          </Div13>
                          {item.media && (
                            <Div17>
                              <Img3
                                loading="lazy"
                                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/09a81fe5-6a33-4f15-8bc2-fafc7880f8b5?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/09a81fe5-6a33-4f15-8bc2-fafc7880f8b5?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/09a81fe5-6a33-4f15-8bc2-fafc7880f8b5?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/09a81fe5-6a33-4f15-8bc2-fafc7880f8b5?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/09a81fe5-6a33-4f15-8bc2-fafc7880f8b5?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/09a81fe5-6a33-4f15-8bc2-fafc7880f8b5?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/09a81fe5-6a33-4f15-8bc2-fafc7880f8b5?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/09a81fe5-6a33-4f15-8bc2-fafc7880f8b5?" />
                              <Div18>
                                <Div19>
                                  {message.sender_object_id !== selectedAccount.id && (<A2 href="javascript:void(0)">{`${designerInfo.first_name} ${designerInfo.last_name}`}</A2>)}
                                  <A8 href="javascript:void(0)">{messageDate(item.created_at)}</A8>
                                </Div19>
                                <Div20>
                                  <Div21>
                                    <img
                                      loading="lazy"
                                      alt="umojalin"
                                      srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/e366f755-5032-492c-98b2-d62b7cbf495d?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/e366f755-5032-492c-98b2-d62b7cbf495d?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e366f755-5032-492c-98b2-d62b7cbf495d?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/e366f755-5032-492c-98b2-d62b7cbf495d?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/e366f755-5032-492c-98b2-d62b7cbf495d?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e366f755-5032-492c-98b2-d62b7cbf495d?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/e366f755-5032-492c-98b2-d62b7cbf495d?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/e366f755-5032-492c-98b2-d62b7cbf495d?" />
                                    <Div22>
                                      <Div23>Shirt requirements.jpg</Div23>
                                      <A9 href="javascript:void(0)">1.2 MB</A9>
                                    </Div22>
                                  </Div21>
                                </Div20>
                              </Div18>
                            </Div17>
                          )}
                        </>
                      )}
                      {item.sender_object_id === selectedAccount.id && (
                        <>
                          <Div24>
                            <Div25>
                              <A10 href="javascript:void(0)">You</A10>
                              <A11 href="javascript:void(0)">{messageDate(item.created_at)}</A11>
                            </Div25>
                            <Div26>{item.content}</Div26>
                          </Div24>
                        </>
                      )}
                    </>
                  ))}
                </>
              ) : (
                <>
                  {/* <DivConv>
                    <DivConv2>No Conversation yet</DivConv2>
                  </DivConv> */}
                </>
              )}
            </Div6>
          </Div2>
          <div
            style={{ position: "relative", marginTop: "25px", width: "90%" }}
          >
            <Form>
              <TextareaAutosize
                placeholder="Send a message"
                style={{
                  width: "100%",
                  height: "10vh",
                  padding: "10px",
                  paddingLeft: "10px",
                  paddingTop: "10px",
                  resize: "none",
                }}
                value={message}
                onChange={handleMessageChange}
              />
              <Button
                style={{
                  position: "absolute",
                  bottom: "15px",
                  right: "16px",
                }}
                className="rounded-0"
                variant="warning"
                onClick={handleMessageSend}
              >
                Send
              </Button>
              <IconButton
                style={{
                  position: "absolute",
                  bottom: "16px",
                  right: "75px",
                }}
                size="small"
                color="default"
              >
                <AttachFile />
              </IconButton>
              <IconButton
                style={{
                  position: "absolute",
                  bottom: "16px",
                  right: "95px",
                }}
                size="small"
                color="default"
              >
                <Call />
              </IconButton>
            </Form>
          </div>
        </>
      )}
    </Div>
  );
}
export default ChatTab;


const Div = styled.div`
  align-items: center;
  background: var(--gray-25, #fcfcfd);
  width: 100%;
  // max-width: 638px;
  padding-bottom: 24px;
  align-self: end;
  display: flex;
  flex-direction: column;
`;

const Div2 = styled.div`
  justify-content: flex-end;
  align-items: flex-start;
  align-self: stretch;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
`;

const Div3 = styled.div`
  align-items: flex-start;
  align-self: center;
  width: 100%;
  padding-top: 3px;
  padding-right: 20px;
  padding-bottom: 3px;
  display: flex;
  flex-direction: row;
  gap: 8.390625px;
  flex-wrap: wrap;
  @media (max-width: 991px) {
    justify-content: center;
  }
`;

const Div4 = styled.div`
  flex: 1 0 0;
  background: var(--gray-200, #eaecf0);
  align-self: center;
  display: flex;
  flex-direction: column;
  min-height: 1px;
`;

const A = styled.a`
  color: var(--gray-600, #475467);
  text-align: center;
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: 2px;
  align-self: stretch;
`;

const Div5 = styled.div`
  flex: 1 0 0;
  background: var(--gray-200, #eaecf0);
  align-self: center;
  display: flex;
  flex-direction: column;
  min-height: 1px;
`;

const Div6 = styled.div`
  align-items: flex-start;
  align-self: center;
  width: 100%;
  max-width: 574px;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
`;

const Div7 = styled.div`
  align-items: flex-start;
  flex: 1 0 0;
  width: 100%;
  max-width: 560px;
  margin-left: 14px;
  align-self: start;
  display: flex;
  flex-direction: column;
  @media (max-width: 991px) {
    margin-left: 10px;
  }
`;

const Div8 = styled.div`
  align-items: flex-start;
  align-self: stretch;
  padding-top: 4px;
  padding-left: 1px;
  padding-right: 20px;
  padding-bottom: 4px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 560px;
`;

const A2 = styled.a`
  flex: 1 0 0;
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: 1px;
  align-self: stretch;
  min-width: 81px;
`;

const A3 = styled.a`
  color: var(--gray-600, #475467);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  margin-top: 1px;
  align-self: stretch;
  min-width: 102px;
`;

const Div9 = styled.div`
  align-items: center;
  align-self: stretch;
  border-radius: 8px 0px 8px 8px;
  border: 1px solid var(--success-200, #a6f4c5);
  background: var(--success-500, #12b76a);
  margin-top: 6px;
  padding-top: 8px;
  padding-left: 10px;
  padding-right: 14px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
`;

const Div10 = styled.div`
  align-items: flex-start;
  flex: 1 0 0;
  margin-top: -2px;
  margin-bottom: 2px;
  padding-right: 20px;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: 12px;
  flex-wrap: wrap;
`;

const Img = styled.img`
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 36px;
  align-self: start;
`;

const Div11 = styled.div`
  align-items: flex-start;
  align-self: start;
  margin-top: 4px;
  padding-top: 5px;
  padding-left: 0px;
  padding-right: 20px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
  flex-grow: 13.555555555555555;
`;

const Div12 = styled.div`
  align-self: start;
  color: var(--base-white, var(--color-neutrals-00, #fff));
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: -1px;
  margin-left: 1px;
  @media (max-width: 991px) {
    margin-left: 1px;
  }
`;

const A4 = styled.a`
  align-self: start;
  color: var(--base-white, var(--color-neutrals-00, #fff));
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  margin-top: 7px;
  margin-bottom: -0px;
`;

const Div13 = styled.div`
  align-items: flex-start;
  width: 100%;
  margin-top: 16px;
  padding-right: 20px;
  align-self: start;
  display: flex;
  flex-direction: row;
  gap: 10.5px;
  flex-wrap: wrap;
`;

const Img2 = styled.img`
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 41.5px;
  align-self: start;
`;

const Div14 = styled.div`
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  flex-grow: 12.240963855421686;
`;

const Div15 = styled.div`
  align-items: flex-start;
  align-self: stretch;
  padding-top: 4px;
  padding-left: 1px;
  padding-right: 20px;
  padding-bottom: 4px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 508px;
`;


const A6 = styled.a`
  color: var(--gray-600, #475467);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  margin-top: 1px;
  align-self: stretch;
  min-width: 102px;
`;

const Div16 = styled.div`
  align-items: center;
  align-self: start;
  border-radius: 0px 8px 8px 8px;
  background: var(--gray-100, #f2f4f7);
  margin-top: 2px;
  padding-top: 14px;
  padding-left: 15px;
  padding-right: 20px;
  padding-bottom: 14px;
  flex: 1 0 0;
  color: var(--gray-900, #101828);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: -1px;
`;

const Div17 = styled.div`
  align-items: flex-start;
  width: 100%;
  margin-top: 16px;
  padding-right: 20px;
  align-self: start;
  display: flex;
  flex-direction: row;
  gap: 10.5px;
  flex-wrap: wrap;
`;

const Img3 = styled.img`
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 41.5px;
  align-self: start;
`;

const Div18 = styled.div`
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  flex-grow: 12.240963855421686;
`;

const Div19 = styled.div`
  align-items: flex-start;
  align-self: stretch;
  padding-top: 5px;
  padding-left: 1px;
  padding-right: 20px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 508px;
`;


const A8 = styled.a`
  color: var(--gray-600, #475467);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  margin-top: 0px;
  align-self: stretch;
  min-width: 45px;
`;

const Div20 = styled.div`
  align-items: center;
  align-self: stretch;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--gray-200, #eaecf0);
  background: var(--color-neutrals-00, #fff);
  margin-top: 6px;
  padding-top: 8px;
  padding-left: 10px;
  padding-right: 14px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
`;

const Div21 = styled.div`
  align-items: flex-start;
  flex: 1 0 0;
  margin-top: -2px;
  margin-bottom: 2px;
  padding-right: 20px;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: 12px;
  flex-wrap: wrap;
`;

const Div22 = styled.div`
  align-items: flex-start;
  align-self: start;
  margin-top: 4px;
  padding-top: 5px;
  padding-left: 1px;
  padding-right: 20px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
  flex-grow: 12.11111111111111;
`;

const Div23 = styled.div`
  align-self: start;
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: -1px;
`;

const A9 = styled.a`
  align-self: start;
  color: var(--gray-600, #475467);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  margin-top: 7px;
  margin-left: 0px;
  margin-bottom: -0px;
  @media (max-width: 991px) {
    margin-left: 0px;
  }
`;

const Div24 = styled.div`
  align-items: flex-start;
  flex: 1 0 0;
  width: 100%;
  max-width: 330px;
  margin-top: 16px;
  align-self: end;
  display: flex;
  flex-direction: column;
`;

const Div25 = styled.div`
  align-items: flex-start;
  align-self: stretch;
  padding-top: 4px;
  padding-left: 0px;
  padding-right: 20px;
  padding-bottom: 4px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 330px;
`;

const A10 = styled.a`
  flex: 1 0 0;
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: 1px;
  align-self: stretch;
  min-width: 24px;
`;

const A11 = styled.a`
  color: var(--gray-600, #475467);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  margin-top: 1px;
  align-self: stretch;
  min-width: 100px;
`;

const Div26 = styled.div`
  align-items: center;
  align-self: stretch;
  border-radius: 8px 0px 8px 8px;
  background: var(--primary-500, #eaaa08);
  margin-top: 2px;
  padding-top: 14px;
  padding-left: 14px;
  padding-right: 18px;
  padding-bottom: 14px;
  flex: 1 0 0;
  color: var(--base-white, var(--color-neutrals-00, #fff));
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: -1px;
`;