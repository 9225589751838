import React, { useState, useEffect, useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";
import { listTeamMates } from "services/teamMatesService";
import Sidebar from "components/SideNav";
import Header from "components/Header";
import { Outlet } from "react-router-dom";
import { signOutAsync } from "store/signout/actions";
import { initAccountAsync } from "store/account/actions";
import Loader from "common/Loader";

export const workspaceRoutes = [
  {
    path: "/workspace/dashboard",
    title: "Dashboard",
    icon: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.4 1H2.6C2.03995 1 1.75992 1 1.54601 1.10899C1.35785 1.20487 1.20487 1.35785 1.10899 1.54601C1 1.75992 1 2.03995 1 2.6V6.4C1 6.96005 1 7.24008 1.10899 7.45399C1.20487 7.64215 1.35785 7.79513 1.54601 7.89101C1.75992 8 2.03995 8 2.6 8H6.4C6.96005 8 7.24008 8 7.45399 7.89101C7.64215 7.79513 7.79513 7.64215 7.89101 7.45399C8 7.24008 8 6.96005 8 6.4V2.6C8 2.03995 8 1.75992 7.89101 1.54601C7.79513 1.35785 7.64215 1.20487 7.45399 1.10899C7.24008 1 6.96005 1 6.4 1Z"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.4 1H13.6C13.0399 1 12.7599 1 12.546 1.10899C12.3578 1.20487 12.2049 1.35785 12.109 1.54601C12 1.75992 12 2.03995 12 2.6V6.4C12 6.96005 12 7.24008 12.109 7.45399C12.2049 7.64215 12.3578 7.79513 12.546 7.89101C12.7599 8 13.0399 8 13.6 8H17.4C17.9601 8 18.2401 8 18.454 7.89101C18.6422 7.79513 18.7951 7.64215 18.891 7.45399C19 7.24008 19 6.96005 19 6.4V2.6C19 2.03995 19 1.75992 18.891 1.54601C18.7951 1.35785 18.6422 1.20487 18.454 1.10899C18.2401 1 17.9601 1 17.4 1Z"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.4 12H13.6C13.0399 12 12.7599 12 12.546 12.109C12.3578 12.2049 12.2049 12.3578 12.109 12.546C12 12.7599 12 13.0399 12 13.6V17.4C12 17.9601 12 18.2401 12.109 18.454C12.2049 18.6422 12.3578 18.7951 12.546 18.891C12.7599 19 13.0399 19 13.6 19H17.4C17.9601 19 18.2401 19 18.454 18.891C18.6422 18.7951 18.7951 18.6422 18.891 18.454C19 18.2401 19 17.9601 19 17.4V13.6C19 13.0399 19 12.7599 18.891 12.546C18.7951 12.3578 18.6422 12.2049 18.454 12.109C18.2401 12 17.9601 12 17.4 12Z"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.4 12H2.6C2.03995 12 1.75992 12 1.54601 12.109C1.35785 12.2049 1.20487 12.3578 1.10899 12.546C1 12.7599 1 13.0399 1 13.6V17.4C1 17.9601 1 18.2401 1.10899 18.454C1.20487 18.6422 1.35785 18.7951 1.54601 18.891C1.75992 19 2.03995 19 2.6 19H6.4C6.96005 19 7.24008 19 7.45399 18.891C7.64215 18.7951 7.79513 18.6422 7.89101 18.454C8 18.2401 8 17.9601 8 17.4V13.6C8 13.0399 8 12.7599 7.89101 12.546C7.79513 12.3578 7.64215 12.2049 7.45399 12.109C7.24008 12 6.96005 12 6.4 12Z"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
  },
  {
    path: "/workspace/projects",
    title: "Projects",
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="stroke-current"
      >
        <path
          d="M22 12H18L15 21L9 3L6 12H2"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
    badge: (count) => (
      <div className="tw-text-xs-medium tw-text-[#344054] tw-bg-[#F2F4F7] tw-w-[24px] tw-h-[24px] tw-flex tw-items-center tw-justify-center tw-rounded-full tw-px-[8px] tw-py-[2px]">
        {count}
      </div>
    ),
    // component: ListProjects,
  },

  {
    path: "/workspace/jobs",
    title: "Jobs",
    icon: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19 19L14.65 14.65M9 4C11.7614 4 14 6.23858 14 9M17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9Z"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
    // component: Jobs,
  },

  {
    path: "/workspace/designers",
    title: "Designers",
    icon: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19 19L14.65 14.65M9 4C11.7614 4 14 6.23858 14 9M17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9Z"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
  },
  {
    path: "/workspace/sizing-templates",
    title: "Sizing Templates",
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21 11L13.4059 3.40589C12.887 2.88703 12.6276 2.6276 12.3249 2.44208C12.0564 2.27759 11.7638 2.15638 11.4577 2.08289C11.1124 2 10.7455 2 10.0118 2L6 2M3 8.7L3 10.6745C3 11.1637 3 11.4083 3.05526 11.6385C3.10425 11.8425 3.18506 12.0376 3.29472 12.2166C3.4184 12.4184 3.59136 12.5914 3.93726 12.9373L11.7373 20.7373C12.5293 21.5293 12.9253 21.9253 13.382 22.0737C13.7837 22.2042 14.2163 22.2042 14.618 22.0737C15.0747 21.9253 15.4707 21.5293 16.2627 20.7373L18.7373 18.2627C19.5293 17.4707 19.9253 17.0747 20.0737 16.618C20.2042 16.2163 20.2042 15.7837 20.0737 15.382C19.9253 14.9253 19.5293 14.5293 18.7373 13.7373L11.4373 6.43726C11.0914 6.09136 10.9184 5.9184 10.7166 5.79472C10.5376 5.68506 10.3425 5.60425 10.1385 5.55526C9.90829 5.5 9.6637 5.5 9.17452 5.5H6.2C5.0799 5.5 4.51984 5.5 4.09202 5.71799C3.7157 5.90973 3.40973 6.21569 3.21799 6.59202C3 7.01984 3 7.57989 3 8.7Z"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
    // component: SizingTemplates,
  },
  {
    path: "/workspace/connections",
    title: "Connections",
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22 21V19C22 17.1362 20.7252 15.5701 19 15.126M15.5 3.29076C16.9659 3.88415 18 5.32131 18 7C18 8.67869 16.9659 10.1159 15.5 10.7092M17 21C17 19.1362 17 18.2044 16.6955 17.4693C16.2895 16.4892 15.5108 15.7105 14.5307 15.3045C13.7956 15 12.8638 15 11 15H8C6.13623 15 5.20435 15 4.46927 15.3045C3.48915 15.7105 2.71046 16.4892 2.30448 17.4693C2 18.2044 2 19.1362 2 21M13.5 7C13.5 9.20914 11.7091 11 9.5 11C7.29086 11 5.5 9.20914 5.5 7C5.5 4.79086 7.29086 3 9.5 3C11.7091 3 13.5 4.79086 13.5 7Z"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
    // component: Connections,
  },
  {
    path: "/workspace/messages",
    title: "Messages",
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3 7.8C3 6.11984 3 5.27976 3.32698 4.63803C3.6146 4.07354 4.07354 3.6146 4.63803 3.32698C5.27976 3 6.11984 3 7.8 3H16.2C17.8802 3 18.7202 3 19.362 3.32698C19.9265 3.6146 20.3854 4.07354 20.673 4.63803C21 5.27976 21 6.11984 21 7.8V13.2C21 14.8802 21 15.7202 20.673 16.362C20.3854 16.9265 19.9265 17.3854 19.362 17.673C18.7202 18 17.8802 18 16.2 18H13.6837C13.0597 18 12.7477 18 12.4492 18.0613C12.1844 18.1156 11.9282 18.2055 11.6875 18.3285C11.4162 18.4671 11.1725 18.662 10.6852 19.0518L8.29976 20.9602C7.88367 21.2931 7.67563 21.4595 7.50054 21.4597C7.34827 21.4599 7.20422 21.3906 7.10923 21.2716C7 21.1348 7 20.8684 7 20.3355V18C6.07003 18 5.60504 18 5.22354 17.8978C4.18827 17.6204 3.37962 16.8117 3.10222 15.7765C3 15.395 3 14.93 3 14V7.8Z"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
    badge: (count) => (
      <div className="tw-text-xs-medium tw-text-[#344054] tw-bg-[#F2F4F7] tw-w-[24px] tw-h-[24px] tw-flex tw-items-center tw-justify-center tw-rounded-full tw-px-[8px] tw-py-[2px]">
        {count}
      </div>
    ),
    // component: Messages,
  },
  {
    path: "/workspace/wallet",
    title: "Wallet",
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16 8.00227V4.50292C16 3.67119 16 3.25532 15.8248 2.99974C15.6717 2.77645 15.4346 2.62477 15.1678 2.57935C14.8623 2.52737 14.4847 2.70164 13.7295 3.05019L4.85901 7.14426C4.18551 7.45511 3.84875 7.61054 3.60211 7.85159C3.38406 8.06469 3.21762 8.32482 3.1155 8.6121C3 8.93706 3 9.30795 3 10.0497V15.0023M16.5 14.5023H16.51M3 11.2023L3 17.8023C3 18.9224 3 19.4824 3.21799 19.9102C3.40973 20.2866 3.71569 20.5925 4.09202 20.7843C4.51984 21.0023 5.07989 21.0023 6.2 21.0023H17.8C18.9201 21.0023 19.4802 21.0023 19.908 20.7843C20.2843 20.5925 20.5903 20.2866 20.782 19.9102C21 19.4824 21 18.9224 21 17.8023V11.2023C21 10.0822 21 9.52211 20.782 9.09429C20.5903 8.71797 20.2843 8.412 19.908 8.22026C19.4802 8.00227 18.9201 8.00227 17.8 8.00227L6.2 8.00227C5.0799 8.00227 4.51984 8.00227 4.09202 8.22026C3.7157 8.412 3.40973 8.71796 3.21799 9.09429C3 9.52211 3 10.0822 3 11.2023ZM17 14.5023C17 14.7784 16.7761 15.0023 16.5 15.0023C16.2239 15.0023 16 14.7784 16 14.5023C16 14.2261 16.2239 14.0023 16.5 14.0023C16.7761 14.0023 17 14.2261 17 14.5023Z"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
    // component: Wallet,
  },
  {
    path: "/workspace/escrow",
    title: "Escrow",
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5 8.99904V16.999M9.5 8.99904V16.999M14.5 8.99904V16.999M19 8.99904V16.999M3 18.599L3 19.399C3 19.9591 3 20.2391 3.10899 20.453C3.20487 20.6412 3.35785 20.7942 3.54601 20.89C3.75992 20.999 4.03995 20.999 4.6 20.999H19.4C19.9601 20.999 20.2401 20.999 20.454 20.8901C20.6422 20.7942 20.7951 20.6412 20.891 20.453C21 20.2391 21 19.9591 21 19.399V18.599C21 18.039 21 17.759 20.891 17.5451C20.7951 17.3569 20.6422 17.2039 20.454 17.108C20.2401 16.999 19.9601 16.999 19.4 16.999H4.6C4.03995 16.999 3.75992 16.999 3.54601 17.108C3.35785 17.2039 3.20487 17.3569 3.10899 17.5451C3 17.759 3 18.039 3 18.599ZM11.6529 3.07617L4.25291 4.72062C3.80585 4.81997 3.58232 4.86964 3.41546 4.98985C3.26829 5.09588 3.15273 5.23994 3.08115 5.40661C3 5.59557 3 5.82455 3 6.28252L3 7.39904C3 7.9591 3 8.23912 3.10899 8.45303C3.20487 8.6412 3.35785 8.79418 3.54601 8.89005C3.75992 8.99904 4.03995 8.99904 4.6 8.99904H19.4C19.9601 8.99904 20.2401 8.99904 20.454 8.89005C20.6422 8.79418 20.7951 8.6412 20.891 8.45303C21 8.23912 21 7.9591 21 7.39904V6.28252C21 5.82455 21 5.59557 20.9188 5.40661C20.8473 5.23994 20.7317 5.09588 20.5845 4.98985C20.4177 4.86964 20.1942 4.81997 19.7471 4.72062L12.3471 3.07617C12.2176 3.04739 12.1528 3.033 12.0874 3.02726C12.0292 3.02216 11.9708 3.02216 11.9126 3.02726C11.8472 3.033 11.7824 3.04739 11.6529 3.07617Z"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
    // component: EscrowHome,
  },
];

const buyerRoutes = ["/workspace/projects", "/workspace/designers"];

const designerRoutes = ["/workspace/jobs", "/workspace/dashboard"];

function WorkSpace({ children }) {
  const { active, loading } = useSelector((state) => state.account);
  const [team_mates, setTeamMates] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const dispatch = useDispatch();
  const filteredRoutes = () => {
    if (active?.account_type.toLowerCase() === "buyer") {
      return workspaceRoutes.filter(
        (route) => !designerRoutes.includes(route.path)
      );
    } else if (active?.account_type.toLowerCase() === "designer") {
      return workspaceRoutes.filter(
        (route) => !buyerRoutes.includes(route.path)
      );
    }
    return workspaceRoutes;
  };
  const handleLogout = useCallback(
    async (e) => {
      e.preventDefault();
      dispatch(signOutAsync());
      window.location.href = "/signin";
    },
    []
  );

  useEffect(() => {
    dispatch(initAccountAsync());
   
  }, []);

  useEffect(()=> {
    if (active) {
      const loadTeamMatesByUserId = async () => {
        try {
          const resp = await listTeamMates(active?.id);
          setTeamMates(resp);
        } catch (err) {
          console.error(err);
        }
      };
      loadTeamMatesByUserId();
    }
  }, [active])


  if (loading) return <Loader />;

  return (
    <div className="">
      {/* <!-- ===== Page Wrapper Start ===== --> */}
      <div className="tw-flex tw-h-screen tw-overflow-hidden">
        {/* <!-- ===== Sidebar Start ===== --> */}
        <Sidebar
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          routes={filteredRoutes()}
          logout={handleLogout}
          account={active}
        />
        {/* <!-- ===== Sidebar End ===== --> */}

        {/* <!-- ===== Content Area Start ===== --> */}
        <div className="tw-relative tw-flex tw-flex-1 tw-flex-col tw-overflow-y-auto tw-overflow-x-hidden">
          {/* <!-- ===== Header Start ===== --> */}
          <Header
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
            logout={handleLogout}
            account={active}
          />
          {/* <!-- ===== Header End ===== --> */}

          {/* <!-- ===== Main Content Start ===== --> */}
          <main className="tw-h-screen tw-overflow-y-auto tw-overflow-x-hidden tw-border-[#EAECF0] !tw-border-l-2 !tw-border-t-2">
            <Outlet />
          </main>
          {/* <!-- ===== Main Content End ===== --> */}
        </div>
        {/* <!-- ===== Content Area End ===== --> */}
      </div>
      {/* <!-- ===== Page Wrapper End ===== --> */}
    </div>
  );
}

export default WorkSpace;
