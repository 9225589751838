import { handleUploadImage } from "constants/Utils";
import React, { useState, useRef } from "react";

export default function Upload({
  handleInputChange,
  gallery,
  multiple = true,
}) {
  const [dragging, setDragging] = useState(false);
  const fileInputRef = useRef(null);

  const handleButtonClick = (e) => {
    e.preventDefault();
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const files = e.dataTransfer.files;
    handleFiles(files);
  };

  const handleFiles = async (files) => {
    if (multiple) {
      const newImages = [...gallery];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file.type.startsWith("image/")) {
          const url = await handleUploadImage(file);
          newImages.push({
            image_title: "",
            image: file,
            is_cover_image: false,
            imageUrl: URL.createObjectURL(file),
            image_url: url.secure_url,
          });
        }
      }
      handleInputChange(newImages);
    } else {
      const file = files[0];
      if (file.type.startsWith("image/")) {
        const url = await handleUploadImage(file);
        const imageObject = {
          image: file,
          imageUrl: URL.createObjectURL(file),
          image_url: url.secure_url,
        };
        handleInputChange(imageObject);
      }
    }
    // Reset the file input value
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const handleInputChangeInternal = (e) => {
    const files = e.target.files;
    handleFiles(files);
  };

  return (
    <div
      className="tw-py-[16px] tw-px-[24px] tw-border tw-border-[#D0D5DD] tw-w-full xl:tw-w-[512px] tw-flex tw-flex-col tw-justify-center tw-items-center"
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <img
        loading="lazy"
        onClick={handleButtonClick}
        className="tw-mb-[12px]"
        alt=""
        style={{ cursor: "pointer" }}
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/bb7cfd0cb734001454a14b62ece96817e37404c0362eb995dbd174e1c5e5fe85?"
      />
      <div>
        {dragging ? (
          <span className="tw-text-sm-semi-bold tw-mb-[4px]"> Drop here </span>
        ) : (
          <div
            style={{ cursor: "pointer" }}
            onClick={handleButtonClick}
            className="tw-text-sm-regular tw-mb-[4px]"
          >
            <span className="tw-text-yellow-500 tw-text-sm-semi-bold">
              Click to upload{" "}
            </span>
            or drag and drop
          </div>
        )}
      </div>
      <div> Pictures (max. 50mb)</div>
      <div>
        <input
          ref={fileInputRef}
          type="file"
          id="fileInput"
          accept="image/*"
          multiple={multiple}
          onChange={handleInputChangeInternal}
          name="file"
          className="d-none"
        />
      </div>
    </div>
  );
}
