import { fireRequest, HttpMethod } from "./axios";

export const listBids = async (projectId, designerId) => {
  try {
    let url =
      designerId && projectId
        ? `/project/bid/list/?project_id=${projectId}&designer_id=${designerId}`
        : projectId
        ? `/project/bid/list/?project_id=${projectId}`
        : `/project/bid/list/?designer_id=${designerId}`;
    const response = await fireRequest(
      {
        url,
        method: HttpMethod.GET,
      },
      true
    );

    return response.data;
  } catch (error) {
    throw error.data;
  }
};

export const createBid = async (values) => {
  try {
    const response = await fireRequest(
      {
        url: `/project/bid/create/`,
        method: HttpMethod.POST,
        body: values,
      },
      true
    );

    return response.data;
  } catch (error) {
    throw error.data;
  }
};

export const updateBid = async (values) => {
  try {
    const response = await fireRequest(
      {
        url: `/project/bid/update`,
        method: HttpMethod.PUT,
        body: values,
      },
      true
    );

    return response.data;
  } catch (error) {
    throw error.data;
  }
};

export const bidDetails = async (id) => {
  try {
    const response = await fireRequest(
      {
        url: `/project/bid/details/${id}`,
        method: HttpMethod.GET,
      },
      true
    );
    return response.data;
  } catch (error) {
    throw error.data;
  }
};

export const acceptBid = async (id) => {
  try {
    const response = await fireRequest(
      {
        url: `/project/bid/${id}/accept/`,
        method: HttpMethod.POST,
        body: { bidId: id },
      },
      true
    );

    return response.data;
  } catch (error) {
    throw error.data;
  }
};

export const rejectBid = async (id, values) => {
  try {
    const response = await fireRequest(
      {
        url: `/project/bid/${id}/reject/`,
        method: HttpMethod.POST,
        body: values,
      },
      true
    );

    return response.data;
  } catch (error) {
    throw error.data;
  }
};
