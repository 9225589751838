import { logoutUser } from "store/actions/userActions";
import { HttpMethod, fireRequest } from "./axios";
import { storageService } from "services";

export const login = async (credentials) => {
  try {
    const response = await fireRequest({
      url: "/auth/login/",
      body: credentials,
      method: HttpMethod.POST,
    });
    storageService.saveAuthData({
      ...response.data,
      account_type: response.data.buyer_id ? "Buyer" : "Designer",
      account_id: response.data.buyer_id
        ? response.data.buyer_id
        : response.data.designer_id,
    });
    return response;
  } catch (error) {
    throw error.data;
  }
};

export const registerUser = async (userData) => {
  try {
    const response = await fireRequest({
      url: "/auth/register",
      body: userData,
      method: HttpMethod.POST,
    });

    return response;
  } catch (error) {
    throw error.data;
  }
};

export const listUserAccounts = async (userData) => {
  try {
    const response = await fireRequest({
      url: "/auth/list-accounts/",
      body: userData,
      method: HttpMethod.POST,
    });

    return response;
  } catch (error) {
    throw error.data;
  }
};

// Logout action
export const logout = () => {
  return (dispatch) => {
    // Perform any necessary cleanup or API calls
    dispatch(logoutUser());
  };
};

//TODO: fetch actual user data
export const fetchUserData = async (userId) => {
  // Simulate an API call to fetch user data
  await new Promise((resolve) => setTimeout(resolve, 1000));
  // Replace this with actual API call
  return {
    id: 1233345677890,
    name: "Example User",
    email: "user@example.com",
  };
};

export const confirmEmail = async (token) => {
  try {
    const response = await fireRequest({
      url: `/auth/verify/${token}`,
      method: HttpMethod.GET,
    });

    return response;
  } catch (error) {
    throw error.data;
  }
};

export const resendEmail = async (email) => {
  try {
    const response = await fireRequest({
      url: `/auth/resend-email-link/${email}`,
      method: HttpMethod.GET,
    });

    return response;
  } catch (err) {
    throw err.data;
  }
};

export const forgotPassword = async (email) => {
  try {
    const response = await fireRequest({
      url: `/auth/reset-password-email/${email}/`,
      method: HttpMethod.POST,
    });

    return response;
  } catch (err) {
    throw err.data;
  }
};

export const changePassword = async (data) => {
  try {
    const response = await fireRequest({
      url: `/auth/reset-password`,
      body: data,
      method: HttpMethod.PATCH,
    });
    return response;
  } catch (err) {
    throw err.data;
  }
};

export const googleCallback = async (data) => {
  try {
    console.log("google callback", data);
    const response = await fireRequest({
      url: `/auth/google-callback/`,
      body: data,
      method: HttpMethod.POST,
    });
    return response;
  } catch (err) {
    throw err.data;
  }
};

export const facebookCallback = async (data) => {
  try {
    console.log("facebook cb", data);
    const response = await fireRequest({
      url: `/auth/facebook-callback/`,
      body: data,
      method: HttpMethod.POST,
    });
    return response;
  } catch (err) {
    throw err.data;
  }
};

export const createUser = async (data) => {
  try {
    const response = await fireRequest({
      url: `/auth/create-user`,
      body: data,
      method: HttpMethod.POST,
    });
    return response;
  } catch (err) {
    throw err.data;
  }
};

export const getUser = async () => {
  try {
    const response = await fireRequest(
      {
        url: `/auth/users`,
        method: HttpMethod.GET,
      },
      true
    );
    return response;
  } catch (err) {
    throw err.data;
  }
};
