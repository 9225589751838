import React from "react";
import { Row} from "react-bootstrap";
import styled from "styled-components";
import {
  Elements
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CardComponent from "./CardComponent";

const stripePromise = loadStripe("pk_test_6uqRRgbOe3pKzJ61krkP5Abj00hNXltBZ7");

const AddCard = () => {
  return (
    <>
      <Row className="projects-tab-bg">
        <BDiv>
          <BImg
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/f29c5d03-56e5-4b9d-84ce-8a306518f122?"
          />
          <img
            loading="lazy"
            alt="wallet"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2c31c096-bf86-40ad-b8ae-e06932cb3085?"
          />
          <BDiv2>Wallet</BDiv2>
          <img
            loading="lazy"
            alt="card"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/d1b49d32-fae7-4129-960a-bda1de650b39?"
          />
          <BDiv2>Add new card</BDiv2>
        </BDiv>
      </Row>
      <Elements stripe={stripePromise}>
        <CardComponent />
      </Elements>
    </>
  );
};

export default AddCard;
const BDiv = styled.div`
  align-items: flex-start;
  display: flex;
  gap: 8px;
  height: 20px;
  @media (max-width: 991px) {
    justify-content: center;
  }
`;

const BImg = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
`;

const BDiv2 = styled.div`
  color: var(--gray-600, #475467);
  align-self: center;
  margin: auto 0;
  font: 500 14px/20px Inter, sans-serif;
`;

//TODO: remove

// const BDiv3 = styled.div`
//   color: var(--gray-700, #344054);
//   white-space: nowrap;
//   justify-content: center;
//   border-radius: 6px;
//   background-color: var(--gray-50, #f9fafb);
//   align-self: stretch;
//   aspect-ratio: 2.4642857142857144;
//   padding: 4px 20px;
//   font: 600 14px/20px Inter, sans-serif;
//   @media (max-width: 991px) {
//     white-space: initial;
//   }
// `;
