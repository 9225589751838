import React from "react";
import Image from "assets/svg/image.svg";
import { differenceInDays, format, isPast } from "date-fns";
import { capitalizeFirstLetter } from "constants/Utils";

export default function TaskCard({ bid, user }) {
  const formatDueDate = (end_date) => {
    const dueDate = new Date(end_date);
    const currentDate = new Date();

    const dueMonthDayFormat = "MMMM d";

    const formattedDueDate = isPast(dueDate)
      ? `Due on ${format(dueDate, dueMonthDayFormat)}`
      : `Due in ${differenceInDays(dueDate, currentDate)} days`;

    return formattedDueDate;
  };
  return (
    <div className="tw-border tw-border-[#F2F4F7] tw-p-[16px] tw-w-full  tw-cursor-pointer tw-bg-white tw-mb-[24px]">
      <div className="tw-mb-[8px]">
        <div className="tw-flex tw-items-center tw-gap-[4px]">
          <div
            className={`tw-w-[6px] tw-h-[6px] tw-rounded-full tw-bg-gray-100 ${
              bid?.project_detail?.project_type === "solo" && "!tw-bg-[#53B1FD]"
            }`}
          ></div>
          <h6
            className={`tw-text-sm-medium ${
              bid?.project_detail?.project_type === "solo" &&
              "!tw-text-[#53B1FD]"
            }`}
          >
            {capitalizeFirstLetter(bid?.project_detail?.project_type)}
          </h6>
        </div>
        <h6 className="tw-text-sm-semi-bold">{bid?.project_detail.title}</h6>
      </div>
      <img
        src={bid?.project_detail?.cover_image_url || Image}
        alt=""
        className="tw-w-full tw-h-[91px] tw-object-cover tw-object-top"
      />
      <div className="tw-my-[16px] tw-flex tw-gap-[8px] tw-items-center">
        {bid.milestones.map((milestone, index) => {
          return (
            <div className="tw-h-[6px] tw-rounded-[3px] tw-bg-[#F2F4F7] tw-flex-1" key={index}></div>
          );
        })}
      </div>
      <h5 className="tw-text-sm-medium tw-text-[#475467] tw-mb-[6px]">
        {formatDueDate(bid?.project_detail?.end_date)}
      </h5>
      <div className="tw-flex tw-justify-between tw-items-center">
        <div>
          <img
            src={`https://ui-avatars.com/api/?background=EAAA08&color=ffffff&name=${user.first_name}`}
            className="tw-w-[24px] tw-h-[24px] tw-rounded-full"
            alt=""
          />
        </div>

        {/* <h6 className="tw-text-xs-medium">Updated Proposal</h6> */}
      </div>
    </div>
  );
}
