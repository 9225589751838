import React, {useState} from "react";
import { Row } from "react-bootstrap";
import styled from "styled-components";
import { useTheme } from "@mui/material";
import TabPanel from "components/Tabs/TabPanel";
import TeammatesTab from "./TeammatesTab";
import GroupProjectsTab from "./GroupProjectsTab";

const MembershipandPermissions = (props) => {
  const [activeTab, setActiveTabValue] = useState(0);

  const theme = useTheme;

  const tabChange = (newValue) => {
    setActiveTabValue(newValue);
  };

  return (
    <>
      <Row className="projects-tab-bg">
        <BDiv>
          <BImg
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/a193718d-3ddf-4daf-a0be-76fcaaab41ed?"
          />
          <img
            loading="lazy"
            alt="umojalin"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/a086deea-8a6e-46ea-8b30-609c4f671c88?"
          />
          <BDiv2>Profile</BDiv2>
          <img
            loading="lazy"
            alt="umojalin"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/8f3947e0-ef61-4ff6-8723-ade9dd558a1e?"
          />
        </BDiv>
      </Row>
      <Row>
      <Div>
      <Div2>
        <Div3>Control & Permissions</Div3>
        <Div4>Manage your team members and account permissions</Div4>
        <Img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/04b7959e-939e-4c95-8f94-1211923a0d71?"
        />
      </Div2>
      <Div5>
        <Div6>
          <Div7 className={activeTab === 0 ? "active-permissions-tab" : "inactive-permissions-tab"} onClick={() => tabChange(0)}>Teammates</Div7>
          <Div7 className={activeTab === 1 ? "active-permissions-tab" : "inactive-permissions-tab"} onClick={() => tabChange(1)}>Group Projects</Div7>
        </Div6>
      </Div5>
      <TabPanel value={activeTab} index={0} dir={theme.direction}>
          <TeammatesTab />
        </TabPanel>
        <TabPanel value={activeTab} index={1} dir={theme.direction}>
          <GroupProjectsTab />
        </TabPanel>
      <Div81>
        <button className="btn-cancel-teammate-add">Cancel</button>
        <button className="btn-teammate-add">Save</button>
      </Div81>
    </Div>
      </Row>
    </>
  );
};

export default MembershipandPermissions;

const BDiv = styled.div`
  display: flex;
  gap: 14px;
  height: 20px;
  @media (max-width: 991px) {
    justify-content: center;
  }
`;

const BImg = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
  overflow: hidden;
  max-width: 100%;
`;

const BDiv2 = styled.div`
  color: var(--gray-600, #475467);
  font: 500 14px/143% Inter, sans-serif;
`;

const Div = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
`;

const Div2 = styled.div`
  align-self: center;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0 20px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div3 = styled.div`
  color: var(--gray-900, #101828);
  margin-right: -20px;
  font: 600 18px/156% Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div4 = styled.div`
  color: var(--gray-600, #475467);
  margin: 4px -20px 0 0;
  font: 400 14px/143% Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Img = styled.img`
  aspect-ratio: 1093;
  object-fit: contain;
  object-position: center;
  width: 100%;
  fill: var(--gray-200, #eaecf0);
  overflow: hidden;
  margin: 20px -20px 0 0;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div5 = styled.div`
  align-items: start;
  border-bottom: 1px solid var(--gray-200, #eaecf0);
  align-self: end;
  display: flex;
  margin-top: 24px;
  width: 100%;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div6 = styled.div`
  display: flex;
  width: 235px;
  max-width: 100%;
  gap: 20px;
  padding: 0 20px;
`;

const Div7 = styled.div`
  cursor: pointer;
`;

const Div81 = styled.div`
  justify-content: flex-end;
  align-self: end;
  display: flex;
  margin-top: 20px;
  width: 157px;
  max-width: 100%;
  gap: 12px;
`;