import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  deleteCollaborator,
  listAllCollaborators,
} from "services/collaboratorService";
import styled from "styled-components";
import { Form } from "react-bootstrap";
import ProfileHolder from "assets/images/icons/user-01.svg";

const GroupProjectsTab = () => {
  const { active } = useSelector((state) => state.account);
  const [selectedProject, setSelectedProject] = useState();
  const [group_projects, setGroupProjects] = useState([]);
  const auth_token = localStorage.getItem("auth_token");
  const [selectedUsers, setSelectedUsers] = useState([]);

  const loadCollaborators = async () => {
    const resp = await listAllCollaborators(auth_token);
    const groupProjects = resp.filter((item) => item.buyer.id === active.id);
    setSelectedProject(group_projects[0]?.project);
    setGroupProjects(groupProjects);
  };

  useEffect(() => {
    loadCollaborators();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group_projects]);

  const handleUserSelection = (userId) => {
    setSelectedUsers((prevSelected) => {
      if (prevSelected.includes(userId)) {
        return prevSelected.filter((id) => id !== userId);
      } else {
        return [...prevSelected, userId];
      }
    });
  };

  const handleSelectAll = () => {
    const allUserIds = selectedProject.collaborators.map((user) => user.id);
    setSelectedUsers(allUserIds);
  };

  const handleDeleteCollaborator = async (collboratorId) => {
    const resp = await deleteCollaborator(collboratorId, auth_token);
    if (resp) {
      window.href = "/workspace/settings/permissions";
    }
  };

  const handleSelectedProject = (project) => {
    setSelectedProject(project);
  };

  return (
    <Div>
      <Div9>
        <Div10>Groups I’m on</Div10>
        <Div11>You’re currently on these groups.</Div11>
      </Div9>
      {group_projects.map((item, index) => (
        <Div12 key={index}>
          <Div13 />
          <Div14>
            <Div15>
              <Img2 loading="lazy" srcSet={item.project.cover_image} />
              <Div16>
                <Div17
                  style={{ cursor: "pointer" }}
                  onClick={() => handleSelectedProject(item.project)}
                >
                  {item.project.title}
                </Div17>
                <Div18>{item.project.collaborators.length} Collaborators</Div18>
              </Div16>
            </Div15>
            <Div19>Leave</Div19>
          </Div14>
          <Div20 />
        </Div12>
      ))}
      <Div21>
        <Div22>{selectedProject?.title}</Div22>
        <Div23>Manage your existing group and change roles/permissions.</Div23>
      </Div21>
      <Div24>
        {selectedProject?.collaborators.length > 0 && (
          <TableContainer className="mt-5">
            <Table>
              <TableHead className="bg-secondary">
                <TableRow>
                  <TableCell>
                    <Checkbox
                      checked={
                        selectedUsers.length ===
                        selectedProject.collaborators.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell>
                    <Typography>Name</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>Permissions</Typography>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedProject.collaborators.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Checkbox
                        checked={selectedUsers.includes(item.id)}
                        onChange={() => handleUserSelection(item.id)}
                      />
                    </TableCell>
                    <TableCell>
                      <div style={{ display: "flex", alignItems: "left" }}>
                        <CustomAvatar size="30px">
                          <AvatarImage
                            alt={`${item.buyer.first_name}`}
                            src={
                              item.buyer?.profile_image
                                ? `${process.env.REACT_APP_MEDIA_URL}${item.buyer?.profile_image}`
                                : ProfileHolder
                            }
                          />
                        </CustomAvatar>
                        <div style={{ marginLeft: "8px" }}>
                          <Typography>
                            {item.buyer.first_name} {item.buyer.last_name}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {item.buyer?.user?.email}
                          </Typography>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <Form.Control
                        as="select"
                        name="projects_role"
                        className="form-control-lg"
                      >
                        <option value="">None</option>
                        <option value="Viewer">Viewer</option>
                        <option value="Editor">Editor</option>
                        <option value="Admin">Admin</option>
                      </Form.Control>
                    </TableCell>
                    <TableCell>
                      <Div57
                        style={{ cursor: "pointer" }}
                        onClick={() => handleDeleteCollaborator(item.id)}
                      >
                        Remove
                      </Div57>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Div24>
    </Div>
  );
};

export default GroupProjectsTab;

const Div = styled.div`
  display: flex;
  width: 800px;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div9 = styled.div`
  display: flex;
  margin-top: 24px;
  width: 100%;
  flex-direction: column;
  padding: 0 20px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div10 = styled.div`
  color: var(--gray-700, #344054);
  margin-right: -20px;
  width: 100%;
  font: 500 14px/143% Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div11 = styled.div`
  color: #475467;
  margin-right: -20px;
  width: 100%;
  font: 400 14px/143% Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div12 = styled.div`
  display: flex;
  margin-top: 40px;
  width: 100%;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div13 = styled.div`
  background-color: var(--gray-200, #eaecf0);
  display: flex;
  min-height: 1px;
  width: 100%;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div14 = styled.div`
  justify-content: space-between;
  display: flex;
  margin-top: 16px;
  width: 100%;
  gap: 20px;
  padding: 0 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Div15 = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
`;

const Img2 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 40px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
`;

const Div16 = styled.div`
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
`;

const Div17 = styled.div`
  color: var(--gray-700, #344054);
  white-space: nowrap;
  font: 600 14px/143% Inter, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div18 = styled.div`
  color: var(--gray-600, #475467);
  white-space: nowrap;
  font: 400 14px/143% Inter, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div19 = styled.div`
  color: var(--gray-600, #475467);
  align-self: center;
  white-space: nowrap;
  margin: auto 0;
  font: 600 14px/143% Inter, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div20 = styled.div`
  background-color: var(--gray-200, #eaecf0);
  display: flex;
  min-height: 1px;
  margin-top: 16px;
  width: 100%;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div21 = styled.div`
  display: flex;
  margin-top: 24px;
  width: 100%;
  flex-direction: column;
  padding: 0 20px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div22 = styled.div`
  color: var(--gray-700, #344054);
  margin-right: -20px;
  width: 100%;
  font: 500 14px/143% Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div23 = styled.div`
  color: var(--gray-600, #475467);
  margin-right: -20px;
  width: 100%;
  font: 400 14px/143% Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div24 = styled.div`
  border: 1px solid var(--gray-200, #eaecf0);
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 1px 2px 0px rgba(16, 24, 40, 0.06);
  background-color: var(--color-neutrals-00, #fff);
  display: flex;
  margin-top: 24px;
  width: 100%;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div57 = styled.div`
  color: var(--error-400, #f97066);
  align-self: center;
  white-space: nowrap;
  margin: auto 0;
  font: 600 14px/143% Inter, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const CustomAvatar = styled.div`
  width: ${(props) => props.size || "30px"};
  height: ${(props) => props.size || "30px"};
  border-radius: 50%;
  overflow: hidden;
`;

const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
