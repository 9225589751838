import { HttpMethod, fireRequest } from "./axios";

export const list = async () => {
  try {
    const response = await fireRequest({
      url: `/project/clothing-type/list/`,
      method: HttpMethod.GET

    }, true)

    return response.data;
  } catch (error) {
    throw error.data;
  }
};


export const createClothingType = async (value) => {
  try {
    const response = await fireRequest({
      url: `/project/clothing-type/create/`,
      method: HttpMethod.POST,
      body: {name: value}

    }, true)

    return response.data;
  } catch (error) {
    throw error.data;
  }
};
