import * as React from "react";
import styled from "styled-components";
import Avatar from "assets/images/icons/avatar-designer.svg";

const DeleteConnection = ({props, usertoDelete}) => {
  return (
    <Div>
      <Img
        loading="lazy"
        srcSet={Avatar}
      />
      <Div2>
        <Div3>
          <Div4>
            {usertoDelete.usertype === "buyer_designer" ? (
              <Div5>{`${usertoDelete.objecttoDelete.buyer_designer.first_name}${" "}${usertoDelete.objecttoDelete.buyer_designer.last_name}`}</Div5>
            ):(
              <Div5>{`${usertoDelete.objecttoDelete.created_by.first_name}${" "}${usertoDelete.objecttoDelete.created_by.last_name}`}</Div5>
            )}
            
            <Div6>
              Are you sure you want to delete this user? This action cannot be
              undone.
            </Div6>
          </Div4>
        </Div3>
      </Div2>
      <Div7>
        <Img2
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/90df7fb1-aac6-47ca-ba1e-78fd6e6f6a25?"
        />
        <Div8>
          <Div9 style={{cursor: "pointer"}}>No</Div9>
          <Div10 style={{cursor: "pointer"}}>Yes</Div10>
        </Div8>
      </Div7>
    </Div>
  );
};

export default DeleteConnection;

const Div = styled.div`
  align-items: center;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  background-color: var(--color-neutrals-00, #fff);
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const Img = styled.img`
  aspect-ratio: 2.14;
  object-fit: contain;
  object-position: center;
  width: 120px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  align-self: center;
  margin-top: 24px;
  max-width: 100%;
`;

const Div2 = styled.div`
  align-items: center;
  align-self: stretch;
  background-color: var(--base-white, #fff);
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const Div3 = styled.div`
  align-items: center;
  align-self: stretch;
  background-color: var(--color-neutrals-00, #fff);
  display: flex;
  width: 100%;
  flex-grow: 1;
  flex-direction: column;
  padding: 24px 24px 0;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const Div4 = styled.div`
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

const Div5 = styled.div`
  align-self: stretch;
  color: var(--gray-900, #101828);
  text-align: center;
  font: 600 18px/156% Inter, sans-serif;
`;

const Div6 = styled.div`
  align-self: stretch;
  color: var(--gray-600, #475467);
  text-align: center;
  margin-top: 4px;
  font: 400 14px/143% Inter, sans-serif;
`;

const Div7 = styled.div`
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  margin-top: 32px;
  flex-grow: 1;
  padding-bottom: 24px;
  flex-direction: column;
`;

const Img2 = styled.img`
  aspect-ratio: 400;
  object-fit: contain;
  object-position: center;
  width: 100%;
  align-self: stretch;
  fill: var(--gray-200, #eaecf0);
  overflow: hidden;
`;

const Div8 = styled.div`
  align-items: flex-start;
  align-self: center;
  display: flex;
  margin-top: 24px;
  width: 352px;
  max-width: 100%;
  gap: 12px;
  padding: 0 20px;
`;

const Div9 = styled.div`
  color: var(--gray-700, #344054);
  align-self: stretch;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--gray-300, #d0d5dd);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  background-color: var(--base-white, #fff);
  flex: 1;
  padding: 10px 74px;
  font: 600 16px/150% Inter, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
    padding: 0 20px;
  }
`;

const Div10 = styled.div`
  color: var(--base-white, var(--color-neutrals-00, #fff));
  align-self: stretch;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--primary-600, #ca8504);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  background-color: var(--error-500, #f04438);
  flex: 1;
  padding: 10px 71px;
  font: 600 16px/150% Inter, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
    padding: 0 20px;
  }
`;