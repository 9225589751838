import Rating from "components/Rating";
import React from "react";

export default function WorkHistoryCard() {
  return (
    <div className="tw-border tw-border-[#D0D5DD] ">
      <div className="lg:tw-px-[24px] tw-pt-[24px]">
        <h5 className="tw-text-md-medium tw-text-[#101828]">Job title</h5>
        <h6 className="tw-text-sm-semi-bold tw-mt-[4px]">
          Designer’s feedback
        </h6>
        <p className="tw-text-sm-regular">
          &apos; I had the pleasure of working with Samuel Alex on a recent
          project and I must say, I was thoroughly impressed with their
          creativity, attention to detail, and overall professionalism &apos;
        </p>
        <h5 className="tw-my-[24px] tw-text-sm-regular">
          Jan 8 2018 – Present
        </h5>
        <Rating rating={4} />
      </div>

      <div className="tw-flex tw-justify-between tw-border-t tw-border-[#EAECF0] lg:tw-p-[24px]">
        <h4 className="tw-text-sm-semi-bold tw-text-[#475467]">$700</h4>
        <h6 className="tw-text-[#667085] tw-text-md-semi-bold">Designer <span className="  tw-text-[#344054]">
        Samuel Alex
        </span></h6>
      </div>
    </div>
  );
}
