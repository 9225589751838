/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import styled from "styled-components";

const ChatTab = () => {
  return (
    <Div>
      <Div2>
        <Div3>
          <Div4 />
          <A href="#">Yesterday</A>
          <Div5 />
        </Div3>
        <Div6>
          <Div7>
            <Div8>
              <A2 href="#">Samuel Alex</A2>
              <A3 href="#">Thursday 10:16am</A3>
            </Div8>
            <Div9>
              <Div10>
                <Img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/1ce31ed4-76f1-4d32-86d0-61f25dbcfd38?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/1ce31ed4-76f1-4d32-86d0-61f25dbcfd38?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1ce31ed4-76f1-4d32-86d0-61f25dbcfd38?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/1ce31ed4-76f1-4d32-86d0-61f25dbcfd38?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/1ce31ed4-76f1-4d32-86d0-61f25dbcfd38?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1ce31ed4-76f1-4d32-86d0-61f25dbcfd38?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/1ce31ed4-76f1-4d32-86d0-61f25dbcfd38?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/1ce31ed4-76f1-4d32-86d0-61f25dbcfd38?"
                />
                <Div11>
                  <Div12>Milestonestone 2 approved</Div12>
                  <A4 href="#">View details</A4>
                </Div11>
              </Div10>
            </Div9>
          </Div7>
          <Div13>
            <Img2
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/62cc517a-a401-4739-9ed4-d503092cd84c?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/62cc517a-a401-4739-9ed4-d503092cd84c?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/62cc517a-a401-4739-9ed4-d503092cd84c?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/62cc517a-a401-4739-9ed4-d503092cd84c?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/62cc517a-a401-4739-9ed4-d503092cd84c?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/62cc517a-a401-4739-9ed4-d503092cd84c?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/62cc517a-a401-4739-9ed4-d503092cd84c?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/62cc517a-a401-4739-9ed4-d503092cd84c?"
            />
            <Div14>
              <Div15>
                <A5 href="#">Samuel Alex</A5>
                <A6 href="#">Thursday 11:40am</A6>
              </Div15>
              <Div16>
                Hey Samuel, I’ve finished with the requirements doc! I made some
                adjustments in the cloths.
              </Div16>
            </Div14>
          </Div13>
          <Div17>
            <Img3
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/09a81fe5-6a33-4f15-8bc2-fafc7880f8b5?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/09a81fe5-6a33-4f15-8bc2-fafc7880f8b5?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/09a81fe5-6a33-4f15-8bc2-fafc7880f8b5?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/09a81fe5-6a33-4f15-8bc2-fafc7880f8b5?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/09a81fe5-6a33-4f15-8bc2-fafc7880f8b5?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/09a81fe5-6a33-4f15-8bc2-fafc7880f8b5?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/09a81fe5-6a33-4f15-8bc2-fafc7880f8b5?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/09a81fe5-6a33-4f15-8bc2-fafc7880f8b5?"
            />
            <Div18>
              <Div19>
                <A7 href="#">Samuel Alex</A7>
                <A8 href="#">11:40am</A8>
              </Div19>
              <Div20>
                <Div21>
                  <img
                    loading="lazy"
                    alt="umojalin"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/e366f755-5032-492c-98b2-d62b7cbf495d?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/e366f755-5032-492c-98b2-d62b7cbf495d?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e366f755-5032-492c-98b2-d62b7cbf495d?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/e366f755-5032-492c-98b2-d62b7cbf495d?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/e366f755-5032-492c-98b2-d62b7cbf495d?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e366f755-5032-492c-98b2-d62b7cbf495d?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/e366f755-5032-492c-98b2-d62b7cbf495d?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/e366f755-5032-492c-98b2-d62b7cbf495d?"
                  />
                  <Div22>
                    <Div23>Shirt requirements.jpg</Div23>
                    <A9 href="#">1.2 MB</A9>
                  </Div22>
                </Div21>
              </Div20>
            </Div18>
          </Div17>
          <Div24>
            <Div25>
              <A10 href="#">You</A10>
              <A11 href="#">Thursday 11:41am</A11>
            </Div25>
            <Div26>Awesome! Thanks. I’ll look at this today.</Div26>
          </Div24>
          <Div27>
            <Img5
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/6e924586-149b-496f-b621-15a01b89424a?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/6e924586-149b-496f-b621-15a01b89424a?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/6e924586-149b-496f-b621-15a01b89424a?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/6e924586-149b-496f-b621-15a01b89424a?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/6e924586-149b-496f-b621-15a01b89424a?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/6e924586-149b-496f-b621-15a01b89424a?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/6e924586-149b-496f-b621-15a01b89424a?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/6e924586-149b-496f-b621-15a01b89424a?"
            />
            <Div28>
              <Div29>
                <A12 href="#">Samuel Alex</A12>
                <A13 href="#">Thursday 11:44am</A13>
              </Div29>
              <Div30>Lets wait it out and see what comes out</Div30>
            </Div28>
          </Div27>
        </Div6>
        <Div31>
          <Div32 />
          <a href="#">Today</a>
          <Div33 />
        </Div31>
        <Div34>
          <Div35>
            <Div36>
              <A15 href="#">You</A15>
              <A16 href="#">Today 2:20pm</A16>
            </Div36>
            <Div37>
              <Div38>
                <img
                  alt="umojalin"
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/f40d9652-4ebc-4999-8d4c-789916ceb15d?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/f40d9652-4ebc-4999-8d4c-789916ceb15d?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/f40d9652-4ebc-4999-8d4c-789916ceb15d?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/f40d9652-4ebc-4999-8d4c-789916ceb15d?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/f40d9652-4ebc-4999-8d4c-789916ceb15d?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/f40d9652-4ebc-4999-8d4c-789916ceb15d?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/f40d9652-4ebc-4999-8d4c-789916ceb15d?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/f40d9652-4ebc-4999-8d4c-789916ceb15d?"
                />
                <Div39>
                  <A17 href="#">image 001</A17>
                  <A18 href="#">200 KB</A18>
                </Div39>
              </Div38>
            </Div37>
          </Div35>
          <Div40>
            <Div41>
              <A19 href="#">You</A19>
              <A20 href="#">Just now</A20>
            </Div41>
            <Div42>
              Sure thing, I’ll have a look today. They’re looking great!
            </Div42>
          </Div40>
          <Div43>
            <Img7
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/d818dd34-4376-4a3f-8b1a-587e6f20111d?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/d818dd34-4376-4a3f-8b1a-587e6f20111d?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d818dd34-4376-4a3f-8b1a-587e6f20111d?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/d818dd34-4376-4a3f-8b1a-587e6f20111d?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/d818dd34-4376-4a3f-8b1a-587e6f20111d?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d818dd34-4376-4a3f-8b1a-587e6f20111d?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/d818dd34-4376-4a3f-8b1a-587e6f20111d?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/d818dd34-4376-4a3f-8b1a-587e6f20111d?"
            />
            <Div44>
              <A21 href="#">Samuel Alex</A21>
              <Div45>
                <Img8
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/f1bf521d-957f-4d9f-bec2-750b993ba922?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/f1bf521d-957f-4d9f-bec2-750b993ba922?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/f1bf521d-957f-4d9f-bec2-750b993ba922?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/f1bf521d-957f-4d9f-bec2-750b993ba922?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/f1bf521d-957f-4d9f-bec2-750b993ba922?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/f1bf521d-957f-4d9f-bec2-750b993ba922?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/f1bf521d-957f-4d9f-bec2-750b993ba922?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/f1bf521d-957f-4d9f-bec2-750b993ba922?"
                />
                <Img9
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/7f11a92d-93b5-45d5-9c76-b2793b522ecf?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/7f11a92d-93b5-45d5-9c76-b2793b522ecf?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/7f11a92d-93b5-45d5-9c76-b2793b522ecf?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/7f11a92d-93b5-45d5-9c76-b2793b522ecf?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/7f11a92d-93b5-45d5-9c76-b2793b522ecf?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/7f11a92d-93b5-45d5-9c76-b2793b522ecf?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/7f11a92d-93b5-45d5-9c76-b2793b522ecf?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/7f11a92d-93b5-45d5-9c76-b2793b522ecf?"
                />
                <Img10
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/15075808-d3f1-421a-9e38-ec864eca4688?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/15075808-d3f1-421a-9e38-ec864eca4688?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/15075808-d3f1-421a-9e38-ec864eca4688?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/15075808-d3f1-421a-9e38-ec864eca4688?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/15075808-d3f1-421a-9e38-ec864eca4688?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/15075808-d3f1-421a-9e38-ec864eca4688?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/15075808-d3f1-421a-9e38-ec864eca4688?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/15075808-d3f1-421a-9e38-ec864eca4688?"
                />
              </Div45>
            </Div44>
          </Div43>
        </Div34>
      </Div2>
      <Div46>
        <Div47>
          <A22 href="#">Send a message</A22>
          <Div48>
            <Div49>
              <Img11
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/d8789bbd-14a6-48da-bcdf-f9ad06af7e30?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/d8789bbd-14a6-48da-bcdf-f9ad06af7e30?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d8789bbd-14a6-48da-bcdf-f9ad06af7e30?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/d8789bbd-14a6-48da-bcdf-f9ad06af7e30?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/d8789bbd-14a6-48da-bcdf-f9ad06af7e30?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d8789bbd-14a6-48da-bcdf-f9ad06af7e30?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/d8789bbd-14a6-48da-bcdf-f9ad06af7e30?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/d8789bbd-14a6-48da-bcdf-f9ad06af7e30?"
              />
              <Img12
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/2feb1a5d-45e7-4c1a-8c11-d1723388ed24?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/2feb1a5d-45e7-4c1a-8c11-d1723388ed24?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/2feb1a5d-45e7-4c1a-8c11-d1723388ed24?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/2feb1a5d-45e7-4c1a-8c11-d1723388ed24?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/2feb1a5d-45e7-4c1a-8c11-d1723388ed24?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/2feb1a5d-45e7-4c1a-8c11-d1723388ed24?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/2feb1a5d-45e7-4c1a-8c11-d1723388ed24?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/2feb1a5d-45e7-4c1a-8c11-d1723388ed24?"
              />
            </Div49>
            <A23 href="#">Send</A23>
          </Div48>
        </Div47>
      </Div46>
    </Div>
  );
}
export default ChatTab;

const Div = styled.div`
  align-items: center;
  background: var(--gray-25, #fcfcfd);
  width: 100%;
  // max-width: 638px;
  padding-bottom: 24px;
  align-self: end;
  display: flex;
  flex-direction: column;
`;

const Div2 = styled.div`
  justify-content: flex-end;
  align-items: flex-start;
  align-self: stretch;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
`;

const Div3 = styled.div`
  align-items: flex-start;
  align-self: center;
  width: 100%;
  padding-top: 3px;
  padding-right: 20px;
  padding-bottom: 3px;
  display: flex;
  flex-direction: row;
  gap: 8.390625px;
  flex-wrap: wrap;
  @media (max-width: 991px) {
    justify-content: center;
  }
`;

const Div4 = styled.div`
  flex: 1 0 0;
  background: var(--gray-200, #eaecf0);
  align-self: center;
  display: flex;
  flex-direction: column;
  min-height: 1px;
`;

const A = styled.a`
  color: var(--gray-600, #475467);
  text-align: center;
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: 2px;
  align-self: stretch;
`;

const Div5 = styled.div`
  flex: 1 0 0;
  background: var(--gray-200, #eaecf0);
  align-self: center;
  display: flex;
  flex-direction: column;
  min-height: 1px;
`;

const Div6 = styled.div`
  align-items: flex-start;
  align-self: center;
  width: 100%;
  max-width: 574px;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
`;

const Div7 = styled.div`
  align-items: flex-start;
  flex: 1 0 0;
  width: 100%;
  max-width: 560px;
  margin-left: 14px;
  align-self: start;
  display: flex;
  flex-direction: column;
  @media (max-width: 991px) {
    margin-left: 10px;
  }
`;

const Div8 = styled.div`
  align-items: flex-start;
  align-self: stretch;
  padding-top: 4px;
  padding-left: 1px;
  padding-right: 20px;
  padding-bottom: 4px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 560px;
`;

const A2 = styled.a`
  flex: 1 0 0;
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: 1px;
  align-self: stretch;
  min-width: 81px;
`;

const A3 = styled.a`
  color: var(--gray-600, #475467);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  margin-top: 1px;
  align-self: stretch;
  min-width: 102px;
`;

const Div9 = styled.div`
  align-items: center;
  align-self: stretch;
  border-radius: 8px 0px 8px 8px;
  border: 1px solid var(--success-200, #a6f4c5);
  background: var(--success-500, #12b76a);
  margin-top: 6px;
  padding-top: 8px;
  padding-left: 10px;
  padding-right: 14px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
`;

const Div10 = styled.div`
  align-items: flex-start;
  flex: 1 0 0;
  margin-top: -2px;
  margin-bottom: 2px;
  padding-right: 20px;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: 12px;
  flex-wrap: wrap;
`;

const Img = styled.img`
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 36px;
  align-self: start;
`;

const Div11 = styled.div`
  align-items: flex-start;
  align-self: start;
  margin-top: 4px;
  padding-top: 5px;
  padding-left: 0px;
  padding-right: 20px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
  flex-grow: 13.555555555555555;
`;

const Div12 = styled.div`
  align-self: start;
  color: var(--base-white, var(--color-neutrals-00, #fff));
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: -1px;
  margin-left: 1px;
  @media (max-width: 991px) {
    margin-left: 1px;
  }
`;

const A4 = styled.a`
  align-self: start;
  color: var(--base-white, var(--color-neutrals-00, #fff));
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  margin-top: 7px;
  margin-bottom: -0px;
`;

const Div13 = styled.div`
  align-items: flex-start;
  width: 100%;
  margin-top: 16px;
  padding-right: 20px;
  align-self: start;
  display: flex;
  flex-direction: row;
  gap: 10.5px;
  flex-wrap: wrap;
`;

const Img2 = styled.img`
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 41.5px;
  align-self: start;
`;

const Div14 = styled.div`
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  flex-grow: 12.240963855421686;
`;

const Div15 = styled.div`
  align-items: flex-start;
  align-self: stretch;
  padding-top: 4px;
  padding-left: 1px;
  padding-right: 20px;
  padding-bottom: 4px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 508px;
`;

const A5 = styled.a`
  flex: 1 0 0;
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: 1px;
  align-self: stretch;
  min-width: 81px;
`;

const A6 = styled.a`
  color: var(--gray-600, #475467);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  margin-top: 1px;
  align-self: stretch;
  min-width: 102px;
`;

const Div16 = styled.div`
  align-items: center;
  align-self: start;
  border-radius: 0px 8px 8px 8px;
  background: var(--gray-100, #f2f4f7);
  margin-top: 2px;
  padding-top: 14px;
  padding-left: 15px;
  padding-right: 20px;
  padding-bottom: 14px;
  flex: 1 0 0;
  color: var(--gray-900, #101828);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: -1px;
`;

const Div17 = styled.div`
  align-items: flex-start;
  width: 100%;
  margin-top: 16px;
  padding-right: 20px;
  align-self: start;
  display: flex;
  flex-direction: row;
  gap: 10.5px;
  flex-wrap: wrap;
`;

const Img3 = styled.img`
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 41.5px;
  align-self: start;
`;

const Div18 = styled.div`
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  flex-grow: 12.240963855421686;
`;

const Div19 = styled.div`
  align-items: flex-start;
  align-self: stretch;
  padding-top: 5px;
  padding-left: 1px;
  padding-right: 20px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 508px;
`;

const A7 = styled.a`
  flex: 1 0 0;
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: -0px;
  align-self: stretch;
  min-width: 81px;
`;

const A8 = styled.a`
  color: var(--gray-600, #475467);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  margin-top: 0px;
  align-self: stretch;
  min-width: 45px;
`;

const Div20 = styled.div`
  align-items: center;
  align-self: stretch;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--gray-200, #eaecf0);
  background: var(--color-neutrals-00, #fff);
  margin-top: 6px;
  padding-top: 8px;
  padding-left: 10px;
  padding-right: 14px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
`;

const Div21 = styled.div`
  align-items: flex-start;
  flex: 1 0 0;
  margin-top: -2px;
  margin-bottom: 2px;
  padding-right: 20px;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: 12px;
  flex-wrap: wrap;
`;

const Div22 = styled.div`
  align-items: flex-start;
  align-self: start;
  margin-top: 4px;
  padding-top: 5px;
  padding-left: 1px;
  padding-right: 20px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
  flex-grow: 12.11111111111111;
`;

const Div23 = styled.div`
  align-self: start;
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: -1px;
`;

const A9 = styled.a`
  align-self: start;
  color: var(--gray-600, #475467);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  margin-top: 7px;
  margin-left: 0px;
  margin-bottom: -0px;
  @media (max-width: 991px) {
    margin-left: 0px;
  }
`;

const Div24 = styled.div`
  align-items: flex-start;
  flex: 1 0 0;
  width: 100%;
  max-width: 330px;
  margin-top: 16px;
  align-self: end;
  display: flex;
  flex-direction: column;
`;

const Div25 = styled.div`
  align-items: flex-start;
  align-self: stretch;
  padding-top: 4px;
  padding-left: 0px;
  padding-right: 20px;
  padding-bottom: 4px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 330px;
`;

const A10 = styled.a`
  flex: 1 0 0;
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: 1px;
  align-self: stretch;
  min-width: 24px;
`;

const A11 = styled.a`
  color: var(--gray-600, #475467);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  margin-top: 1px;
  align-self: stretch;
  min-width: 100px;
`;

const Div26 = styled.div`
  align-items: center;
  align-self: stretch;
  border-radius: 8px 0px 8px 8px;
  background: var(--primary-500, #eaaa08);
  margin-top: 2px;
  padding-top: 14px;
  padding-left: 14px;
  padding-right: 18px;
  padding-bottom: 14px;
  flex: 1 0 0;
  color: var(--base-white, var(--color-neutrals-00, #fff));
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: -1px;
`;

const Div27 = styled.div`
  align-items: flex-start;
  width: 100%;
  margin-top: 16px;
  padding-right: 20px;
  align-self: start;
  display: flex;
  flex-direction: row;
  gap: 10.5px;
  flex-wrap: wrap;
`;

const Img5 = styled.img`
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 41.5px;
  align-self: start;
`;

const Div28 = styled.div`
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  flex-grow: 12.240963855421686;
`;

const Div29 = styled.div`
  align-items: flex-start;
  align-self: stretch;
  padding-top: 4px;
  padding-left: 1px;
  padding-right: 20px;
  padding-bottom: 4px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 508px;
`;

const A12 = styled.a`
  flex: 1 0 0;
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: 1px;
  align-self: stretch;
  min-width: 81px;
`;

const A13 = styled.a`
  color: var(--gray-600, #475467);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  margin-top: 1px;
  align-self: stretch;
  min-width: 102px;
`;

const Div30 = styled.div`
  align-items: center;
  align-self: start;
  border-radius: 0px 8px 8px 8px;
  background: var(--gray-100, #f2f4f7);
  margin-top: 6px;
  padding-top: 16px;
  padding-left: 15px;
  padding-right: 20px;
  padding-bottom: 16px;
  flex: 1 0 0;
  color: var(--gray-900, #101828);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: -0px;
`;

const Div31 = styled.div`
  align-items: flex-start;
  align-self: center;
  width: 100%;
  margin-top: 24px;
  padding-top: 3px;
  padding-right: 20px;
  padding-bottom: 3px;
  display: flex;
  flex-direction: row;
  gap: 8.48046875px;
  flex-wrap: wrap;
  @media (max-width: 991px) {
    justify-content: center;
  }
`;

const Div32 = styled.div`
  flex: 1 0 0;
  background: var(--gray-200, #eaecf0);
  align-self: center;
  display: flex;
  flex-direction: column;
  min-height: 1px;
`;

const Div33 = styled.div`
  flex: 1 0 0;
  background: var(--gray-200, #eaecf0);
  align-self: center;
  display: flex;
  flex-direction: column;
  min-height: 1px;
`;

const Div34 = styled.div`
  align-items: flex-start;
  align-self: center;
  width: 100%;
  max-width: 574px;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
`;

const Div35 = styled.div`
  align-items: flex-start;
  flex: 1 0 0;
  width: 100%;
  max-width: 440px;
  align-self: end;
  display: flex;
  flex-direction: column;
`;

const Div36 = styled.div`
  align-items: flex-start;
  align-self: stretch;
  padding-top: 4px;
  padding-left: 0px;
  padding-right: 20px;
  padding-bottom: 4px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 440px;
`;

const A15 = styled.a`
  flex: 1 0 0;
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: 1px;
  align-self: stretch;
  min-width: 24px;
`;

const A16 = styled.a`
  color: var(--gray-600, #475467);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  margin-top: 1px;
  align-self: stretch;
  min-width: 80px;
`;

const Div37 = styled.div`
  align-items: center;
  align-self: stretch;
  border-radius: 8px 0px 8px 8px;
  border: 1px solid var(--gray-200, #eaecf0);
  background: var(--color-neutrals-00, #fff);
  margin-top: 6px;
  padding-top: 8px;
  padding-left: 10px;
  padding-right: 14px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
`;

const Div38 = styled.div`
  align-items: flex-start;
  flex: 1 0 0;
  margin-top: -2px;
  margin-bottom: 2px;
  padding-right: 20px;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: 12px;
  flex-wrap: wrap;
`;

const Div39 = styled.div`
  align-items: flex-start;
  align-self: start;
  margin-top: 4px;
  padding-top: 5px;
  padding-left: 1px;
  padding-right: 20px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
  flex-grow: 10.222222222222221;
`;

const A17 = styled.a`
  align-self: start;
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: -1px;
`;

const A18 = styled.a`
  align-self: start;
  color: var(--gray-600, #475467);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  margin-top: 7px;
  margin-left: 0px;
  margin-bottom: -0px;
  @media (max-width: 991px) {
    margin-left: 0px;
  }
`;

const Div40 = styled.div`
  align-items: flex-start;
  flex: 1 0 0;
  width: 100%;
  max-width: 442px;
  margin-top: 16px;
  align-self: end;
  display: flex;
  flex-direction: column;
`;

const Div41 = styled.div`
  align-items: flex-start;
  align-self: stretch;
  padding-top: 5px;
  padding-left: 0px;
  padding-right: 20px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 442px;
`;

const A19 = styled.a`
  flex: 1 0 0;
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: -0px;
  align-self: stretch;
  min-width: 24px;
`;

const A20 = styled.a`
  color: var(--gray-600, #475467);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  margin-top: 0px;
  align-self: stretch;
  min-width: 50px;
`;

const Div42 = styled.div`
  align-items: center;
  align-self: stretch;
  border-radius: 8px 0px 8px 8px;
  background: var(--primary-500, #eaaa08);
  margin-top: 2px;
  padding-top: 14px;
  padding-left: 15px;
  padding-right: 19px;
  padding-bottom: 14px;
  flex: 1 0 0;
  color: var(--base-white, var(--color-neutrals-00, #fff));
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: -1px;
`;

const Div43 = styled.div`
  align-items: flex-start;
  width: 100%;
  margin-top: 16px;
  padding-right: 20px;
  align-self: start;
  display: flex;
  flex-direction: row;
  gap: 10.5px;
  flex-wrap: wrap;
`;

const Img7 = styled.img`
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 41.5px;
  align-self: start;
`;

const Div44 = styled.div`
  align-items: flex-start;
  flex: 1 0 0;
  padding-top: 2px;
  padding-right: 20px;
  padding-bottom: 2px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  flex-grow: 12.240963855421686;
`;

const A21 = styled.a`
  align-self: start;
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: 3px;
  margin-left: 1px;
  @media (max-width: 991px) {
    margin-left: 1px;
  }
`;

const Div45 = styled.div`
  align-items: flex-start;
  border-radius: 0px 8px 8px 8px;
  background: var(--gray-100, #f2f4f7);
  width: 100%;
  margin-top: 11px;
  margin-bottom: -2px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 20px;
  padding-bottom: 10px;
  align-self: start;
  display: flex;
  flex-direction: row;
  gap: 4px;
  flex-wrap: wrap;
  @media (max-width: 991px) {
    justify-content: center;
  }
`;

const Img8 = styled.img`
  aspect-ratio: 0.5;
  object-fit: cover;
  object-position: center;
  width: 4px;
  align-self: stretch;
`;

const Img9 = styled.img`
  aspect-ratio: 0.5;
  object-fit: cover;
  object-position: center;
  width: 4px;
  align-self: stretch;
`;

const Img10 = styled.img`
  aspect-ratio: 0.5;
  object-fit: cover;
  object-position: center;
  width: 4px;
  align-self: stretch;
`;

const Div46 = styled.div`
  align-items: flex-start;
  flex: 1 0 0;
  align-self: center;
  border: 1px solid var(--gray-300, #d0d5dd);
  background: var(--color-neutrals-00, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  width: 100%;
  max-width: 574px;
  padding-top: 18px;
  padding-left: 15px;
  padding-right: 10px;
  padding-bottom: 9px;
  display: flex;
  flex-direction: column;
`;

const Div47 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
`;

const A22 = styled.a`
  flex: 1 0 0;
  align-self: start;
  color: var(--gray-500, #667085);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
`;

const Div48 = styled.div`
  align-items: flex-start;
  width: 100%;
  margin-top: 41px;
  padding-top: 2px;
  padding-right: 20px;
  padding-bottom: 2px;
  align-self: end;
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
`;

const Div49 = styled.div`
  align-items: flex-start;
  margin-top: 1px;
  padding-right: 20px;
  align-self: start;
  display: flex;
  flex-direction: row;
  gap: 2px;
  flex-wrap: wrap;
`;

const Img11 = styled.img`
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 36px;
  align-self: stretch;
`;

const Img12 = styled.img`
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 36px;
  align-self: stretch;
`;

const A23 = styled.a`
  justify-content: center;
  align-items: center;
  border: 1px solid var(--primary-500, #eaaa08);
  background: var(--primary-500, #eaaa08);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  margin-top: -0px;
  padding-top: 15px;
  padding-left: 17px;
  padding-right: 17px;
  padding-bottom: 15px;
  align-self: stretch;
  color: var(--base-white, var(--color-neutrals-00, #fff));
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  margin-bottom: -0px;
`;
