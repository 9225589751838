import React, { useState, useEffect, useCallback } from "react";
import DiscoverTab from "./DiscoverTab";
import MyHiresTab from "./MyHiresTab";
import FavoritesTab from "./FavoritesTab";
import { listDesigners } from "services/designerService";
import { useSelector } from "react-redux";
import { listProjects } from "services/projectService";
import HorizontalMain from "components/Tabs/HorizontalMain";
import Loader from "common/Loader";

const designerTabs = [
  { name: "Discover", key: "discover" },
  { name: "My Hires", key: "hires" },
  { name: "Favorites", key: "favorites" },
];

const Designers = () => {
  const [actValue, setActValue] = useState("discover");
  const [designers, setDesigners] = useState([]);
  const [myHires, setMyHires] = useState([]);
  const { active } = useSelector((state) => state.account);
  const [loading, isLoading] = useState(false);
  const loadDesigners = async () => {
    try {

      const resp = await listDesigners();
      console.log(resp);
      setDesigners(resp);
    } catch(err){
      
    } finally {
      isLoading(false);

    }
  };

  const loadProjectsByBuyerId = useCallback(async () => {
    try {
      isLoading(true);
      const resp = await listProjects(active.id);
      const allBids = resp.flatMap((project) => project.bids);
      const acceptedBids = allBids.filter((bid) => bid.status === 'accepted');
      
      console.log(acceptedBids)
      setMyHires(
        acceptedBids
          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
      );
    } catch (err) {
    } finally {
      isLoading(false);
    }
  }, [active.id]);

  useEffect(() => {
    Promise.allSettled([loadDesigners(), loadProjectsByBuyerId()]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) <Loader />;

  return (
    <div className="tw-relative ">
      <div className="tw-bg-designer-banner tw-w-full tw-h-[285px] tw-bg-no-repeat tw-bg-cover tw-relative">
        <div className="tw-p-[32px] tw-h-full tw-flex tw-flex-col tw-justify-center">
          <h3 className="tw-text-xs-bold !tw-text-[24px]">
            Discover Designers
          </h3>
          <p className="tw-text-[16px] tw-font-[400] tw-mt-[8px] tw-w-[300px] tw-text-gray-500">
            Find the perfect designer to bring your style to life
          </p>
        </div>
      </div>

      <div className="lg:tw-px-[32px]">
        <HorizontalMain
          tabs={designerTabs}
          activeTab={actValue}
          setActiveTab={setActValue}
        />
        {/* show filter icon */}
      </div>

      <div className=" lg:tw-px-[32px]">
        {actValue === "discover" && <DiscoverTab designers={designers} />}
        {actValue === "hires" && <MyHiresTab myHires={myHires} />}
        {actValue === "favorites" && <FavoritesTab />}
      </div>
    </div>
  );
};

export default Designers;
