import React, { useState } from "react";
import logo from "../../assets/img/logo.png";
import { NavLink } from "react-router-dom";
import DropdownUser from "components/Header/DropDownUser";
import { Modal } from "react-bootstrap";
import AddConnections from "pages/Workspace/Connections/InviteToOnboard";
import Search from "components/Input/Search";

function Header({ sidebarOpen, setSidebarOpen, logout, account }) {
  const [show, setShow] = useState(false);

  const handleCloseModal = () => setShow(false);
  const handleShowModal = () => setShow(true);

  return (
    <header className="tw-sticky tw-top-0 tw-z-99 tw-h-[88px]  tw-flex tw-w-full tw-bg-white tw-drop-shadow-1  tw-pr-[20px]">
      <div className="tw-flex tw-flex-grow tw-items-center tw-justify-between  tw-shadow-2 ">
        <div className="tw-flex tw-items-center tw-gap-2 sm:tw-gap-4 lg:tw-hidden">
          {/* Here check user type and either redirect to workspace etc */}
          <NavLink className="tw-block tw-flex-shrink-0 tw-lg:hidden" to="/">
            <img src={logo} alt="Logo" className="tw-w-[40px] tw-h-[40px]" />
          </NavLink>
          {/* <!-- Hamburger Toggle BTN --> */}
          <button
            aria-controls="sidebar"
            onClick={(e) => {
              e.stopPropagation();
              setSidebarOpen(!sidebarOpen);
            }}
            className="tw-z-99999 tw-block  tw-p-1.5 tw-shadow-sm lg:tw-hidden"
          >
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.90625 12H15.9062M3.90625 6H21.9062M3.90625 18H21.9062"
                stroke="#667085"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
          {/* <!-- Hamburger Toggle BTN --> */}
        </div>

        <div className="tw-hidden sm:tw-block">
          <Search />
        </div>

        <div className="tw-flex tw-items-center tw-gap-[16px] ">
          <ul className="tw-flex tw-items-center tw-gap-[16px] tw-border-r-2 tw-border-[#EAECF0] ">
            <li className="lg:tw-hidden">
              <button className="">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.5 16.5L13.5834 13.5833M15.6667 8.58333C15.6667 12.4954 12.4954 15.6667 8.58333 15.6667C4.67132 15.6667 1.5 12.4954 1.5 8.58333C1.5 4.67132 4.67132 1.5 8.58333 1.5C12.4954 1.5 15.6667 4.67132 15.6667 8.58333Z"
                    stroke="#667085"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </li>
            <li className="tw-mr-[4px] sm:tw-mr-[24px]">
              <button
                className="tw-flex tw-items-center tw-gap-[8px] tw-text-sm-medium"
                onClick={handleShowModal}
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.99609 11.625H5.6211C4.57442 11.625 4.05108 11.625 3.62524 11.7542C2.66644 12.045 1.91612 12.7953 1.62527 13.7541C1.49609 14.18 1.49609 14.7033 1.49609 15.75M14.2461 15.75V11.25M11.9961 13.5H16.4961M10.8711 5.625C10.8711 7.48896 9.36006 9 7.49609 9C5.63213 9 4.12109 7.48896 4.12109 5.625C4.12109 3.76104 5.63213 2.25 7.49609 2.25C9.36006 2.25 10.8711 3.76104 10.8711 5.625Z"
                    stroke="#667085"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <span className="tw-hidden sm:tw-block">Invite to onboard</span>
              </button>
            </li>
          </ul>
          {/* Move into seperate Notification Component */}
          <button>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.4996 15.75H7.49959M13.4996 6C13.4996 4.80653 13.0255 3.66193 12.1816 2.81802C11.3377 1.97411 10.1931 1.5 8.99959 1.5C7.80611 1.5 6.66152 1.97411 5.81761 2.81802C4.97369 3.66193 4.49959 4.80653 4.49959 6C4.49959 8.31764 3.91494 9.90447 3.26184 10.9541C2.71093 11.8394 2.43548 12.2821 2.44558 12.4056C2.45676 12.5423 2.48573 12.5944 2.59592 12.6762C2.69543 12.75 3.14403 12.75 4.04123 12.75H13.9579C14.8551 12.75 15.3037 12.75 15.4033 12.6762C15.5134 12.5944 15.5424 12.5423 15.5536 12.4056C15.5637 12.2821 15.2882 11.8394 14.7373 10.9541C14.0842 9.90447 13.4996 8.31764 13.4996 6Z"
                stroke="#475467"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
          {/* <!-- User Area --> */}
          <DropdownUser
            logout={logout}
            img={
              account?.profile_image_url
                ? account?.profile_image_url
                : `https://ui-avatars.com/api/?background=EAAA08&color=ffffff&name=${account?.first_name}`
            }
          />
          {/* <!-- User Area --> */}
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleCloseModal}
        className="tw-rounded-0"
        centered
        size="md"
      >
        <Modal.Body>
          <AddConnections onHide={handleCloseModal} />
        </Modal.Body>
      </Modal>
    </header>
  );
}

export default Header;
