import { HttpMethod, fireRequest } from "./axios";

export const listKeywords = async () => {
  try {
    const response = await fireRequest(
      {
        url: `/project/keyword/list/`,
        method: HttpMethod.GET,
      },
      true
    );

    return response.data;
  } catch (error) {
    throw error.data;
  }
};

export const listKeywordById = async (id) => {
  try {
    const response = await fireRequest(
      {
        url: `/project/keyword/details/${id}`,
        method: HttpMethod.GET,
      },
      true
    );

    return response.data;
  } catch (error) {
    throw error.data;
  }
};

export const create = async (value) => {
  try {
    const response = await fireRequest(
      {
        url: `/project/keyword/create/`,
        body: { value: value },
        method: HttpMethod.POST,
      },
      true
    );

    return response.data;
  } catch (error) {
    throw error.data;
  }
};

export const update = async (values) => {
  try {
    const response = await fireRequest(
      {
        url: `/project/keyword/update`,
        method: HttpMethod.PUT,
        body: values,
      },
      true
    );

    return response.data;
  } catch (error) {
    throw error.data;
  }
};

export const details = async (id) => {
  try {
    const response = await fireRequest(
      {
        url: `/project/keyword/details/${id}`,
        method: HttpMethod.GET,
      },
      true
    );
    return response.data;
  } catch (error) {
    throw error.data;
  }
};
