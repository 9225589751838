import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "./Styles/ListProjects.css";
import Deposit from "../Wallet/Deposit";
import { fundEscrow } from "services/escrowService";
import { listPaymentMethod } from "services/paymentService";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const FundingMethodModal = (props) => {
  const [selectedWallet, setSelectedWallet] = useState(props.wallets[0]);
  const [card, setCard] = useState("");
  const [componentIndex, setComponentIndex] = useState(0);
  const [payment_cards, setPaymentCards] = useState([]);
  const buyer = useSelector((state) => state.buyer.buyer);
  const designer = useSelector((state) => state.designer.designer);
  const selectedAccount = buyer ? buyer : designer;
  const token = localStorage.getItem("auth_token");
  const navigate = useNavigate();

  const loadPaymentCards = async () => {
    const resp = await listPaymentMethod(selectedAccount.id, token);
    setPaymentCards(resp);
  };

  const handleFundingMethodChange = (method) => {
    if (method === "card") {
      setCard("card");
      setSelectedWallet({});
    } else {
      setSelectedWallet(method);
      setCard("");
    }
  };

  const handleConfirmPayment = async () => {
    if (card === "card") {
      setComponentIndex(1);
    } else {
      const data = {
        milestone: props.nextMilestone.id,
        project: props.nextMilestone.project,
        buyer: selectedAccount.id,
        wallet: selectedWallet.id,
      };
      const resp = await fundEscrow(data, token);
      if(resp) {
        navigate('/workspace/projects')
      }
    }
  };
  useEffect(() => {
    loadPaymentCards();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {componentIndex === 0 && (
        <Div>
          <Div2>
            <Span>
              <Img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/940b662647db719697f1e5ff465ac5c4acc0dd126a0ea4f6973fc8c4d07fb340?"
              />
              <Div3>Select Funding Method</Div3>
              <Div4>Please select a method to fund escrow.</Div4>
            </Span>
          </Div2>
          {props.wallets.map((wallet, index) => (
            <Div5
              key={index}
              className={`${
                selectedWallet === wallet
                  ? "selected-method"
                  : "unselected-method"
              } mb-3`}
            >
              <Div6>
                <Img2
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/d85225de5dab7a631ce5d61d3231d934f0a130ab32fff9e848a8fe26e602274f?"
                />
                <Span2>
                  <Div7
                    className={`${
                      selectedWallet === wallet
                        ? "selected-method-name"
                        : "unselected-method-name"
                    }`}
                  >
                    {wallet.currency === "eur"
                      ? "Euro Wallet"
                      : wallet.currency === "usd"
                      ? "Dollar Wallet"
                      : wallet.currency === "ngn"
                      ? "Naira Wallet"
                      : "No Wallet"}
                  </Div7>
                  <Div8
                    className={`${
                      selectedWallet === wallet
                        ? "selected-method-bal"
                        : "unselected-method-bal"
                    }`}
                  >
                    Current balance:{" "}
                    <span style={{ fontWeight: "600" }}>
                      {wallet.currency === "eur"
                        ? "€"
                        : wallet.currency === "usd"
                        ? "$"
                        : wallet.currency === "ngn"
                        ? "#"
                        : ""}
                      {wallet.balance}
                    </span>
                  </Div8>
                </Span2>
              </Div6>
              {selectedWallet === wallet ? (
                <Img3
                  style={{ cursor: "pointer" }}
                  onClick={() => handleFundingMethodChange(wallet)}
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/317f15f825cb1ae22c11888a7ef3c08296ad781ab3c2fa0fd21aa4eb8cd2e1a3?"
                />
              ) : (
                <Div17
                  style={{ cursor: "pointer" }}
                  onClick={() => handleFundingMethodChange(wallet)}
                />
              )}
            </Div5>
          ))}
          {selectedWallet.balance < 0 && (
            <Span3>
              <Div9>Insufficient funds in account</Div9>
              <Span4>
                <Div10>Go to wallet</Div10>
                <Div11>Wallet</Div11>
                <Div12>to fund your account</Div12>
              </Span4>
            </Span3>
          )}
          <Div13
            className={`${
              card === "card" ? "selected-method" : "unselected-method"
            }`}
          >
            <Div14>
              <Img4
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/7c973fb6b41545c47a6ddf3b91bf9309628434753fe72c41b621e9b812f8d98b?"
              />
              <Span5>
                <Div7
                  className={`${
                    card === "card"
                      ? "selected-method-name"
                      : "unselected-method-name"
                  }`}
                >
                  Credit or Debit Card
                </Div7>
                <Div8
                  className={`${
                    card === "card"
                      ? "selected-method-bal"
                      : "unselected-method-bal"
                  }`}
                >
                  Fund escrow with your card
                </Div8>
              </Span5>
            </Div14>
            {card === "card" ? (
              <Img3
                style={{ cursor: "pointer" }}
                onClick={() => handleFundingMethodChange("card")}
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/317f15f825cb1ae22c11888a7ef3c08296ad781ab3c2fa0fd21aa4eb8cd2e1a3?"
              />
            ) : (
              <Div17
                style={{ cursor: "pointer" }}
                onClick={() => handleFundingMethodChange("card")}
              />
            )}
          </Div13>
          <button
            type="button"
            className="btn-confirm-fund-escrow"
            onClick={handleConfirmPayment}
          >
            Confirm
          </button>
        </Div>
      )}
      {componentIndex === 1 && (
        <Deposit
          payment_cards={payment_cards}
          selectedWallet={selectedWallet}
          paymentMode={card}
          nextMilestone={props.nextMilestone}
        />
      )}
    </>
  );
};

export default FundingMethodModal;

const Div = styled.div`
  align-items: center;
  border: 2px solid var(--Error-600, #d92d20);
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  background-color: var(--Base-White, #fff);
  display: flex;
  max-width: 480px;
  padding-bottom: 24px;
  flex-direction: column;
`;

const Div2 = styled.div`
  align-self: stretch;
  background-color: var(--Base-White, #fff);
  display: flex;
  width: 100%;
  padding-bottom: 20px;
  flex-direction: column;
`;

const Span = styled.span`
  align-items: start;
  background-color: var(--Base-White, #fff);
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 24px 24px 0;
`;

const Img = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 48px;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  overflow: hidden;
  max-width: 100%;
`;

const Div3 = styled.div`
  align-self: stretch;
  color: var(--Gray-900, #101828);
  margin-top: 16px;
  font: 600 18px/156% Inter, sans-serif;
`;

const Div4 = styled.div`
  align-self: stretch;
  color: var(--Gray-600, #475467);
  margin-top: 4px;
  font: 400 14px/143% Inter, sans-serif;
`;

const Div5 = styled.div`
  align-self: center;
  display: flex;
  width: 100%;
  max-width: 432px;
  gap: 4px;
  padding: 16px;
`;

const Div6 = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

const Img2 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 32px;
  justify-content: center;
  align-items: center;
  mix-blend-mode: multiply;
  overflow: hidden;
  max-width: 100%;
  margin: auto 0;
`;

const Span2 = styled.span`
  align-self: stretch;
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
`;

const Div7 = styled.div`
  font: 500 14px/143% Inter, sans-serif;
`;

const Div8 = styled.div`
  white-space: nowrap;
  font: 400 14px/20px Inter, sans-serif;
`;

const Img3 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 16px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  align-self: stretch;
  max-width: 100%;
  margin: auto 0;
`;

const Span3 = styled.span`
  align-self: center;
  background-color: var(--Error-50, #fef3f2);
  display: flex;
  width: 100%;
  max-width: 432px;
  flex-direction: column;
  padding: 16px;
`;

const Div9 = styled.div`
  color: var(--Error-700, #b42318);
  white-space: nowrap;
  font: 400 14px/143% Inter, sans-serif;
`;

const Span4 = styled.span`
  display: flex;
  gap: 6px;
`;

const Div10 = styled.div`
  color: var(--Error-800, #912018);
  font: 400 14px/143% Inter, sans-serif;
`;

const Div11 = styled.div`
  color: var(--Primary-400, #fac515);
  font: 600 14px/143% Inter, sans-serif;
`;

const Div12 = styled.div`
  color: var(--Error-800, #912018);
  font: 400 14px/143% Inter, sans-serif;
`;

const Div13 = styled.div`
  align-self: center;
  display: flex;
  margin-top: 12px;
  width: 100%;
  max-width: 432px;
  gap: 4px;
  padding: 16px;
`;

const Div14 = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

const Img4 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 32px;
  justify-content: center;
  align-items: center;
  mix-blend-mode: multiply;
  overflow: hidden;
  max-width: 100%;
  margin: auto 0;
`;

const Span5 = styled.span`
  align-self: stretch;
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
`;

const Div17 = styled.div`
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background-color: var(--Base-White, #fff);
  align-self: center;
  display: flex;
  width: 16px;
  height: 16px;
  flex-direction: column;
  margin: auto 0;
`;
