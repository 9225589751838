import React, { forwardRef, useMemo } from "react";
import { TbLoader } from "react-icons/tb";

const CustomButton = forwardRef((props, ref) => {
  // destructure neccesary props
  const {
    type = "button",
    children,
    cls,
    buttonVariant = "primary",
    disabled,
    isLoading,
    leftIcon,
    rightIcon,
    click,
    ...rest
  } = props;

  // determine icon placement
  const { newIcon: icon, iconPlacement } = useMemo(() => {
    let newIcon = rightIcon || leftIcon;

    if (isLoading) {
      newIcon = (
        <TbLoader className="tw-animate-spin" color="#ffff" size={25} />
      );
    }

    return {
      newIcon,
      iconPlacement: rightIcon ? "right" : "left",
    };
  }, [isLoading, leftIcon, rightIcon]);

  let VARIANTS = {
    primary:
      "tw-bg-yellow-500 tw-py-[10px] disabled:tw-opacity-25 tw-flex tw-items-center tw-text-md-semi-bold tw-justify-center tw-text-white",
    secondary: "",
    transparent: "tw-flex tw-items-center",
    tertiary:
      "tw-border tw-border-[#D0D5DD] tw-flex tw-items-center tw-text-md-semi-bold tw-justify-center tw-text-gray-400 tw-py-[10px] hover:tw-bg-yellow-100 hover:tw-border-yellow-500",
  };

  return (
    <button
      className={`${VARIANTS[buttonVariant]} ${cls}`}
      type={type}
      onClick={click}
      {...rest}
      ref={ref}
      disabled={disabled || isLoading}
    >
      {/** render icon before */}
      {icon && iconPlacement === "left" ? (
        <span
          className={`tw-inline-flex tw-shrink-0 tw-self-center ${
            children && !isLoading && "tw-mr-2"
          }`}
        >
          {icon}
        </span>
      ) : null}

      {/** hide button text during loading state */}
      {!isLoading && children}

      {/** render icon after */}
      {icon && iconPlacement === "right" ? (
        <span
          className={`tw-inline-flex tw-shrink-0 tw-self-center  ${
            children && !isLoading && "tw-ml-2"
          }`}
        >
          {icon}
        </span>
      ) : null}
    </button>
  );
});

export default CustomButton;
