import axios from "axios";

export const listEscrows = async (projectId, designerId, authToken) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/payment/escrow/list/?project=${projectId}&designer=${designerId}`,
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${authToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const fundEscrow = async (values, authToken) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/payment/escrow/create/`,
      values,
      {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${authToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const updateEscrow = async (values, authToken) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_ENDPOINT}/payment/escrow/update`,
      values,
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${authToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const escrowDetails = async (id, authToken) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/payment/escrow/details/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${authToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};
