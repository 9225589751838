import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import { Box, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { Badge } from "react-bootstrap";
import TabPanel from "components/Tabs/TabPanel";
import AllConnections from "./AllConnections";
import DesignersTab from "./DesignersTab";
import "./Connections.css";
import EmptyConnections from "./EmptyConnections";
import { useSelector } from "react-redux";
import { listConnections } from "services/connectionsServices";
import { listDesigners } from "services/designerService";
import { ReactComponent as Arrow } from "assets/svg/connection-arrow.svg";

const Connections = () => {
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const {active} = useSelector(state=> state.account)
  const [connections_list, setConnections] = useState([]);
  const [designers, setDesigners] = useState([]);

  const tabChange = (event, newValue) => {
    setValue(newValue);
  };

  // const [expanded, setExpanded] = useState(false);
  // const handleExpand = () => {
  //   setExpanded(!expanded);
  // };

  // const closeExpand = () => {
  //   setExpanded(false);
  // };

  const listConnectionsByUserId = async (userId) => {
    try {
      const resp = await listConnections(userId);
      console.log(resp);
      setConnections(resp);
    } catch (err) {
      console.error(err);
    }
  };

  const loadDesigners = async () => {
    try {
      const resp = await listDesigners();
      setDesigners(resp);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    listConnectionsByUserId(active.id);
    loadDesigners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredConnections = connections_list.filter((connection) => {
    if (connection.buyer_designer.id !== active?.id) {
      return designers.some(
        (designer) => designer.id === connection.buyer_designer.id
      );
    } else {
      return designers.some(
        (designer) => designer.id === connection.created_by.id
      );
    }
  });

  return (
    <div className="tw-relative ">
      <div className="tw-bg-dashboard-banner tw-w-full tw-h-[120px] tw-bg-no-repeat tw-bg-cover">
        <div className="tw-absolute tw-top-0 tw-p-[32px]">
          <h3 className="tw-text-xs-bold !tw-text-[24px]">Connections</h3>
        </div>
      </div>

      <div className="tw-absolute tw-right-[46px] tw-top-4">
        <Arrow className="tw-mx-auto" />

        <p className=" tw-w-[246px] tw-font-[400] tw-text-[16px] tw-text-[#667085]">
          <span className="!tw-font-[600]">
            {" "}
            Hi {active?.first_name}!
          </span>{" "}
          you can also click here to add new connections anytime
        </p>
      </div>
      {connections_list.length > 0 ? (
        <>
          <Col md={11}>
            {/* Tab View */}
            <Tabs
              value={value}
              onChange={tabChange}
              indicatorColor="primary"
              textColor="primary"
              aria-label="Work History Tabs"
              className="mb-4"
            >
              <Tab
                className="text-capitalize"
                label={
                  <Box display="flex" alignItems="center">
                    <Typography>All Connections</Typography>
                    <Badge
                      className="text-dark bg-secondary"
                      size={10}
                      style={{ marginLeft: 8 }}
                    >
                      {connections_list.length}
                    </Badge>
                  </Box>
                }
              />
              <Tab
                className="text-capitalize"
                label={
                  <Box display="flex" alignItems="center">
                    <Typography>Designers</Typography>
                    <Badge
                      className="text-dark bg-secondary"
                      size={10}
                      style={{ marginLeft: 8 }}
                    >
                      {filteredConnections.length}
                    </Badge>
                  </Box>
                }
              />
            </Tabs>
            <TabPanel value={value} index={0} dir={theme.direction}>
              <AllConnections usersData={connections_list} />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <DesignersTab filteredConnections={filteredConnections} />
            </TabPanel>
          </Col>
        </>
      ) : (
        <EmptyConnections />
      )}
    </div>
  );
};

export default Connections;
