import React, { useState } from "react";
import { ReactComponent as ArrowLeft } from "assets/svg/arrow-left.svg";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as Heart } from "assets/svg/heart.svg";
import { ReactComponent as Dot } from "assets/svg/dot.svg";
import CustomButton from "components/Buttons/Button";
import HorizontalTab from "components/Tabs/HorizontalTab";
import { designerDetails } from "services/designerService";
import Loader from "common/Loader";
import { formatMoney } from "constants/Utils";

const tabs = [
  { key: "work-history", name: "Work History" },
  {
    key: "portfolio",
    name: "Portfolio",
  },
];

function DesignerDetails() {
  const navigate = useNavigate();
  const [activeTab, setActiveTabValue] = useState("work-history");
  const [loading, isLoading] = React.useState(true);
  const [profile, setProfile] = useState(null);
  const { id } = useParams();

  React.useEffect(() => {
    async function fetch() {
      try {
        isLoading(true);
        const resp = await designerDetails(id);
        console.log(resp);
        setProfile(resp);
      } catch (err) {
      } finally {
        isLoading(false);
      }
    }
    fetch();
  }, [id]);

  if (loading) <Loader />;

  const handleBack = () => {
    navigate("/workspace/designers");
  };
  return (
    <div className="tw-relative">
      <button
        className="tw-flex tw-gap-[4px] tw-px-[16px] tw-py-[10px] tw-mb-[24px]"
        type="button"
        onClick={handleBack}
      >
        <ArrowLeft /> Back{" "}
      </button>
      <div className="tw-bg-designer-details-banner tw-w-full tw-h-[240px] tw-bg-no-repeat tw-bg-cover tw-relative">
        <div className="tw-p-[32px] tw-h-full tw-flex tw-flex-col tw-justify-center tw-items-center">
          <h3 className="tw-text-xs-bold !tw-text-[20px] sm:!tw-text-[72px] tw-text-center">
            Umoja linn
          </h3>
        </div>
      </div>
      <div className="tw-flex tw-justify-between tw-px-[32px] tw-items-center -tw-mt-[34px]">
        <div className="tw-flex tw-gap-[24px] tw-items-end">
          <div className="tw-relative">
            <img
              src={`${profile?.profile_image_url}`}
              alt=""
              className="tw-w-[160px] tw-h-[160px]  tw-border-[4px] tw-border-gray-25"
            />

            <div
              className={`${
                profile?.availability ? "tw-bg-[#32D583]" : "tw-bg-red-500"
              } tw-w-full tw-absolute tw-bottom-0 tw-left-0 tw-py-[6px] tw-text-center`}
            >
              <span className=" tw-text-sm-semi-bold tw-text-white ">
                {profile?.availability ? "Available" : "Unavailable"}
              </span>
            </div>
          </div>
          <div>
            <h5 className="tw-text-sm-semi-bold !tw-text-[30px] tw-mb-[4px]">
              {profile?.last_name} {profile?.first_name}
            </h5>
            <p className="tw-text-[16px] tw-text-[#475467] tw-mb-[4px]">
              {profile?.business_name}
            </p>
            <p className="tw-text-[16px] tw-text-[#475467]">
              {/* Member since {formatDate(profile?.created_at)}{" "} */}
              {formatMoney(0)}
            </p>
          </div>
        </div>
        <div className="tw-flex tw-gap-[8px]">
          <button className="tw-px-[14px] tw-py-[12px] tw-shadow-sm tw-border tw-border-[#D0D5DD]">
            <Dot />
          </button>
          <button className="tw-px-[14px] tw-py-[12px] tw-shadow-sm tw-border tw-border-[#D0D5DD]">
            <Heart />
          </button>
          <CustomButton cls=" tw-px-[16px] tw-py-[10px]" onClick={() => {}}>
            Rehire me
          </CustomButton>
        </div>
      </div>

      <div className="tw-px-[32px] tw-my-[24px]">
        <h6 className="tw-text-md-medium tw-text-[#101828]"> About me</h6>
        {profile?.about && (
          <p className="tw-mt-[8px] tw-text-md-regular tw-text-[#475467]">
            I am a passionate fashion designer with [number] years of experience
            in the industry. My design aesthetic is a blend of classic elegance
            and contemporary flair, with an emphasis on creating pieces that are
            both beautiful and functional. I earned my degree in fashion design
            from [Name of School], where I honed my skills in sketching,
            pattern-making, and garment construction. Since then, I have had the
            opportunity to work with a variety of clients
          </p>
        )}
      </div>
      <div className="tw-px-[32px]">
        <div className="tw-bg-[#F9FAFB] tw-py-[20px] tw-px-[24px] tw-flex tw-flex-col tw-gap-y-[24px]">
          <div className="tw-flex tw-justify-between">
            <div>
              <label className="tw-text-sm-medium tw-text-[#667085]">
                Location{" "}
              </label>
              <h3 className="tw-text-md-medium tw-text-[#344054]">
                 {profile?.state}, {profile?.country}
              </h3>
            </div>
            <div>
              <label className="tw-text-sm-medium tw-text-[#667085]">
                Total Earnings{" "}
              </label>
              <h3 className="tw-text-md-medium tw-text-[#344054]">0</h3>
            </div>
            <div>
              <label className="tw-text-sm-medium tw-text-[#667085]">
                Total Jobs{" "}
              </label>
              <h3 className="tw-text-md-medium tw-text-[#344054]">0</h3>
            </div>
            <div>
              <label className="tw-text-sm-medium tw-text-[#667085]">
                Success Rate
              </label>
              <h3 className="tw-text-md-medium tw-text-[#344054]">0%</h3>
            </div>
            <div>
              <label className="tw-text-sm-medium tw-text-[#667085]">
                Years of Experience
              </label>
              <h3 className="tw-text-md-medium tw-text-[#344054]">0</h3>
            </div>
            <div>
              <label className="tw-text-sm-medium tw-text-[#667085]">
                Reviews
              </label>
              <h3 className="tw-text-md-medium tw-text-[#344054]">0</h3>
            </div>
          </div>
          <div className="tw-flex tw-gap-[56px]">
            <div>
              <label className="tw-text-sm-medium tw-text-[#667085]">
                Language
              </label>
              <h3 className="tw-text-md-medium tw-text-[#344054]">Nil</h3>
            </div>
            <div>
              <label className="tw-text-sm-medium tw-text-[#667085]">
                Ratings
              </label>
              <h3 className="tw-text-md-medium tw-text-[#344054]">0.0</h3>
            </div>
          </div>
          <div className="tw-flex">
            <div>
              <label className="tw-text-sm-medium tw-text-[#667085]">
                Specialization
              </label>
              <h3 className="tw-text-md-medium tw-text-[#344054]">Nil</h3>
            </div>
          </div>
        </div>
      </div>

      <div className="tw-px-[32px] tw-my-[24px]">
        <HorizontalTab
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTabValue}
        />
      </div>
    </div>
  );
}

export default DesignerDetails;
