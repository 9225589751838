import * as React from "react";
import styled from "styled-components";

const MediaTab = () => {
  return (
    <Div>
      <Div2>
        <A href="#">Last month</A>
        <Div3>
          <Div4>
            <Div5>
              <Img
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/e5df1b56-c17b-4900-932b-f10262a4b49b?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/e5df1b56-c17b-4900-932b-f10262a4b49b?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e5df1b56-c17b-4900-932b-f10262a4b49b?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/e5df1b56-c17b-4900-932b-f10262a4b49b?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/e5df1b56-c17b-4900-932b-f10262a4b49b?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e5df1b56-c17b-4900-932b-f10262a4b49b?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/e5df1b56-c17b-4900-932b-f10262a4b49b?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/e5df1b56-c17b-4900-932b-f10262a4b49b?"
              />
              <Div6>
                <Div7>Sizing requirments.pdf</Div7>
                <A2 href="#">200 KB </A2>
              </Div6>
            </Div5>
          </Div4>
          <Div8>
            <Div9>
              <img
                loading="lazy"
                alt="umojalin"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/c24dd95a-e740-4cc2-886e-a1717aa28c67?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/c24dd95a-e740-4cc2-886e-a1717aa28c67?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c24dd95a-e740-4cc2-886e-a1717aa28c67?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/c24dd95a-e740-4cc2-886e-a1717aa28c67?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/c24dd95a-e740-4cc2-886e-a1717aa28c67?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c24dd95a-e740-4cc2-886e-a1717aa28c67?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/c24dd95a-e740-4cc2-886e-a1717aa28c67?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/c24dd95a-e740-4cc2-886e-a1717aa28c67?"
              />
              <Div10>
                <A3 href="#">Shirt type.mp4</A3>
                <A4 href="#">16 MB</A4>
              </Div10>
            </Div9>
          </Div8>
          <Div11>
            <Div12>
              <img
                loading="lazy"
                alt="umojalin"

                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/330de8ab-e738-430b-b8ce-80c6e06c306c?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/330de8ab-e738-430b-b8ce-80c6e06c306c?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/330de8ab-e738-430b-b8ce-80c6e06c306c?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/330de8ab-e738-430b-b8ce-80c6e06c306c?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/330de8ab-e738-430b-b8ce-80c6e06c306c?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/330de8ab-e738-430b-b8ce-80c6e06c306c?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/330de8ab-e738-430b-b8ce-80c6e06c306c?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/330de8ab-e738-430b-b8ce-80c6e06c306c?"
              />
              <Div13>
                <A5 href="#">Pandaescrow.io</A5>
                <Div14>https/pandaescrow.io/beta</Div14>
              </Div13>
            </Div12>
          </Div11>
        </Div3>
      </Div2>
      <Div15>
        <A6 href="#">Yesterday</A6>
        <Div16>
          <Div17>
            <Div18>
              <img
                loading="lazy"
                alt="umojalin"

                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/7def0911-9875-4418-9f02-9ea74b7f2e6d?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/7def0911-9875-4418-9f02-9ea74b7f2e6d?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/7def0911-9875-4418-9f02-9ea74b7f2e6d?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/7def0911-9875-4418-9f02-9ea74b7f2e6d?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/7def0911-9875-4418-9f02-9ea74b7f2e6d?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/7def0911-9875-4418-9f02-9ea74b7f2e6d?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/7def0911-9875-4418-9f02-9ea74b7f2e6d?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/7def0911-9875-4418-9f02-9ea74b7f2e6d?"
              />
              <Div19>
                <A7 href="#">Shirt type.mp4</A7>
                <A8 href="#">16 MB</A8>
              </Div19>
            </Div18>
          </Div17>
        </Div16>
      </Div15>
    </Div>
  );
}

export default MediaTab;

const Div = styled.div`
  align-items: center;
  width: 100%;
  max-width: 605px;
  align-self: end;
  display: flex;
  flex-direction: column;
`;

const Div2 = styled.div`
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  display: flex;
  flex-direction: column;
`;

const A = styled.a`
  align-items: center;
  align-self: start;
  padding-top: 5px;
  padding-left: 1px;
  padding-right: 20px;
  padding-bottom: 5px;
  flex: 1 0 0;
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: -0px;
  margin-bottom: -0px;
`;

const Div3 = styled.div`
  align-items: flex-start;
  align-self: stretch;
  margin-top: 6px;
  display: flex;
  flex-direction: column;
`;

const Div4 = styled.div`
  align-items: center;
  align-self: stretch;
  border: 1px solid var(--gray-200, #eaecf0);
  background: var(--gray-50, #f9fafb);
  padding-top: 14px;
  padding-left: 12px;
  padding-right: 20px;
  padding-bottom: 14px;
  display: flex;
  flex-direction: column;
`;

const Div5 = styled.div`
  align-items: flex-start;
  flex: 1 0 0;
  width: 100%;
  margin-top: -2px;
  margin-bottom: 2px;
  padding-right: 20px;
  align-self: start;
  display: flex;
  flex-direction: row;
  gap: 12px;
  flex-wrap: wrap;
`;

const Img = styled.img`
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 36px;
  align-self: start;
`;

const Div6 = styled.div`
  align-items: flex-start;
  align-self: start;
  margin-top: 4px;
  padding-top: 5px;
  padding-left: 1px;
  padding-right: 20px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
  flex-grow: 13.583333333333334;
`;

const Div7 = styled.div`
  align-self: start;
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: -1px;
`;

const A2 = styled.a`
  align-self: start;
  color: var(--gray-600, #475467);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  margin-top: 7px;
  margin-left: 0px;
  margin-bottom: -0px;
  @media (max-width: 991px) {
    margin-left: 0px;
  }
`;

const Div8 = styled.div`
  align-items: center;
  align-self: stretch;
  border: 1px solid var(--gray-200, #eaecf0);
  background: var(--gray-blue-50, #f8f9fc);
  margin-top: 12px;
  padding-top: 14px;
  padding-left: 12px;
  padding-right: 20px;
  padding-bottom: 14px;
  display: flex;
  flex-direction: column;
`;

const Div9 = styled.div`
  align-items: flex-start;
  flex: 1 0 0;
  width: 100%;
  margin-top: -2px;
  margin-bottom: 2px;
  padding-right: 20px;
  align-self: start;
  display: flex;
  flex-direction: row;
  gap: 12px;
  flex-wrap: wrap;
`;

const Div10 = styled.div`
  align-items: flex-start;
  align-self: start;
  margin-top: 4px;
  padding-top: 5px;
  padding-left: 1px;
  padding-right: 20px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
  flex-grow: 13.583333333333334;
`;

const A3 = styled.a`
  align-self: start;
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: -1px;
`;

const A4 = styled.a`
  align-self: start;
  color: var(--gray-600, #475467);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  margin-top: 7px;
  margin-left: 0px;
  margin-bottom: -0px;
  @media (max-width: 991px) {
    margin-left: 0px;
  }
`;

const Div11 = styled.div`
  align-items: center;
  align-self: stretch;
  border: 1px solid var(--gray-200, #eaecf0);
  background: var(--gray-50, #f9fafb);
  margin-top: 12px;
  padding-top: 14px;
  padding-left: 12px;
  padding-right: 20px;
  padding-bottom: 14px;
  display: flex;
  flex-direction: column;
`;

const Div12 = styled.div`
  align-items: flex-start;
  flex: 1 0 0;
  width: 100%;
  margin-top: -2px;
  margin-bottom: 2px;
  padding-right: 20px;
  align-self: start;
  display: flex;
  flex-direction: row;
  gap: 12px;
  flex-wrap: wrap;
`;

const Div13 = styled.div`
  align-items: flex-start;
  align-self: start;
  margin-top: 4px;
  padding-top: 3px;
  padding-left: 1px;
  padding-right: 20px;
  padding-bottom: 3px;
  display: flex;
  flex-direction: column;
  flex-grow: 13.583333333333334;
`;

const A5 = styled.a`
  align-self: start;
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: 1px;
  margin-left: 0px;
  @media (max-width: 991px) {
    margin-left: 0px;
  }
`;

const Div14 = styled.div`
  align-self: start;
  color: var(--gray-600, #475467);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  margin-top: 9px;
  margin-bottom: -1px;
`;

const Div15 = styled.div`
  align-items: flex-start;
  flex: 1 0 0;
  margin-top: 24px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
`;

const A6 = styled.a`
  align-items: center;
  align-self: start;
  padding-top: 3px;
  padding-left: 0px;
  padding-right: 20px;
  padding-bottom: 3px;
  flex: 1 0 0;
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: 2px;
  margin-bottom: -1px;
`;

const Div16 = styled.div`
  align-items: flex-start;
  align-self: stretch;
  margin-top: 6px;
  display: flex;
  flex-direction: column;
`;

const Div17 = styled.div`
  align-items: center;
  align-self: stretch;
  border: 1px solid var(--gray-200, #eaecf0);
  background: var(--gray-blue-50, #f8f9fc);
  padding-top: 14px;
  padding-left: 12px;
  padding-right: 20px;
  padding-bottom: 14px;
  display: flex;
  flex-direction: column;
`;

const Div18 = styled.div`
  align-items: flex-start;
  flex: 1 0 0;
  width: 100%;
  margin-top: -2px;
  margin-bottom: 2px;
  padding-right: 20px;
  align-self: start;
  display: flex;
  flex-direction: row;
  gap: 12px;
  flex-wrap: wrap;
`;

const Div19 = styled.div`
  align-items: flex-start;
  align-self: start;
  margin-top: 4px;
  padding-top: 5px;
  padding-left: 1px;
  padding-right: 20px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
  flex-grow: 13.583333333333334;
`;

const A7 = styled.a`
  align-self: start;
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: -1px;
`;

const A8 = styled.a`
  align-self: start;
  color: var(--gray-600, #475467);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  margin-top: 7px;
  margin-left: 0px;
  margin-bottom: -0px;
  @media (max-width: 991px) {
    margin-left: 0px;
  }
`;
