import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import JobListCard from "components/JobListCard";

const DiscoverTab = ({ projects, toggleSaveJob, saved_jobs }) => {
  const { active } = useSelector((state) => state.account);
  const navigate = useNavigate();

  const handleJobClick = (project) => {
    navigate(`/workspace/jobs/${project?.id}`);
  };

  return (
    <div className="tw-px-[32px]">
    {console.log(projects)}
      {projects.length > 0 &&
        projects
          ?.slice()
          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).filter(project=> project.status !== 'draft')
          .map((project, index) => {
            const saved = saved_jobs.find(
              (job) => job.project.id === project.id
            );
            return (
              <div key={index}>
                {project.project_type !== "group" &&
                  !project.bids.find(
                    (bid) => bid.designer.id === active.id
                  ) && (
                    <JobListCard
                      project={project}
                      onClick={handleJobClick}
                      onToggleSave={toggleSaveJob}
                      isSaved={saved !== undefined}
                      saved={saved}
                    />
                  )}
              </div>
            );
          })}
    </div>
  );
};

export default DiscoverTab;
