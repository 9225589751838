import { HttpMethod, fireRequest } from "./axios";

export const listConnections = async (userId) => {
  try {
    const response = await fireRequest({
      url: `/connection/list/${userId}/`,
      method: HttpMethod.GET
    }, true)

    return response.data ? response.data : response;
  } catch (error) {
    throw error.data;
  }
};

export const listMessagedConnections = async (userId) => {
  try {
    const response = await fireRequest({
      url: `/connection/messaged-connections/${userId}/`,
      method: HttpMethod.GET
    }, true)

    return response.data;
  } catch (error) {
    throw error.data;
  }
};

export const createConnection = async (values) => {
  try {
    const response = await fireRequest({
      url: `/connection/create/`,
      method: HttpMethod.POST,
      body: values
    }, true)

    return response.data;
  } catch (error) {
    throw error.data;
  }
};

export const updateConnection = async (values) => {
  try {
    const response = await fireRequest({
      url: `/connection/update`,
      method: HttpMethod.PUT,
      body: values
    }, true)

    return response.data;
  } catch (error) {
    throw error.data;
  }
};

export const connectionDetails = async (id) => {
  try {
    const response = await fireRequest({
      url: `/connection/details/${id}`,
      method: HttpMethod.GET,
    }, true)

    return response.data;
  } catch (error) {
    throw error.data;
  }
};
