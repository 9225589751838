/* eslint-disable no-script-url */
import React, { useState, useRef } from "react";
import styled from "styled-components";
import { InputGroup, FormControl, Form } from "react-bootstrap";
import { Formik, Form as FormikForm } from "formik";
import * as Yup from "yup";
import { handleUploadImage } from "constants/Utils";
import { useNavigate } from "react-router-dom";

const validationSchema = Yup.object().shape({
  project_gallery: Yup.array().min(1, "Upload at least 1 image"),
  cover_image_url: Yup.string().required("Please select a cover image"),
});

// TODO: CHANGE UPLOAD TO UPLOAD COMPONENT
const GalleryStepper = ({ data, next, prev, onSave }) => {
  const [isLoading, setIsLoading] = useState(false); // State for loader
const navigate = useNavigate()
  const [dragging, setDragging] = useState(false);
  const fileInputRef = useRef(null);

  const handleButtonClick = (e) => {
    e.preventDefault();
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Trigger the hidden file input
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = async (e, setFieldValue, values) => {
    e.preventDefault();
    setDragging(false);
    const newImages = [...values.project_gallery];

    for (let i = 0; i < e.dataTransfer.files.length; i++) {
      const file = e.dataTransfer.files[i];
      if (file.type.startsWith("image/")) {
        setIsLoading(true);
        const { secure_url } = await handleUploadImage(file);
        console.log(secure_url);
        setIsLoading(false);
        newImages.push({
          image_title: "",
          image: file,
          is_cover_image: false,
          imageUrl: URL.createObjectURL(file),
          image_url: secure_url,
        });
      }
    }

    setFieldValue("project_gallery", newImages);
  };

  const handleInputChange = async (e, setFieldValue, values) => {
    const newImages = [...values.project_gallery];

    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files[i];
      if (file.type.startsWith("image/")) {
        // upload image
        setIsLoading(true);
        const { secure_url } = await handleUploadImage(file);
        setIsLoading(false);
        console.log(secure_url, "URL");
        newImages.push({
          image_title: "",
          image: file,
          is_cover_image: false,
          imageUrl: URL.createObjectURL(file),
          image_url: secure_url,
        });
      }
    }

    setFieldValue("project_gallery", newImages);
  };

  const removeImage = (index, setFieldValue, values) => {
    const newImages = [...values.project_gallery];

    newImages.splice(index, 1);

    setFieldValue("project_gallery", newImages);
  };

  const handleTitleChange = (index, event, setFieldValue, values) => {
    const newImages = [...values.project_gallery];
    newImages[index].image_title = event.target.value;
    setFieldValue("project_gallery", newImages);
  };

  const handleIsCoverImage = (index, event, setFieldValue, values) => {
    const newImages = [...values.project_gallery];
    const updatedImages = newImages.map((image, i) => {
      if (i === index) {
        setFieldValue("cover_image_url", newImages[index].image_url);
        return { ...image, is_cover_image: true };
      } else {
        return { ...image, is_cover_image: false };
      }
    });
    newImages[index].is_cover_image = true;
    setFieldValue("project_gallery", updatedImages);
  };

  const handleSubmit = (values) => {
    next(values);
  };
  return (
    <Formik
      initialValues={data}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({
        values,
        errors,
        touched,
        dirty,
        isValid,
        handleChange,
        setFieldValue,
      }) => (
        <FormikForm>
          <div className="lg:tw-px-[32px] lg:tw-pt-[32px]">
            <div className="tw-flex tw-gap-[32px]">
              <div>
                <h4 className="tw-text-sm-medium tw-text-gray-700 tw-mb-1">
                  {data.title} gallery
                </h4>
                <div className="lg:tw-w-[280px] tw-text-sm-regular tw-text-gray-600">
                  Upload styling inspiration to help designers
                </div>
              </div>

              <div className="tw-w-full lg:tw-w-[512px]">
                {values.project_gallery.map((image, index) => (
                  <Div6 key={index} className="mb-3">
                    <Div7 className="tw-mb-[8px]">
                      <Div8 className="w-100">
                        <InputGroup size="lg">
                          <FormControl
                            name={`project_gallery[${index}].image_title`}
                            onChange={(e) =>
                              handleTitleChange(index, e, setFieldValue, values)
                            }
                            value={image.image_title.slice(0, 250)}
                            placeholder="Style Inspo Description"
                            aria-label="Text input with button"
                            aria-describedby="basic-addon2"
                            className="rounded-0"
                          />
                        </InputGroup>
                      </Div8>
                      <div className="tw-text-sm-regular tw-mt-[6px]">
                        {image.image_title.length}/250 characters
                      </div>
                    </Div7>
                    <Div9
                      style={{
                        backgroundImage: `url(${image.imageUrl})`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                      }}
                    >
                      <Div10>
                        <Img
                          loading="lazy"
                          onClick={() =>
                            removeImage(index, setFieldValue, values)
                          }
                          style={{ cursor: "pointer" }}
                          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/981fc107-683a-4778-a3f3-9cbd4a65b9ce?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/981fc107-683a-4778-a3f3-9cbd4a65b9ce?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/981fc107-683a-4778-a3f3-9cbd4a65b9ce?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/981fc107-683a-4778-a3f3-9cbd4a65b9ce?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/981fc107-683a-4778-a3f3-9cbd4a65b9ce?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/981fc107-683a-4778-a3f3-9cbd4a65b9ce?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/981fc107-683a-4778-a3f3-9cbd4a65b9ce?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/981fc107-683a-4778-a3f3-9cbd4a65b9ce?"
                        />
                      </Div10>
                    </Div9>
                    <Div11 className="justify-content-end">
                      <Form.Check
                        type="radio"
                        label="Use as cover image"
                        name={`project_gallery[${index}].is_cover_image`}
                        checked={values.project_gallery[index].is_cover_image}
                        value={values.project_gallery[index].is_cover_image}
                        onChange={(e) =>
                          handleIsCoverImage(index, e, setFieldValue, values)
                        }
                      />
                    </Div11>
                  </Div6>
                ))}
                <Div19
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={(e) => handleDrop(e, setFieldValue, values)}
                >
                  {isLoading ? (
                    <div className="tw-h-5 tw-w-5 tw-animate-spin tw-rounded-full tw-border-4 tw-border-solid tw-border-yellow-400 tw-border-t-transparent"></div>
                  ) : (
                    <Div20>
                      <Img6
                        loading="lazy"
                        onClick={handleButtonClick}
                        style={{ cursor: "pointer" }}
                        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/c4b8a553-01c4-4296-88fa-7bdfe0a972d5?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/c4b8a553-01c4-4296-88fa-7bdfe0a972d5?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c4b8a553-01c4-4296-88fa-7bdfe0a972d5?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/c4b8a553-01c4-4296-88fa-7bdfe0a972d5?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/c4b8a553-01c4-4296-88fa-7bdfe0a972d5?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c4b8a553-01c4-4296-88fa-7bdfe0a972d5?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/c4b8a553-01c4-4296-88fa-7bdfe0a972d5?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/c4b8a553-01c4-4296-88fa-7bdfe0a972d5?"
                      />

                      <Div21>
                        <div>
                          <Div23>
                            {dragging ? (
                              "Drop here"
                            ) : (
                              <>
                                <A6
                                  onClick={handleButtonClick}
                                >
                                  Click to upload
                                </A6>
                                <A7>
                                  or drag and drop
                                </A7>
                              </>
                            )}
                          </Div23>
                        </div>
                        <Div24> Pictures (max. 50mb)</Div24>
                        <div>
                          <input
                            ref={fileInputRef}
                            type="file"
                            id="fileInput"
                            accept="image/*"
                            multiple
                            disabled={isLoading}
                            onChange={(e) =>
                              handleInputChange(e, setFieldValue, values)
                            }
                            name="file"
                            className="d-none"
                          />
                        </div>
                      </Div21>
                    </Div20>
                  )}
                </Div19>
                {errors.project_gallery && touched.project_gallery && (
                  <Form.Text className="text-danger">
                    {errors.project_gallery}
                  </Form.Text>
                )}
                <br />
                {errors.cover_image_url && touched.cover_image_url && (
                  <Form.Text className="text-danger">
                    {errors.cover_image_url}
                  </Form.Text>
                )}
              </div>
            </div>
            {/* Back & Next Button */}
            <div className="tw-px-1 tw-w-full">
              <hr className="tw-mt-10 tw-text-[#EAECF0]" />
              <div className="tw-flex tw-justify-between tw-my-[20px]">
                <button
                  className="tw-text-sm-semi-bold"
                  onClick={() => prev(values)}
                  type="button"
                >
                  Back
                </button>
                <div className="tw-flex tw-gap-[12px]">
                  {/* <button
                    type="button"
                    className="tw-text-sm-semi-bold tw-border tw-border-[#EAECF0] tw-py-[10px] tw-px-[16px] tw-text-[#D0D5DD] tw-hidden lg:tw-block"
                    onClick={() =>
                onSave(data, () => {
                  navigate("/workspace/projects");
                })
              }
                  >
                    Save & exit
                  </button> */}
                  <button
                    className="tw-text-sm-semi-bold tw-py-[10px] tw-px-[16px] tw-bg-yellow-500 tw-text-white"
                    type="submit"
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
            {/*  */}
          </div>
        </FormikForm>
      )}
    </Formik>
  );
};

export default GalleryStepper;

const Div6 = styled.div`
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const Div7 = styled.div`
  align-items: flex-start;
  align-self: stretch;
  padding-top: 2px;
  padding-left: 2px;
  padding-right: 2px;
  padding-bottom: 2px;
  display: flex;
  flex-direction: column;
`;

const Div8 = styled.div`
  align-items: center;
  align-self: start;
  background: var(--color-neutrals-00, #fff);
  flex: 1 0 0;
  color: var(--gray-500, #667085);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: -1px;
`;

const Div9 = styled.div`
  align-items: flex-end;
  flex: 1 0 0;
  align-self: center;
  background: var(--gray-50, #f9fafb);
  width: 100%;
  max-width: 512px;
  padding-top: 11px;
  padding-left: 20px;
  padding-right: 16px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
`;

const Div10 = styled.div`
  width: 100%;
  max-width: 256px;
  margin-bottom: 78px;
  align-self: end;
  display: flex;
  flex-direction: column;
`;

const Img = styled.img`
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 100%;
  max-width: 34px;
  align-self: end;
`;

// const Img2 = styled.img`
//   aspect-ratio: 1;
//   object-fit: cover;
//   object-position: center;
//   width: 100%;
//   max-width: 32px;
//   margin-top: 48px;
//   align-self: start;
// `;

const Div11 = styled.div`
  align-items: flex-start;
  width: 100%;
  margin-top: 8px;
  margin-right: 2px;
  padding-left: 1px;
  padding-right: 20px;
  align-self: end;
  display: flex;
  flex-direction: row;
  gap: 9.01953125px;
  flex-wrap: wrap;
  @media (max-width: 991px) {
    margin-right: 2px;
  }
`;

const Div19 = styled.div`
  align-items: center;
  align-self: center;
  border: 1px dashed var(--gray-300, #d0d5dd);
  background: var(--base-white, #fff);
  width: 100%;
  max-width: 512px;
  padding-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
`;

const Div20 = styled.div`
  align-items: center;
  align-self: center;
  width: 100%;
  max-width: 464px;
  margin-top: -2px;
  margin-bottom: 1px;
  display: flex;
  flex-direction: column;
`;

const Img6 = styled.img`
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 100%;
  max-width: 46px;
  align-self: center;
`;

const Div21 = styled.div`
  align-items: center;
  align-self: stretch;
  margin-top: 9px;
  padding-top: 2px;
  padding-bottom: 2px;
  display: flex;
  flex-direction: column;
`;

const Div23 = styled.div`
  width: 100%;
  margin-top: 1px;
  margin-left: -0px;
  margin-bottom: -1px;
  align-self: center;
  display: flex;
  flex-direction: row;
  gap: 5.9609375px;
  align-items: flex-start;
  flex-wrap: wrap;
`;

const A6 = styled.a`
  color: var(--primary-500, var(--primary-500, #eaaa08));
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  align-self: stretch;
`;

const A7 = styled.a`
  color: var(--gray-600, #475467);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  margin-top: 0px;
  align-self: stretch;
`;

const Div24 = styled.div`
  align-self: center;
  color: var(--gray-600, #475467);
  text-align: center;
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  margin-top: 8px;
  margin-left: 2px;
  margin-bottom: 1px;
`;
