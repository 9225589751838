import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { listWallets } from "services/paymentService";
import styled from "styled-components";
import FundingMethodModal from "./FundingMethodModal";
import { useSelector } from "react-redux";
import { Card, Col, Dropdown } from "react-bootstrap";
import { Chip, Typography } from "@mui/material";
import AvatartDesigner from "assets/images/icons/avatar-designer.svg";
import CalendarPlusIcon from "assets/images/icons/calendar-plus-01.svg";
import CircleCheckIcon from "assets/images/icons/check-circle.svg";
import VerticalDots from "assets/images/icons/dots-vertical.svg";
import ImagePlaceholder from "assets/images/workspace/image-placeholder.png";
import { getCurrencySymbol } from "constants/Utils";
import "./Styles/ListProjects.css";

const FundContract = ({ projects }) => {
  const [show, setShow] = useState(false);
  const [wallets, setUserWallets] = useState([]);
  const auth_token = localStorage.getItem("auth_token");
  const buyer = useSelector((state) => state.buyer.buyer);
  const [selectedProject, setSelectedProject] = useState(projects[0]);

  const options = { year: "numeric", month: "short", day: "numeric" };
  const startDate = new Date(selectedProject.created_at);
  const start_date = new Intl.DateTimeFormat("en-US", options).format(
    startDate
  );
  const endDate = new Date(selectedProject.end_date);
  const end_date = new Intl.DateTimeFormat("en-US", options).format(endDate);

  const handleProjectChange = (value) => {
    setSelectedProject(value);
  };

  const handleCloseModal = () => setShow(false);
  const handleShowModal = () => setShow(true);

  const loadWallets = async () => {
    const resp = await listWallets(buyer.id, auth_token);
    setUserWallets(resp);
  };

  useEffect(() => {
    loadWallets();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const allMilestones = selectedProject.bids
    .filter((bid) => bid.is_accepted)
    .flatMap((acceptedBid) => acceptedBid.milestones);

  const nextMilestone = allMilestones
    .slice()
    .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
    .find((milestone) => !milestone.is_funded && !milestone.is_approved);

  return (
    <>
      <div className="row mt-4">
        {projects.map((project, index) => (
          <>
            {project.status === "ongoing" && (
              <Col
                xs={12}
                md={3}
                key={index}
                onClick={() => handleProjectChange(project)}
                style={{ cursor: "pointer" }}
                className="portfolio-card mb-3 mb-lg-3"
              >
                <div className="card-img-container">
                  <Card.Img
                    className={`rounded-0 ${
                      selectedProject === project
                        ? "selected-col"
                        : "unselected-col"
                    }`}
                    height={125}
                    loading="lazy"
                    src={
                      project.cover_image
                        ? project.cover_image
                        : ImagePlaceholder
                    }
                    alt="Cover Image"
                    fluid
                    style={{ objectFit: "cover" }}
                  />
                  <Dropdown className="dots-icon">
                    <img src={VerticalDots} icon="icon" alt="umojalin" />
                    <Dropdown.Menu>
                      <Dropdown.Item>Edit</Dropdown.Item>
                      <Dropdown.Item>Delete</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <h5 className="project-title text-dark">{project.title}</h5>
                </div>
              </Col>
            )}
          </>
        ))}
      </div>
      <div className="row">
        <div className="row mt-2">
          <div className="job-title">
            <div className="job-text">{selectedProject.title}</div>
          </div>
        </div>
        <div className="row mt-5">
          <Col md={2}>
            <h5 className="text-gray-light">Project Type:</h5>
          </Col>
          <Col md={4} className="align-items-left">
            <Chip
              className="bg-info text-white text-capitalize"
              label={selectedProject.project_type}
            ></Chip>
          </Col>
        </div>
        <div className="row mt-3">
          <Col md={2}>
            <h5 className="text-gray-light">Designer:</h5>
          </Col>
          <Col md={4} className="align-items-left">
            <Chip
              icon={<img src={AvatartDesigner} alt="icon" />}
              label={<Typography>Prince Emanuel</Typography>}
            ></Chip>
          </Col>
        </div>
        <div className="row mt-3">
          <Col md={2}>
            <h5 className="text-gray-light">Sizing Template:</h5>
          </Col>
          <Col md={4} className="align-items-left">
            <Chip
              label={
                <Typography>
                  View sizing templates <img src={CircleCheckIcon} alt="icon" />
                </Typography>
              }
            ></Chip>
          </Col>
        </div>
        <div className="row mt-3">
          <Col md={2}>
            <h5 className="text-gray-light">Timeline:</h5>
          </Col>
          <Col md={4} className="align-items-left">
            <Chip
              size="large"
              label={
                <Typography>
                  {start_date} to {end_date}{" "}
                  <img src={CalendarPlusIcon} alt="icon" />
                </Typography>
              }
            ></Chip>
          </Col>
        </div>
      </div>
      <Div className="row mt-3">
        <Div2>
          <Div3>
            <Div4>Attention required</Div4>
            <Img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/bd7fa0fcb48c3e1d355c7b719519dfdcf61941ff67fba9f354dbb954fe7b9c5f?"
            />
          </Div3>
        </Div2>
        <Div5>
          <Div6>Project Milestones</Div6>
          <Div7>
            {selectedProject.bids
              .filter((bid) => bid.is_accepted)
              .map((acceptedBid) => (
                <>
                  {acceptedBid.milestones
                    .slice()
                    .sort(
                      (a, b) => new Date(a.created_at) - new Date(b.created_at)
                    )
                    .map((item, index) => (
                      <Div8 key={index}>
                        <Div9>
                          <Div10>{index}</Div10>
                          <Div11 />
                        </Div9>
                        <Div12>{item.name}</Div12>
                        <Div13>{item.description}</Div13>
                      </Div8>
                    ))}
                </>
              ))}
            <Div38>
              <Div39>
                <Div40>6</Div40>
                <Div41 />
              </Div39>
              <Div42>Delivery</Div42>
              <Div43>This is a description text.</Div43>
            </Div38>
          </Div7>
          <Div44>
            <Img2
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/5cd100608298d633cfc545ffea0bc84e7d1f2d78860f2313981ce234e516ba2e?"
            />
            <Img3
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/f9089fdba2f191be3b119e004d21ba724cc97a45bf5aa65ef57e05cc94f9ea8c?"
            />
          </Div44>
          <Div45>Project Summary</Div45>
          <Div46>
            <Div47>Milestones</Div47>
            <Div48>
              {selectedProject.bids
                .filter((bid) => bid.is_accepted)
                .map((acceptedBid) => (
                  <>{acceptedBid.milestones.length}</>
                ))}
            </Div48>
          </Div46>
          <Div49>
            <Div50>Service fee</Div50>
            <Div51>
              {`${getCurrencySymbol(
                JSON.parse(selectedProject?.budget)?.currency
              )}`}
              10
            </Div51>
          </Div49>
          <Div52>
            <Div53>Project Price</Div53>
            <Div54>{`${getCurrencySymbol(
              JSON.parse(selectedProject?.budget)?.currency
            )}${JSON.parse(selectedProject?.budget)?.amount}`}</Div54>
          </Div52>
        </Div5>
        <Div55>
        <button disabled={selectedProject.status === 'ongoing' && !selectedProject.is_onhold} className={selectedProject.status === 'ongoing' && !selectedProject.is_onhold ? "disabled-fund-escrow-btn" : "fund-escrow-btn"} type="button" onClick={handleShowModal}>
          Fund contract
        </button>
        </Div55>
        <Modal
          show={show}
          onHide={handleCloseModal}
          className="rounded-0"
          centered
        >
          <Modal.Body>
            <FundingMethodModal
              wallets={wallets}
              nextMilestone={nextMilestone}
            />
          </Modal.Body>
        </Modal>
      </Div>
    </>
  );
};

export default FundContract;

const Div = styled.div`
  display: flex;
  flex-direction: column;
`;

const Div2 = styled.div`
  align-items: start;
  border-bottom: 1px solid var(--Gray-200, #eaecf0);
  align-self: start;
  display: flex;
  width: 586px;
  max-width: 100%;
  padding-right: 60px;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 991px) {
    padding-right: 20px;
  }
`;

const Div3 = styled.div`
  justify-content: center;
  align-items: start;
  border-bottom: 2px solid var(--Primary-500, #eaaa08);
  display: flex;
  gap: 8px;
  padding: 0 4px 10px;
`;

const Div4 = styled.div`
  color: var(--Primary-500, var(--Primary-500, #eaaa08));
  flex-grow: 1;
  white-space: nowrap;
  font: 600 14px/143% Inter, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Img = styled.img`
  aspect-ratio: 1.09;
  object-fit: contain;
  object-position: center;
  width: 24px;
  overflow: hidden;
  align-self: stretch;
  max-width: 100%;
`;

const Div5 = styled.div`
  border-top: 1px solid var(--Gray-200, #eaecf0);
  background-color: var(--Gray-modern-100, #eef2f6);
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 20px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div6 = styled.div`
  color: var(--Gray-900, #101828);
  font: 600 16px/150% Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div7 = styled.div`
  justify-content: space-between;
  display: flex;
  margin-top: 24px;
  gap: 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Div8 = styled.div`
  justify-content: center;
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
`;

const Div9 = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;

const Div10 = styled.div`
  color: var(--Gray-700, #344054);
  text-align: center;
  letter-spacing: 0.06px;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  border-radius: var(--Components-Stepper-Icon-Border-Radius, 999px);
  background-color: var(--Gray-25, #fcfcfd);
  aspect-ratio: 1;
  height: 28px;
  padding: 0 11px;
  font: 500 12px/133% Inter, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div11 = styled.div`
  background-color: var(--Gray-50, #f9fafb);
  align-self: center;
  display: flex;
  width: 90px;
  height: 1px;
  flex-direction: column;
  margin: auto 0;
`;

const Div12 = styled.div`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: var(--Gray-700, #344054);
  text-overflow: ellipsis;
  margin-top: 8px;
  white-space: nowrap;
  font: 500 16px/150% Inter, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div13 = styled.div`
  color: var(--Gray-500, #667085);
  font: 500 14px/20px Inter, sans-serif;
`;

const Div38 = styled.div`
  justify-content: center;
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
`;

const Div39 = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;

const Div40 = styled.div`
  color: var(--Gray-700, #344054);
  text-align: center;
  letter-spacing: 0.06px;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  border-radius: var(--Components-Stepper-Icon-Border-Radius, 999px);
  background-color: var(--Gray-25, #fcfcfd);
  aspect-ratio: 1;
  height: 28px;
  padding: 0 10px;
  font: 500 12px/133% Inter, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div41 = styled.div`
  background-color: var(--Gray-50, #f9fafb);
  align-self: center;
  display: flex;
  width: 90px;
  height: 1px;
  flex-direction: column;
  margin: auto 0;
`;

const Div42 = styled.div`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: var(--Gray-700, #344054);
  text-overflow: ellipsis;
  margin-top: 8px;
  white-space: nowrap;
  font: 500 16px/150% Inter, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div43 = styled.div`
  color: var(--Gray-500, #667085);
  font: 500 14px/20px Inter, sans-serif;
`;

const Div44 = styled.div`
  disply: flex;
  flex-direction: column;
  stroke-width: 4px;
  stroke: var(--Gray-25, #fcfcfd);
  overflow: hidden;
  position: relative;
  display: flex;
  min-height: 3px;
  margin-top: 8px;
  padding-right: 60px;
  justify-content: center;
  align-items: start;
  @media (max-width: 991px) {
    max-width: 100%;
    padding-right: 20px;
  }
`;

const Img2 = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const Img3 = styled.img`
  aspect-ratio: 24.67;
  object-fit: contain;
  object-position: center;
  width: 74px;
  stroke-width: 4px;
  stroke: var(--Gray-modern-300, #cdd5df);
  overflow: hidden;
  max-width: 100%;
`;

const Div45 = styled.div`
  color: var(--Gray-900, #101828);
  align-self: start;
  margin-top: 24px;
  font: 600 16px/150% Inter, sans-serif;
`;

const Div46 = styled.div`
  justify-content: space-between;
  align-self: start;
  display: flex;
  margin-top: 16px;
  width: 326px;
  max-width: 100%;
  gap: 20px;
`;

const Div47 = styled.div`
  color: var(--Gray-700, #344054);
  font: 400 14px/143% Inter, sans-serif;
`;

const Div48 = styled.div`
  color: var(--Gray-700, #344054);
  font: 500 14px/143% Inter, sans-serif;
`;

const Div49 = styled.div`
  justify-content: space-between;
  align-self: start;
  display: flex;
  margin-top: 16px;
  width: 326px;
  max-width: 100%;
  gap: 20px;
`;

const Div50 = styled.div`
  color: var(--Gray-700, #344054);
  font: 400 14px/143% Inter, sans-serif;
`;

const Div51 = styled.div`
  color: var(--Gray-700, #344054);
  font: 500 14px/143% Inter, sans-serif;
`;

const Div52 = styled.div`
  justify-content: space-between;
  align-self: start;
  display: flex;
  margin-top: 16px;
  width: 326px;
  max-width: 100%;
  gap: 20px;
`;

const Div53 = styled.div`
  color: var(--Gray-700, #344054);
  font: 400 14px/143% Inter, sans-serif;
`;

const Div54 = styled.div`
  color: var(--Gray-700, #344054);
  font: 500 14px/143% Inter, sans-serif;
`;

const Div55 = styled.div`
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  align-self: start;
  margin-top: 16px;
  width: 326px;
  max-width: 100%;
  @media (max-width: 991px) {
    white-space: initial;
    padding: 0 20px;
  }
`;
