import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import AddConnections from "./InviteToOnboard";
import { ReactComponent as NoConnect } from "assets/svg/no-connection.svg";
import CustomButton from "components/Buttons/Button";
import { ReactComponent as PlusIcon } from "assets/svg/plus-icon.svg";

const EmptyConnections = () => {
  const [show, setShow] = useState(false);

  const handleCloseModal = () => setShow(false);
  const handleShowModal = () => setShow(true);

  return (
    <>
      <div className=" tw-mt-[87px] tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full tw-overflow-y-scroll">
        <NoConnect className="tw-w-full tw-h-[200px]" />

        <div className="tw-mt-[32px] tw-flex tw-flex-col tw-justify-center">
          <h3 className="tw-text-center tw-text-md-semi-bold !tw-text-[36px] tw-text-[#D0D5DD] tw-mb-[32px]">
            No connections Yet
          </h3>
          <CustomButton onClick={handleShowModal} leftIcon={<PlusIcon />}>
            Invite to Onboard
          </CustomButton>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleCloseModal}
        className="rounded-0"
        centered
        size="md"
      >
        <Modal.Body>
          <AddConnections onHide={handleCloseModal} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EmptyConnections;
