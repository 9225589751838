import React, { forwardRef } from "react";

const Switch = forwardRef((props, ref) => {
  return (
    <label className="tw-cursor-pointer">
      <input
        type="checkbox"
        className="tw-hidden"
        ref={forwardRef}
        {...props}
      />
      <div className={`tw-w-[44px] tw-p-[2px] tw-rounded-full tw-bg-yellow-500`}>
        <div
          className={`tw-w-[20px] tw-h-[20px]  tw-shadow-sm tw-rounded-full tw-transition-all tw-duration-500  tw-bg-white ${
            props.checked ? "tw-translate-x-6" : "-tw-rotate-180"
          }`}
        ></div>
      </div>
    </label>
  );
});

// <div className='tw-flex tw-w-[20px] tw-h-[10px] tw-bg-yellow-500 tw-rounded-full' onClick={()=> setIsSelected(!isSelected)} >
//     <span className={`tw-h-[10px] tw-w-[10px] tw-bg-white tw-rounded-full tw-transition-all tw-duration-500 ${isSelected ? 'tw-ml-[10px]' : ''}`}></span>
// </div>

export default Switch;
