import React, { useState, useEffect } from "react";

const FilterComponent = ({ setShow, show, children }) => {
  const [isMediumScreen, setIsMediumScreen] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMediumScreen(window.innerWidth < 768); // Adjust this breakpoint as needed
    };

    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  return (
    // tw-bg-black/50
    <div
      className="filter-container 
     tw-w-full tw-absolute tw-top-0  tw-h-screen tw-flex tw-justify-end tw-right-0"
    >

<div
        className={`filter-content tw-relative ${
          isMediumScreen ? (show ? "tw-block" : "tw-hidden") : "desktop"
        } tw-w-[300px] tw-p-[20px] tw-border tw-border-gray-200 tw-right-0 tw-block tw-bg-white tw-h-fit `}
      >
      {children}

      </div>
    </div>
  );
};

export default FilterComponent;
