import React, { useState, useEffect, useCallback } from "react";
// import ActivitiesTab from "./ActivitiesTab";
// import ProjectEscrow from "./ProjectEscrow";
// import ChatTab from "./ChatTab";
// import MediaTab from "./MediaTab";
// import DetailsTab from "./DetailsTab";
import { useNavigate } from "react-router-dom";
import { designerDetails } from "services/designerService";
import { listCollaborators } from "services/collaboratorService";
import ProjectThumbnail from "components/ProjectThumbnail";
import { ReactComponent as VerticalDotIcon } from "assets/svg/vertical-dot.svg";
import { ReactComponent as Edit } from "assets/svg/edit.svg";
import { ReactComponent as Trash } from "assets/svg/trash.svg";
import { ReactComponent as Message } from "assets/svg/mail.svg";
import { ReactComponent as Reuse } from "assets/svg/refresh.svg";
import ProjectMiniDetailsCard from "components/ProjectMiniDetailsCard";
import Accordion from "components/Accordion";
import { ReactComponent as NoProject } from "../../../assets/svg/no_project.svg";
import CustomButton from "components/Buttons/Button";
import { ReactComponent as PlusIcon } from "../../../assets/svg/plus-icon.svg";

const AdsTab = ({ projects, onSelectedProject }) => {
  const [selectedProject, setSelectedProject] = useState(null);
  const navigate = useNavigate();
  const [, setCollaborators] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  useEffect(() => {
    setSelectedProject(projects.length ? projects[0] : null);
  }, [projects]);

  // const options = { year: "numeric", month: "short", day: "numeric" };
  // const startDate = new Date(selectedProject.created_at);
  // const start_date = new Intl.DateTimeFormat("en-US", options).format(
  //   startDate
  // );
  // const endDate = new Date(selectedProject.end_date);
  // const end_date = new Intl.DateTimeFormat("en-US", options).format(endDate);

  const handleProjectChange = (value) => {
    setSelectedProject(value);
    onSelectedProject(value);
  };

  const handleCreateChildProject = () => {
    const dataToPass = {
      group_project: selectedProject,
      projecttype: "child",
    };

    navigate({
      pathname: "/workspace/create-project",
      state: dataToPass,
    });
  };

  const loadCollaborators = async () => {
    const resp = await listCollaborators(selectedProject.id);
    setCollaborators(resp);
  };

  const handleClick = useCallback(async (project) => {
    setSelectedProject(project);
  }, []);

  const handleCreateProject = (e) => {
    e.preventDefault();
    navigate("/workspace/create-project");
  };

  // useEffect(() => {
  //   const fetchDesignerDetails = async (designerId) => {
  //     try {
  //       const resp = await designerDetails(designerId, auth_token);
  //       setDesignerInfo(resp);
  //     } catch (error) {
  //       console.error('Error fetching designer information:', error);
  //     }
  //   };
  //   if (selectedProject.bids.length > 0 && selectedProject.bids[0].milestones.length > 0) {
  //     fetchDesignerDetails(selectedProject.bids[0].milestones[0].created_by);
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedProject.bids]);

  // useEffect(() => {
  //   // loadCollaborators();
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [collaborators]);

  return (
    <div >
      <div className="tw-flex tw-flex-row tw-mt-5 tw-gap-[30px] tw-overflow-x-auto tw-w-full">
        {projects.map((project, i) => {
          return (
            <ProjectThumbnail project={project} key={i} click={handleClick} />
          );
        })}
      </div>

      {projects.length <= 0 && (
        <div className=" tw-mt-[87px] tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full tw-overflow-y-scroll">
          <NoProject />

          <div className="tw-mt-[32px]">
            <h3 className="tw-text-center tw-text-md-semi-bold !tw-text-[36px] tw-text-[#D0D5DD] tw-mb-[32px]">
              No active project yet
            </h3>
            <CustomButton
              click={handleCreateProject}
              leftIcon={<PlusIcon />}
              cls={`tw-w-full`}
            >
              Create New Project
            </CustomButton>
          </div>
        </div>
      )}
      {selectedProject && (
        <div>
          <div className="tw-border-b tw-border-[#EAECF0] tw-py-[8px] tw-flex tw-justify-between tw-items-center tw-my-[16px]">
            <h3 className="tw-text-xl-bold">{selectedProject?.title}</h3>

            <div
              onMouseEnter={() => setDropdownVisible(true)}
              onMouseLeave={() => setDropdownVisible(false)}
              className="tw-relative"
            >
              <div className="tw-bg-yellow-25 tw-px-[10px] tw-py-[10px] tw-cursor-pointer">
                <VerticalDotIcon />
              </div>
              {dropdownVisible && (
                <div className="tw-absolute tw-right-0 tw-z-50 tw-w-[200px] tw-rounded tw-bg-white tw-shadow-lg">
                  <ul className="">
                    <li className="tw-flex tw-cursor-pointer tw-items-center tw-gap-[12px] tw-px-[16px] tw-py-[12px] hover:tw-bg-yellow-25 tw-text-sm-medium tw-text-[#344054]">
                      <Edit  className="tw-text-[#344054] "/>
                      Edit Job
                    </li>
                    <li className="tw-flex tw-cursor-pointer tw-items-center tw-gap-[12px] tw-px-[16px] tw-py-[12px] hover:tw-bg-yellow-25 tw-text-sm-medium tw-text-[#344054]">
                      <Message /> Invite Designers
                    </li>
                    <li className="tw-flex tw-cursor-pointer tw-items-center tw-gap-[12px] tw-px-[16px] tw-py-[12px] hover:tw-bg-yellow-25 tw-text-sm-medium tw-text-[#344054]">
                      <Reuse /> Reuse Job ad
                    </li>
                    <li className="tw-flex tw-cursor-pointer tw-items-center tw-gap-[12px] tw-px-[16px] tw-py-[12px] hover:tw-bg-yellow-25 tw-text-sm-medium tw-text-[#344054]">
                      <Trash /> Delete Job ad
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>

          <ProjectMiniDetailsCard project={selectedProject} />

          <Accordion title="Project Gallery" closable={false}>
            <div className="no-scrollbar tw-mb-[16px] tw-flex-col tw-items-stretch lg:tw-items-center lg:tw-flex-row tw-overflow-x-auto tw-flex tw-gap-[20px]">
              {selectedProject.project_gallery &&
                selectedProject.project_gallery.map((gallery, index) => {
                  return (
                    <div key={index} className="tw-flex tw-w-full  ">
                      <div
                        className={`tw-w-full lg:tw-w-[314px] tw-relative !tw-h-[198px]`} >
                      <img src={gallery?.image_url} alt="" style={{height: 'inherit'}} className="tw-block  tw-w-inherit tw-aspect-[100/100] tw-object-cover tw-w-full tw-object-right-top"/>
                        <div className=" tw-bg-black/50 tw-absolute tw-w-full tw-h-full tw-top-0 tw-flex tw-justify-center tw-items-end">
                       <p className="tw-text-xs-regular !tw-text-[12px] tw-text-gray-300 ">
                       {gallery.image_title ? gallery.image_title : ""}

                       </p>  
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </Accordion>

          <Accordion title={"  Delivery Details"}>
            <div className="tw-flex-col tw-gap-[16px] tw-my-[16px]">
              <div className="tw-flex tw-justify-between tw-p-[8px]">
                <h4 className="tw-text-sm-regular tw-text-gray-600  ">
                  Country
                </h4>
                <div className=" tw-text-sm-medium tw-text-gray-700  tw-border tw-border-gray-100 tw-rounded-md tw-px-[10px] tw-py-[4px] tw-w-fit">
                  {selectedProject?.country}
                </div>
              </div>
              <div className="tw-flex tw-justify-between tw-p-[8px]">
                <h4 className="tw-text-sm-regular tw-text-gray-600  ">City</h4>
                <div className=" tw-text-sm-medium tw-text-gray-700  tw-border tw-border-gray-100 tw-rounded-md tw-px-[10px] tw-py-[4px] tw-w-fit">
                  {selectedProject?.city}
                </div>
              </div>
              <div className="tw-flex tw-justify-between tw-p-[8px]">
                <h4 className="tw-text-sm-regular tw-text-gray-600 ">
                  Province / State/ Zip code
                </h4>
                <div className="tw-flex tw-justify-end tw-gap-[18px]">
                  <div className="tw-text-sm-medium tw-text-gray-700  tw-border tw-border-gray-100 tw-rounded-md tw-px-[10px] tw-py-[4px] tw-w-fit">
                    {selectedProject?.state}
                  </div>
                  <div className="   tw-text-sm-medium tw-text-gray-700  tw-border tw-border-gray-100 tw-rounded-md tw-px-[10px] tw-py-[4px] tw-w-fit">
                    {selectedProject.zip_code}
                  </div>
                </div>
              </div>
              <div className="tw-flex tw-justify-between tw-p-[8px]">
                <h4 className="tw-text-sm-regular tw-text-gray-600 ">
                  House Number
                </h4>
                <div className=" tw-text-sm-medium tw-text-gray-700  tw-border tw-border-gray-100 tw-rounded-md tw-px-[10px] tw-py-[4px] tw-w-fit tw-h-[60px]">
                  {selectedProject.address}
                </div>
              </div>
            </div>
          </Accordion>

          <Accordion title={"  Other Details"}>
            <div className="tw-flex-col tw-gap-[16px] tw-my-[16px]">
              <div className="tw-flex tw-justify-between tw-p-[8px]">
                <h4 className="tw-text-sm-regular tw-text-gray-600  ">
                  Clothing Type
                </h4>
                <div className="tw-flex tw-gap-[18px]">
                  {selectedProject.clothing_type &&
                    selectedProject?.clothing_type.map((type, index) => (
                      <h6
                        key={index}
                        className=" tw-text-sm-medium tw-text-gray-700  tw-border tw-border-gray-100 tw-rounded-md tw-px-[10px] tw-py-[4px] tw-w-fit"
                      >
                        {type.name}
                      </h6>
                    ))}
                  {/* <A20 className=" tw-text-sm-regular tw-text-gray-600">Shirt</A20>
                      <A21 className=" tw-text-sm-regular tw-text-gray-600">Trousers</A21> */}
                </div>
              </div>
              <div className="tw-flex tw-justify-between tw-p-[8px]">
                <h4 className="tw-text-sm-regular tw-text-gray-600  ">
                  Specialist
                </h4>
                <div className="tw-flex tw-gap-[18px]">
                  {selectedProject.specialist &&
                    selectedProject?.specialist.map((type, index) => (
                      <h6
                        className=" tw-text-sm-medium tw-text-gray-700  tw-border tw-border-gray-100 tw-rounded-md tw-px-[10px] tw-py-[4px] tw-w-fit"
                        key={index}
                      >
                        {type.value}
                      </h6>
                    ))}
                  {/* <A20 className=" tw-text-sm-regular tw-text-gray-600">Shirt</A20>
                      <A21 className=" tw-text-sm-regular tw-text-gray-600">Trousers</A21> */}
                </div>
              </div>
              <div className="tw-flex tw-justify-between tw-p-[8px]">
                <h4 className="tw-text-sm-regular tw-text-gray-600 ">
                  Experience Level
                </h4>
                <h6 className=" tw-text-sm-regular tw-text-gray-600 tw-justify-end">
                  {selectedProject?.experience_level?.name}
                </h6>
              </div>
              <div className="tw-flex tw-justify-between tw-p-[8px]">
                <h4 className="tw-text-sm-regular tw-text-gray-600 ">
                  {selectedProject?.language?.name ?? "null"}
                </h4>
                <h6 className=" tw-text-sm-regular tw-text-gray-600 tw-justify-end">
                  {selectedProject?.language_level}
                </h6>
              </div>
            </div>
          </Accordion>
        </div>
      )}
    </div>
  );
};

export default AdsTab;
