import React from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";

const UpperBody = ({ props, handleChange, values, selectedUnit, tabIndex }) => {
  const dynamicStyles = {
    maxHeight: tabIndex === 2 ? 'none' : '400px',
    overflowY: tabIndex === 2 ? 'visible' : 'auto',
  };
  return (
    <Div24 style={dynamicStyles}>
      <Div25>
        <Div26>
          <Div27>Neck Circumference</Div27>
          <Div28>
            <div className="d-flex justify-content-end align-items-end">
              <Form.Control
                style={{ textAlign: "right" }}
                type="number"
                onChange={handleChange}
                value={values.neck_circumference}
                name="neck_circumference"
                className="rounded-0 w-75"
                min="1"
                max="300"
              />
              <span className="pb-2" style={{ color: "rgba(102,112,133,1)" }}>
                {" "}
                {selectedUnit}
              </span>
            </div>
          </Div28>
        </Div26>
      </Div25>
      <Div25>
        <Div26>
          <Div27>Chest Circumference</Div27>
          <Div28>
            <div className="d-flex justify-content-end align-items-end">
              <Form.Control
                style={{ textAlign: "right" }}
                type="number"
                onChange={handleChange}
                value={values.chest_circumference}
                name="chest_circumference"
                className="rounded-0 w-75"
                min="1"
                max="300"
              />
              <span className="pb-2" style={{ color: "rgba(102,112,133,1)" }}>
                {" "}
                {selectedUnit}
              </span>
            </div>
          </Div28>
        </Div26>
      </Div25>
      <Div25>
        <Div26>
          <Div27>Shoulder Width</Div27>
          <Div28>
            <div className="d-flex justify-content-end align-items-end">
              <Form.Control
                style={{ textAlign: "right" }}
                type="number"
                onChange={handleChange}
                value={values.shoulder_width}
                name="shoulder_width"
                className="rounded-0 w-75"
                min="1"
                max="300"
              />
              <span className="pb-2" style={{ color: "rgba(102,112,133,1)" }}>
                {" "}
                {selectedUnit}
              </span>
            </div>
          </Div28>
        </Div26>
      </Div25>
      <Div25>
        <Div26>
          <Div27>Shoulder Diameter</Div27>
          <Div28>
            <div className="d-flex justify-content-end align-items-end">
              <Form.Control
                style={{ textAlign: "right" }}
                type="number"
                onChange={handleChange}
                value={values.shoulder_diameter}
                name="shoulder_diameter"
                className="rounded-0 w-75"
                min="1"
                max="300"
              />
              <span className="pb-2" style={{ color: "rgba(102,112,133,1)" }}>
                {" "}
                {selectedUnit}
              </span>
            </div>
          </Div28>
        </Div26>
      </Div25>
      <Div25>
        <Div26>
          <Div27>Upper arm Circumference</Div27>
          <Div28>
            <div className="d-flex justify-content-end align-items-end">
              <Form.Control
                style={{ textAlign: "right" }}
                type="number"
                onChange={handleChange}
                value={values.upper_arm_circumference}
                name="upper_arm_circumference"
                className="rounded-0 w-75"
                min="1"
                max="300"
              />
              <span className="pb-2" style={{ color: "rgba(102,112,133,1)" }}>
                {" "}
                {selectedUnit}
              </span>
            </div>
          </Div28>
        </Div26>
      </Div25>
      <Div25>
        <Div26>
          <Div27>Arm length</Div27>
          <Div28>
            <div className="d-flex justify-content-end align-items-end">
              <Form.Control
                style={{ textAlign: "right" }}
                type="number"
                onChange={handleChange}
                value={values.arm_length}
                name="arm_length"
                className="rounded-0 w-75"
                min="1"
                max="300"
              />
              <span className="pb-2" style={{ color: "rgba(102,112,133,1)" }}>
                {" "}
                {selectedUnit}
              </span>
            </div>
          </Div28>
        </Div26>
      </Div25>
      <Div25>
        <Div26>
          <Div27>Biceps</Div27>
          <Div28>
            <div className="d-flex justify-content-end align-items-end">
              <Form.Control
                style={{ textAlign: "right" }}
                type="number"
                onChange={handleChange}
                value={values.biceps}
                name="biceps"
                className="rounded-0 w-75"
                min="1"
                max="300"
              />
              <span className="pb-2" style={{ color: "rgba(102,112,133,1)" }}>
                {" "}
                {selectedUnit}
              </span>
            </div>
          </Div28>
        </Div26>
      </Div25>
      <Div25>
        <Div26>
          <Div27>Wrist Circumference</Div27>
          <Div28>
            <div className="d-flex justify-content-end align-items-end">
              <Form.Control
                style={{ textAlign: "right" }}
                type="number"
                onChange={handleChange}
                value={values.wrist_circumference}
                name="wrist_circumference"
                className="rounded-0 w-75"
                min="1"
                max="300"
              />
              <span className="pb-2" style={{ color: "rgba(102,112,133,1)" }}>
                {" "}
                {selectedUnit}
              </span>
            </div>
          </Div28>
        </Div26>
      </Div25>
      <Div25>
        <Div26>
          <Div27>Back Width</Div27>
          <Div28>
            <div className="d-flex justify-content-end align-items-end">
              <Form.Control
                style={{ textAlign: "right" }}
                type="number"
                onChange={handleChange}
                value={values.back_width}
                name="back_width"
                className="rounded-0 w-75"
                min="1"
                max="300"
              />
              <span className="pb-2" style={{ color: "rgba(102,112,133,1)" }}>
                {" "}
                {selectedUnit}
              </span>
            </div>
          </Div28>
        </Div26>
      </Div25>
      <Div25>
        <Div26>
          <Div27>Upper Body Length</Div27>
          <Div28>
            <div className="d-flex justify-content-end align-items-end">
              <Form.Control
                style={{ textAlign: "right" }}
                type="number"
                onChange={handleChange}
                value={values.upper_body_length}
                name="upper_body_length"
                className="rounded-0 w-75"
                min="1"
                max="300"
              />
              <span className="pb-2" style={{ color: "rgba(102,112,133,1)" }}>
                {" "}
                {selectedUnit}
              </span>
            </div>
          </Div28>
        </Div26>
      </Div25>
      <Div25>
        <Div26>
          <Div27>Back Length</Div27>
          <Div28>
            <div className="d-flex justify-content-end align-items-end">
              <Form.Control
                style={{ textAlign: "right" }}
                type="number"
                onChange={handleChange}
                value={values.back_length}
                name="back_length"
                className="rounded-0 w-75"
                min="1"
                max="300"
              />
              <span className="pb-2" style={{ color: "rgba(102,112,133,1)" }}>
                {" "}
                {selectedUnit}
              </span>
            </div>
          </Div28>
        </Div26>
      </Div25>
      <Div25>
        <Div26>
          <Div27>Waist Circumference</Div27>
          <Div28>
            <div className="d-flex justify-content-end align-items-end">
              <Form.Control
                style={{ textAlign: "right" }}
                type="number"
                onChange={handleChange}
                value={values.waist_circumference}
                name="waist_circumference"
                className="rounded-0 w-75"
                min="1"
                max="300"
              />
              <span className="pb-2" style={{ color: "rgba(102,112,133,1)" }}>
                {" "}
                {selectedUnit}
              </span>
            </div>
          </Div28>
        </Div26>
      </Div25>
      <Div25>
        <Div26>
          <Div27>Hips Circumference</Div27>
          <Div28>
            <div className="d-flex justify-content-end align-items-end">
              <Form.Control
                style={{ textAlign: "right" }}
                type="number"
                onChange={handleChange}
                value={values.hips_circumference}
                name="hips_circumference"
                className="rounded-0 w-75"
                min="1"
                max="300"
              />
              <span className="pb-2" style={{ color: "rgba(102,112,133,1)" }}>
                {" "}
                {selectedUnit}
              </span>
            </div>
          </Div28>
        </Div26>
      </Div25>
      <Div25>
        <Div26>
          <Div27>Thigh Circumference</Div27>
          <Div28>
            <div className="d-flex justify-content-end align-items-end">
              <Form.Control
                style={{ textAlign: "right" }}
                type="number"
                onChange={handleChange}
                value={values.thigh_circumference}
                name="thigh_circumference"
                className="rounded-0 w-75"
                min="1"
                max="300"
              />
              <span className="pb-2" style={{ color: "rgba(102,112,133,1)" }}>
                {" "}
                {selectedUnit}
              </span>
            </div>
          </Div28>
        </Div26>
      </Div25>
      <Div25>
        <Div26>
          <Div27>Crochet dept</Div27>
          <Div28>
            <div className="d-flex justify-content-end align-items-end">
              <Form.Control
                style={{ textAlign: "right" }}
                type="number"
                onChange={handleChange}
                value={values.crochet_dept}
                name="crochet_dept"
                className="rounded-0 w-75"
                min="1"
                max="300"
              />
              <span className="pb-2" style={{ color: "rgba(102,112,133,1)" }}>
                {" "}
                {selectedUnit}
              </span>
            </div>
          </Div28>
        </Div26>
      </Div25>
      <Div25>
        <Div26>
          <Div27>Inseam</Div27>
          <Div28>
            <div className="d-flex justify-content-end align-items-end">
              <Form.Control
                style={{ textAlign: "right" }}
                type="number"
                onChange={handleChange}
                value={values.inseam}
                name="inseam"
                className="rounded-0 w-75"
                min="1"
                max="300"
              />
              <span className="pb-2" style={{ color: "rgba(102,112,133,1)" }}>
                {" "}
                {selectedUnit}
              </span>
            </div>
          </Div28>
        </Div26>
      </Div25>
    </Div24>
  );
};

export default UpperBody;

const Div24 = styled.div`
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  margin-top: 24px;
  flex-grow: 1;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div25 = styled.div`
  align-items: flex-start;
  border-bottom: 1px solid var(--gray-100, #f2f4f7);
  align-self: stretch;
  display: flex;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div26 = styled.div`
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--color-neutrals-00, #fff);
  display: flex;
  justify-content: space-between;
  gap: 16px;
  padding: 10px 20px 10px 14px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Div27 = styled.div`
  color: var(--gray-500, #667085);
  align-self: stretch;
  max-width: 100%;
  flex-grow: 1;
  flex-basis: auto;
  font: 400 16px/150% Inter, sans-serif;
`;

const Div28 = styled.div`
  align-items: flex-start;
  border-radius: 16px;
  mix-blend-mode: multiply;
  align-self: center;
  display: flex;
  gap: 4px;
  margin: auto 0;
`;
