import { HttpMethod, fireRequest } from "./axios";

export const listCollaborators = async (projectId) => {
  try {
    const response = await fireRequest(
      {
        url: `/collaborator/list/${projectId}/`,
        method: HttpMethod.GET,
      },
      true
    );

    return response;
  } catch (error) {
    throw error.data;
  }
};

export const listAllCollaborators = async () => {
  try {
    const response = await fireRequest(
      {
        url: `/collaborator/list-all/`,
        method: HttpMethod.GET,
      },
      true
    );

    return response;
  } catch (error) {
    throw error.data;
  }
};

export const createCollaborator = async (values) => {
  try {
    const response = await fireRequest(
      {
        url: ``,
        method: HttpMethod.POST,
        body: values,
      },
      true
    );

    return response;
  } catch (error) {
    throw error.data;
  }
};

export const updateCollaborator = async (values) => {
  try {
    const response = await fireRequest(
      {
        url: `/collaborator/update`,
        body: values,
        method: HttpMethod.PUT,
      },
      true
    );

    return response;
  } catch (error) {
    throw error.data;
  }
};

export const collaboratorDetails = async (id) => {
  try {
    const response = await fireRequest(
      {
        url: `/collaborator/details/${id}`,
        method: HttpMethod.GET,
      },
      true
    );

    return response;
  } catch (error) {
    throw error.data;
  }
};

export const deleteCollaborator = async (id) => {
  try {
    const response = await fireRequest(
      {
        url: `/collaborator/${id}/delete/`,
        method: HttpMethod.GET,
      },
      true
    );

    return response;
  } catch (error) {
    throw error.data;
  }
};
