import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { listAllProjects } from "services/projectService";
import "./Styles/Dashboard.css";
import DashTab from "./DashTab";
import JobWatchList from "./JobWatchList";
import Loader from "common/Loader";

const MyProjects = () => {
  const [activeTab, setActiveTabValue] = useState(0);
  const { active } = useSelector((state) => state.account);
  const [projects, setProjects] = useState([]);
  const [job_watch_list, setJobWatchList] = useState([]);
  const [loading, isLoading] = useState(false);

  const loadAllProjects = async () => {
    try {
      isLoading(true);
      const resp = await listAllProjects();
      setProjects(resp);
      setJobWatchList(resp);
    } catch (err) {
    } finally {
      isLoading(false);
    }
  };

  const tabChange = (newValue) => {
    setActiveTabValue(newValue);
  };

  useEffect(() => {
    loadAllProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (loading) return <Loader />;

  return (
    <div className="tw-relative ">
      <div className="tw-bg-dashboard-banner tw-w-full tw-h-[120px] tw-bg-no-repeat tw-bg-cover tw-relative">
        <div className="tw-p-[32px] tw-h-full tw-flex tw-flex-col tw-justify-center">
          <h3 className="tw-text-xs-bold !tw-text-[24px]">
            {" "}
            Welcome, {active && <span>{active?.first_name}</span>}{" "}
          </h3>
        </div>
      </div>


      <div className="tw-px-[32px]">
        <div className="tw-flex tw-mt-[20px] tw-gap-[12px] tw-border-b tw-border-[#EAECF0]">
          <div
            style={{ cursor: "pointer" }}
            className={
              activeTab === 0 ? "active-dash-tab" : "inactive-dash-tab"
            }
            onClick={() => tabChange(0)}
          >
            Dashboard
          </div>
        </div>
      </div>
      {/*  */}
      {activeTab === 0 && (
        <div className="lg:tw-px-[32px] tw-flex">
          <DashTab projects={projects} />
        </div>
      )}
      {activeTab === 1 && (
        <div className="lg:tw-p-[32px]">
          <JobWatchList job_watch_list={job_watch_list} />
        </div>
      )}

      {/* {selected_job_type === "active" && (
        <MyActiveJobs
          active_jobs={bids.filter(
            (bid) => bid.is_accepted && bid.project.status === "ongoing"
          )}
        />
      )}
      {selected_job_type === "past" && <MyPastJobs projects={projects} />} */}
    </div>
  );
};

export default MyProjects;
