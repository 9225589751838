import * as React from "react";
import StarImg from "../../../assets/gif/successful.gif";
import CustomButton from "components/Buttons/Button";

const PublishedModal = (props) => {
  return (
    <div>
      <div className="tw-mb-[32px]">
        <img loading="lazy" src={StarImg} alt="" className="tw-w-[227px] tw-h-[227px] tw-object-cover tw-mx-auto" />
        <h3 className="!tw-text-[24px] tw-text-xs-semi-bold tw-mt-[4px] tw-text-center">Your work has been published</h3>
      </div>
      <div className={'tw-px-[24px] tw-py-[24px]'}>

  
      <CustomButton
         onClick={props.onHide}
                  buttonVariant={"tertiary"}
                  type={"button"}
                  cls={`hover:!tw-bg-transparent tw-w-full`}
                >
                  Back
                </CustomButton>
      </div>
    </div>
  );
};

export default PublishedModal;

