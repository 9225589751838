import { fireRequest, HttpMethod } from "./axios";

export const listDesigners = async () => {
  try {
    const response = await fireRequest(
      {
        url: `/designer/list/`,
        method: HttpMethod.GET,
      },
      true
    );

    return response.data;
  } catch (error) {
    throw error.data;
  }
};

export const createDesigner = async (values) => {
  try {
    const response = await fireRequest(
      {
        url: `/designer/create/`,
        method: HttpMethod.POST,
        body: values,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
      true
    );

    return response;
  } catch (error) {
    throw error.data;
  }
};

export const updateDesigner = async (id,values) => {
  try {
    const response = await fireRequest(
      {
        url: `/designer/update/${id}`,
        method: HttpMethod.PUT,
        body: values,
      },
      true
    );
    return response.data;
  } catch (error) {
    throw error.data;
  }
};

export const designerDetails = async (id) => {
  try {
    const response = await fireRequest(
      {
        url: `/designer/details/${id}`,
        method: HttpMethod.GET,
      },
      true
    );

    return response.data;
  } catch (error) {
    throw error.data;
  }
};

export const createDesignerPortfolio = async (values) => {
  try {
    const response = await fireRequest(
      {
        url: `/designer/portfolio/create/`,
        method: HttpMethod.POST,
        body: values,
      },
      true
    );

    return response;
  } catch (error) {
    throw error.data;
  }
};

export const listAllDesignersPortfolio = async () => {
  try {
    const response = await fireRequest(
      {
        url: `/designer/portfolio/list/`,
        method: HttpMethod.GET,
      },
      true
    );

    return response.data;
  } catch (err) {
    throw err.data;
  }
};


export const listDesignerPortfolio = async (designerId) => {
  try {
    const response = await fireRequest(
      {
        url: `/designer/portfolios/${designerId}`,
        method: HttpMethod.GET,
      },
      true
    );

    return response.data;
  } catch (err) {
    throw err.data;
  }
};
