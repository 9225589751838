import { useCallback } from "react";
import { useSelector } from "react-redux";
import { saveJob } from "services/projectService";
import JobListCard from "components/JobListCard";
import { useNavigate } from "react-router-dom";

const JobWatchList = ({ job_watch_list }) => {
  const { active } = useSelector((state) => state.account);
  const navigate = useNavigate();

  const handleJobClick = (project) => {
    navigate(`/workspace/jobs/${project?.id}`);
  };

  const handleSaveJob = useCallback(
    async (project) => {
      const data = {
        designer: active.id,
        project: project.id,
      };
      const resp = await saveJob(data);
      return resp;
    },
    [active.id]
  );

  return (
    <div>
      {job_watch_list
        .slice()
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        .map((project, index) => (
          <>
            {project.project_type !== "group" && (
              <JobListCard
                project={project}
                key={index}
                onClick={handleJobClick}
                onSave={handleSaveJob}
              />
            )}
          </>
        ))}
    </div>
  );
};

export default JobWatchList;
