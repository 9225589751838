import axios from "axios";
import { HttpMethod, fireRequest } from "./axios";


export const listCities = async (country, state) => {
  try {
    const response = await axios.post(
      `https://countriesnow.space/api/v0.1/countries/state/cities`,
      {
        country: country,
        state: state,
      }
    );
    return response.data
  } catch (error) {
    throw error.data;
  }
};

export const listCountries = async () => {
  try {
    const response = await axios.get(
      `https://countriesnow.space/api/v0.1/countries/states`
    );
    return response.data;
  } catch (error) {
    throw error.data;
  }
};

export const listRegion = async (countryId) => {
  try {
    const response = await fireRequest({
      url: `/commons/region-by-country/${countryId}`,
      method: HttpMethod.GET,
    });

    return response;
  } catch (error) {
    throw error.data;
  }
};

export async function fetchCountriesAndCodesApi() {
  try {
    const response = await axios.get(
      `https://countriesnow.space/api/v0.1/countries/codes`
    );
    return response.data;
  } catch (err) {
    throw err;
  }
}