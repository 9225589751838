import buyerReducer from "./reducers/buyerReducers";
import connectionsReducer from "./reducers/connectionReducers";
import designerReducer from "./reducers/designerReducers";
import messageReducer from "./reducers/messageReducer";
import { ProcessReducer } from "./reducers/process";
// import projectReducer from "./reducers/projectReducers";
import teamMatesReducer from "./reducers/teamMateReducers";
import userReducer from "./reducers/userReducers";
import accountReducer from "./account/reducers";
import projectReducer from "./project/reducers";
import { combineReducers } from "redux";
import milestoneReducer from "./milestone/reducers";

const rootReducer = combineReducers({
	project: projectReducer,
	connections: connectionsReducer,
	messages: messageReducer,
	teammates: teamMatesReducer,
	ProcessReducer: ProcessReducer,
	account: accountReducer,
	milestone: milestoneReducer,
});

export default rootReducer;
