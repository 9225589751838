import { saveToStorage, removeFromStorage, getFromStorage } from "constants/Utils";



export default class StorageService {

  saveAuthData(authData) {
    this.saveItemIfProvided("access_token", authData.access_token);
    this.saveItemIfProvided("refresh_token", authData.refresh_token);
    this.saveItemIfProvided("user_id", authData.id);
    this.saveItemIfProvided("account_type", authData.account_type);
    this.saveItemIfProvided("account_id", authData.account_id);
  }

   saveItemIfProvided(key, value) {
    if (value) {
      saveToStorage(key, value);
    }
  }

  clearAuthData() {
    removeFromStorage("access_token");
    removeFromStorage("refresh_token");
    removeFromStorage("user_id");
    removeFromStorage("account_type");
    removeFromStorage("account_id");
    removeFromStorage("user_email");
  }

  getAuthData() {
    return {
      user_id: getFromStorage("user_id"),
      access_token: getFromStorage("access_token"),
      refresh_token: getFromStorage("refresh_token"),
      account_type: getFromStorage("account_type"),
      account_id: getFromStorage("account_id"),
    };
  }

  get(key) {
    return getFromStorage(key);
  }

  set(key, value) {
    this.saveItemIfProvided(key, value);
  }
}
