import React from "react";
import { Link } from "react-router-dom";

export default function DesignerListCard({ designer }) {
  return (
    <Link to={`/workspace/designers/${designer.id}`} className="lg:tw-gap-[50px] lg:tw-my-[16px] tw-border-[2px] tw-border-gray-200 tw-bg-gray-25 tw-px-[16px] lg:tw-px-[30px] tw-py-[19px] tw-flex">
      <div className="tw-flex tw-gap-2 lg:tw-gap-[13px]">
        <img
          src={
            designer?.profile_image_url ||
            `https://ui-avatars.com/api/?background=EAAA08&color=ffffff&name=${designer?.first_name}`
          }
          className="tw-w-[40px] tw-h-[40px] lg:tw-w-[160px] lg:tw-h-[160px] tw-object-cover"
          alt="profile"
        />
        {/*  */}
        <div className="tw-flex tw-flex-row lg:tw-flex-col tw-justify-between">
          <div>
            <h3 className="lg:tw-text-lg-semi-bold">
              {designer?.last_name} {designer?.first_name}
            </h3>
            <div className="tw-flex tw-gap-[2px] tw-items-center tw-mt-[4px]">
              <h4 className="tw-text-[12px]  lg:tw-text-sm-regular tw-text-gray-500">
                {designer?.country}
              </h4>
              <div className="tw-w-[4px] tw-h-[4px] tw-bg-gray-500 tw-rounded-full"></div>
              <h6 className=" tw-text-[12px] lg:tw-text-sm-regular  tw-text-gray-500">
                0k - 0k (USD)
              </h6>
            </div>
          </div>
          <div>
            <div
              className={`${
                designer.availability
                  ? "tw-bg-green-200 tw-green-700"
                  : "tw-bg-[#FFF4ED] tw-text-[#B93815]"
              } tw-text-[12px] lg:tw-text-sm-medium tw-py-[2px] lg:tw-py-[4px] tw-px-[8px] lg:tw-px-[12px] tw-rounded-[16px] tw-mb-[8px] tw-w-fit tw-h-[22px] lg:tw-h-[28px]`}
            >
              {designer.availability ? "Available" : "Unavailable"}
            </div>
            <div className="tw-hidden lg:tw-flex tw-flex-1 tw-justify-between lg:tw-justify-normal lg:tw-gap-[31.5px] tw-items-center">
              <h4 className="!tw-text-[12px] tw-text-sm-medium tw-flex lg:!tw-text-[16px] tw-items-center">
                0{" "}
                <span className="tw-mx-1 tw-text-[12px] tw-font-[400] lg:!tw-text-[16px]  tw-text-gray-400">
                  Jobs
                </span>
              </h4>

              <h5 className="!tw-text-[12px] tw-text-sm-medium tw-flex lg:!tw-text-[16px] tw-items-center">
                0%{" "}
                <span className="tw-mx-1 tw-text-[12px] tw-font-[400] lg:!tw-text-[16px] tw-text-gray-400">
                  success rate
                </span>{" "}
              </h5>

              <h5 className="!tw-text-[12px] tw-text-sm-medium tw-flex lg:!tw-text-[16px] tw-items-center">
                0{" "}
                <span className="tw-mx-1 tw-text-[12px] tw-font-[400] lg:!tw-text-[16px] tw-text-gray-400">
                  Reviews
                </span>
              </h5>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="tw-flex tw-gap-[8px]">
          {designer.portfolio &&
            designer.portfolio.map((portfolio, index) => {
              return (
                <img
                key={index}
                  src={portfolio.image_url}
                  alt="portfolio"
                  className="tw-w-[160px] tw-h-[160px] tw-border-gray-100 tw-border tw-object-cover"
                />
              );
            })}
        </div>
      </div>
    </Link>
  );
}
