import axios from "axios";

export const createWallet = async (values, authToken) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/payment/wallets/create/`,
      values,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const topUpWallet = async (values, authToken) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/payment/wallets/top-up/`,
      values,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const withdraw = async (values, authToken) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/payment/wallets/withdraw/`,
      values,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const listWallets = async (userId, authToken) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/payment/wallets/list/?created_by=${userId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const addPaymentMethod = async (values, authToken) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/payment/payment-methods/add/`,
      values,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const addOtherPaymentDetails = async (values, authToken) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/payment/connect-stripe-account/`,
      values,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const listPaymentMethod = async (userId, authToken) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/payment/payment-methods/list/?created_by=${userId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};
