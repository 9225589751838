import React, { useState } from "react";

const bannerType = {
  success: "tw-text-[#12B76A] tw-bg-[#F6FEF9]",
  warn: "tw-bg-[#FEFDF0] tw-text-[#667085]",
  error: "tw-bg-[#FFFCF5] tw-text-[#F04438]",
};

const headCls = {
  success: "tw-text-[#027A48]",
  warn: "tw-text-[#344054]",
  error: "tw-text-[#B42318]",
};

export default function Banner({
  head = "Complete your Buyers profile",
  text = "Each account linked to your email has its own unique Umojalinn ID",
  type = "warn",
}) {
  const [show, setShow] = useState(true);
  return (
    <>
      {show && (
        <div
          className={`${bannerType[type]} tw-flex  tw-py-[6px] tw-px-[16px] tw-items-center tw-my-[28px]`}
        >
          <div className="tw-flex-wrap lg:tw-text-center tw-w-full tw-flex  tw-items-center lg:tw-justify-center tw-gap-2">
            <h6 className={`tw-text-md-semi-bold ${headCls[type]}`}>{head} </h6>
            <p className="tw-mx-md-regular !tw-text-[16px]"> {text}</p>
          </div>
          <button
            className="tw-cursor-pointer"
            type="button"
            onClick={() => setShow(false)}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 5L5 15M5 5L15 15"
                stroke="currentColor"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
      )}
    </>
  );
}
