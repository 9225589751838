/* eslint-disable no-script-url */
import React, { useState, useEffect } from "react";
import { Form, Modal } from "react-bootstrap";
import styled from "styled-components";
import RecommendDesignersModal from "./RecommendDesignersModal";
import { useSelector } from "react-redux";
import { listConnections } from "services/connectionsServices";
import "./wizardStyle.css";
import { listDesigners } from "services/designerService";
import { Formik, Form as FormikForm } from "formik";
import Loader from "common/Loader";
import { useNavigate } from "react-router-dom";

const CollaboratorsStepper = ({ data, next, prev, onSave }) => {
  const [show, setShow] = useState(false);
  const [tag, setTag] = useState("");
  const [designer_tag, setDesignerTag] = useState("");
  const [foundTags, setFoundTags] = useState([]);
  const [connectionTags, setConnectionTags] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [designerNotFound, setDesignerNotFound] = useState(false);
  const [, setShowTagBox] = useState(false);
  const buyer = useSelector((state) => state.buyer.buyer);
  const designer = useSelector((state) => state.designer.designer);
  const selectedAccount = buyer ? buyer : designer;
  const [, setConnections] = useState([]);
  const [designers, setSelectedDesigners] = useState([]);
  const [designerTags, setDesignerTags] = useState([]);
  const [designers_to_recommend, setDesignersToRecommend] = useState([]);
  const [loading, isLoading] = useState(false);
  const navigate = useNavigate();

  const listConnectionsByUserId = async () => {
    try {
      const resp = await listConnections(selectedAccount.id);
      const filteredConnections = resp.reduce((result, item) => {
        if (item.buyer_designer.id === selectedAccount.id) {
          result.push(item.created_by);
        } else if (item.created_by.id === selectedAccount.id) {
          result.push(item.buyer_designer);
        } else {
          result.push(item);
        }
        return result;
      }, []);

      const tagsArray = filteredConnections.map(
        (connection) => connection.slug
      );
      setConnections(filteredConnections);
      setConnectionTags(tagsArray);
    } catch (err) {
      console.error(err);
    }
  };

  const loadDesigners = async () => {
    try {
      isLoading(true);
      const resp = await listDesigners();
      const filteredDesigners = resp.filter(
        (designer) => designer.id !== selectedAccount.id
      );
      const desTags = filteredDesigners.map((designer) => designer.slug);
      setDesignersToRecommend(filteredDesigners);
      setDesignerTags(desTags);
      isLoading(false);
    } catch (err) {
      isLoading(false);
      console.error(err);
    }
  };

  const handleTagChange = (e) => {
    setTag(e.target.value);
    setNotFound(false);
  };

  const handleDesignerTagChange = (e) => {
    setDesignerTag(e.target.value);
    setDesignerNotFound(false);
  };

  const handleSearch = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (connectionTags.includes(tag)) {
        setFoundTags((prevTags) => [...prevTags, tag]);
        setTag("");
        setShowTagBox(false);
      } else {
        setNotFound(true);
      }
    }
  };

  const handleDesignerSearch = (event) => {
    setShow(true);
    // if (event.key === "Enter") {
    //   event.preventDefault();
    //   if (designerTags.includes(designer_tag)) {
    //     setFoundDesigners((prevTags) => [...prevTags, designer_tag]);
    //     setDesignerTag("");
    //   } else {
    //     setDesignerNotFound(true);
    //   }
    // }
  };

  const handleRemoveTag = (index) => {
    setFoundTags((prevTags) => prevTags.filter((_, i) => i !== index));
  };

  const handleRemoveDesigner = (value) => {
    const updatedDesigners = designers_to_recommend.map((designer) =>
      designer.user.id === value.user.id
        ? { ...designer, selected: !designer.selected }
        : designer
    );
    setDesignersToRecommend(updatedDesigners);
  };

  useEffect(() => {
    listConnectionsByUserId();
    loadDesigners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseModal = () => {
    setShow(false);
  };

  const handleSubmit = (values) => {
    let newValue = values;
    newValue.recommended_designers = designers_to_recommend.filter(
      (designer) => designer.selected
    );
    next(newValue);
  };
  if (loading) <Loader />;
  return (
    <Formik
      initialValues={data}
      onSubmit={handleSubmit}
      // validationSchema={data.project_type === 'solo' ?validationSchema : validationSchemaGroup}
    >
      {({
        values,
        errors,
        touched,
        dirty,
        isValid,
        setFieldValue,
        handleChange,
      }) => (
        <FormikForm>
          <Div>
            <Div2>
              <Div3>
                <Column>
                  <Div4>
                    <Div5>Invite Collaborators</Div5>
                    <Div6>
                      Invite connections to collaborate on this project.
                    </Div6>
                  </Div4>
                </Column>
                <Column2>
                  <Div7>
                    <Div8>
                      <Div9>
                        <A href="javascript:void(0)">User tag</A>
                        <div className="row tw-w-full">
                          <Form.Group controlId="tagSearch">
                            <div style={{ position: "relative" }}>
                              <span
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  left: "10px",
                                  cursor: "pointer",
                                  transform: "translateY(-50%)",
                                }}
                              >
                                <Img
                                  loading="lazy"
                                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/3dacf247-c62b-41ea-8694-923cd75a94a1?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/3dacf247-c62b-41ea-8694-923cd75a94a1?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3dacf247-c62b-41ea-8694-923cd75a94a1?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/3dacf247-c62b-41ea-8694-923cd75a94a1?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/3dacf247-c62b-41ea-8694-923cd75a94a1?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3dacf247-c62b-41ea-8694-923cd75a94a1?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/3dacf247-c62b-41ea-8694-923cd75a94a1?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/3dacf247-c62b-41ea-8694-923cd75a94a1?"
                                />
                              </span>
                              <Form.Control
                                type="text"
                                placeholder="User tag/email"
                                value={tag}
                                onChange={handleTagChange}
                                onKeyDown={handleSearch}
                                className="rounded-0 form-control-lg px-5 tag-input-field"
                              />
                              {notFound && (
                                <span
                                  style={{
                                    position: "absolute",
                                    top: "50%",
                                    right: "10px",
                                    cursor: "pointer",
                                    transform: "translateY(-50%)",
                                  }}
                                  tag
                                >
                                  <Img2
                                    loading="lazy"
                                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/d324d0f5-0a43-4b00-be2d-a4010b5c25d5?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/d324d0f5-0a43-4b00-be2d-a4010b5c25d5?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d324d0f5-0a43-4b00-be2d-a4010b5c25d5?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/d324d0f5-0a43-4b00-be2d-a4010b5c25d5?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/d324d0f5-0a43-4b00-be2d-a4010b5c25d5?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d324d0f5-0a43-4b00-be2d-a4010b5c25d5?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/d324d0f5-0a43-4b00-be2d-a4010b5c25d5?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/d324d0f5-0a43-4b00-be2d-a4010b5c25d5?"
                                  />
                                </span>
                              )}
                            </div>
                          </Form.Group>
                        </div>
                      </Div9>
                      {notFound && (
                        <Div13>
                          <span
                            style={{
                              fontFamily:
                                "Inter, -apple-system, Roboto, Helvetica, sans-serif",
                              fontWeight: "400",
                              color: "rgba(240,68,56,1)",
                            }}
                          >
                            tag not assigned to any user{" "}
                          </span>
                          <span
                            style={{
                              fontFamily:
                                "Inter, -apple-system, Roboto, Helvetica, sans-serif",
                              fontWeight: "600",
                              color: "rgba(234,170,8,1)",
                            }}
                          >
                            Invite new user to join Umoja linn
                          </span>
                        </Div13>
                      )}
                    </Div8>
                    <div className="tw-flex tw-items-center tw-gap-3 tw-justify-end tw-mt-[16px] tw-cursor-pointer">
                      <img
                        loading="lazy"
                        alt="umojalin"
                        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/4d4a9a89-d4f1-46c9-8c42-6409d7d0db99?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/4d4a9a89-d4f1-46c9-8c42-6409d7d0db99?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/4d4a9a89-d4f1-46c9-8c42-6409d7d0db99?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/4d4a9a89-d4f1-46c9-8c42-6409d7d0db99?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/4d4a9a89-d4f1-46c9-8c42-6409d7d0db99?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/4d4a9a89-d4f1-46c9-8c42-6409d7d0db99?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/4d4a9a89-d4f1-46c9-8c42-6409d7d0db99?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/4d4a9a89-d4f1-46c9-8c42-6409d7d0db99?"
                      />
                      <h6
                        className="tw-text-sm-semi-bold"
                        onClick={() => setShow(true)}
                      >
                        Add another user
                      </h6>
                    </div>
                    <Div14>
                      {foundTags.map((tag, index) => (
                        <Div26 key={index} className="mb-2">
                          <A7 href="javascript:void(0)">{tag}</A7>
                          <A8
                            href="javascript:void(0)"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleRemoveTag(index)}
                          >
                            remove
                          </A8>
                        </Div26>
                      ))}
                    </Div14>
                    {notFound && (
                      <div className="tw-flex tw-bg-[#EAECF0] tw-px-[8px] tw-py-[10px] tw-gap-[12px] tw-rounded-[4px]">
                        <Img5
                          loading="lazy"
                          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/0ca704cb-4561-4105-8c31-03595925a29a?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/0ca704cb-4561-4105-8c31-03595925a29a?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/0ca704cb-4561-4105-8c31-03595925a29a?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/0ca704cb-4561-4105-8c31-03595925a29a?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/0ca704cb-4561-4105-8c31-03595925a29a?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/0ca704cb-4561-4105-8c31-03595925a29a?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/0ca704cb-4561-4105-8c31-03595925a29a?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/0ca704cb-4561-4105-8c31-03595925a29a?"
                        />
                        <div>
                          <span
                            style={{
                              fontFamily:
                                "Inter, -apple-system, Roboto, Helvetica, sans-serif",
                              fontWeight: "400",
                              color: "rgba(71,84,103,1)",
                            }}
                          >
                            Collaborators are only people you have a connection
                            with on Umoja linn.{" "}
                          </span>
                          <span
                            style={{
                              fontFamily:
                                "Inter, -apple-system, Roboto, Helvetica, sans-serif",
                              fontWeight: "600",
                              color: "rgba(234,170,8,1)",
                            }}
                          >
                            Invite new user to join Umoja linn
                          </span>
                        </div>
                      </div>
                    )}
                  </Div7>
                </Column2>
              </Div3>
            </Div2>
            <Div2>
              <Div3>
                <Column>
                  <Div4>
                    <Div5>Recommended Designers</Div5>
                    <Div6>Recommend designers to collaborators</Div6>
                  </Div4>
                </Column>
                <Column2>
                  <div>
                    <Div8>
                      <Div9>
                        <A href="javascript:void(0)">User tag</A>
                        <div className="row tw-w-full">
                          <Form.Group controlId="tagSearch">
                            <div style={{ position: "relative" }}>
                              <span
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  left: "10px",
                                  cursor: "pointer",
                                  transform: "translateY(-50%)",
                                }}
                              >
                                <Img
                                  loading="lazy"
                                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/3dacf247-c62b-41ea-8694-923cd75a94a1?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/3dacf247-c62b-41ea-8694-923cd75a94a1?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3dacf247-c62b-41ea-8694-923cd75a94a1?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/3dacf247-c62b-41ea-8694-923cd75a94a1?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/3dacf247-c62b-41ea-8694-923cd75a94a1?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3dacf247-c62b-41ea-8694-923cd75a94a1?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/3dacf247-c62b-41ea-8694-923cd75a94a1?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/3dacf247-c62b-41ea-8694-923cd75a94a1?"
                                />
                              </span>
                              <Form.Control
                                type="text"
                                placeholder="User tag/email"
                                value={designer_tag}
                                onChange={handleDesignerTagChange}
                                onClick={handleDesignerSearch}
                                className="rounded-0 form-control-lg px-5 tag-input-field"
                              />
                              {designerNotFound && (
                                <span
                                  style={{
                                    position: "absolute",
                                    top: "50%",
                                    right: "10px",
                                    cursor: "pointer",
                                    transform: "translateY(-50%)",
                                  }}
                                >
                                  <Img2
                                    loading="lazy"
                                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/d324d0f5-0a43-4b00-be2d-a4010b5c25d5?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/d324d0f5-0a43-4b00-be2d-a4010b5c25d5?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d324d0f5-0a43-4b00-be2d-a4010b5c25d5?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/d324d0f5-0a43-4b00-be2d-a4010b5c25d5?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/d324d0f5-0a43-4b00-be2d-a4010b5c25d5?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d324d0f5-0a43-4b00-be2d-a4010b5c25d5?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/d324d0f5-0a43-4b00-be2d-a4010b5c25d5?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/d324d0f5-0a43-4b00-be2d-a4010b5c25d5?"
                                  />
                                </span>
                              )}
                            </div>
                          </Form.Group>
                        </div>
                      </Div9>
                      {designerNotFound && (
                        <Div13>
                          <span
                            style={{
                              fontFamily:
                                "Inter, -apple-system, Roboto, Helvetica, sans-serif",
                              fontWeight: "400",
                              color: "rgba(240,68,56,1)",
                            }}
                          >
                            tag not assigned to any user{" "}
                          </span>
                          <span
                            style={{
                              fontFamily:
                                "Inter, -apple-system, Roboto, Helvetica, sans-serif",
                              fontWeight: "600",
                              color: "rgba(234,170,8,1)",
                            }}
                          >
                            Invite new user to join Umoja linn
                          </span>
                        </Div13>
                      )}
                    </Div8>
                    <div className="tw-flex tw-items-center tw-gap-3 tw-justify-end tw-mt-[16px] tw-cursor-pointer">
                      <img
                        loading="lazy"
                        alt="umojalin"
                        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/4d4a9a89-d4f1-46c9-8c42-6409d7d0db99?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/4d4a9a89-d4f1-46c9-8c42-6409d7d0db99?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/4d4a9a89-d4f1-46c9-8c42-6409d7d0db99?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/4d4a9a89-d4f1-46c9-8c42-6409d7d0db99?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/4d4a9a89-d4f1-46c9-8c42-6409d7d0db99?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/4d4a9a89-d4f1-46c9-8c42-6409d7d0db99?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/4d4a9a89-d4f1-46c9-8c42-6409d7d0db99?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/4d4a9a89-d4f1-46c9-8c42-6409d7d0db99?"
                      />
                      <h6
                        className="tw-text-sm-semi-bold"
                        onClick={() => setShow(true)}
                      >
                        Add another user
                      </h6>
                    </div>
                    <Div14>
                      {designers_to_recommend.map((tag, index) => {
                        return (
                          <>
                            {tag.selected ? (
                              <Div26 key={index} className="mb-2">
                                <A7 href="javascript:void(0)">{tag.slug}</A7>
                                <A8
                                  href="javascript:void(0)"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleRemoveDesigner(tag)}
                                >
                                  remove
                                </A8>
                              </Div26>
                            ) : (
                              ""
                            )}
                          </>
                        );
                      })}
                    </Div14>
                    <div className="tw-flex tw-rounded-md tw-items-center tw-gap-2 tw-bg-[#EAECF0] tw-py-[10px] tw-px-[8px]">
                      <Img7
                        loading="lazy"
                        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/c257acd8-e305-4f73-8f79-7d90aff58af5?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/c257acd8-e305-4f73-8f79-7d90aff58af5?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c257acd8-e305-4f73-8f79-7d90aff58af5?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/c257acd8-e305-4f73-8f79-7d90aff58af5?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/c257acd8-e305-4f73-8f79-7d90aff58af5?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c257acd8-e305-4f73-8f79-7d90aff58af5?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/c257acd8-e305-4f73-8f79-7d90aff58af5?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/c257acd8-e305-4f73-8f79-7d90aff58af5?"
                      />
                      <div>
                        Hired or favorite designers are the only designers
                        recommendations
                      </div>
                    </div>
                  </div>
                </Column2>
              </Div3>
            </Div2>
            <Modal show={show} handleClose={handleCloseModal}>
              <Modal.Body className="!tw-px-0">
                <RecommendDesignersModal
                  designers={designers_to_recommend}
                  hide={handleCloseModal}
                  setSelected={setDesignersToRecommend}
                />
              </Modal.Body>
            </Modal>
          </Div>
          {/* Back & Next Button */}
          <div className="tw-px-1">
            <hr className="tw-mt-10 tw-text-[#EAECF0]" />
            <div className="tw-flex tw-justify-between tw-my-[20px]">
              <button
                className="tw-text-sm-semi-bold"
                onClick={() => prev(values, true)}
                type="button"
              >
                Back
              </button>
              <div className="tw-flex tw-gap-[12px]">
                <button
                  className="tw-text-sm-semi-bold tw-border tw-border-[#EAECF0] tw-py-[10px] tw-px-[16px] tw-text-[#D0D5DD] tw-hidden lg:tw-block"
                  onClick={() =>
                    onSave(data, () => {
                      navigate("/workspace/projects");
                    })
                  }
                >
                  Save & exit
                </button>
                <button
                  className="tw-text-sm-semi-bold tw-py-[10px] tw-px-[16px] tw-bg-yellow-500 tw-text-white"
                  type="submit"
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </FormikForm>
      )}
    </Formik>
  );
};

export default CollaboratorsStepper;

const Div = styled.div`
  align-items: flex-start;
  align-self: end;
  width: 100%;
  max-width: 1096px;
  margin-top: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
`;

const Div2 = styled.div`
  width: 100%;
  max-width: 826px;
  padding-right: 20px;
  align-self: start;
  display: flex;
  flex-direction: column;
`;

const Div3 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 35%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div4 = styled.div`
  align-items: flex-start;
  padding-top: 3px;
  padding-left: 1px;
  padding-right: 16px;
  padding-bottom: 3px;
  display: flex;
  flex-direction: column;
  @media (max-width: 991px) {
    margin-top: 30px;
  }
`;

const Div5 = styled.div`
  align-self: start;
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: 1px;
  margin-left: 0px;
  @media (max-width: 991px) {
    margin-left: 0px;
  }
`;

const Div6 = styled.div`
  align-self: stretch;
  color: var(--gray-500, #667085);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  margin-top: 9px;
  margin-bottom: -1px;
`;

const Column2 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 65%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div7 = styled.div`
  justify-content: flex-end;
  align-items: flex-end;
  padding-top: 2px;
  padding-bottom: 2px;
  display: flex;
  flex-direction: column;
  @media (max-width: 991px) {
    margin-top: 30px;
  }
`;

const Div8 = styled.div`
  align-items: flex-start;
  margin-top: -2px;
  padding-top: 1px;
  padding-bottom: 1px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
`;

const Div9 = styled.div`
  align-items: flex-start;
  align-self: stretch;
  margin-top: -1px;
  padding-top: 4px;
  padding-left: 2px;
  padding-right: 2px;
  padding-bottom: 4px;
  display: flex;
  flex-direction: column;
`;

const A = styled.a`
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: 1px;
  margin-left: 1px;
  align-self: start;
  @media (max-width: 991px) {
    margin-left: 1px;
  }
`;

const Img = styled.img`
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 20px;
  align-self: stretch;
`;

const Img2 = styled.img`
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 16px;
  align-self: center;
`;

const Div13 = styled.div`
  align-self: start;
  color: var(--primary-500, #eaaa08);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  margin-top: 7px;
  margin-left: 2px;
  margin-bottom: 1px;
  @media (max-width: 991px) {
    margin-left: 2px;
  }
`;

const Div14 = styled.div`
  align-items: flex-start;
  align-self: stretch;
  margin-top: 16px;
  padding-top: 4px;
  padding-left: 2px;
  padding-right: 2px;
  padding-bottom: 4px;
  display: flex;
  flex-direction: column;
`;

const Img5 = styled.img`
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 24px;
  align-self: center;
`;

const Div26 = styled.div`
  align-items: flex-start;
  align-self: stretch;
  margin-top: -2px;
  padding-top: 2px;
  padding-left: 2px;
  padding-right: 20px;
  padding-bottom: 2px;
  display: flex;
  flex-direction: row;
  gap: 16.92578125px;
  flex-wrap: wrap;
`;

const A7 = styled.a`
  align-items: center;
  align-self: start;
  border: 1px solid var(--gray-300, #d0d5dd);
  background: var(--color-neutrals-00, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  margin-top: -1px;
  padding-top: 16px;
  padding-left: 15px;
  padding-right: 20px;
  padding-bottom: 16px;
  flex-grow: 9.05236270753512;
  flex: 1 0 0;
  color: var(--gray-500, #667085);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: -0px;
`;

const A8 = styled.a`
  color: var(--error-400, #f97066);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  margin-top: 0px;
  align-self: center;
  text-align: center;
`;

const Img7 = styled.img`
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 24px;
  align-self: stretch;
`;
