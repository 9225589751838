import React from "react";
import AuthLayout from "layout/authLayout";
import Input from "components/Input";
import Upload from "components/Upload";
import { handleUploadImage } from "constants/Utils";

function Test({ children }) {

  const handleInputChange = async (e, setFieldValue, values) => {
    const newImages = [...values.project_gallery];

    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files[i];
      if (file.type.startsWith("image/")) {
        // upload image
        const url = await handleUploadImage(file);
        console.log(url, "URL");
        newImages.push({
          image_title: "",
          image: file,
          is_cover_image: false,
          imageUrl: URL.createObjectURL(file),
          image_url: url,
        });
      }
    }

    setFieldValue("project_gallery", newImages);
  };

  return (
    <AuthLayout>
      <form>
        <Input
          placeholder={"Aimes"}
          label="Email"
          icon={
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.61342 8.47806C1.52262 8.3343 1.47723 8.26242 1.45182 8.15155C1.43273 8.06827 1.43273 7.93694 1.45182 7.85366C1.47723 7.74279 1.52262 7.67091 1.61341 7.52715C2.36369 6.33916 4.59693 3.33594 8.00027 3.33594C11.4036 3.33594 13.6369 6.33916 14.3871 7.52715C14.4779 7.67091 14.5233 7.74279 14.5487 7.85366C14.5678 7.93694 14.5678 8.06827 14.5487 8.15155C14.5233 8.26242 14.4779 8.3343 14.3871 8.47806C13.6369 9.66604 11.4036 12.6693 8.00027 12.6693C4.59693 12.6693 2.36369 9.66604 1.61342 8.47806Z"
                stroke="#667085"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.00027 10.0026C9.10484 10.0026 10.0003 9.10717 10.0003 8.0026C10.0003 6.89803 9.10484 6.0026 8.00027 6.0026C6.8957 6.0026 6.00027 6.89803 6.00027 8.0026C6.00027 9.10717 6.8957 10.0026 8.00027 10.0026Z"
                stroke="#667085"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          }
        />
        <Input placeholder={"Aimes"} label="FullName" />

        <Upload handleInputChange={handleInputChange} gallery={[]} />
      </form>
    </AuthLayout>
  );
}

export default Test;
