import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ArrowLeft } from "assets/svg/arrow-left.svg";
import { ReactComponent as Dot } from "assets/svg/dot.svg";
import { ReactComponent as Flag } from "assets/svg/flag.svg";
import CustomButton from "components/Buttons/Button";
import avatar from "assets/img/Avatar-3.png";
import { details as projectDetails } from "services/projectService";
import { details as buyerDetails } from "services/buyerService";
import Loader from "common/Loader";
import { capitalizeFirstLetter, formatMoney } from "constants/Utils";

const JobDetails = (props) => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [project, setProject] = useState(null);
  const [loading, isLoading] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  useEffect(() => {
    console.log(slug, "SLUG");
    async function fetchDetails() {
      try {
        isLoading(true);
        const response = await projectDetails(slug);
        const details = await buyerDetails(response.created_by.id);
        setProject({ ...response, creator: details });
        isLoading(false);
      } catch (err) {
        isLoading(false);
      }
    }
    fetchDetails();
  }, [slug]);
  // const originalEndDate = parse(project?.end_date, 'yyyy-MM-dd', new Date());
  // const formattedEndDate = format(originalEndDate, 'd MMM, yyyy');
  const handleCreateBid = () => {
    navigate(`/workspace/jobs/${slug}/create-bid`);
  };

  const handleBack = () => {
    navigate("/workspace/jobs");
  };

  if (loading) return <Loader />;
  return (
    <div className="tw-relative ">
      <button
        className="tw-flex tw-gap-[4px] tw-px-[16px] tw-py-[10px] tw-mb-[24px]"
        type="button"
        onClick={handleBack}
      >
        <ArrowLeft /> Back{" "}
      </button>
      <div className="tw-bg-job-details tw-w-full tw-h-[285px] tw-bg-no-repeat tw-bg-cover tw-relative"></div>

      {/*  */}
      <div className="tw-flex tw-flex-row tw-px-[32px] tw-relative tw-gap-[24px] -tw-top-6 tw-items-center">
        <img
          src={project?.cover_image_url}
          className="tw-w-[160px] tw-h-[160px]tw-object-cover  "
          alt=""
        />

        <div className="tw-flex tw-justify-between tw-flex-1">
          <div>
            <h2 className="tw-text-sm-semi-bold !tw-text-[30px] tw-mb-[10px]">
              {project?.title}
            </h2>
            <div className="tw-flex tw-gap-[16px] tw-items-center">
              <div className="tw-flex tw-gap-[8px] tw-items-center">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_4625_66902)">
                    <path
                      d="M7.08366 12.2221C7.08366 13.2959 7.95422 14.1665 9.0281 14.1665H10.8337C11.9843 14.1665 12.917 13.2338 12.917 12.0832C12.917 10.9326 11.9843 9.99984 10.8337 9.99984H9.16699C8.0164 9.99984 7.08366 9.0671 7.08366 7.9165C7.08366 6.76591 8.0164 5.83317 9.16699 5.83317H10.9725C12.0464 5.83317 12.917 6.70373 12.917 7.77762M10.0003 4.58317V5.83317M10.0003 14.1665V15.4165M18.3337 9.99984C18.3337 14.6022 14.6027 18.3332 10.0003 18.3332C5.39795 18.3332 1.66699 14.6022 1.66699 9.99984C1.66699 5.39746 5.39795 1.6665 10.0003 1.6665C14.6027 1.6665 18.3337 5.39746 18.3337 9.99984Z"
                      stroke="#98A2B3"
                      stroke-width="1.66667"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_4625_66902">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>

                <h5 className="tw-text-md-medium tw-text-[#475467]">
                  {formatMoney(
                    Number(project?.budget.amount),
                    project?.budget.currency
                  )}
                </h5>
              </div>

              <div>
                <Badge
                  text={project?.negotiable ? "Negotiable" : "Non Negotiable"}
                />
              </div>
            </div>
          </div>

          <div className="tw-flex tw-gap-[12px] tw-justify-end tw-items-center ">
            <div
              onMouseEnter={() => setDropdownVisible(true)}
              onMouseLeave={() => setDropdownVisible(false)}
              className="tw-relative h-fit"
            >
              <button className="tw-border-[#D0D5DD] tw-border tw-p-[10px] tw-flex tw-items-center">
                <Dot />
              </button>
              {dropdownVisible && (
                <div className="tw-absolute tw-right-0 tw-z-50 tw-w-[200px] tw-rounded tw-bg-white tw-shadow-lg">
                  <ul className="">
                    <li className="tw-flex tw-cursor-pointer tw-items-center tw-gap-[12px] tw-px-[16px] tw-py-[12px] hover:tw-bg-yellow-25 tw-text-sm-medium tw-text-[#344054]">
                      <Flag />
                      Report this job
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <div className="tw-border-[#D0D5DD] tw-border tw-p-[10px]  tw-flex tw-items-center">
              <h6 className="tw-text-md-medium">S/Template</h6>
            </div>
            <CustomButton
              cls=" tw-px-[16px] tw-py-[10px]"
              onClick={handleCreateBid}
              disabled={project?.status === "ongoing"}
            >
              Create Bid
            </CustomButton>
          </div>
        </div>
      </div>
      {/*  */}

      {/* Creator details */}
      <div className="tw-flex tw-px-[32px] tw-mb-[32px] ">
        <Link
          to={`/workspace/jobs/${project?.id}/client/${project?.creator?.id}`}
          className="tw-flex tw-bg-[#F9FAFB] tw-gap-[12px] tw-w-full tw-py-[8px] tw-items-center "
        >
          <img
            src={project?.creator?.profile_image_url || avatar}
            alt="profile"
            className="tw-object-cover tw-w-[40px] tw-h-[40px] tw-rounded-full"
          />
          <div>
            <h6 className="tw-text-sm-semi-bold">
              {project?.creator?.first_name} {project?.creator?.last_name}
            </h6>
            <p className="tw-text-sm-regular">{project?.creator?.country}</p>
          </div>
        </Link>
      </div>

      <div className="tw-flex tw-px-[32px]  tw-flex-col tw-mb-[24px]">
        <div className="tw-flex tw-gap-[8px] tw-items-center">
          <h6 className="tw-text-md-medium tw-text-[#101828]">About Job</h6>
          <Badge text={capitalizeFirstLetter(project?.project_type)} />
        </div>
        <p className="tw-text-md-regular tw-mt-[8px]">{project?.about}</p>
      </div>
      <div className="tw-px-[32px] tw-mb-[24px]">
        <ProjectBrief project={project} />
      </div>
      {project?.additional_note && (
        <div className="tw-flex tw-px-[32px]  tw-flex-col tw-mb-[24px]">
          <div className="tw-flex tw-gap-[8px] tw-items-center">
            <h6 className="tw-text-md-medium">Additional Note</h6>
          </div>
          <p className="tw-text-md-regular tw-mt-[8px]">
            {project?.additional_note}
          </p>
        </div>
      )}
      <div className="tw-px-[32px] tw-mb-[24px]">
        <InspirationGallery
          gallery={project?.project_gallery ? project.project_gallery : []}
        />
      </div>
    </div>
  );
};

export default JobDetails;

function Badge({ text }) {
  return (
    <div className="tw-bg-[#F2F4F7] tw-py-[4px] tw-px-[12px]  tw-rounded-[16px] tw-w-fit">
      {text}
    </div>
  );
}

function ProjectBrief({ project }) {
  return (
    <div className="tw-bg-[#F9FAFB] tw-pl-[24px] tw-py-[20px] tw-flex tw-flex-wrap tw-justify-between">
      <ProjectBriefDetails text={`${project?.state}, ${project?.country}`} />
      <ProjectBriefDetails
        title={"Language"}
        text={`${project?.language?.name} ${project?.language_level}`}
      />
      <ProjectBriefDetails
        title={"Project Due date"}
        text={`${project?.end_date}`}
      />
      <ProjectBriefDetails
        title={"Experience Level"}
        text={`${project?.experience_level?.name} `}
      />
      <ProjectBriefDetails
        title={"Specialty"}
        text={`${project?.specialist[0]?.value} `}
      />
    </div>
  );
}

function ProjectBriefDetails({ title = "Delivery Location", text }) {
  return (
    <div className="tw-min-w-[117px]">
      <h3 className="tw-text-sm-medium tw-text-[#667085]">{title}</h3>
      <h3 className="tw-mt-[10px] tw-text-sm-medium tw-text-[#344054]">
        {text}
      </h3>
    </div>
  );
}

function InspirationGallery({ gallery }) {
  return (
    <div className="">
      <div className="tw-mb-[24px]">
        <h5 className="tw-text-lg-semi-bold">Styling Inspiration</h5>
        <p className="tw-text-sm-regular">Project images</p>
      </div>
      <div className="tw-flex  tw-gap-[24px]">
        {gallery.map(({ image_url, image_title }, i) => {
          return (
            <GalleryImage
              image_url={image_url}
              image_title={image_title}
              key={i}
            />
          );
        })}
      </div>
    </div>
  );
}

export function GalleryImage({ image_url, image_title }) {
  return (
    <div className="tw-flex  tw-relative tw-w-full lg:tw-max-w-[520px] tw-h-fit">
      <img
        src={image_url}
        alt=""
        className="tw-block tw-h-auto tw-w-inherit tw-aspect-[100/100] tw-object-cover tw-w-full"
      />
      <div className="tw-bg-project-gallery  tw-absolute tw-z-10 tw-w-full tw-h-full tw-flex tw-items-end tw-justify-center">
        <div className="tw-text-xs-regular !tw-text-[12px] tw-px-[18px] tw-text-white tw-text-center">
          {image_title ? image_title : ""}
        </div>
      </div>
    </div>
  );
}
