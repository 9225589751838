import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import { formatMoney } from "constants/Utils";
import { acceptBid, bidDetails } from "services/bidService";
import RejectProposalModal from "./RejectProposalModal";
import {
  HorizontalBtwTabs,
  Milestone,
  ProjectDetailsCard,
} from "../Jobs/CreateBid";
import { details } from "services/projectService";
import CustomButton from "components/Buttons/Button";
import Loader from "common/Loader";

const ViewProposal = () => {
  const navigate = useNavigate();
  const { slug, projectId } = useParams();
  const [bid, setBid] = useState(null);
  const [project, setProject] = useState(null);
  const [active, setActive] = useState(0);
  const [isPending, setIsPending] = useState(false);
  const [loading, isLoading]= useState(true)

  useEffect(() => {
    async function fetchBidDetails() {
      const response = await bidDetails(slug);

      setBid(response);
    }
    fetchBidDetails();
  }, [slug]);

  useEffect(() => {
    async function fetch() {
      try {

        const response = await details(projectId);
  
        setProject(response);
      } catch(err){

      } finally {
        isLoading(false)
      }
    }
    fetch();
  }, [projectId]);

  const [show, setShow] = useState(false);
  const handleCloseModal = () => setShow(false);
  const handleShowModal = () => setShow(true);

  const handleBack = () => {
    navigate("/workspace/projects");
  };

  const handleAcceptProposal = async () => {
    try {
      setIsPending(true);
      const resp = await acceptBid(bid.id);
      if (resp) {
        navigate("/workspace/projects");
      }
    } catch (err) {
    } finally {
      setIsPending(false);
    }
  };

  const handleRejectProposal = async () => {
    // open modal for reject reason
    handleShowModal();
  };


  if(loading) return <Loader />


  return (
    <Div>
      <Div2>
        <Img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/1cd8ffc39498ef7c69a9b855f361d7779fe5679a716d20634bc2bac2a53464a4?"
        />
        <Div3 style={{ cursor: "pointer" }} onClick={handleBack}>
          Back
        </Div3>
      </Div2>
      <Div4>
        <Div5>
          <Div6>
            <Img2
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/4c8c957e1a62bdaa7158c54d95f7729f5550a2054bf3b1d55abe45004f5c3093?"
            />
            <Div7>{bid?.project.title}</Div7>
          </Div6>
        </Div5>
        <Div8>
          <Div9>
            <Column className="!tw-h-fit">
              <Div10>
                {bid &&
                  bid.milestones.map((milestone, index) => {
                    return (
                      <Milestone
                        key={index}
                        milestone={milestone}
                        project={bid.project}
                        view={true}
                      />
                    );
                  })}
              </Div10>
              <div className="tw-px-[32px] tw-mb-[24px]">
                <div className="tw-flex tw-justify-end">
                  <h6 className="tw-text-lg-bold tw-text-[#344054] tw-flex tw-items-center tw-gap-4">
                    <span className="!tw-text-[#667085]">Budget</span>{" "}
                    {formatMoney(
                      project?.budget?.amount,
                      project?.budget?.currency
                    )}{" "}
                  </h6>
                </div>
              </div>
              <div className="tw-px-[32px]">
                <hr className="tw-border-[#EAECF0] " />
              </div>
              <div className="tw-flex tw-justify-end tw-my-8 tw-gap-[20px] tw-px-[32px]">
                <CustomButton
                  cls="tw-px-4 !tw-bg-transparent !tw-border-red-400 !tw-text-red-500 !tw-border"
                  type={"submit"}
                  disabled={isPending}
                  click={handleRejectProposal}
                >
                  Reject Proposal
                </CustomButton>
                <CustomButton
                  cls="tw-px-4 !tw-bg-green-500 !tw-text-white"
                  isLoading={isPending}
                  type={"submit"}
                  disabled={isPending}
                  click={handleAcceptProposal}
                >
                  Accept Proposal
                </CustomButton>
              </div>
            </Column>
            <Column2>
              <ProjectDetailsCard
                project={project}
                bids={bid && bid.milestones}
                view={true}
                active={active}
                tab={
                  <HorizontalBtwTabs
                    active={active}
                    setActive={setActive}
                    tabs={[
                      { name: "Project Details" },
                      { name: "Escrow Details" },
                    ]}
                  />
                }
              />
            </Column2>
          </Div9>
        </Div8>
      </Div4>
      <Modal
        show={show}
        onHide={handleCloseModal}
        className="rounded-0"
        size="md"
        centered
      >
        <Modal.Body>
          <RejectProposalModal bid={bid} />
        </Modal.Body>
      </Modal>
    </Div>
  );
};

export default ViewProposal;

const Div = styled.div`
  align-items: start;
  background-color: var(--base-white, #fff);
  display: flex;
  flex-direction: column;
  padding: 10px 25px 48px 0;
  @media (max-width: 991px) {
    padding-right: 20px;
  }
`;

const Div2 = styled.div`
  justify-content: center;
  display: flex;
  margin-left: 16px;
  width: 62px;
  max-width: 100%;
  gap: 8px;
  @media (max-width: 991px) {
    margin-left: 10px;
  }
`;

const Img = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 100%;
  overflow: hidden;
  flex: 1;
`;

const Div3 = styled.div`
  color: var(--gray-600, #475467);
  flex-grow: 1;
  white-space: nowrap;
  font: 600 14px/20px Inter, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div4 = styled.div`
  background-color: var(--base-white, #fff);
  align-self: stretch;
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  padding: 32px 32px 50px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`;

const Div5 = styled.div`
  align-items: start;
  border-bottom: 1px solid var(--gray-200, #eaecf0);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px 0;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`;

const Div6 = styled.div`
  display: flex;
  gap: 4px;
`;

const Img2 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 32px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
`;

const Div7 = styled.div`
  color: var(--gray-700, #344054);
  align-self: start;
  flex-grow: 1;
  white-space: nowrap;
  font: 700 20px/30px Inter, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div8 = styled.div`
  margin: 20px 0 17px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div9 = styled.div`
  ${"" /* gap: 20px; */}
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column = styled.div`
  background: #f9fafb;
  height: fit;
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 67%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div10 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 32px 24px 13px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`;

// const Img6 = styled.img`
//   aspect-ratio: 1;
//   object-fit: contain;
//   object-position: center;
//   width: 20px;
//   overflow: hidden;
//   align-self: center;
//   max-width: 100%;
//   margin: auto 0;
// `;

const Column2 = styled.div`
  background: #f9fafb;
  height: fit;
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 33%;
  @media (max-width: 991px) {
    width: 100%;
  }
`;
