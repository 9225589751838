import React from "react";
import manique from "../../assets/img/mainque.png";
import CustomButton from "components/Buttons/Button";

export default function SizingTemplateBuyerCard({ handleClick }) {
  return (
    <div className="tw-relative tw-w-[336px] tw-mt-3">
      <img src={manique} alt="" className="tw-mx-auto tw-my-0" />
      <div className="tw-z-99 tw-h-full tw-w-full tw-absolute  tw-top-0 tw-items-center tw-justify-center tw-flex tw-flex-col tw-px-[16px] tw-backdrop-filter tw-backdrop-blur-sm tw-bg-opacity-5 tw-bg-clip-padding tw-bg-[#F2F4F7]/30 card-blur">
        <div>
          <CustomButton
            click={handleClick}
            cls="tw-w-full tw-px-[16px] tw-py-[10px] "
            rightIcon={
              <div className="tw-bg-white tw-w-[20px] tw-h-[20px] tw-rounded-full tw-flex tw-items-center tw-justify-center">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.00016 3.33594V12.6693M3.3335 8.0026H12.6668"
                    stroke="#EAAA08"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            }
          >
            Add Measurement
          </CustomButton>
        </div>
        <div className="tw-bg-[#FEFDF0] tw-w-[30px] tw-h-[30px] tw-rounded-full tw-flex tw-items-center tw-justify-center tw-absolute tw-right-[12px] tw-bottom-[16px] tw-z-999">
          <h4 className="tw-text-xl-bold tw-text-yellow-500 tw-p-[2px]">X3</h4>
        </div>
      </div>
    </div>
  );
}
