import React from "react";
import { ReactComponent as VerticalDotIcon } from "assets/svg/vertical-dot.svg";

export default function ProjectThumbnail({ project , click}) {
  return (
    <div className="tw-bg-[#F2F4F7] tw-relative tw-max-w-[336px] !tw-min-w-[336px] tw-h-[125px] tw-border-b-4 tw-cursor-pointer tw-flex-1" onClick={()=> click(project)}>
      <div className="tw-px-[20px] tw-py-[14px] tw-absolute tw-top-0 tw-right-0 tw-w-full tw-h-full tw-bg-black/50 tw-z-10">
        <ProjectDropDown />

        <h2 className="tw-text-xl-bold tw-text-gray-300 tw-text-center">
          {project.title}
        </h2>
      </div>
      <img
        src={project.cover_image_url ?? ""}
        alt=""
        className="tw-w-full  tw-h-full tw-object-cover tw-block  tw-object-right-top"
      />
    </div>
  );
}

export function ProjectDropDown() {
  return (
    <div className="tw-w-full tw-flex">
      <div className="tw-w-full tw-flex tw-justify-end">
        <VerticalDotIcon />
      </div>
    </div>
  );
}
