import React, { useState, useEffect, useCallback } from "react";
import ProjectDetailsTab from "./ProjectDetailsTab";
import { Modal } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { createMilestone, listMilestones } from "services/milestoneService";
import TotalPriceErrorModal from "./TotalPriceErrorModal";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as ArrowLeft } from "assets/svg/arrow-left.svg";
import { details } from "services/projectService";
import { ReactComponent as Avatar } from "assets/svg/avatar.svg";
import { ReactComponent as Save } from "assets/svg/save.svg";
import { ReactComponent as Plus } from "assets/svg/plus.svg";
import { ReactComponent as Minus } from "assets/svg/minus.svg";
import { ReactComponent as Delete } from "assets/svg/trash.svg";
import { ReactComponent as Edit } from "assets/svg/edit.svg";
import Input from "components/Input";
import TextArea from "components/Input/TextArea";
import CustomButton from "components/Buttons/Button";
import {
  addMilestone,
  updateMilestone,
  deleteMilestone,
  createMilestoneSuccess,
} from "store/milestone/actions";
import Switch from "components/Toggle";
import { createBid, listBids } from "services/bidService";
import { formatMoney, showErrors } from "constants/Utils";
import { showToast } from "services/toastService";
import Loader from "common/Loader";

const CreateBid = () => {
  const { slug } = useParams();
  const [additionalNotes, setAdditionalNotes] = useState(false);
  const [project, setProject] = useState(null);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [loading, isLoading] = useState(false);
  const { milestones } = useSelector((state) => state.milestone);
  const { active } = useSelector((state) => state.account);
  const [totalMilestonesAmount, setTotalMilestonesAmount] = useState(0);
  const [bids, setBids] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [additional_note, setAdditional_note] = useState("");
  const [activeTab, setActiveTab] = useState(0);

  const handleCloseModal = () => setShow(false);
  const handleShowModal = () => setShow(true);

  const handleBack = () => {
    navigate(-1);
  };
  useEffect(() => {
    if (milestones) {
      const totalAmount = milestones.reduce((a, b) => a + b.amount, 0);
      setTotalMilestonesAmount(totalAmount);
    }
  }, [milestones]);

  const loadMilestonesByProject = async () => {
    try {
      // const resp = await listMilestones(project?.id, active?.id);
    } catch (err) {}
  };

  useEffect(() => {
    const loadBidsByProject = async () => {
      try {
        isLoading(true);
        const resp = await listBids(project?.id, active?.id);
        setBids(resp);
        isLoading(false);
      } catch (err) {
        isLoading(false);
      }
    };
    if (project) {
      loadBidsByProject();
    }
  }, [active?.id, project]);

  const handleSubmit = async () => {
    const projectBudget = project?.budget?.amount;

    if (totalMilestonesAmount < projectBudget) {
      handleShowModal();
    } else {
      setIsSubmitting(true);
      try {
        const formatted = milestones.map((milestone) => ({
          ...milestone,
          currency: project?.budget?.currency,
          start_date: new Date().toISOString().split("T")[0],
          end_date: project.end_date,
          additional_note,
          milestone_gallery: [
            {
              image_url:
                "https://res.cloudinary.com/ellarious/image/upload/v1721152646/rx8dvb3ux2qs91ybbbmx.jpg",
            },
          ],
        }));
        await createBid({
          project: project?.id,
          designer: active.id,
          status: "pending",
          milestones: formatted,
        });
        dispatch(createMilestoneSuccess());
        showToast("success", "Bid Created");
        setIsSubmitting(false);
        navigate("/workspace/jobs");
      } catch (err) {
        setIsSubmitting(false);
        showErrors(err);
      }
    }
  };

  const onSave = async (status) => {
    try {
      setIsSaving(true);
      const formatted = milestones.map((milestone) => ({
        ...milestone,
        currency: project?.budget?.currency,
        start_date: new Date().toISOString().split("T")[0],
        end_date: project.end_date,
        milestone_gallery: [
          {
            image_url:
              "https://res.cloudinary.com/ellarious/image/upload/v1721152646/rx8dvb3ux2qs91ybbbmx.jpg",
          },
        ],
      }));
      await createBid({
        project: project?.id,
        designer: active.id,
        status,
        milestones: formatted,
      });
      setIsSaving(false);
      showToast("success", `Bid ${status}`);
      dispatch(createMilestoneSuccess());

      navigate("/workspace/jobs");
    } catch (err) {
      showErrors(err);
      setIsSaving(false);
    }
  };
  useEffect(() => {
    loadMilestonesByProject();
  }, []);

  useEffect(() => {
    console.log(slug, "slug");
    async function fetchDetails() {
      try {
        isLoading(true);
        const response = await details(slug);
        console.log(response);
        setProject(response);
        isLoading(false);
      } catch (err) {
        isLoading(false);
      }
    }
    fetchDetails();
  }, []);

  const update = useCallback((index, milestone) => {
    dispatch(updateMilestone(index, milestone));
  }, []);

  const handleDelete = useCallback((index) => {
    dispatch(deleteMilestone(index));
  }, []);

  if (loading) return <Loader />;
  return (
    <div className="tw-relative ">
      <button
        className="tw-flex tw-gap-[4px] tw-px-[16px] tw-py-[10px] tw-mb-[24px]"
        type="button"
        onClick={handleBack}
      >
        <ArrowLeft /> Back{" "}
      </button>
      <div className="tw-px-[32px] ">
        <div className="tw-flex tw-items-center tw-gap-[4px] tw-border-b tw-border-[#EAECF0] tw-py-[8px]">
          <div className="tw-bg-[#F2F4F7] tw-p-[10px]">
            <Avatar />
          </div>
          <h2 className="tw-text-xl-bold">{project?.title}</h2>
        </div>
      </div>

      <div className="tw-px-[32px] tw-my-[20px] tw-relative">
        <div className="tw-bg-[#F9FAFB] tw-flex tw-w-full tw-h-fit">
          <div className="tw-px-[24px] tw-py-[32px] tw-flex-1">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              {milestones &&
                project &&
                milestones.map((milestone, index) => {
                  return (
                    <Milestone
                      key={index}
                      milestone={milestone}
                      update={update}
                      index={index}
                      deleteMilestone={handleDelete}
                      project={project && project}
                    />
                  );
                })}
              <div className="tw-my-[14px]">
                <CustomButton
                  click={() => dispatch(addMilestone())}
                  buttonVariant={"transparent"}
                  type={"button"}
                  leftIcon={<Plus />}
                  cls="tw-text-yellow-500 tw-text-sm-semi-bold"
                >
                  Add another milestone
                </CustomButton>
              </div>
              <PriceCard milestones={milestones} project={project} />
              <div className="tw-bg-white tw-border tw-py-[32px] tw-px-[20px]">
                <div className="tw-flex tw-items-center tw-gap-[6px] tw-mb-[24px]">
                  <Switch
                    checked={additionalNotes}
                    onClick={() => setAdditionalNotes(!additionalNotes)}
                  />
                  <span className="tw-text-sm-semi-bold">Add Note</span>
                </div>

                {additionalNotes && (
                  <TextArea
                    row={20}
                    label={"Additional Nodes"}
                    onChange={(e) => setAdditional_note(e.target.value)}
                    id="additional_notes"
                    name="additional_notes"
                    cls={"!tw-w-full"}
                    placeholder={"Additional Notes"}
                  />
                )}
              </div>
              <hr className="tw-border-[#EAECF0] tw-mt-[12px]" />
              <div className="tw-py-[20px] tw-flex tw-justify-end tw-w-full tw-gap-[12px]">
                <CustomButton
                  click={() => onSave("draft")}
                  buttonVariant={"tertiary"}
                  type={"button"}
                  cls="tw-text-sm-semi-bold tw-py-[10px] tw-px-[16px]"
                  isLoading={isSaving}
                  disabled={isSubmitting || isSaving}
                >
                  Save and Exit
                </CustomButton>
                <CustomButton
                  buttonVariant={"primary"}
                  type={"submit"}
                  cls=" tw-text-sm-semi-bold tw-py-[10px] tw-px-[16px]"
                  isLoading={isSubmitting}
                  disabled={isSubmitting || isSaving}
                >
                  Submit
                </CustomButton>
              </div>
            </form>
          </div>
          <div className="tw-flex-[.5]">
            <ProjectDetailsCard
              project={project}
              bids={bids}
              active={activeTab}
              tab={
                <HorizontalBtwTabs
                  active={activeTab}
                  setActive={setActiveTab}
                />
              }
            />
          </div>
        </div>

        <div></div>
      </div>
      <Modal
        show={show}
        onHide={handleCloseModal}
        className="rounded-0"
        centered
        style={{
          boxShadow:
            "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
        }}
      >
        <Modal.Body>
          <TotalPriceErrorModal
            onHide={handleCloseModal}
            onContinue={onSave}
            amount={formatMoney(
              project?.budget?.amount - totalMilestonesAmount,
              project?.budget?.currency
            )}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CreateBid;

export function Milestone({
  milestone,
  update,
  index,
  deleteMilestone,
  project,
  view,
}) {
  console.log(project, milestone);
  const handleValueChange = (e) => {
    update(index, { ...milestone, name: e.target.value });
  };
  return (
    <div className="tw-mb-[16px] tw-bg-white tw-border tw-border-[#EAECF0] tw-py-[32px] tw-px-[20px] tw-w-full tw-h-fit tw-shadow-sm">
      {milestone.is_delivery_milestone ? (
        <div>
          <div>
            <h2 className="tw-text-xs-semi-bold !tw-text-[20px] tw-text-[#98A2B3]">
              Delivery milestone
            </h2>
            <h2 className="tw-text-xl-semi-bold !tw-text-[20px] tw-text-[#98A2B3]">
              Lagos, Nigeria
            </h2>
            <p className="tw-text-[#98A2B3]">
              The complete location information of the client will be made
              available at the commencement of the project.{" "}
            </p>
          </div>
          <div className="tw-flex tw-gap-20 tw-items-center tw-mt-[24px]">
            <label
              className="tw-flex tw-gap-[12px] tw-items-center tw-text-md-medium tw-cursor-pointer"
              htmlFor="delivery_milestone_name-tracked"
            >
              <input
                type="radio"
                name="name"
                id="delivery_milestone_name-tracked"

                value="tracked"
                onChange={handleValueChange}
                checked={milestone.name === "tracked"}
              />
              Tracked
            </label>
            <label
              className="tw-flex tw-gap-[12px] tw-items-center tw-text-md-medium tw-cursor-pointer"
              htmlFor="delivery_milestone_name"
            >
              <input
                type="radio"
                name="name"
                id="delivery_milestone_name"
                value="non tracked"
                onChange={handleValueChange}
                checked={milestone.name === "non tracked"}
              />
              Non Tracked
            </label>
            <label
              className="tw-flex tw-gap-[12px] tw-items-center tw-text-md-medium tw-cursor-pointer"
              htmlFor="delivery_milestone_name-in-person"
            >
              <input
                type="radio"
                name="name"
                id="delivery_milestone_name-in-person"

                value="In person pickup"
                onChange={handleValueChange}
                checked={milestone.name === "In person pickup"}
              />
              In person pickup
            </label>
          </div>

          <Payment
            milestone={milestone}
            update={update}
            index={index}
            project={project}
            view={view}
          />
        </div>
      ) : (
        <div>
          {(milestone.saved || view) && (
            <div>
              <div className="tw-flex tw-justify-between tw-items-center">
                <h3 className="tw-text-xl-semi-bold !tw-text-[20px] !tw-leading-none">
                  {milestone.name}{" "}
                </h3>
                {!view && (
                  <button
                    className="tw-p-[8px] "
                    type="button"
                    onClick={() => deleteMilestone(index)}
                  >
                    <Delete />
                  </button>
                )}
              </div>
              <p className="tw-text-md-medium tw-my-[12px]">
                {milestone.description}
              </p>
            </div>
          )}
          {!milestone.saved && !view && (
            <>
              <Input
                type={"text"}
                id="name"
                name="name"
                value={milestone.name}
                onChange={(e) =>
                  update(index, { ...milestone, name: e.target.value })
                }
                cls="!tw-w-full"
                label={"Milestone Name"}
              />
              <TextArea
                row={20}
                label={"Description"}
                value={milestone.description}
                onChange={(e) =>
                  update(index, { ...milestone, description: e.target.value })
                }
                id="description"
                name="description"
                cls={"!tw-w-full"}
                placeholder={"Enter Description"}
              />
            </>
          )}
          <Payment
            milestone={milestone}
            update={update}
            index={index}
            project={project}
            view={view}
          />

          {!view && (
            <div>
              <CustomButton
                click={() =>
                  update(index, { ...milestone, saved: !milestone.saved })
                }
                buttonVariant={"transparent"}
                type={"button"}
                leftIcon={milestone.saved ? <Edit /> : <Save />}
                cls="tw-text-yellow-500 tw-text-sm-semi-bold"
              >
                {milestone.saved ? "Edit" : " Save"}
              </CustomButton>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

function Payment({ milestone, update, index, project, view }) {
  return (
    <div className="tw-flex tw-justify-between tw-my-[24px]">
      <h3 className="tw-text-xl-semi-bold !tw-text-[20px]">
        {milestone?.is_delivery_milestone ? "Milestone Payment" : "Payment"}
      </h3>

      <div className="tw-flex tw-gap-[24px] tw-items-center">
        {!view && (
          <button
            className="tw-p-[8px] tw-border tw-border-[#D0D5DD]"
            type="button"
            disabled={milestone.saved}
            onClick={() =>
              update(index, {
                ...milestone,
                amount: Number(milestone.amount - 100),
              })
            }
          >
            <Minus />
          </button>
        )}
        <Input
          type="number"
          id="amount"
          name="amount"
          value={milestone.amount}
          readOnly={view}
          containerCls="!tw-mb-0"
          onChange={(e) => {
            update(index, { ...milestone, amount: Number(e.target.value) });
          }}
        />
        {!view && (
          <button
            className="tw-p-[8px] tw-border tw-border-[#D0D5DD]"
            type="button"
            disabled={milestone.saved}
            onClick={() =>
              update(index, {
                ...milestone,
                amount: Number(milestone.amount + 100),
              })
            }
          >
            <Plus />
          </button>
        )}
      </div>
    </div>
  );
}

function PriceCard({ milestones, project }) {
  const total =
    milestones &&
    milestones.reduce((a, b) => {
      return a + b.amount;
    }, 0);
  const fee = 0.1;
  return (
    <>
      <div className="tw-bg-gray-100 ">
        <div className="tw-p-[8px]">
          <div className="tw-flex tw-justify-between tw-items-center tw-mb-[8px]">
            <h3 className="tw-text-[#667085] tw-text-md-semi-bold">
              Total Price{" "}
            </h3>
            <h5 className="tw-text-[#344054] tw-text-md-semi-bold">
              {formatMoney(total, project?.budget.currency)}
            </h5>
          </div>
          <div className="tw-flex tw-justify-between tw-items-center">
            <h3 className="tw-text-[#667085] tw-text-md-semi-bold">
              Commission Fee{" "}
            </h3>
            <h5 className="tw-text-[#344054] tw-text-md-semi-bold">
              {formatMoney(total * fee, project?.budget.currency)}
            </h5>
          </div>
        </div>
        <div className="tw-bg-gray-200 tw-p-[8px] tw-flex tw-justify-between tw-items-center">
          <h3 className="tw-text-[#667085] tw-text-lg-semi-bold">
            You Receive{" "}
          </h3>
          <h5 className="tw-text-[#344054] tw-text-lg-semi-bold">
            {total > 0
              ? formatMoney(total - total * fee, project?.budget.currency)
              : formatMoney(0, project?.budget.currency)}
          </h5>
        </div>
      </div>

      <div className="tw-flex tw-justify-end tw-mt-[36px] tw-p-[8px]">
        <h5 className="tw-text-lg-bold tw-text-[#667085]">
          Total Price:{" "}
          <span className="tw-text-[#344054]">
            {" "}
            {formatMoney(total, project?.budget.currency)}
          </span>
        </h5>
      </div>
    </>
  );
}

export function ProjectDetailsCard({ project, bids, tab, active, view }) {
  return (
    <div className="tw-p-[20px]">
      {tab}
      <div className="tw-mt-[16px]">
        {active === 0 && (
          <div>
            <p className="tw-text-md-regular">{project?.about}</p>

            <div className="tw-mt-[24px]">
              <h4 className="tw-text-sm-medium tw-text-[#667085]">
                Delivery Location
              </h4>
              <p className="tw-text-md-medium tw-text-[#344054]">
                {project?.state}, {project?.country}
              </p>
            </div>
            <div className="tw-mt-[24px]">
              <h4 className="tw-text-sm-medium tw-text-[#667085]">Budget</h4>
              <p className="tw-text-md-medium tw-text-[#344054]">
                {formatMoney(
                  project?.budget?.amount,
                  project?.budget
                    ? project?.budget?.currency
                    : bids[0]?.currency
                )}
              </p>
            </div>
            <div className="tw-mt-[24px]">
              <h4 className="tw-text-sm-medium tw-text-[#667085]">Language</h4>
              <p className="tw-text-md-medium tw-text-[#344054]">
                {project?.language?.name} {project?.language_level}
              </p>
            </div>
            <div className="tw-mt-[24px]">
              <h4 className="tw-text-sm-medium tw-text-[#667085]">
                Total Jobs
              </h4>
              <p>{}</p>
            </div>
            <div className="tw-mt-[24px]">
              <h4 className="tw-text-sm-medium tw-text-[#667085]">
                Project Deadline
              </h4>
              <p className="tw-text-md-medium tw-text-[#344054]">
                {project?.end_date}
              </p>
            </div>
            <div className="tw-mt-[24px]">
              <h4 className="tw-text-sm-medium tw-text-[#667085]">
                Years of Experience
              </h4>
              <p className="tw-text-md-medium tw-text-[#344054]">
                {project?.experience_level?.name}
              </p>
            </div>
            <div className="tw-mt-[24px]">
              <h4 className="tw-text-sm-medium tw-text-[#667085]">
                Clothing Types
              </h4>
              <p>{}</p>
            </div>
            <div className="tw-mt-[24px]">
              <h4 className="tw-text-md-medium tw-text-[#101828]">
                Additional Notes
              </h4>
              <p>{project?.additional_note}</p>
            </div>
            <div className="tw-mt-[24px]">
              <h4 className="tw-text-lg-bold tw-text-[#101828]">
                Style Inspirations
              </h4>
              <div className="tw-flex tw-flex-col tw-gap-[24px]">
                {project?.project_gallery.map((gallery, index) => (
                  <img src={gallery.image_url} alt="" className="" key={index}/>
                ))}
              </div>
            </div>
          </div>
        )}
        {active === 1 && (
          <>
            {view ? (
              <div className="tw-flex tw-flex-col tw-gap-[24px]">
                <div className="tw-border tw-border-[#EAECF0] tw-bg-white tw-py-[16px] tw-px-[20px] ">
                  <div className="tw-flex tw-gap-[10px]">
                    {bids.map((bid, index) => {
                      return (
                        <div
                          className="tw-bg-[#98A2B3] tw-h-[6px] tw-rounded-[3px] tw-flex-1"
                          key={index}
                        ></div>
                      );
                    })}
                  </div>
                  <h4 className="tw-text-xs-semi-bold tw-my-[8px]">
                    Total Payment
                  </h4>
                  <span className="tw-text-xs-semi-bold">
                    {formatMoney(
                      bids.reduce((acc, bid) => {
                        return acc + Number(bid.amount);
                      }, 0),
                      bids[0]?.currency
                    )}
                  </span>
                </div>
              </div>
            ) : (
              <div className="tw-flex tw-flex-col tw-gap-[24px]">
                {bids.map((bid, index) => {
                  return (
                    <div
                      key={index}
                      className="tw-border tw-border-[#EAECF0] tw-bg-white tw-py-[16px] tw-px-[20px] "
                    >
                      <div className="tw-flex tw-items-center tw-gap-[8px] tw-mb-[12px]">
                        {bid.milestones &&
                          bid.milestones.map((milestone, index) => (
                            <div
                              className="tw-bg-[#98A2B3] tw-h-[6px] tw-rounded-[3px] tw-flex-1"
                              key={index}
                            ></div>
                          ))}
                      </div>
                      <h4 className="tw-text-xs-semi-bold tw-mb-[8px]">
                        Total Payment
                      </h4>
                      <span className="tw-text-xs-semi-bold">
                        {bid.milestones &&
                          formatMoney(
                            bid.milestones.reduce(
                              (a, b) => a + Number(b.amount),
                              0
                            ),
                            project?.budget?.currency
                          )}
                      </span>
                    </div>
                  );
                })}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export function HorizontalBtwTabs({
  tabs = [{ name: "Project Details" }, { name: "Bid History" }],
  active = 0,
  setActive,
}) {
  return (
    <div className="tw-flex tw-justify-between tw-border-b tw-border-gray-100">
      {tabs.map((tab, index) => {
        return (
          <button
            type="button"
            onClick={() => setActive(index)}
            className={`tw-text-md-semi-bold tw-pb-[12px] tw-px-[4px] tw-outline-none ${
              active === index &&
              "tw-border-b tw-border-yellow-500 tw-text-yellow-500"
            }`}
            key={index}
          >
            {tab.name}
          </button>
        );
      })}
    </div>
  );
}
