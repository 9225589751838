import React from "react";
import { ReactComponent as User } from "assets/svg/user-icon.svg";

export default function BuyerBidCard({ bid, onClick }) {
  return (
    <div
      className={`tw-pt-[24px] tw-border-b tw-border-[#EAECF0] tw-mb-[8px] tw-cursor-pointer ${bid?.status === 'accepted' && 'tw-opacity-40'}`}
      onClick={()=>onClick(bid)}
    >
      <div className="tw-flex tw-gap-[8px] tw-items-center">
        <div className="tw-bg-[#F2F4F7] tw-w-[53px] tw-h-[53px] tw-rounded-full tw-border-[2px] tw-border-white tw-flex tw-items-center tw-justify-center tw-drop-shadow-md">
          <User />
        </div>
        <div className="">
          <h4 className="tw-h-[24px] tw-py-[2px] tw-text-md-medium tw-text-[#344054]">
          {bid.designer.first_name}      {bid.designer.last_name}    </h4>

          <div className="tw-flex tw-gap-[8px] tw-items-center">
            <h4 className="tw-text-[#344054] tw-text-sm-regular">{bid.designer.country}</h4>
            <div className="tw-w-[4px] tw-h-[4px] tw-bg-[#3F3F46] tw-rounded-full"></div>
            <h6 className="!tw-leading-none  tw-text-[#344054] tw-text-sm-medium">
              <span className="!tw-text-gray-500 tw-text-sm-regular">Sent</span>{" "}
              {bid.created_at}
            </h6>
          </div>
        </div>
      </div>
      <p className="tw-text-md-regular tw-text-[#475467] tw-my-[8px]">
      {bid.additional_note}
      </p>
      <div className="tw-flex tw-bg-[#F9FAFB] tw-py-[10px] tw-px-[4px] tw-justify-between">
        <div>
          <h6 className="tw-text-[#344054] tw-text-md-medium tw-flex tw-gap-2 tw-items-center">
            {bid?.project?.title}{" "}
            <span className="tw-hidden sm:tw-block tw-text-gray-500">
              Project
            </span>
          </h6>{" "}
        </div>
        <div>
          <h6 className="tw-text-[#344054] tw-text-md-medium tw-flex tw-gap-2 tw-items-center">
            {bid?.milestones.length}{" "}
            <span className="tw-hidden sm:tw-block  tw-text-gray-500">
              Milestones
            </span>
          </h6>{" "}
        </div>
        <div>
          <h6 className="tw-text-[#344054] tw-text-md-medium tw-flex tw-gap-2 tw-items-center">
            100%{" "}
            <span className="tw-hidden sm:tw-block  tw-text-gray-500">
              Budget
            </span>
          </h6>{" "}
        </div>{" "}
        <div>
          <h6 className="tw-text-[#344054] tw-text-md-medium tw-flex tw-gap-2 tw-items-center">
            {bid.milestones[bid.milestones.length - 1]?.name}{" "}
            <span className="tw-hidden sm:tw-block  tw-text-gray-500">
              Delivery Method
            </span>
          </h6>{" "}
        </div>
      </div>
    </div>
  );
}
