import React, { useState, useEffect } from "react";
import { InputGroup, FormControl, Form } from "react-bootstrap";
import { listLanguages } from "services/languageService";
import { listExperienceLevels } from "services/experienceLevelService";
import { listKeywords } from "services/keywordService";
import { Lock, LockOpen } from "@mui/icons-material";
import * as Yup from "yup";
import { Formik, Form as FormikForm } from "formik";
import Loader from "common/Loader";
import Banner from "components/Banner";
import { getCurrencySymbol, formatMoney } from "constants/Utils";
import { useNavigate } from "react-router-dom";

const validationSchema = Yup.object().shape({
  specialist: Yup.string().required(),
  experience_level: Yup.string().required("Experience Level is required"),
  // language: Yup.string().required("Language is required"),
  language_level: Yup.string().required("Language Level is required"),
  negotiable: Yup.boolean(),
});

export const language_levels = [
  { id: 1, name: "basic" },
  { id: 2, name: "intermediate" },
  { id: 3, name: "fluent" },
  { id: 4, name: "native" },
];

const BudgetSteper = ({ data, next, prev, onSave, loading: submitting, isLoading: isSubmitting }) => {
  const [languages, setLanguages] = useState([]);
  const [experience_levels, setExperienceLevels] = useState([]);
  const [specialists, setSpecialists] = useState([]);
  const [loading, isLoading] = useState(false)

  const handleLevelClick = (setFieldValue, levelName) => {
    setFieldValue("experience_level", levelName);
  };

  const getKeywords = async () => {
    try {
      isLoading(true);
      const resp = await listKeywords();
      isLoading(false);
      setSpecialists(resp);
    } catch (err) {
      isLoading(false);
      console.error(err);
    }
  };

  const handleLanguageLevel = (setFieldValue, value) => {
    setFieldValue("language_level", value);
  };

  const getExperienceLevels = async () => {
    try {
      isLoading(true);
      const resp = await listExperienceLevels();
      isLoading(false);

      setExperienceLevels(resp);
    } catch (err) {
      isLoading(false);

      console.error(err);
    }
  };

  const getLanguages = async () => {
    try {
      isLoading(true);

      const resp = await listLanguages();
      isLoading(false);

      setLanguages(
        resp.sort((a, b) => {
          var textA = a.name?.toUpperCase();
          var textB = b.name?.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        })
      );
    } catch (err) {
      console.log(err);
      isLoading(false);
    }
  };

  const handleNegotiable = (setFieldValue, values) => {
    setFieldValue("negotiable", !values.negotiable);
  };

  useEffect(() => {
    Promise.allSettled([getExperienceLevels(), getLanguages(), getKeywords()]);
  }, []);

  const handleSubmit = (values) => {
    next(values);
  };

  if (loading) return <Loader />;
  return (
    <Formik
      initialValues={data}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({
        values,
        errors,
        touched,
        dirty,
        isValid,
        handleChange,
        setFieldValue,
      }) => (
        <FormikForm>
          <div className="lg:tw-px-[32px] lg:tw-pt-[32px]">
            {/* SECTION  ONE */}
            <div className="tw-flex tw-items-center tw-gap-[32px] tw-mb-[20px]">
              <div>
                <h4 className="tw-text-sm-medium tw-text-gray-700 tw-mb-1">
                  Specialist
                </h4>
                <div className="lg:tw-w-[280px] tw-text-sm-regular tw-text-gray-600">
                  Add specialist tag to help with your search for a designer
                </div>
              </div>
              <div className="tw-w-full lg:tw-w-[512px]">
                <select
                  name="specialist"
                  onChange={handleChange}
                  value={values.specialist}
                  className="form-select form-select-lg rounded-0 !tw-text-gray-600"
                >
                  <option>Select Specialist</option>
                  {specialists.length &&
                    specialists.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.value}
                      </option>
                    ))}
                </select>

                {errors.specialist && touched.specialist && (
                  <Form.Text className="text-danger">
                    {errors.specialist}
                  </Form.Text>
                )}
              </div>
            </div>
            {/*  */}
            {/* SECTION TWO */}
            <div className="tw-flex tw-mb-[20px] tw-gap-[32px]">
              <div>
                <h4 className="tw-text-sm-medium tw-text-gray-700 tw-mb-1">
                  Experience level
                </h4>
                <div className="lg:tw-w-[280px] tw-text-sm-regular tw-text-gray-600">
                  Select level of Experience for your designer
                </div>
              </div>
              <div className="tw-w-full lg:tw-w-[512px]">
                <div className="tw-mt-[16px] tw-bg-gray-50 tw-p-[4px] tw-border tw-border-[#F2F4F7]">
                  <div className="tw-flex tw-gap-[8px]">
                    {experience_levels.map((level) => (
                      <div
                        key={level.id}
                        className={`text-center rounded-0 tw-flex-1 tw-py-[8px] ${
                          values.experience_level === level.id
                            ? " tw-border tw-border-yellow-500 tw-bg-white "
                            : ""
                        }`}
                        onClick={() =>
                          handleLevelClick(setFieldValue, level.id)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <h6
                          className={`tw-capitalize tw-text-sm-semi-bold tw-text-gray-500 ${
                            values.experience_level === level.id &&
                            "!tw-text-yellow-600"
                          }`}
                        >
                          {level.name}
                        </h6>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
            {/* SECTION THREE*/}
            <div className="tw-flex tw-mb-[20px] tw-gap-[32px]">
              <div>
                <h4 className="tw-text-sm-medium tw-text-gray-700 tw-mb-1">
                  Language details
                </h4>
                <div className="lg:tw-w-[280px] tw-text-sm-regular tw-text-gray-600">
                  Select your preferred language and level of proficiency.
                </div>
              </div>

              <div className="tw-w-full lg:tw-w-[512px]">
                <select
                  name="language"
                  onChange={handleChange}
                  value={values.language}
                  className="form-select form-select-lg rounded-0 !tw-text-gray-600"
                >
                  <option>Select language</option>
                  {languages.length &&
                    languages.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                </select>
                <div className="tw-mt-[16px] tw-bg-gray-50 tw-p-[4px] tw-border tw-border-[#F2F4F7]">
                  <div className="tw-flex tw-gap-[8px]">
                    {language_levels.map((level) => (
                      <div
                        key={level.id}
                        className={`text-center rounded-0 tw-flex-1 tw-py-[8px] ${
                          values.language_level === level.name
                            ? " tw-border tw-border-yellow-500 tw-bg-white "
                            : ""
                        }`}
                        onClick={() =>
                          handleLanguageLevel(setFieldValue, level.name)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <h6
                          className={`tw-capitalize tw-text-sm-semi-bold tw-text-gray-500 ${
                            values.language_level === level.name &&
                            "!tw-text-yellow-600"
                          }`}
                        >
                          {level.name}
                        </h6>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
            {/* SECTION FOUR */}
            <div className="tw-mb-[20px] tw-gap-[32px] tw-flex">
              <div>
                <h4 className="tw-text-sm-medium tw-text-gray-700 tw-mb-1">
                  Budget
                </h4>
                <div className="lg:tw-w-[280px] tw-text-sm-regular tw-text-gray-600">
                  Control your budget
                </div>
              </div>
              <div className="tw-w-full lg:tw-w-[512px]">
                <label className="tw-text-sm-medium tw-text-[#344054]">
                  {values.title}
                </label>
                <InputGroup size="lg" className="rounded-0">
                  <FormControl
                    placeholder="Amount"
                    aria-label="Amount"
                    name="budget.amount"
                    value={values?.budget?.amount}
                    onChange={handleChange}
                    className="rounded-0 "
                    // className="rounded-0 !tw-border-r-0"
                    disabled={!values.negotiable}
                  />

                  <select
                    onChange={handleChange}
                    value={values.budget?.currency}
                    name="budget.currency"
                    className="tw-outline-none tw-border tw-border-[#D0D5DD] !tw-border-l-none tw-px-[14px] focus:tw-border-yellow-500"
                  >
                    <option>EUR</option>
                    <option>NGN</option>
                  </select>
                  <button
                    onClick={() => handleNegotiable(setFieldValue, values)}
                    className="!tw-ml-[9px]"
                    type="button"
                  >
                    {values.negotiable ? (
                      <LockOpen className="tw-text-yellow-500" />
                    ) : (
                      <Lock className="tw-text-yellow-500" />
                    )}
                  </button>
                </InputGroup>
                <div className="mt-2">
                  {values.budget?.amount > 0 && values.negotiable && (
                    <Banner
                      head={`${formatMoney(
                        Number(values.budget?.amount),
                        values?.budget?.currency
                      )} Budget unlocked.`}
                      text="Budget can be negotiated"
                      type="success"
                    />
                  )}
                  {values.budget?.amount > 0 && !values.negotiable && (
                    <Banner
                      head={`${formatMoney(
                        Number(values.budget?.amount),
                        values?.budget?.currency
                      )} Budget locked.`}
                      text="Budget cannot be negotiated"
                      type="error"
                    />
                  )}
                </div>
              </div>
            </div>
            {/* Back & Next Button */}
            <div className="tw-px-1 tw-w-full">
              <hr className="tw-mt-10 tw-text-[#EAECF0]" />
              <div className="tw-flex tw-justify-between tw-my-[20px]">
                <button
                  className="tw-text-sm-semi-bold"
                  onClick={() => prev(values)}
                  type="button"
                >
                  Back
                </button>
                {console.log(submitting, 'IS SAVING')}
                <div className="tw-flex tw-gap-[12px]">
                  <button
                  type="button"
                    className="tw-text-sm-semi-bold tw-border tw-border-[#EAECF0] tw-py-[10px] tw-px-[16px] tw-text-[#D0D5DD] tw-hidden lg:tw-block"
                    onClick={() =>
                      onSave(data)
                    }
                  >
                    {submitting ? "Saving" : " Save & exit"}
                  </button>
                  <button
                    className="tw-text-sm-semi-bold tw-py-[10px] tw-px-[16px] tw-bg-yellow-500 tw-text-white"
                    type="submit"
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        </FormikForm>
      )}
    </Formik>
  );
};

export default BudgetSteper;
