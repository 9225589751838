const Radio = ({
  checked,
  disabled,
  id,
  label,
  name,
  onChange,
  value,
}) => (
  <div className="tw-flex tw-gap-2 tw-items-start">
    <div className="tw-grid tw-place-items-center tw-mt-1">
      <input
        type="radio"
        id={id}
        name={name}
       checked={checked}
        disabled={disabled}
        className="
            tw-peer
            tw-col-start-1 tw-row-start-1
            tw-appearance-none tw-shrink-0
            tw-w-4 tw-h-4 tw-border-2 tw-border-yellow-500 tw-rounded-full
            focus:tw-outline-none focus:tw-ring-offset-0 focus:tw-ring-2 focus:tw-ring-yellow-400
            disabled:tw-border-gray-400
          "
        onChange={onChange}
        value={value}
      />
      <div
        className={
          "tw-pointer-events-none tw-col-start-1 tw-row-start-1 tw-w-2 tw-h-2 tw-rounded-full peer-checked:tw-bg-yellow-500 peer-checked:peer-disabled:tw-bg-gray-400"
        }
      />
    </div>
    <label
      htmlFor={id}
      className={`tw-text-start hover:tw-cursor-pointer
          ${disabled && "tw-text-gray-400"}
          `}
    >
      {label}
    </label>
  </div>
);

export default Radio;
