import React from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";

const LowerBody = ({ props, handleChange, values, selectedUnit, tabIndex }) => {
  const dynamicStyles = {
    maxHeight: tabIndex === 2 ? 'none' : '400px',
    overflowY: tabIndex === 2 ? 'visible' : 'auto',
  };
  return (
    <Div24 style={dynamicStyles}>
      <Div25>
        <Div26>
          <Div27>Hips</Div27>
          <Div28>
            <div className="d-flex justify-content-end align-items-end">
              <Form.Control
                style={{ textAlign: "right" }}
                type="number"
                onChange={handleChange}
                value={values.hips}
                name="hips"
                className="rounded-0 w-75"
                min="1"
                max="300"
              />
              <span className="pb-2" style={{ color: "rgba(102,112,133,1)" }}>
                {" "}
                {selectedUnit}
              </span>
            </div>
          </Div28>
        </Div26>
      </Div25>
      <Div25>
        <Div26>
          <Div27>Body rise</Div27>
          <Div28>
            <div className="d-flex justify-content-end align-items-end">
              <Form.Control
                style={{ textAlign: "right" }}
                type="number"
                onChange={handleChange}
                value={values.body_rise}
                name="body_rise"
                className="rounded-0 w-75"
                min="1"
                max="300"
              />
              <span className="pb-2" style={{ color: "rgba(102,112,133,1)" }}>
                {" "}
                {selectedUnit}
              </span>
            </div>
          </Div28>
        </Div26>
      </Div25>
      <Div25>
        <Div26>
          <Div27>Neck to waistline</Div27>
          <Div28>
            <div className="d-flex justify-content-end align-items-end">
              <Form.Control
                style={{ textAlign: "right" }}
                type="number"
                onChange={handleChange}
                value={values.neck_to_waistline}
                name="neck_to_waistline"
                className="rounded-0 w-75"
                min="1"
                max="300"
              />
              <span className="pb-2" style={{ color: "rgba(102,112,133,1)" }}>
                {" "}
                {selectedUnit}
              </span>
            </div>
          </Div28>
        </Div26>
      </Div25>
      <Div25>
        <Div26>
          <Div27>Waist</Div27>
          <Div28>
            <div className="d-flex justify-content-end align-items-end">
              <Form.Control
                style={{ textAlign: "right" }}
                type="number"
                onChange={handleChange}
                value={values.waist}
                name="waist"
                className="rounded-0 w-75"
                min="1"
                max="300"
              />
              <span className="pb-2" style={{ color: "rgba(102,112,133,1)" }}>
                {" "}
                {selectedUnit}
              </span>
            </div>
          </Div28>
        </Div26>
      </Div25>
      <Div25>
        <Div26>
          <Div27>Waist to Knee</Div27>
          <Div28>
            <div className="d-flex justify-content-end align-items-end">
              <Form.Control
                style={{ textAlign: "right" }}
                type="number"
                onChange={handleChange}
                value={values.waist_to_knee}
                name="waist_to_knee"
                className="rounded-0 w-75"
                min="1"
                max="300"
              />
              <span className="pb-2" style={{ color: "rgba(102,112,133,1)" }}>
                {" "}
                {selectedUnit}
              </span>
            </div>
          </Div28>
        </Div26>
      </Div25>
      <Div25>
        <Div26>
          <Div27>Knee</Div27>
          <Div28>
            <div className="d-flex justify-content-end align-items-end">
              <Form.Control
                style={{ textAlign: "right" }}
                type="number"
                onChange={handleChange}
                value={values.knee}
                name="knee"
                className="rounded-0 w-75"
                min="1"
                max="300"
              />
              <span className="pb-2" style={{ color: "rgba(102,112,133,1)" }}>
                {" "}
                {selectedUnit}
              </span>
            </div>
          </Div28>
        </Div26>
      </Div25>
    </Div24>
  );
};

export default LowerBody;

const Div24 = styled.div`
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  margin-top: 24px;
  flex-grow: 1;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div25 = styled.div`
  align-items: flex-start;
  border-bottom: 1px solid var(--gray-100, #f2f4f7);
  align-self: stretch;
  display: flex;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div26 = styled.div`
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--color-neutrals-00, #fff);
  display: flex;
  justify-content: space-between;
  gap: 16px;
  padding: 10px 20px 10px 14px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Div27 = styled.div`
  color: var(--gray-500, #667085);
  align-self: stretch;
  max-width: 429px;
  flex-grow: 1;
  flex-basis: auto;
  font: 400 16px/150% Inter, sans-serif;
`;

const Div28 = styled.div`
  align-items: flex-start;
  border-radius: 16px;
  mix-blend-mode: multiply;
  align-self: center;
  display: flex;
  gap: 4px;
  margin: auto 0;
`;
