import React from "react";
import { ReactComponent as Error } from "assets/svg/help-circle.svg";
import { ReactComponent as Close } from "assets/svg/close.svg";
import CustomButton from "components/Buttons/Button";

const TotalPriceErrorModal = ({ onHide, onContinue, amount }) => {

  const handleContinue = async () => {
    await onContinue("pending");
  };
  return (
    <div>
      <div className="tw-flex tw-justify-between tw-items-start tw-mt-[14px]">
        <div className="tw-flex tw-gap-[12px]">
          <div className="tw-bg-[#FEE4E2] tw-border-[8px] tw-border-[#FEF3F2] tw-rounded-full tw-w-[48px] tw-h-[48px] tw-flex tw-items-center tw-justify-center">
            <Error />
          </div>
          <div>
            <h6 className="tw-text-lg-semi-bold">Total Price Error</h6>
            <p className="tw-text-sm-regular">
              Your total price is {amount} below the project budget
            </p>
          </div>
        </div>
        <button type="button" onClick={() => onHide()}>
          <Close />
        </button>
      </div>
      <div className="tw-ml-[64px] tw-mt-[44px] tw-mb-[14px] tw-flex tw-items-center tw-justify-between">
        <p className="tw-text-sm-medium">Would you like to continue?</p>
        <div className="tw-flex tw-gap-[8px]">
          <CustomButton
            click={() => onHide()}
            buttonVariant={"tertiary"}
            type={"button"}
            cls="tw-text-sm-semi-bold tw-py-[10px] tw-px-[16px] tw-rounded-[8px]"
          >
            No
          </CustomButton>
          <CustomButton
            click={() => handleContinue()}
            buttonVariant={"primary"}
            type={"submit"}
            cls=" tw-text-sm-semi-bold tw-py-[10px] tw-px-[16px] tw-rounded-[8px]"
          >
            Continue
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

export default TotalPriceErrorModal;
