import React from "react";

export default function Search({ placeholder = "Type to search..." , onChange}) {
  return (
    <form action="https://formbold.com/s/unique_form_id" method="POST">
      <div className="tw-relative tw-border-[#EAECF0] tw-px-[14px] tw-py-[10px] tw-border focus-within:tw-border-yellow-500">
        <button className="tw-absolute  tw-top-1/2 -tw-translate-y-1/2">
          <svg
            width="15"
            height="15"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.5 16.5L13.5834 13.5833M15.6667 8.58333C15.6667 12.4954 12.4954 15.6667 8.58333 15.6667C4.67132 15.6667 1.5 12.4954 1.5 8.58333C1.5 4.67132 4.67132 1.5 8.58333 1.5C12.4954 1.5 15.6667 4.67132 15.6667 8.58333Z"
              stroke="#667085"
              stroke-width="1.66667"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>

        <input
          type="text"
          placeholder={placeholder}
          onChange={onChange}
          className="tw-w-full tw-bg-transparent tw-pl-9 tw-pr-4 tw-text-black focus:tw-outline-none xl:tw-w-125"
        />
      </div>
    </form>
  );
}
