import React, { useState, useEffect } from "react";
import styled from "styled-components";
import MyActiveJobs from "./MyActiveJobs";
import MyPastJobs from "./MyPastJobs";
import { useSelector } from "react-redux";
import { listBids } from "services/bidService";
import Search from "components/Input/Search";
import TaskCard from "components/Bids/TaskCard";
import { details } from "services/projectService";
import Loader from "common/Loader";
import { ReactComponent as Grid } from "assets/svg/grid.svg";
import { ReactComponent as List } from "assets/svg/list.svg";
import Table from "components/Table/Table";
import { formatDueDate } from "constants/Utils";

export const tableTwo = [
  {
    title: "Project Name",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Project Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Project Deadline",
    dataIndex: "end_date",
    key: "end_date",
  },
  {
    title: "Users",
    dataIndex: "users",
    key: "users",
  },
  {
    title: "Milestones",
    dataIndex: "milestones",
    key: "milestones",
  },
];

// eslint-disable-next-line react/prop-types
const DashTab = ({ projects }) => {
  const { active } = useSelector((state) => state.account);
  const [bids, setBids] = useState([]);
  const [grid, setGrid] = useState(true);
  const [filter, setFilter] = useState("");

  let statusStyles = {
    pending:
      "tw-text-[12px] tw-text-[#98A2B3] tw-border tw-border-[#98A2B3] tw-rounded-[16px] tw-px-[8px] tw-w-fit tw-font-bold",
    accepted:
      "tw-text-[12px] tw-text-[#F97066] tw-border tw-border-[#F97066] tw-rounded-[16px] tw-px-[8px] tw-w-fit  tw-font-bold",
    rejected:
      "tw-text-[12px] tw-text-[#98A2B3] tw-border tw-border-[#98A2B3] tw-rounded-[16px] tw-px-[8px] tw-w-fit  tw-font-bold",
    draft:
      "tw-text-[12px] tw-text-[#98A2B3] tw-border tw-border-[#98A2B3] tw-rounded-[16px] tw-px-[8px] tw-w-fit  tw-font-bold",
  };

  useEffect(() => {
    const loadBids = async () => {
      try {
        const resp = await listBids("", active?.id);
        const promise = resp.map(async (bid) => {
          const projectDetails = await details(bid.project.id);
          return { ...bid, project_detail: projectDetails };
        });
        const data = await Promise.all(promise);
        console.log(data, "data");
        setBids(data);
      } catch (err) {
        console.error(err);
      } finally {
      }
    };
    loadBids();
  }, [active?.id]);

  return (
    <>
      <div className="tw-w-full">
        <div className="tw-flex tw-justify-between tw-flex-wrap tw-mt-[16px] tw-mb-[24px] tw-pr-[32px]">
          <div>
            <Search
              placeholder="Search Project"
              onChange={(e) => setFilter(e.target.value)}
            />
          </div>
          <div className="tw-flex tw-gap-[8px]">
            {/* <Div11>
              <img
                loading="lazy"
                alt="umojalin"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/648f8550effedd9bbae9061c773aebce60e03fc2e144775a10dd4ac9ed1e2eb5?"
              />
              <Div12>Sort by</Div12>
              <img
                loading="lazy"
                alt="umojalin"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/7df104d05bec971f325068869378a51d53aa518ba47cba1788f00c136bddd830?"
              />
            </Div11> */}
            <button
              onClick={() => setGrid(true)}
              type="button"
              className={`tw-border-[#FEFBE8] tw-border tw-p-[10px] ${
                grid && "tw-bg-[#FEF7C3]"
              }`}
            >
              <Grid />
            </button>

            <button
              onClick={() => setGrid(false)}
              type="button"
              className={`tw-border-[#FEFBE8] tw-border tw-p-[10px] ${
                !grid && "tw-bg-[#FEF7C3]"
              }`}
            >
              <List />
            </button>
          </div>
        </div>

        {!grid && (
          <div className="tw-my-[24px] tw-w-full">
            <div className="">
              <Table
                columns={tableTwo}
                dataSource={bids
                  .filter((bid) =>
                    bid.project.title
                      .toLowerCase()
                      .includes(filter.toLowerCase())
                  )
                  .map((bid, index) => {
                    return {
                      title: (
                        <div>
                          {console.log(bid.project, "project")}
                          <h5 className="tw-text-[#344054] tw-text-[14px] tw-font-semibold">
                            {bid.project.title}
                          </h5>
                          {/* <p>{bid.project}</p> */}
                        </div>
                      ),
                      status: (
                        <div
                          className={`tw-uppercase ${statusStyles[bid.status]}`}
                        >
                          {bid.status === "rejected"
                            ? "Closed Bids"
                            : bid.status === "pending"
                            ? "My Bids"
                            : bid.status === "accepted"
                            ? "My Active Jobs"
                            : bid.status === "draft"
                            ? "Draft"
                            : "My Past Jobs"}
                        </div>
                      ),
                      type: bid.project.project_type,
                      end_date: formatDueDate(bid?.project_detail?.end_date),
                      milestones: (
                        <div className="tw-w-fit tw-text-[344054] tw-font-bold tw-text-[12px] tw-bg-gray-100 tw-rounded-[16px] tw-py-[2px] tw-px-[8px]">
                          {" "}
                          {bid.milestones.filter((m) => m.is_approved).length}/
                          {bid.milestones.length}{" "}
                        </div>
                      ),
                    };
                  })}
                projects={projects}
              />
            </div>
          </div>
        )}
        {grid && (
          <div className="tw-my-[24px] tw-w-full tw-overflow-x-scroll">
            <div className="tw-flex tw-w-fit  tw-gap-[16px] ">
              <div className="tw-px-[16px] tw-py-[8px] tw-bg-[#F2F4F7] tw-w-[342px]   ">
                <div className="tw-mb-[24px] tw-flex tw-items-center tw-justify-center tw-gap-[8px]">
                  <div className="tw-bg-[#98A2B3] tw-w-[20px] tw-h-[20px] tw-flex tw-items-center tw-justify-center tw-text-white tw-text-xs-medium">
                    {bids.length}
                  </div>
                  <h6 className="tw-uppercase tw-text-sm-semi-bold tw-text-[#98A2B3]">
                    MY BIDS
                  </h6>
                </div>
                <div className="tw-h-screen tw-overflow-y-auto no-scrollbar">
                  {bids.filter((bid) =>
                      bid.project.title.toLowerCase()
                        .includes(filter.toLowerCase())
                    )
                    .filter((bid) => bid.status !== "draft")
                    .map((bid, index) => {
                      return <TaskCard key={index} bid={bid} user={active} />;
                    })}
                  {bids.filter((bid) => bid.status === "draft").length > 0 && (
                    <div className="tw-flex tw-gap-x-[8px] tw-items-center tw-my-[24px]">
                      <div className="tw-bg-[#D0D5DD] tw-h-[1px] tw-flex-1" />
                      <h6 className="tw-text-[12px] tw-italic tw-text-[#667085]">
                        Bids in draft
                      </h6>
                      <div className="tw-bg-[#D0D5DD] tw-h-[1px] tw-flex-1" />
                    </div>
                  )}
                  {bids
                    .filter((bid) =>
                      bid.project.title
                        .toLowerCase()
                        .includes(filter.toLowerCase())
                    )
                    .filter((bid) => bid.status === "draft")
                    .map((bid, index) => {
                      return <TaskCard key={index} bid={bid} user={active} />;
                    })}
                </div>
              </div>

              <div className="tw-px-[16px] tw-py-[8px] tw-bg-[#F2F4F7]  tw-w-[342px]  ">
                <div className="tw-mb-[24px] tw-flex tw-items-center tw-justify-center tw-gap-[8px]">
                  <div className="tw-bg-[#F97066] tw-w-[20px] tw-h-[20px] tw-flex tw-items-center tw-justify-center tw-text-white tw-text-xs-medium">
                    {bids.filter((bid) => bid.status === "accepted").length}
                  </div>
                  <h6 className="tw-uppercase tw-text-sm-semi-bold tw-text-[#F97066]">
                    MY ACTIVE JOBS
                  </h6>
                </div>
                <div className="tw-h-screen tw-overflow-y-auto no-scrollbar">
                  {bids
                    .filter((bid) =>
                      bid.project.title
                        .toLowerCase()
                        .includes(filter.toLowerCase())
                    )
                    .filter((bid) => bid.status === "accepted")
                    .map((bid, index) => {
                      return <TaskCard key={index} bid={bid} user={active} />;
                    })}
                </div>
              </div>

              <div className="tw-px-[16px] tw-py-[8px] tw-bg-[#F2F4F7]  tw-w-[342px] ">
                <div className="tw-mb-[24px] tw-flex tw-items-center tw-justify-center tw-gap-[8px]">
                  <div className="tw-bg-[#32D583] tw-w-[20px] tw-h-[20px] tw-flex tw-items-center tw-justify-center tw-text-white tw-text-xs-medium">
                    0
                  </div>
                  <h6 className="tw-uppercase tw-text-sm-semi-bold tw-text-[#32D583]">
                    MY PAST JOBS
                  </h6>
                </div>
                <div className="tw-h-screen tw-overflow-y-auto no-scrollbar">
                  {/* {bids.map((bid, index) => {
                    return <TaskCard key={index} bid={bid} user={active} />;
                  })} */}
                </div>
              </div>
              {/* LOST BIDS */}
              <div className="tw-px-[16px] tw-py-[8px] tw-bg-[#F2F4F7]  tw-w-[342px] ">
                <div className="tw-mb-[24px] tw-flex tw-items-center tw-justify-center tw-gap-[8px]">
                  <div className="tw-bg-[#98A2B3] tw-w-[20px] tw-h-[20px] tw-flex tw-items-center tw-justify-center tw-text-white tw-text-xs-medium">
                    {bids.filter((bid) => bid.status === "rejected").length}
                  </div>
                  <h6 className="tw-uppercase tw-text-sm-semi-bold tw-text-[#98A2B3]">
                    CLOSED BIDS
                  </h6>
                </div>
                <div className="tw-h-screen tw-overflow-y-auto no-scrollbar">
                  {bids
                    .filter((bid) =>
                      bid.project.title
                        .toLowerCase()
                        .includes(filter.toLowerCase())
                    )
                    .filter((bid) => bid.status === "rejected")
                    .map((bid, index) => {
                      return <TaskCard key={index} bid={bid} user={active} />;
                    })}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DashTab;

const Div11 = styled.div`
  justify-content: center;
  border: 1px solid var(--gray-300, #d0d5dd);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  background-color: var(--base-white, #fff);
  display: flex;
  gap: 8px;
  padding: 10px 16px;
`;

const Div12 = styled.div`
  color: var(--gray-500, #667085);
  font: 600 14px/20px Inter, sans-serif;
`;
