import React from "react";
import Test from "../test";
import ViewProposal from "pages/Workspace/Project/ViewProposal";
import MyProjects from "pages/Workspace/Dashboard/MyProjects";
import Jobs from "pages/Workspace/Jobs/Jobs";
import JobDetails from "pages/Workspace/Jobs/JobDetails";
import ClientProfile from "pages/Workspace/Jobs/ClientProfile";
import CreateBid from "pages/Workspace/Jobs/CreateBid";
import Designers from "pages/Workspace/Designers/Designer";
import DesignerDetails from "pages/Workspace/Designers/DesignerDetails";
import SizingTemplates from "pages/Workspace/SizingTemplate/SizingTemplates";
import Connections from "pages/Workspace/Connections/Connections";
import EscrowHome from "pages/Workspace/Escrow/Escrow";
import Settings from "pages/Workspace/Settings/Settings";
import Profile from "pages/Workspace/Settings/Profile/Profile";
import Security from "pages/Workspace/Settings/Security";
import Payment from "pages/Workspace/Settings/Payment/Payment";
import MembershipandPermissions from "pages/Workspace/Settings/Membership/MembershipandPermissions";
import Wallet from "pages/Workspace/Wallet/Wallet";
import ListProjects from "pages/Workspace/Project/ListProjects";
import ShareYourWork from "pages/Workspace/ShareWork/ShareYourWork";
import ProjectType from "pages/Workspace/Project/Create/ProjectType";

//WORKSPACE
import WorkSpace from "pages/Workspace";

//AUTH
const Signup = React.lazy(() => import("pages/SignupPage/Signup"));
const ConfirmEmailAddress = React.lazy(() =>
  import("pages/SignupPage/ConfirmEmailAddress")
);
const EmailVerified = React.lazy(() =>
  import("pages/SignupPage/EmailVerified")
);
const Signin = React.lazy(() => import("pages/SigninPage/Signin"));
const ForgotPassword = React.lazy(() =>
  import("pages/ForgotPassword/ForgotPassword")
);
const CheckEmail = React.lazy(() => import("pages/ForgotPassword/CheckEmail"));
const ResetPassword = React.lazy(() =>
  import("pages/ForgotPassword/ResetPassword")
);
const PasswordChanged = React.lazy(() =>
  import("pages/ForgotPassword/PasswordChanged")
);
const Onboard = React.lazy(() => import("pages/OnboardingPage/Onboard"));
const AccountMultiStepForm = React.lazy(() =>
  import("pages/OnboardingPage/Account/AccountMultiStepForm")
);
const Congratulations = React.lazy(() =>
  import("pages/OnboardingPage/Congratulations")
);

//LANDING PAGE
const FindDesigners = React.lazy(() =>
  import("pages/LandingPage/FindDesigners")
);
const AboutUs = React.lazy(() => import("pages/LandingPage/AboutUs"));
const ContactUs = React.lazy(() => import("pages/LandingPage/ContactUs"));
const Blogs = React.lazy(() => import("pages/LandingPage/ContactUs"));
const HomePage = React.lazy(() => import("pages/LandingPage/HomePage"));
const FindJobs = React.lazy(() => import("pages/LandingPage/FindJobs"));

const Invoice = React.lazy(() => import("pages/Workspace/Escrow/Invoice"));

const MultiStepForm = React.lazy(() =>
  import("pages/RegistrationPage/MultiStepForm")
);
const FreelancerFeed = React.lazy(() =>
  import("pages/Feeds/Freelancer/FreelancerFeed")
);
const Notifications = React.lazy(() =>
  import("pages/Notification/Notifications")
);
const Messages = React.lazy(() => import("pages/Message/Messages"));
const UserLanding = React.lazy(() => import("pages/UserLanding"));

// CALLBACKS
const FacebookCallback = React.lazy(() =>
  import("pages/Callback/FacebookCallback")
);
const AppleCallback = React.lazy(() => import("pages/Callback/AppleCallback"));
const GoogleCallback = React.lazy(() =>
  import("pages/Callback/GoogleCallback")
);

// LazyLoad this components
const ROUTES = [
  { path: "/", exact: true, component: <HomePage /> },
  { path: "/jobs", exact: true, component: <FindJobs /> },
  { path: "/designers", exact: true, component: <FindDesigners /> },
  { path: "/about", exact: true, component: <AboutUs /> },
  { path: "/contact", exact: true, component: <ContactUs /> },
  { path: "/blog", exact: true, component: <Blogs /> },
  { path: "/feeds", exact: true, component: <FreelancerFeed /> },
  { path: "/user", component: <UserLanding /> },
  { path: "/register", exact: true, component: <MultiStepForm /> },
  { path: "/signin", exact: true, component: <Signin /> },
  { path: "/invoice", exact: true, component: <Invoice /> },
  { path: "/signup", exact: true, component: <Signup /> },
  { path: "/facebook-callback", exact: true, component: <FacebookCallback /> },
  { path: "/apple-callback", exact: true, component: <AppleCallback /> },
  { path: "/google-callback", exact: true, component: <GoogleCallback /> },
  { path: "/forgot-password", exact: true, component: <ForgotPassword /> },
  { path: "/check-email", exact: true, component: <CheckEmail /> },
  { path: "/reset-password", exact: true, component: <ResetPassword /> },
  { path: "/password-changed", exact: true, component: <PasswordChanged /> },
  { path: "/confirm-email", exact: true, component: <ConfirmEmailAddress /> },
  { path: "/email-verified", exact: true, component: <EmailVerified /> },
  { path: "/onboard", exact: true, component: <Onboard /> },
  { path: "/congratulations", exact: true, component: <Congratulations /> },
  { path: "/create-account", exact: true, component: <AccountMultiStepForm /> },
  {
    path: "/workspace",
    exact: true,
    component: <WorkSpace />,
    routes: [
      { path: "create-project", exact: true, component: <ProjectType /> },
      { path: "share-work", exact: true, component: <ShareYourWork /> },
      { path: "projects", exact: true, component: <ListProjects /> },
      {
        path: "projects/:projectId/bid/:slug",
        exact: true,
        component: <ViewProposal />,
      },
      { path: "dashboard", exact: true, component: <MyProjects /> },
      { path: "jobs", exact: true, component: <Jobs /> },
      { path: "jobs/:slug", exact: true, component: <JobDetails /> },
      { path: "jobs/:slug/create-bid", exact: true, component: <CreateBid /> },
      { path: "jobs/:slug/client/:clientId", exact: true, component: <ClientProfile /> },
      { path: "designers", exact: true, component: <Designers /> },
      { path: "designers/:id", exact: true, component: <DesignerDetails /> },
      { path: "sizing-templates", exact: true, component: <SizingTemplates /> },
      { path: "connections", exact: true, component: <Connections /> },
      { path: "messages", exact: true, component: <Messages /> },
      { path: "wallet", exact: true, component: <Wallet /> },
      { path: "escrow", exact: true, component: <EscrowHome /> },
      { path: "settings", exact: true, component: <Settings /> },
      { path: "settings/profile", exact: true, component: <Profile /> },
      { path: "settings/security", exact: true, component: <Security /> },
      {
        path: "settings/permissions",
        exact: true,
        component: <MembershipandPermissions />,
      },
      {
        path: "settings/notifications",
        exact: true,
        component: <Notifications />,
      },
      { path: "settings/payment", exact: true, component: <Payment /> },
    ],
  },
  

  { path: "/test", component: <Test /> },
  // { path: "/notifications", component: Notifications },
  // { path: "/messages", exact: true, component: Messages },
  // { path: "/messages/:roomname/:username", component: Messages },
];

export default ROUTES;
