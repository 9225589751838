import * as React from "react";
import styled from "styled-components";

const BreadCrumbs = ({name}) => {
  return (
    <Div>
      <Img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/e49174496e5540ecde9f93d20dd3cce2f4f32c9c456db50db8c3ea2376f36cb0?"
      />
      <img
        loading="lazy"
        alt="umojalin"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/fae851990da013b68968988bc982161f04ebd538c4dc0efa28b0bdca7435319a?"
      />
      <Div2>{name}</Div2>
    </Div>
  );
};

export default BreadCrumbs;

const Div = styled.div`
  display: flex;
  gap: 8px;
  @media (max-width: 991px) {
    justify-content: center;
  }
`;

const Img = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
`;

const Div2 = styled.div`
  color: var(--gray-700, #344054);
  white-space: nowrap;
  justify-content: center;
  border-radius: 6px;
  background-color: var(--gray-50, #f9fafb);
  flex-grow: 1;
  padding: 4px 8px;
  font: 600 14px/20px Inter, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;