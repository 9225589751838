import React from "react";
import Pagination from "./Pagination";
import { isElement } from "constants/Utils";

export default function Table({ columns, entries, dataSource, handleClick }) {
  return (
    <div>
      <div className="tw-overflow-x-auto">
        <table className="tw-w-full tw-border-collapse">
          <thead>
            <tr data-testid="table-head-tr">
              {columns && columns.length > 0 ? (
                columns.map((column) => (
                  <th
                  className="tw-px-[24px] tw-py-[12px] tw-border-b tw-text-xs-medium tw-text-[#475467]"
                    entries={entries}
                    data-testid="table-head-th"
                    key={column.key}
                  >
                    <div>{column.title}</div>
                  </th>
                ))
              ) : (
                <></>
              )}
            </tr>
          </thead>
          <tbody data-testid="table-body">
            {dataSource?.length > 0 &&
              dataSource.map((data, i) => (
                <tr
                  data-testid="table-body-tr"
                  key={data.key}
                  onClick={() => (entries ? handleClick(data.key) : null)}
                  ref={data?.ref}
                  className={`${i%2 === 0 && 'tw-bg-[#EAECF0]'}`}
                >
                  {columns && columns.length > 0 ? (
                    columns.map((column, index) => {
                      return isElement(data[column.dataIndex]) ? (
                        <td key={index} className="tw-px-[24px] tw-py-[16px] tw-border-b">{data[column.dataIndex]}</td>
                      ) : (
                        <td key={index} className="tw-px-[24px] tw-py-[16px] tw-border-b">
                          {/*if you are not returning an element*/}
                          <div>{data[column.dataIndex]}</div>
                        </td>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <Pagination />
    </div>
  );
}
