import AvatarGroup from "components/AvatarGroup/AvatarGroup";

function AddTeam({
  teammates = [
    {
      name: "",
      imageSrc:
        "https://ui-avatars.com/api/?background=B2DDFF&color=001F3F&name=Aimes",
    },
    {
      imageSrc:
        "https://ui-avatars.com/api/?background=EAAA08&color=ffffff&name=Ukpai",
      name: "",
    },
  ],
}) {
  //TODO: handle add team
  return (
    <div className="tw-pt-[10px] tw-pb-[14px] tw-flex tw-justify-between tw-cursor-pointer">
      <div className="tw-relative tw-flex tw-items-center tw-gap-[12px]">
        <AvatarGroup
          cls={`tw-flex tw-w-[32px] tw-h-[32px] tw-rounded-[200px] tw-border-white tw-border-1.5 tw-inset`}
          avatars={teammates}
        />
        <h3 className="tw-text-sm-semi-bold">Add Team</h3>
      </div>

      <div className="tw-border tw-border-dashed tw-bg-white tw-w-[36px] tw-h-[36px] tw-rounded-[12px] tw-border-[#D0D5DD] tw-flex tw-items-center tw-justify-center">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.9987 3.33594V12.6693M3.33203 8.0026H12.6654"
            stroke="#98A2B3"
            stroke-width="1.33333"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
  );
}

export default AddTeam;
