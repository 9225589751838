import React from "react";
import useWindowSize from "hooks/useWindowSize";

export default function HorizontalMain({ activeTab, tabs, setActiveTab }) {
  const { width } = useWindowSize();
  const isMobile = width <= 768;
  return (
    <>
      {isMobile ? (
        <> </>
      ) : (
        <div className="tw-flex tw-border-b tw-border-gray-200 tw-gap-[16px]">
          {tabs.map((tab, i) => {
            return (
              <div
                onClick={() => setActiveTab(tab.key)}
                key={tab.key}
                className={`tw-px-[4px] tw-pb-[12px]   tw-text-md-semi-bold tw-cursor-pointer tw-text-gray-500 ${
                  activeTab === tab.key &&
                  "tw-border-yellow-500 !tw-text-yellow-500 tw-border-b-[2px]"
                }`}
              >
                {tab.name}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}


