import React, { useState, useEffect } from "react";
import { Card, Col } from "react-bootstrap";
import { Box, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { Badge } from "react-bootstrap";
import TabPanel from "components/Tabs/TabPanel";

import TemplatesList from "./TemplatesList";
import { listSizingTemplates } from "services/sizingTemplateService";
import { useSelector } from "react-redux";
import SizingTemplateCard from "components/SizingTemplate/SizingTemplateCard";
import SizingTemplateBuyerCard from "components/SizingTemplate/SizingTemplateBuyerCard";

const SizingTemplates = () => {
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [sizingTemplates, setSizingTemplates] = useState([]);
  const {active}= useSelector(state=> state.account)


  const tabChange = (event, newValue) => {
    setValue(newValue);
  };

  // const [expanded, setExpanded] = useState(false);
  // const handleExpand = () => {
  //   setExpanded(!expanded);
  // };
  // const closeExpand = () => {
  //   setExpanded(false);
  // };

  const getSizingTemplates = async (buyerId) => {
    const resp = await listSizingTemplates(buyerId);
    setSizingTemplates(resp);
  };

  useEffect(() => {
    if (active.account_type.toLowerCase() === 'buyer') {
  
      getSizingTemplates(active.id);
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="tw-relative">
      <div className="tw-bg-dashboard-banner tw-w-full tw-h-[120px] tw-bg-no-repeat tw-bg-cover"></div>
      <div className="tw-absolute tw-top-0 tw-p-[32px]">
        <h3 className="tw-text-xs-bold !tw-text-[24px]">Sizing Template</h3>
      </div>
      {/* If buyer he/she would have a tab */}
      {active.account_type.toLowerCase() === 'buyer' && <></>}

      {/* <TemplatesList sizing_templates={sizingTemplates} /> */}
      <div className="tw-mx-5">
        <SizingTemplateCard />
        <SizingTemplateBuyerCard />
      </div>
    </div>
  );
};

export default SizingTemplates;
