import { HttpMethod, fireRequest } from "./axios";

export const listRecommendedDesigners = async (projectId) => {
  try {
    const response = await fireRequest(
      {
        url: `/recommended-designer/list/${projectId}/`,
        method: HttpMethod.GET,
      },
      true
    );

    return response;
  } catch (error) {
    throw error.data;
  }
};

export const createRecommendedDesigner = async (values) => {
  try {
    const response = await fireRequest(
      {
        url: `/recommended-designer/create/`,
        body: values,
        method: HttpMethod.POST,
      },
      true
    );
    return response;
  } catch (error) {
    throw error.data;
  }
};

export const updateRecommendedDesigner = async (values) => {
  try {
    const response = await fireRequest(
      {
        url: `/recommended-designer/update`,
        body: values,
        method: HttpMethod.PUT,
      },
      true
    );

    return response;
  } catch (error) {
    throw error.data;
  }
};

export const recommendedDesignerDetails = async (id) => {
  try {
    const response = await fireRequest(
      {
        url: `/recommended-designer/details/${id}`,
        method: HttpMethod.GET,
      },
      true
    );

    return response;
  } catch (error) {
    throw error.data;
  }
};
