import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import styled from "styled-components";
import { Button } from "react-bootstrap";
import { listBuyers } from "services/buyerService";
import { getCurrencySymbol } from "constants/Utils";

const itemsPerPage = 5;

const ClientWatchTab = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const [buyers, setBuyers] = useState([]);
  const auth_token = localStorage.getItem("auth_token");
  const acceptedBids = props.bids.filter((bid) => bid.is_accepted);
  const uniqueBuyerIds = [
    ...new Set(acceptedBids.map((bid) => bid.project.created_by)),
  ];
  const clientsData = buyers.filter((buyer) =>
    uniqueBuyerIds.some((id) => id === buyer.id)
  );
  const totalPages = Math.ceil(clientsData.length / itemsPerPage);
  const tableData = clientsData.slice(startIndex, endIndex);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const loadBuyers = async () => {
    const resp = await listBuyers(auth_token);
    setBuyers(resp);
  };

  const format_date = (dateTime) => {
    const options = { month: "short", day: "numeric", year: "numeric" };
    const formattedDate = new Date(dateTime).toLocaleDateString(
      undefined,
      options
    );
    return formattedDate;
  };

  // const getProjectCoverImage = async (projectId) => {
  //   const resp = await details(projectId, auth_token);
  //   return resp;
  // }

  useEffect(() => {
    loadBuyers();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyers]);

  return (
    <Div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Project Name</TableCell>
              <TableCell align="center">Amount</TableCell>
              <TableCell align="center">Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((data, index) => (
              <TableRow key={index}>
                <TableCell>
                  <ProjectInfo>
                    {/* <Img loading="lazy" srcSet={getProjectCoverImage(data.projects.filter((item) => item.created_by === data.id)[0].id).cover_image} alt="Project Image" /> */}
                    <ProjectDetails>
                      <ProjectTitle>
                        {
                          data.projects.filter(
                            (item) => item.created_by === data.id
                          )[0]?.title
                        }
                      </ProjectTitle>
                      <ProjectID>@{data.slug}</ProjectID>
                    </ProjectDetails>
                  </ProjectInfo>
                </TableCell>
                <TableCell align="center">
                  {`${getCurrencySymbol(
                    JSON.parse(
                      data.projects.filter(
                        (item) => item.created_by === data.id
                      )[0]?.budget
                    ).currency
                  )}`}
                  {`${
                    JSON.parse(
                      data.projects.filter(
                        (item) => item.created_by === data.id
                      )[0]?.budget
                    ).amount
                  }`}
                </TableCell>
                <TableCell align="center">
                  {format_date(
                    data.projects.filter(
                      (item) => item.created_by === data.id
                    )[0]?.end_date
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <PaginationContainer>
          <div>
            Page {currentPage} of {totalPages}
          </div>
          <div style={{ display: "flex", gap: 12 }}>
            <Button
              variant="outline-light"
              className="btn-lg rounded-0 text-dark"
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              Previous
            </Button>
            <Button
              variant="outline-light"
              className="btn-lg rounded-0 mx-2 text-dark"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Next
            </Button>
          </div>
        </PaginationContainer>
      </TableContainer>
    </Div>
  );
};

export default ClientWatchTab;

const Div = styled.div`
  align-self: stretch;
  margin-top: 20px;
  background-color: var(--color-neutrals-00, #fff);
  display: flex;
  flex-direction: column;
`;

const ProjectInfo = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  padding: 16px 80px 16px 24px;
`;



const ProjectDetails = styled.div`
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
`;

const ProjectTitle = styled.div`
  color: var(--gray-900, #101828);
  white-space: nowrap;
  font: 500 14px/20px Inter, sans-serif;
`;

const ProjectID = styled.div`
  color: var(--gray-600, #475467);
  white-space: nowrap;
  font: 400 14px/20px Inter, sans-serif;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 14px 24px;
  border-top: 1px solid var(--gray-200, #eaecf0);
`;
