import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { listConnections } from "services/connectionsServices";
import styled from "styled-components";
import { createTeamMate, deleteTeamMate, listTeamMates } from "services/teamMatesService";
import {
  teamMateFailure,
  teamMateSuccess,
} from "store/actions/teamMateActions";
import ProfileHolder from "assets/images/icons/user-01.svg";
import InvitationPendingIcon from "assets/images/icons/invitation-pending-icon.svg";
import DeleteIcon from "assets/images/icons/delete-icon.svg";

const TeammatesTab = (props) => {
  const [tag, setTag] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [foundTags, setFoundTags] = useState([]);
  const [connectionTags, setConnectionTags] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [showTagBox, setShowTagBox] = useState(false);
  const {active}= useSelector((state)=> state.account)

  const [connections_list, setConnections] = useState([]);
  const [team_mates, setTeamMates] = useState([]);
  const auth_token = localStorage.getItem("auth_token");
  const dispatch = useDispatch();

  const listConnectionsByUserId = async () => {
    const resp = await listConnections(active.id, auth_token);
    const filteredConnections = resp.reduce((result, item) => {
      if (item.buyer_designer.id === active.id) {
        result.push(item.created_by);
      } else if (item.created_by.id === active.id) {
        result.push(item.buyer_designer);
      } else {
        result.push(item);
      }
      return result;
    }, []);

    const tagsArray = filteredConnections.map((connection) => connection.slug);
    setConnections(filteredConnections);
    setConnectionTags(tagsArray);
  };

  const listTeamMatesByUserId = async () => {
    const resp = await listTeamMates(active.id, auth_token);
    setTeamMates(resp);
  };

  const handleTagChange = (e) => {
    setTag(e.target.value);
    setNotFound(false);
  };

  const handleSearch = () => {
    if (connectionTags.includes(tag)) {
      setFoundTags((prevTags) => [...prevTags, tag]);
      setTag("");
      setShowTagBox(false);
    } else {
      setNotFound(true);
    }
  };

  const handleShowTagBox = () => {
    setShowTagBox(true);
  };

  const handleRemoveTag = (index) => {
    setFoundTags((prevTags) => prevTags.filter((_, i) => i !== index));
  };

  useEffect(() => {
    listConnectionsByUserId();
    listTeamMatesByUserId();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUserSelection = (userId) => {
    setSelectedUsers((prevSelected) => {
      if (prevSelected.includes(userId)) {
        return prevSelected.filter((id) => id !== userId);
      } else {
        return [...prevSelected, userId];
      }
    });
  };

  const handleSelectAll = () => {
    const allUserIds = team_mates.map((user) => user.id);
    setSelectedUsers(allUserIds);
  };

  const handleSubmit = async () => {
    const correspondingIds = foundTags.map((tag) => {
      const connection = connections_list.find((item) => item.slug === tag);
      return connection ? connection.id : null;
    });
    const teammates_data = {
      created_by: active.id,
      team_mates: correspondingIds,
    };
    try {
      if (auth_token) {
        const resp = await createTeamMate(teammates_data, auth_token);
        dispatch(teamMateSuccess(resp));
        setShowTagBox(false);
        setTag("");
        setFoundTags([]);
      }
    } catch (err) {
      dispatch(teamMateFailure(err.message));
    }
  };

  const handleDeleteTeamMate = async (teamMateId) => {
    const resp = await deleteTeamMate(teamMateId, auth_token);
    if(resp) {
      window.href = "/workspace/settings/permissions";
    }
  }

  return (
    <>
      <Div9>
        <Div10>
          <Div11>Invite teamates</Div11>
          <Div12>
            Get your projects up and running faster by inviting teammates to
            help out{" "}
          </Div12>
        </Div10>
        <Div13>
          {foundTags.map((foundTag, index) => (
            <div key={index}>
              <Div14>
                <Div15>
                  <Div16>
                    <Img2
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/9a9faf65-8523-435d-a845-099f610d8984?"
                    />
                    <Div17>{foundTag}</Div17>
                  </Div16>
                </Div15>
                <Div18>
                  <img loading="lazy" src={InvitationPendingIcon}  alt="umojalin"/> <img style={{ cursor: "pointer" }} alt="umojalin" onClick={() => handleRemoveTag(index)} loading="lazy" src={DeleteIcon} />
                </Div18>
              </Div14>
              <Div19 className="mb-2">
                <Div20>
                  <Div21>Last session</Div21>
                  <Img3
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/6df8c2f0-7545-46bf-908f-3596123b496f?"
                  />
                </Div20>
                <Div22>
                  <Div23>
                    <Div24>2018 Macbook Pro 15-inch</Div24>
                    <Div25>
                      <Img4
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/c5cb1456-d314-4a06-930f-80a7286abab2?"
                      />
                      <Div26>Active now</Div26>
                    </Div25>
                  </Div23>
                  <Div27>Lagos, Nigeria • 22 Jan at 10:40am</Div27>
                </Div22>
                <Div28 />
              </Div19>
            </div>
          ))}
          <Div29>
            <Div30>
              {!showTagBox ? (
                <>
                  <Img5
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/b4493456-8adf-4a02-975d-749087b021b3?"
                  />
                  <Div31
                    style={{ cursor: "pointer" }}
                    onClick={handleShowTagBox}
                  >
                    Add another
                  </Div31>
                </>
              ) : (
                <div className="col-md-12">
                  <Form.Group controlId="tagSearch">
                    <Form.Control
                      type="text"
                      placeholder="add user tag"
                      value={tag}
                      onChange={handleTagChange}
                      onKeyDown={handleSearch}
                      className="rounded-0 form-control-lg"
                    />
                  </Form.Group>
                  {notFound && (
                    <span className="text-danger">Tag not found</span>
                  )}
                </div>
              )}
            </Div30>
            <button
              className="btn-send-teammate-invites"
              onClick={handleSubmit}
            >
              <Img6
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/989675de-ba76-488e-bb15-52cf44ed16d7?"
              />
              <Div33>Send invites</Div33>
            </button>
          </Div29>
        </Div13>
        <TableContainer className="mt-5">
          <Table>
            <TableHead className="bg-secondary">
              <TableRow>
                <TableCell>
                  <Checkbox
                    checked={selectedUsers.length === team_mates.length}
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell>
                  <Typography>Name</Typography>
                </TableCell>
                <TableCell>
                  <Typography>Projects</Typography>
                </TableCell>
                <TableCell>
                  <Typography>Designers</Typography>
                </TableCell>
                <TableCell>
                  <Typography>S/templates</Typography>
                </TableCell>
                <TableCell>
                  <Typography>Connections</Typography>
                </TableCell>
                <TableCell>
                  <Typography>Messages</Typography>
                </TableCell>
                <TableCell>
                  <Typography>Wallet</Typography>
                </TableCell>
                <TableCell>
                  <Typography>Escrow</Typography>
                </TableCell>
                <TableCell>
              </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {team_mates.map((item, index) => (
                <>
                  {item.buyer_designer &&
                  item.buyer_designer.id !== active?.id ? (
                    <TableRow key={index}>
                      <TableCell>
                        <Checkbox
                          checked={selectedUsers.includes(item.id)}
                          onChange={() => handleUserSelection(item.id)}
                        />
                      </TableCell>
                      <TableCell>
                        <div style={{ display: "flex", alignItems: "left" }}>
                          <CustomAvatar size="30px">
                          <AvatarImage alt={`${item.buyer_designer.first_name}`} src={item.buyer_designer.profile_image ? `${process.env.REACT_APP_MEDIA_URL}${item.buyer_designer.profile_image}` : ProfileHolder} />
                        </CustomAvatar>
                          <div style={{ marginLeft: "8px" }}>
                            <Typography>
                              {item.buyer_designer.first_name}{" "}
                              {item.buyer_designer.last_name}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              @{item.buyer_designer.slug}
                            </Typography>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <Form.Control
                          as="select"
                          name="projects_role"
                          className="form-control-lg"
                        >
                          <option value="">None</option>
                          <option value="Viewer">Viewer</option>
                          <option value="Editor">Editor</option>
                          <option value="Admin">Admin</option>
                        </Form.Control>
                      </TableCell>
                      <TableCell>
                        <Form.Control
                          as="select"
                          name="designers_role"
                          className="form-control-lg"
                        >
                          <option value="">None</option>
                          <option value="Viewer">Viewer</option>
                          <option value="Editor">Editor</option>
                          <option value="Admin">Admin</option>
                        </Form.Control>
                      </TableCell>
                      <TableCell>
                        <Form.Control
                          as="select"
                          name="templates_role"
                          className="form-control-lg"
                        >
                          <option value="">None</option>
                          <option value="Viewer">Viewer</option>
                          <option value="Editor">Editor</option>
                          <option value="Admin">Admin</option>
                        </Form.Control>
                      </TableCell>
                      <TableCell>
                        <Form.Control
                          as="select"
                          name="connections_role"
                          className="form-control-lg"
                        >
                          <option value="">None</option>
                          <option value="Viewer">Viewer</option>
                          <option value="Editor">Editor</option>
                          <option value="Admin">Admin</option>
                        </Form.Control>
                      </TableCell>
                      <TableCell>
                        <Form.Control
                          as="select"
                          name="messages_role"
                          className="form-control-lg"
                        >
                          <option value="">None</option>
                          <option value="Viewer">Viewer</option>
                          <option value="Editor">Editor</option>
                          <option value="Admin">Admin</option>
                        </Form.Control>
                      </TableCell>
                      <TableCell>
                        <Form.Control
                          as="select"
                          name="wallets_role"
                          className="form-control-lg"
                        >
                          <option value="">None</option>
                          <option value="Viewer">Viewer</option>
                          <option value="Editor">Editor</option>
                          <option value="Admin">Admin</option>
                        </Form.Control>
                      </TableCell>
                      <TableCell>
                        <Form.Control
                          as="select"
                          name="escrow_role"
                          className="form-control-lg"
                        >
                          <option value="">None</option>
                          <option value="Viewer">Viewer</option>
                          <option value="Editor">Editor</option>
                          <option value="Admin">Admin</option>
                        </Form.Control>
                      </TableCell>
                      <TableCell>
                      <Div18
                      style={{cursor: "pointer"}}
                      onClick={() => handleDeleteTeamMate(item.id)}>remove</Div18>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableRow key={index}>
                      <TableCell>
                        <Checkbox
                          checked={selectedUsers.includes(item.id)}
                          onChange={() => handleUserSelection(item.id)}
                        />
                      </TableCell>
                      <TableCell>
                        <div style={{ display: "flex", alignItems: "left" }}>
                          <CustomAvatar size="30px">
                          <AvatarImage alt={`${item.created_by.first_name}${" "}${item.created_by.last_name}`} src={item.created_by.profile_image ? `${process.env.REACT_APP_MEDIA_URL}${item.created_by.profile_image}` : ProfileHolder} />
                        </CustomAvatar>
                          <div style={{ marginLeft: "8px" }}>
                            <Typography>
                              {item.created_by.first_name}{" "}
                              {item.created_by.last_name}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              @{item.created_by.slug}
                            </Typography>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <Form.Control
                          as="select"
                          name="projects_role"
                          className="form-control-lg"
                        >
                          <option value="">None</option>
                          <option value="Viewer">Viewer</option>
                          <option value="Editor">Editor</option>
                          <option value="Admin">Admin</option>
                        </Form.Control>
                      </TableCell>
                      <TableCell>
                        <Form.Control
                          as="select"
                          name="designers_role"
                          className="form-control-lg"
                        >
                          <option value="">None</option>
                          <option value="Viewer">Viewer</option>
                          <option value="Editor">Editor</option>
                          <option value="Admin">Admin</option>
                        </Form.Control>
                      </TableCell>
                      <TableCell>
                        <Form.Control
                          as="select"
                          name="templates_role"
                          className="form-control-lg"
                        >
                          <option value="">None</option>
                          <option value="Viewer">Viewer</option>
                          <option value="Editor">Editor</option>
                          <option value="Admin">Admin</option>
                        </Form.Control>
                      </TableCell>
                      <TableCell>
                        <Form.Control
                          as="select"
                          name="connections_role"
                          className="form-control-lg"
                        >
                          <option value="">None</option>
                          <option value="Viewer">Viewer</option>
                          <option value="Editor">Editor</option>
                          <option value="Admin">Admin</option>
                        </Form.Control>
                      </TableCell>
                      <TableCell>
                        <Form.Control
                          as="select"
                          name="messages_role"
                          className="form-control-lg"
                        >
                          <option value="">None</option>
                          <option value="Viewer">Viewer</option>
                          <option value="Editor">Editor</option>
                          <option value="Admin">Admin</option>
                        </Form.Control>
                      </TableCell>
                      <TableCell>
                        <Form.Control
                          as="select"
                          name="wallets_role"
                          className="form-control-lg"
                        >
                          <option value="">None</option>
                          <option value="Viewer">Viewer</option>
                          <option value="Editor">Editor</option>
                          <option value="Admin">Admin</option>
                        </Form.Control>
                      </TableCell>
                      <TableCell>
                        <Form.Control
                          as="select"
                          name="escrow_role"
                          className="form-control-lg"
                        >
                          <option value="">None</option>
                          <option value="Viewer">Viewer</option>
                          <option value="Editor">Editor</option>
                          <option value="Admin">Admin</option>
                        </Form.Control>
                      </TableCell>
                      <TableCell>
                      <Div18
                      style={{cursor: "pointer"}}
                      onClick={() => handleDeleteTeamMate(item.id)}>remove</Div18>
                      </TableCell>
                    </TableRow>
                  )}
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Div9>
      <Img14
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/93657994-afb6-485e-a51c-55046b534d8e?"
      />
    </>
  );
};

export default TeammatesTab;

const Div9 = styled.div`
  align-self: center;
  display: flex;
  margin-top: 24px;
  width: 100%;
  max-width: 1093px;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div10 = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div11 = styled.div`
  color: var(--gray-700, #344054);
  margin-right: -20px;
  font: 500 14px/143% Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div12 = styled.div`
  color: var(--gray-600, #475467);
  margin-right: -20px;
  font: 400 14px/143% Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div13 = styled.div`
  display: flex;
  margin-top: 8px;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div14 = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  padding: 0 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Div15 = styled.div`
  border: 1px solid var(--gray-300, #d0d5dd);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  background-color: var(--color-neutrals-00, #fff);
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
  padding: 10px 14px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div16 = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Img2 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
  overflow: hidden;
  max-width: 100%;
  margin: auto 0;
`;

const Div17 = styled.div`
  color: var(--gray-500, #667085);
  align-self: stretch;
  flex-grow: 1;
  flex-basis: auto;
  font: 400 16px/150% Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div18 = styled.div`
  color: var(--error-400, #f97066);
  align-self: center;
  white-space: nowrap;
  margin: auto 0;
  font: 600 14px/143% Inter, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div19 = styled.div`
  display: flex;
  margin-top: 8px;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div20 = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  padding: 0 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Div21 = styled.div`
  color: var(--gray-600, #475467);
  font: 400 14px/143% Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Img3 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
  overflow: hidden;
  max-width: 100%;
`;

const Div22 = styled.div`
  align-items: start;
  display: flex;
  margin-top: 13px;
  flex-direction: column;
  padding: 0 20px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div23 = styled.div`
  display: flex;
  width: 322px;
  max-width: 100%;
  padding-right: 15px;
  gap: 8px;
`;

const Div24 = styled.div`
  color: var(--gray-700, #344054);
  font: 500 14px/143% Inter, sans-serif;
`;

const Div25 = styled.div`
  align-items: center;
  border-radius: 16px;
  border: 1.5px solid var(--success-600, #039855);
  display: flex;
  justify-content: space-between;
  gap: 4px;
  padding: 2px 8px 2px 6px;
`;

const Img4 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 8px;
  overflow: hidden;
  max-width: 100%;
  margin: auto 0;
`;

const Div26 = styled.div`
  color: var(--success-700, #027a48);
  text-align: center;
  align-self: stretch;
  white-space: nowrap;
  font: 500 12px/150% Inter, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div27 = styled.div`
  align-self: stretch;
  color: var(--gray-600, #475467);
  margin: 4px -20px 0 0;
  font: 400 14px/143% Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div28 = styled.div`
  background-color: var(--gray-200, #eaecf0);
  display: flex;
  margin-top: 8px;
  height: 1px;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div29 = styled.div`
  justify-content: space-between;
  align-items: center;
  display: flex;
  margin-top: 16px;
  width: 100%;
  gap: 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Div30 = styled.div`
  justify-content: left;
  display: flex;
  gap: 8px;
  margin: auto 0;
  padding: 0 20px;
  width: 100%;
`;

const Img5 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
  overflow: hidden;
  max-width: 100%;
`;

const Div31 = styled.div`
  color: var(--gray-600, #475467);
  white-space: nowrap;
  font: 600 14px/143% Inter, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Img6 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
  overflow: hidden;
  max-width: 100%;
`;

const Div33 = styled.div`
  color: var(--base-white, var(--color-neutrals-00, #fff));
  white-space: nowrap;
  font: 600 14px/143% Inter, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Img14 = styled.img`
  aspect-ratio: 1128;
  object-fit: contain;
  object-position: center;
  width: 100%;
  align-self: stretch;
  fill: var(--gray-200, #eaecf0);
  overflow: hidden;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
const CustomAvatar = styled.div`
  width: ${(props) => props.size || '30px'};
  height: ${(props) => props.size || '30px'};
  border-radius: 50%;
  overflow: hidden;
`;

const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;