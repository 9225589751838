import { fireRequest, HttpMethod } from "./axios";

export const listBuyers = async () => {
  try {
    const response = await fireRequest(
      {
        url: `/buyer/list/`,
        method: HttpMethod.GET,
      },
      true
    );

    return response.data;
  } catch (error) {
    throw error.data;
  }
};

export const create = async (values) => {
  try {
    const response = await fireRequest(
      {
        url: `/buyer/create/`,
        method: HttpMethod.POST,
        body: values,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
      true
    );
    return response;
  } catch (error) {
    throw error.data;
  }
};

export const updateBuyer = async (id, values) => {
  try {
    const response = await fireRequest(
      {
        url: `/buyer/update/${id}`,
        method: HttpMethod.PUT,
        body: values,
      },
      true
    );
    return response.data;
  } catch (error) {
    throw error.data;
  }
};

export const details = async (id) => {
  try {
    const response = await fireRequest(
      {
        url: `/buyer/details/${id}`,
        method: HttpMethod.GET,
      },
      true
    );

    return response.data;
  } catch (error) {
    throw error.data;
  }
};
