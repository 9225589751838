import { formatDate, formatMoney } from "constants/Utils";
import React from "react";

export default function ProjectMiniDetailsCard({project}) {
  return (
    <div className="tw-p-[16px] tw-bg-[#F2F4F7] tw-mb-[16px]">
      <h6 className="tw-text-md-semi-bold tw-text-[#475467]">{project?.title}</h6>
      <p className="tw-mb-[20px] tw-text-[#667085]">
      {project?.about}
      </p>
      <hr className="tw-border-[#EAECF0]"/>

      <div className="tw-mt-[16px] tw-flex tw-gap-[4px] tw-items-center tw-justify-between">
      <div><h6 className="tw-text-md-semi-bold text-[#667085]"><span className="tw-text-md-regular">End Date</span>: {project.end_date ? formatDate(project.end_date) : ''}</h6></div>
      <div><h6 className="tw-text-md-semi-bold text-[#667085]"><span className="tw-text-md-regular">Project Budget:</span> {project.budget ? formatMoney(project.budget?.amount, project.budget.currency) : ''}</h6></div>
      </div>
    </div>
  );
}
