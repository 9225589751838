import React, { useState } from "react";

export default function Accordion({ children, title, closable = true }) {
  const [open, isOpen] = useState(true);
  return (
    <div className="tw-w-full">
      <div
        className="tw-mb-[16px] tw-flex tw-justify-between tw-items-center tw-cursor-pointer"
        onClick={() => {
          closable && isOpen(!open);
        }}
      >
        <h6 className="tw-text-md-semi-bold tw-text-gray-700">{title}</h6>

        {closable && (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={`${open ? "" : "tw-rotate-180"}`}
          >
            <path
              d="M6 9L12 15L18 9"
              stroke="#EAAA08"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        )}
      </div>
      <div className={`${closable && "tw-border-t tw-border-[#EAECF0]"} tw-transition-all tw-ease-in-out tw-delay-150`}>
        {open && children}
      </div>
    </div>
  );
}
