/* eslint-disable no-script-url */
import React, { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import { useSelector } from "react-redux";
import "./Escrow.css";
import TabPanel from "components/Tabs/TabPanel";
import ActiveProjectsTab from "./ActiveProjectsTab";
import PaidOutTab from "./PaidOutTab";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import VerifyEscrowModal from "./VerifyEscrowModal";
import { listBids } from "services/bidService";
import { listProjects } from "services/projectService";

const EscrowHome = () => {
  const [show, setShow] = useState(true);
  const [componentIndex, setComponentIndex] = useState(0);
  const [activeTab, setActiveTabValue] = useState(0);
  const theme = useTheme;
  const navigate = useNavigate();
  const [bids, setBids] = useState([]);
  const [projects, setProjects] = useState([]);
  const {active} = useSelector(state=> state.account)
  const selectedAccount = active;

  const handleCloseModal = () => {
    setComponentIndex(1);
    setShow(false);
  };

  const loadBids = async () => {
    const resp = await listBids("", selectedAccount?.id);
    setBids(resp);
  };

  const loadProjects = async () => {
    const resp = await listProjects(selectedAccount?.id);
    setProjects(resp);
  }

  const tabChange = (newValue) => {
    setActiveTabValue(newValue);
  };

  const handleRedirectToDashboard = () => {
    if(active.accountType === 'buyer') {
      navigate("/workspace/projects");
    } else {
      navigate("/workspace/dashboard");
    }
  };

  // useEffect(() => {
  //   if(buyer) {
  //     loadProjects();
  //   } else if(designer) {
  //     loadBids();
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // const activeBids = bids.filter((bid) => bid.project.status === 'ongoing');
  // const activeProjects = projects.filter((project) => project.status === 'ongoing' && project.created_by.id === selectedAccount.id);

  return (
    <>
    {/* {componentIndex === 1 && (
      <Div>
      <Div2>
        <Div3>
          <A href="javascript:void(0)">Escrow</A>
          <Div4>
            <Div5>
              <Div13>
                <Div14>
                  <Div15
                    className={
                      activeTab === 0
                        ? "active-escrow-tab"
                        : "inactive-escrow-tab"
                    }
                    onClick={() => tabChange(0)}
                  >
                    <A10 href="javascript:void(0)">Active projects</A10>
                    <A11 href="javascript:void(0)">2</A11>
                  </Div15> */}
                  {/* <A12
                    className={
                      activeTab === 1
                        ? "active-escrow-tab"
                        : "inactive-escrow-tab"
                    }
                    onClick={() => tabChange(1)}
                    href="javascript:void(0)"
                  >
                    Pending Payments
                  </A12> */}
                  {/* <A12
                    className={
                      activeTab === 1
                        ? "active-escrow-tab"
                        : "inactive-escrow-tab"
                    }
                    onClick={() => tabChange(1)}
                    href="javascript:void(0)"
                  >
                    Paid out
                  </A12>
                </Div14>
              </Div13>
            </Div5>
          </Div4>
          <TabPanel value={activeTab} index={0} dir={theme.direction}>
              <ActiveProjectsTab activeBids={activeBids} activeProjects={activeProjects} />
            </TabPanel>
            <TabPanel value={activeTab} index={1} dir={theme.direction}> */}
              {/* <PendingProjectsTab /> */}

            {/* </TabPanel>
            <TabPanel value={activeTab} index={1} dir={theme.direction}>
              <PaidOutTab activeBids={activeBids} activeProjects={activeProjects} />
            </TabPanel>
        </Div3>
      </Div2>
    </Div>
    )} */}
    {/* <Modal
        show={show}
        onHide={handleCloseModal}
        className="rounded"
        centered
        backdrop="static"
        style={{
          boxShadow:
            "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
        }}
      >
        <Modal.Body>
          <VerifyEscrowModal
            onHide={handleCloseModal}
            onRedirectToDash={handleRedirectToDashboard}
          />
        </Modal.Body>
      </Modal> */}
    </>
  );
};
export default EscrowHome;

const Div = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const Div2 = styled.div`
  align-items: flex-start;
  width: 100%;
  // max-width: 1157px;
  padding-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 32px;
  align-self: end;
  display: flex;
  flex-direction: column;
`;

const Div3 = styled.div`
  width: 100%;
  // max-width: 1093px;
  align-self: center;
  display: flex;
  flex-direction: column;
`;

const A = styled.a`
  color: var(--gray-900, #101828);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  margin-left: 34px;
  align-self: start;
  @media (max-width: 991px) {
    margin-left: 10px;
  }
`;

const Div4 = styled.div`
  align-items: flex-start;
  margin-top: 27px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
`;

const Div5 = styled.div`
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
`;

const Div13 = styled.div`
  align-items: flex-start;
  border-bottom: 1px solid var(--gray-200, #eaecf0);
  width: 100%;
  align-self: start;
  display: flex;
  flex-direction: column;
`;

const Div14 = styled.div`
  align-items: flex-start;
  padding-top: 1px;
  padding-right: 20px;
  padding-bottom: 1px;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 400px;
  @media (max-width: 991px) {
    justify-content: center;
  }
`;

const Div15 = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 162px;
`;

const A10 = styled.a`
  color: var(--primary-500, var(--primary-500, #eaaa08));
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  margin-top: 6px;
  align-self: start;
`;

const A11 = styled.a`
  align-items: center;
  border-radius: 16px;
  background: var(--primary-50, #fefbe8);
  mix-blend-mode: multiply;
  padding-top: 7px;
  padding-left: 11px;
  padding-right: 11px;
  padding-bottom: 7px;
  align-self: stretch;
  color: var(--primary-500, var(--primary-500, #eaaa08));
  text-align: center;
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: -0px;
`;

const A12 = styled.a`
  min-width: 142px;
`;
