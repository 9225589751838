import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import { updateBuyer } from "services/buyerService";
import { useDispatch, useSelector } from "react-redux";
import { updateDesigner } from "services/designerService";
import { updateAccount } from "store/account/actions";
import TextArea from "components/Input/TextArea";
import Input from "components/Input";

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
});

const MyDetailsTab = (props) => {
  const dispatch = useDispatch();
  const { active } = useSelector((state) => state.account);

  const formik = useFormik({
    initialValues: {
      first_name: active?.first_name,
      last_name: active?.last_name,
      gender: active?.gender,
      dob: active?.dob,
      email: active?.user.email,
      alternative_email: "",
      phone_number: active?.phone,
      address: active?.address,
      zip_code: active?.zip_code,
      state: active?.state,
      country: active?.country?.id,
      city: active?.city?.id,
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const template_data = { ...values };
        if (active.account_type === "buyer") {
          const resp = await updateBuyer(active.id, template_data);
          dispatch(updateAccount(resp));
        } else {
          const resp = await updateDesigner(active.id, template_data);
          dispatch(updateAccount(resp));
        }

        resetForm();
      } catch (err) {
        //
      }
    },
  });
  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        {/* <Div13 >
            <h6 className="tw-flex-1">About me </h6>
            <Div15 >
              <TextArea className="tw-w-full"/>
            </Div15>
        </Div13> */}
        <Div13>
          <Div14>Name</Div14>
          <Div15>
            <Form.Group controlId="first_name">
              <Form.Control
                type="text"
                name="first_name"
                onChange={formik.handleChange}
                value={formik.values.first_name}
                className="form-control-lg rounded-0"
              />
              {formik.errors.first_name && formik.touched.first_name && (
                <Form.Text className="text-danger">
                  {formik.errors.first_name}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group controlId="last_name">
              <Form.Control
                type="text"
                name="last_name"
                onChange={formik.handleChange}
                value={formik.values.last_name}
                className="form-control-lg rounded-0"
              />
              {formik.errors.last_name && formik.touched.last_name && (
                <Form.Text className="text-danger">
                  {formik.errors.last_name}
                </Form.Text>
              )}
            </Form.Group>
          </Div15>
        </Div13>
        <Div13>
          <Div14>Gender</Div14>
          <Div15>
            <Form.Group className="w-100">
              <Form.Control
                as="select"
                name="gender"
                onChange={formik.handleChange}
                value={formik.values.gender}
                className="form-control-lg rounded-0"
              >
                <option value="">Select</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </Form.Control>
            </Form.Group>
          </Div15>
        </Div13>
        <Div13>
          <Div14>Dob</Div14>
          <Div15>
            <Form.Group className="w-100">
              <div className="input-group">
                <Form.Control
                  type="date"
                  className="form-control-lg rounded-0"
                  name="dob"
                  value={formik.values.dob}
                  onChange={formik.handleChange}
                />
              </div>
            </Form.Group>
          </Div15>
        </Div13>
        <Div13>
          <Div14>Tag</Div14>
          <Div15>
            <Div28>
              <Div29>umojalinn.com/</Div29>
              <Div30>
                <Div31>{active?.slug}</Div31>
                <Img6
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/65957338-3b87-493b-a7e7-2b5d6f4d50e3?"
                />
              </Div30>
            </Div28>
          </Div15>
        </Div13>
        <Div32 />
        <Div13>
          <Div14>Email</Div14>
          <Div15>
            <Form.Group className="w-100">
              <div className="input-group">
                <Form.Control
                  type="email"
                  className="form-control-lg rounded-0"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
              </div>
            </Form.Group>
          </Div15>
        </Div13>
        <Div13>
          <Div14>
            <Div38>Alternative contact email</Div38>
            <Div39>
              Enter an alternative email if you’d like to be contacted via a
              different email.
            </Div39>
          </Div14>
          <Div15>
            <Div40>
              <Div41>
                <Img7
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/e0d8aab0-99c2-4b86-9d26-77c7ec6a8a10?"
                />
                <Form.Control
                  type="email"
                  className="form-control-lg rounded-0 border-0"
                  name="alternative_email"
                  value={formik.values.alternative_email}
                  onChange={formik.handleChange}
                  placeholder="you@example.com"
                />
              </Div41>
            </Div40>
          </Div15>
        </Div13>
        <Div13>
          <Div14>Phone Number</Div14>
          <Div15>
            <Div45>
              <Div46>
                <select className="border-0 bg-none">
                  <option>US</option>
                  <option>EU</option>
                </select>
              </Div46>
              <Form.Control
                type="phone"
                className="form-control-lg rounded-0 border-0"
                name="phone_number"
                value={formik.values.phone_number}
                onChange={formik.handleChange}
              />
            </Div45>
          </Div15>
        </Div13>
        <Div32 />
        <Div13>
          <Div14>Country</Div14>
          <Div15>
            <Form.Group className="w-100">
              <Form.Control
                as="select"
                name="country"
                onChange={formik.handleChange}
                value={formik.values.country}
                className="form-control-lg rounded-0"
              >
                <option value="">Select</option>
                <option value="Ireland">Ireland</option>
                <option value="Nigeria">Nigeria</option>
              </Form.Control>
            </Form.Group>
          </Div15>
        </Div13>
        <Div13>
          <Div14>State / Province</Div14>
          <Div15>
            <Form.Group className="w-100">
              <Form.Control
                type="text"
                className="form-control-lg rounded-0"
                name="state"
                value={formik.values.state}
                onChange={formik.handleChange}
              />
            </Form.Group>
          </Div15>
        </Div13>
        <Div13>
          <Div14>City</Div14>
          <Div15>
            <Form.Group className="w-100">
              <Form.Control
                as="select"
                name="city"
                onChange={formik.handleChange}
                value={formik.values.city}
                className="form-control-lg rounded-0"
              >
                <option value="">Select</option>
                <option value="Lagos">Lagos</option>
                <option value="Dublin">Dublin</option>
              </Form.Control>
            </Form.Group>
          </Div15>
        </Div13>
        <Div13>
          <Div14>Zip Code</Div14>
          <Div15>
            <Form.Group className="w-100">
              <Form.Control
                type="text"
                className="form-control-lg rounded-0"
                name="zip_code"
                value={formik.values.zip_code}
                onChange={formik.handleChange}
              />
            </Form.Group>
          </Div15>
        </Div13>
        <Div13>
          <Div14>Home Address</Div14>
          <Div15>
            <Form.Group className="w-100">
              <Form.Control
                type="text"
                className="form-control-lg rounded-0"
                name="address"
                value={formik.values.address}
                onChange={formik.handleChange}
              />
            </Form.Group>
          </Div15>
        </Div13>
        <Div68>
          <Div32 />
          <Div69 style={{ cursor: "pointer" }}>Edit</Div69>
        </Div68>
        <Div70 />
      </Form>
    </>
  );
};

export default MyDetailsTab;

const Div13 = styled.div`
  align-items: start;
  align-self: stretch;
  display: flex;
  margin-top: 20px;
  width: 100%;
  padding-right: 158px;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
    padding-right: 20px;
  }
`;

const Div14 = styled.div`
  color: var(--gray-700, #344054);
  font: 500 14px/143% Inter, sans-serif;
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
`;

const Div15 = styled.div`
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: row;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
    padding-right: 20px;
  }
`;

const Div28 = styled.div`
  align-items: center;
  align-self: stretch;
  border: 1px solid var(--gray-300, #d0d5dd);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  background-color: var(--color-neutrals-00, #fff);
  display: flex;
  padding-left: 14px;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Div29 = styled.div`
  color: var(--gray-600, #475467);
  margin: auto 0;
  font: 400 16px/150% Inter, sans-serif;
`;

const Div30 = styled.div`
  align-self: stretch;
  border: 1px solid var(--gray-300, #d0d5dd);
  background-color: var(--color-neutrals-00, #fff);
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding: 10px 14px;
`;

const Div31 = styled.div`
  color: var(--gray-500, #667085);
  flex-grow: 1;
  flex-basis: auto;
  font: 400 16px/150% Inter, sans-serif;
`;

const Img6 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 16px;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
`;

const Div32 = styled.div`
  align-self: stretch;
  background-color: var(--gray-200, #eaecf0);
  display: flex;
  min-height: 1px;
  margin-top: 20px;
  width: 100%;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div38 = styled.div`
  color: var(--gray-700, #344054);
  font: 500 14px/143% Inter, sans-serif;
`;

const Div39 = styled.div`
  color: var(--gray-600, #475467);
  font: 400 14px/143% Inter, sans-serif;
`;

const Div40 = styled.div`
  align-self: start;
  border: 1px solid var(--gray-300, #d0d5dd);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  background-color: var(--color-neutrals-00, #fff);
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  padding: 0 0 0 10px;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div41 = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Img7 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
  overflow: hidden;
  max-width: 100%;
  margin: auto 0;
`;

const Div45 = styled.div`
  align-self: stretch;
  border: 1px solid var(--gray-300, #d0d5dd);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  background-color: var(--color-neutrals-00, #fff);
  display: flex;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
  padding: 0 0 0 14px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Div46 = styled.div`
  justify-content: space-between;
  display: flex;
  gap: 4px;
`;

const Div68 = styled.div`
  align-self: stretch;
  display: flex;
  margin-top: 20px;
  width: 100%;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div69 = styled.div`
  color: var(--gray-700, #344054);
  white-space: nowrap;
  justify-content: center;
  border: 1px solid var(--gray-300, #d0d5dd);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  background-color: var(--base-white, #fff);
  align-self: end;
  margin-top: 20px;
  aspect-ratio: 1.475;
  padding: 10px 16px;
  font: 600 14px/143% Inter, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div70 = styled.div`
  align-self: stretch;
  background-color: var(--gray-200, #eaecf0);
  display: flex;
  min-height: 1px;
  margin-top: 24px;
  width: 100%;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
