import React, { useState, useEffect } from "react";
import {
  InputGroup,
  FormControl,
  Dropdown,
  DropdownButton,
  Row,
  Form,
  Col,
} from "react-bootstrap";
import styled from "styled-components";
import { TextField, Autocomplete, Chip } from "@mui/material";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "react-datepicker/dist/react-datepicker.css";
import { createClothingType, list } from "services/clothingTypeService";
import { listCities, listCountries } from "services/commonService";
import { listSizingTemplates } from "services/sizingTemplateService";
import { useSelector } from "react-redux";
import withMaterialUITheme from "hoc/withMaterialTheme";
import * as Yup from "yup";
import { Formik, Form as FormikForm } from "formik";
import Loader from "common/Loader";
import { useNavigate } from "react-router-dom";

const validationSchema = Yup.object().shape({
  title: Yup.string().required("Name is required"),
  gender: Yup.string().required("Gender is required"),
  clothing_type: Yup.array()
    .max(8, "selected value can't be more than 8")
    .required("Clothing Type is required"),
  about: Yup.string().required("Brief Description about project required"),
  country: Yup.string().required("Country is required"),
  city: Yup.string().required("City is required"),
  zip_code: Yup.string().required("Zip Code is required"),
  state: Yup.string().required("State is required"),
  address: Yup.string().required("Address is required"),
  sizing_template: Yup.string(),
  end_date: Yup.date()
    .required("End Date is required")
    .min(new Date(), "End Date must be in the future"),
});

const validationSchemaGroup = Yup.object().shape({
  title: Yup.string().required("Name is required"),
  gender: Yup.string().required("Gender is required"),
  clothing_type: Yup.array()
    .max(8, "selected value can't be more than 8")
    .required("Clothing Type is required"),
  about: Yup.string().required("Brief Description about project required"),

  end_date: Yup.date()
    .required("End Date is required")
    .min(new Date(), "End Date must be in the future"),
});

const DescriptionStepper = ({ prev, next, data, onSave, loading, isLoading}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedState, setState] = useState("");
  const [states, setStates] = useState([]);
  const [selectedCountry, setCountry] = useState("");
  const [sizing_templates, setSizingTemplates] = useState([]);
  const [clothing_types, setClothingTypes] = useState([]);
  const { active } = useSelector((state) => state.account);
  const navigate = useNavigate();


  const handleToggleChange = () => {
    setIsChecked(!isChecked);
  };

  const getClothingTypes = async () => {
    try {
      isLoading(true);

      const resp = await list();
      isLoading(false);

      setClothingTypes(resp);
    } catch (err) {
      isLoading(false);

      console.error(err);
    }
  };

  const getCountries = async () => {
    try {
      isLoading(true);
      const { data } = await listCountries();
      isLoading(false);
      setCountries(data);
    } catch (err) {
      isLoading(false);
      console.error(err);
    }
  };

  const getCitiesByStateId = async (country, state) => {
    try {
      console.log(country, state);
      const { data } = await listCities(country, state);
      setCities(data);
    } catch (err) {
      console.log(err);
    }
  };

  const getSizingTemplates = async () => {
    try {
      isLoading(true);

      const resp = await listSizingTemplates(active?.id);
      isLoading(false);

      setSizingTemplates(resp);
    } catch (err) {
      isLoading(false);

      console.error(err);
    }
  };

  const handleClothingTypeChange = async (_, value, reason, setFieldValue) => {
    try {
      if (value.length > 8) return value.pop();
      if (_.key === "Enter" && reason === "createOption") {
        if (
          !clothing_types.some(
            (selectedType) => selectedType.name === _.target.value.trim()
          )
        ) {
          const response = await createClothingType(_.target.value.trim());
          setClothingTypes([...clothing_types, response]);
          value.pop();
          value.push(response);
        } else {
          const found = clothing_types.find(
            (type) => type.name === _.target.value
          );
          value.pop();
          value.push(found);
        }
      }

      setFieldValue("clothing_type", value);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getClothingTypes();
    getCountries();
    getSizingTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if ((selectedCountry || data.country) && countries.length) {
      setStates(
        countries.find(
          (country) => country.name === (selectedCountry || data.country)
        ).states
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry, data.country, countries]);

  useEffect(() => {
    console.log(selectedState, data.state);
    if (selectedState || data.state) {
      getCitiesByStateId(
        selectedCountry || data.country,
        selectedState || data.state
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedState, data.state]);

  const handleSubmit = (values) => {
    next(values);
  };

  const handleChipDelete = (chipToDelete, setFieldValue) => () => {
    // const updatedChips = selectedClothingTypes.filter(
    //   (chip) => chip.id !== chipToDelete.id
    // );
    // setFieldValue("clothing_type", updatedChips);
    // setSelectedClothingTypes(updatedChips);
  };

  if (loading) return <Loader />;
  return (
    <Formik
      initialValues={data}
      onSubmit={handleSubmit}
      validationSchema={
        data.project_type === "solo" ? validationSchema : validationSchemaGroup
      }
    >
      {({
        values,
        errors,
        touched,
        dirty,
        isValid,
        setFieldValue,
        handleChange,
      }) => (
        <FormikForm>
          <div className="lg:tw-px-[32px] lg:tw-pt-[32px]">
            <Div13>
              <Div14>
                <Div15>
                  <Column>
                    <Div16>
                      <A10 href="#">Title</A10>
                      <Div17>Project name</Div17>
                    </Div16>
                  </Column>
                  <Column2>
                    <InputGroup size="lg" className="tw-flex tw-items-center">
                      <FormControl
                        placeholder="name"
                        name="title"
                        onChange={handleChange}
                        value={values.title}
                        type="text"
                        className="rounded-0 form-control"
                      />
                      <DropdownButton
                        variant="outline-warning"
                        title={
                          <span className="text-capitalize tw-text-gray-700">
                            {values.gender}
                          </span>
                        }
                        align="end"
                        id="gender"
                        name="gender"
                        value={values.gender}
                        className="remove-border-radius"
                        onSelect={(value)=> {setFieldValue('gender', value)}}
                      >
                        <Dropdown.Item eventKey="Male" href="#">
                          Male
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="Female" href="#">
                          Female
                        </Dropdown.Item>
                      </DropdownButton>
                      {errors.title && touched.title && (
                        <Form.Text className="text-danger !tw-ml-2">
                          {errors.title}
                        </Form.Text>
                      )}
                      {errors.gender && touched.gender && (
                        <Form.Text className="text-danger !tw-ml-2">
                          {errors.gender}
                        </Form.Text>
                      )}
                    </InputGroup>
                    <A9 href="#">8/15 characters</A9>
                  </Column2>
                </Div15>
              </Div14>
            </Div13>
            <Div13>
              <Div14>
                <Div15>
                  <Column>
                    <Div16>
                      <A10 href="#">About Project</A10>
                      <Div17>
                        Tell the designer what you want and how you want it done
                      </Div17>
                    </Div16>
                  </Column>
                  <Column2>
                    <InputGroup size="lg">
                      <FormControl
                        as="textarea"
                        className="rounded-0"
                        rows={5}
                        name="about"
                        onChange={handleChange}
                        value={values.about}
                      />
                    </InputGroup>
                    {errors.about && touched.about && (
                      <Form.Text className="text-danger">
                        {errors.about}
                      </Form.Text>
                    )}
                  </Column2>
                </Div15>
              </Div14>
            </Div13>
            <Div13>
              <Div14>
                <Div15>
                  <Column>
                    <Div16>
                      <A10 href="#">Select Categories</A10>
                      <Div17>Choose clothing type</Div17>
                    </Div16>
                  </Column>
                  <Column2>
                    <Autocomplete
                      multiple
                      id="clothing_type"
                      freeSolo
                      value={values.clothing_type}
                      isOptionEqualToValue={(option, value) =>
                        (option.name || option) === value?.name
                      }
                      options={clothing_types}
                      getOptionLabel={(option) => option?.name || option}
                      onChange={(e, value, reason) =>
                        handleClothingTypeChange(
                          e,
                          value,
                          reason,
                          setFieldValue
                        )
                      }
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            key={index}
                            label={option?.name || option}
                            onDelete={() =>
                              handleChipDelete(option, setFieldValue)
                            }
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          rows={4}
                          placeholder="Type and Press Enter"
                          variant="outlined"
                        />
                      )}
                    />
                    <A9 href="#">{values.clothing_type.length}/8</A9>
                    {errors.clothing_type && touched.clothing_type && (
                      <Form.Text className="text-danger">
                        {errors.clothing_type}
                      </Form.Text>
                    )}
                  </Column2>
                </Div15>
              </Div14>
            </Div13>
            <Div13>
              <Div14>
                <Div15>
                  <Column>
                    <Div16>
                      <A10 href="#">Project due date</A10>
                      <Div17>Choose a project end date</Div17>
                    </Div16>
                  </Column>
                  <Column2>
                    <div style={{ width: "50%" }}>
                      <Form.Group>
                        <div className="input-group">
                          <Form.Control
                            type="date"
                            className="form-control-lg rounded-0"
                            name="end_date"
                            value={values.end_date}
                            placeholder="select dates"
                            onChange={handleChange}
                          />
                        </div>
                      </Form.Group>
                      {errors.end_date && touched.end_date && (
                        <Form.Text className="text-danger">
                          {errors.end_date}
                        </Form.Text>
                      )}
                    </div>
                  </Column2>
                </Div15>
              </Div14>
            </Div13>
            {values.project_type === "group" && (
              <Div13>
                <Div14>
                  <Div15>
                    <Column>
                      <Div16>
                        <A10 href="#">Additional note</A10>
                        <Div17>
                          Include confidential notes intended for collaborators
                          only.
                        </Div17>
                      </Div16>
                    </Column>
                    <Column2>
                      <InputGroup size="lg">
                        <FormControl
                          as="textarea"
                          className="rounded-0"
                          rows={5}
                          name="additional_note"
                          onChange={handleChange}
                          value={values.additional_note}
                        />
                      </InputGroup>
                    </Column2>
                  </Div15>
                </Div14>
              </Div13>
            )}
            {values.project_type === "solo" && (
              <Div13>
                <Div14>
                  <Div15>
                    <Column>
                      <Div16>
                        <A10 href="#">Delivery details</A10>
                        <Div17>
                          Provide the necessary information to make your
                          delivery fast and efficient
                        </Div17>
                      </Div16>
                    </Column>
                    <Column2>
                      <Row>
                        <Col>
                          <select
                            name="country"
                            onChange={(e) => {
                              setCountry(e.target.value);
                              handleChange(e);
                            }}
                            value={values.country}
                            className="form-select form-select-lg rounded-0 !tw-text-gray-600"
                          >
                            <option>Select country</option>
                            {countries &&
                              countries.map((country, index) => (
                                <option key={index} value={country.id}>
                                  {country.name}
                                </option>
                              ))}
                          </select>
                          {errors.country && touched.country && (
                            <Form.Text className="text-danger">
                              {errors.country}
                            </Form.Text>
                          )}
                        </Col>
                      </Row>
                      {states && states.length > 0 && (
                        <Row className="mt-3">
                          <Col>
                            <select
                              name="state"
                              onChange={(e) => {
                                handleChange(e);
                                setState(e.target.value);
                              }}
                              value={values.state}
                              className="form-select form-select-lg rounded-0 !tw-text-gray-600"
                            >
                              <option>Select State</option>
                              {states.map((state, i) => (
                                <option key={i} value={state.name}>
                                  {state.name}
                                </option>
                              ))}
                            </select>
                            {errors.state && touched.state && (
                              <Form.Text className="text-danger">
                                {errors.state}
                              </Form.Text>
                            )}
                          </Col>
                        </Row>
                      )}
                      <Row className="mt-3">
                        <Col>
                          <select
                            name="city"
                            onChange={handleChange}
                            value={values.city}
                            className="form-select form-select-lg rounded-0 !tw-text-gray-600"
                          >
                            <option value="">Province/City</option>
                            {cities.map((city, i) => (
                              <option key={i} value={city}>
                                {city}
                              </option>
                            ))}
                          </select>
                          {errors.city && touched.city && (
                            <Form.Text className="text-danger">
                              {errors.city}
                            </Form.Text>
                          )}
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Control
                              name="zip_code"
                              onChange={handleChange}
                              value={values.zip_code}
                              className="form-control-lg rounded-0 !tw-text-gray-600"
                              type="number"
                              placeholder="Zip code"
                            />
                          </Form.Group>
                          {errors.zip_code && touched.zip_code && (
                            <Form.Text className="text-danger">
                              {errors.zip_code}
                            </Form.Text>
                          )}
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col>
                          <Form.Group>
                            <Form.Control
                              name="address"
                              onChange={handleChange}
                              value={values.address}
                              className="form-control-lg rounded-0"
                              type="text"
                              placeholder="House Address"
                            />
                          </Form.Group>
                          {errors.address && touched.address && (
                            <Form.Text className="text-danger">
                              {errors.address}
                            </Form.Text>
                          )}
                        </Col>
                      </Row>
                    </Column2>
                  </Div15>
                </Div14>
              </Div13>
            )}
          </div>
          <div className="lg:tw-px-[32px]">
            {values.project_type === "solo" && (
              <Div6>
                <Div50>
                  <Div51>
                    <Div52>
                      <A31 href="#">Sizing template</A31>
                      <Div53>Choose appropriate sizing templates</Div53>
                    </Div52>
                    <Div54>
                      <Form.Check
                        type="switch"
                        id="toggle-switch"
                        label=""
                        checked={isChecked}
                        onChange={handleToggleChange}
                      />

                      <Div55 className="tw-flex tw-items-center">
                        <Img11
                          loading="lazy"
                          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/d9ea5419-f542-4756-b3fc-1d73e4ff85e3?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/d9ea5419-f542-4756-b3fc-1d73e4ff85e3?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d9ea5419-f542-4756-b3fc-1d73e4ff85e3?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/d9ea5419-f542-4756-b3fc-1d73e4ff85e3?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/d9ea5419-f542-4756-b3fc-1d73e4ff85e3?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d9ea5419-f542-4756-b3fc-1d73e4ff85e3?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/d9ea5419-f542-4756-b3fc-1d73e4ff85e3?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/d9ea5419-f542-4756-b3fc-1d73e4ff85e3?"
                        />
                        <p className="tw-text-sm-regular tw-text-[#475467]">
                          Include Sizing template in your Project description or
                          at project start
                        </p>
                      </Div55>
                      {isChecked && (
                        <select
                          name="sizing_template"
                          onChange={handleChange}
                          value={values.sizing_template}
                          className="form-select form-select-lg rounded-0"
                        >
                          <option>Select template</option>
                          {sizing_templates.map((option, index) => (
                            <option key={index} value={option.id}>
                              {option.name}
                            </option>
                          ))}
                        </select>
                      )}
                    </Div54>
                  </Div51>
                </Div50>
              </Div6>
            )}
          </div>

          {/* Back & Next Button */}
          <div className="tw-px-1">
            <hr className="tw-mt-10 tw-text-[#EAECF0]" />
            <div className="tw-flex tw-justify-between tw-my-[20px]">
              <button
                className="tw-text-sm-semi-bold"
                onClick={() => prev(values, true)}
                type="button"
              >
                Back
              </button>
              <div className="tw-flex tw-gap-[12px]">
                {/* <button
                type="button"
                  className="tw-text-sm-semi-bold tw-border tw-border-[#EAECF0] tw-py-[10px] tw-px-[16px] tw-text-[#D0D5DD] tw-hidden lg:tw-block"
                  onClick={() =>
                    onSave(values, () => {
                      navigate("/workspace/projects");
                    })
                  }
                  
                >
                {loading? 'Saving' : ' Save & exit'}
                </button> */}
                <button
                  className="tw-text-sm-semi-bold tw-py-[10px] tw-px-[16px] tw-bg-yellow-500 tw-text-white"
                  type="submit"
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </FormikForm>
      )}
    </Formik>
  );
};
export default withMaterialUITheme()(DescriptionStepper);

const Div6 = styled.div`
  align-items: flex-start;
  width: 100%;
  max-width: 1064px;
  align-self: center;
  display: flex;
  flex-direction: column;
`;

const A9 = styled.a`
  align-self: start;
  color: var(--gray-600, #475467);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  margin-top: 10px;
  margin-left: 1px;
  margin-bottom: 1px;
  @media (max-width: 991px) {
    margin-left: 1px;
  }
`;

const Div13 = styled.div`
  align-items: flex-start;
  margin-top: 19px;
  padding-top: 2px;
  padding-right: 20px;
  padding-bottom: 2px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
`;

const Div14 = styled.div`
  width: 100%;
  max-width: 824px;
  margin-top: -1px;
  margin-bottom: 1px;
  align-self: start;
  display: flex;
  flex-direction: column;
`;

const Div15 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 35%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div16 = styled.div`
  align-items: flex-start;
  padding-top: 3px;
  padding-left: 0px;
  padding-right: 9px;
  padding-bottom: 3px;
  display: flex;
  flex-direction: column;
  @media (max-width: 991px) {
    margin-top: 32px;
  }
`;

const A10 = styled.a`
  align-self: start;
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: 1px;
`;

const Div17 = styled.div`
  align-self: stretch;
  color: var(--gray-600, #475467);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  margin-top: 7px;
  margin-left: 0px;
  margin-bottom: -1px;
  @media (max-width: 991px) {
    margin-left: 0px;
  }
`;

const Column2 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 65%;
  ${"" /* margin-left: 20px; */}
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div50 = styled.div`
  align-items: flex-start;
  align-self: stretch;
  margin-top: 17px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
`;

const Div51 = styled.div`
  width: 100%;
  max-width: 822px;
  align-self: start;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Div52 = styled.div`
  align-items: flex-start;
  padding-top: 3px;
  padding-left: 1px;
  padding-right: 20px;
  padding-bottom: 3px;
  align-self: start;
  display: flex;
  flex-direction: column;
  min-width: 280px;
  flex-grow: 1;
`;

const A31 = styled.a`
  align-self: start;
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: 1px;
`;

const Div53 = styled.div`
  align-self: start;
  color: var(--gray-600, #475467);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  margin-top: 6px;
  margin-left: 0px;
  margin-bottom: -1px;
  @media (max-width: 991px) {
    margin-left: 0px;
  }
`;

const Div54 = styled.div`
  align-items: flex-start;
  padding-top: 2px;
  padding-bottom: 2px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  min-width: 300px;
  flex-grow: 1.8214285714285714;
`;

const Div55 = styled.div`
  align-items: flex-start;
  align-self: stretch;
  border-radius: 4px;
  background: var(--gray-200, #eaecf0);
  margin-top: 12px;
  margin-bottom: 2px;
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 20px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: row;
  gap: 13.234375px;
  flex-wrap: wrap;
`;

const Img11 = styled.img`
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 24px;
  align-self: stretch;
`;
