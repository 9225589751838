import { reducerMultiplexer } from "constants/Utils";
import {
  ADD_MILESTONE,
  DELETE_MILESTONE,
  milestones,
  SET_ACTIVE_MILESTONE,
  SET_MILESTONE,
  UPDATE_MILESTONE,
  CREATE_MILESTONE,
} from "./actions";

export default function milestoneReducer(
  milestoneState = milestones,
  milestoneAction
) {
  return reducerMultiplexer({
    [ADD_MILESTONE]: (state, action) => {
      const milestones = [...state.milestones];
      const insertionIndex = Math.max(milestones.length - 1, 0);

      milestones.splice(insertionIndex, 0, {
        name: "",
        is_approved: false,
        is_funded: false,
        is_delivery_milestone: false,
        description: "",
        amount: 0,
        currency: "",
        start_date: "",
        end_date: "",
        milestone_gallery: [{ image_url: "" }],
        saved: false,
      });
      return {
        ...state,
        milestones,
        activeMilestone: ++state.activeMilestone,
      };
    },
    [SET_MILESTONE]: (state, action) => {
      const milestones = action.data.milestones.map((milestone) => {
        return { ...milestone };
      });
      return {
        ...state,
        milestones,
        activeMilestone: milestones.length,
      };
    },

    [SET_ACTIVE_MILESTONE]: (state, action) => {
      return {
        ...state,
        activeMilestone: action.data,
      };
    },
    [CREATE_MILESTONE]: (state, action) => {
      return { ...state, milestones: milestones.milestones };
    },

    [UPDATE_MILESTONE]: (state, action) => {
      return {
        ...state,
        milestones: state.milestones.map((milestone, index) => {
          if (index === action.index) {
            return action.data;
          }
          return milestone;
        }),
      };
    },
    [DELETE_MILESTONE]: (state, action) => {
      let activeMilestone = state.activeMilestone;
      const lastMilestoneIndexAfterDeletion = state.milestones.length - 2;

      if (activeMilestone > lastMilestoneIndexAfterDeletion) {
        activeMilestone = lastMilestoneIndexAfterDeletion;
      }
      return {
        ...state,
        milestones: state.milestones.filter(
          (milestone, index) => index !== action.data
        ),
        activeMilestone,
      };
    },
  })(milestoneState, milestoneAction);
}
