import React, { useState, useEffect } from "react";
import { details } from "services/keywordService";

const fetchTagDetail = async (id) => {
  try {
    const response = await details(id);
    return response; // Adjust according to the response structure
  } catch (error) {
    console.error("Error fetching tag detail:", error);
    return null;
  }
};

const useTagDetails = (tagIds) => {
  const [tagDetails, setTagDetails] = useState([]);

  useEffect(() => {
    const fetchAllTagDetails = async () => {
      const fetchedTagDetails = await Promise.all(tagIds.map(fetchTagDetail));
      setTagDetails(fetchedTagDetails.filter((detail) => detail !== null));
    };

    fetchAllTagDetails();
  }, [tagIds]);

  return tagDetails;
};

export default function PortfolioCard({ image, tags, description }) {
  const tagDetails = useTagDetails(tags);
  return (
    <div className="tw-relative ">
      <img
        src={image}
        alt=""
        className="tw-w-full tw-h-[200px] tw-object-cover"
      />
      <div className="tw-mt-[32px]">
        <p>{description}</p>

        <div className="tw-flex tw-gap-[8px] tw-mt-[24px] tw-flex-wrap">
          {tagDetails.map((tag, i) => {
            return (
              <div
                key={i}
                className="tw-rounded-[16px] tw-bg-[#FEFBE8] tw-border-yellow-400 tw-border tw-py-[2px] tw-px-[10px]"
              >
                {tag.value}
              </div>
            );
          })}
        </div>
      </div>
      <ActionIcon />
    </div>
  );
}

function ActionIcon() {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  return (
    <div className="tw-absolute tw-top-[8px] tw-right-[19px] tw-cursor-pointer tw-z-999 tw-flex tw-flex-col">
      <svg
        width="4"
        height="18"
        viewBox="0 0 4 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={toggleDropdown}
      >
        <path
          d="M2 10C2.55228 10 3 9.55228 3 9C3 8.44772 2.55228 8 2 8C1.44772 8 1 8.44772 1 9C1 9.55228 1.44772 10 2 10Z"
          stroke="#EAAA08"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M2 3C2.55228 3 3 2.55228 3 2C3 1.44772 2.55228 1 2 1C1.44772 1 1 1.44772 1 2C1 2.55228 1.44772 3 2 3Z"
          stroke="#EAAA08"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M2 17C2.55228 17 3 16.5523 3 16C3 15.4477 2.55228 15 2 15C1.44772 15 1 15.4477 1 16C1 16.5523 1.44772 17 2 17Z"
          stroke="#EAAA08"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      {isDropdownVisible && (
        <div className="dropdown tw-w-[200px] tw-bg-white tw-border tw-border-[#EAECF0] tw-absolute tw-z-9999 -tw-bottom-28 tw-flex tw-flex-col">
          <button className="tw-py-[2px] tw-px-[6px] tw-h-[42px]">Edit</button>
          <button className="tw-py-[2px] tw-px-[6px] tw-h-[42px]">
            Delete
          </button>
        </div>
      )}
    </div>
  );
}
