import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { rejectBid } from "services/bidService";
import CustomButton from "components/Buttons/Button";
import { useNavigate } from "react-router-dom";

const RejectProposalModal = (props) => {
  const [message, setMessage] = useState("");
  const [isPending, setIsPending] = useState(false);
  const navigate = useNavigate();

  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSubmitReason = async () => {
    try {
      setIsPending(true);
      await rejectBid(props.bid.id, {
        rejection_reason: message,
      });
      navigate("/workspace/projects");
    } catch (err) {
    } finally {
      setIsPending(false);
    }
  };

  return (
    <div className="tw-p-[24px]">
      <div className="tw-mb-[20px]">
        <div className="tw-flex tw-justify-between">
          <h5 className="tw-text-lg-semi-bold tw-text-[#101828]">Rationale</h5>
          <span>X</span>
        </div>
        <p className="tw-text-sm-regular tw-text-[#475467]">
          Please provide the rationale for your decision to decline the
          designer's proposal
        </p>
      </div>{" "}
      <label className="tw-text-sm-medium tw-text-[#344054] tw-mb-[6px]">
        Specify your reason
      </label>
      <Form.Control
        as="textarea"
        rows={5}
        className="rounded-0"
        value={message}
        onChange={handleChange}
      />
      <div className="tw-mt-[32px] tw-w-full">
        <CustomButton
          cls="tw-px-4 tw-w-full"
          isLoading={isPending}
          type={"submit"}
          disabled={isPending}
          click={handleSubmitReason}
        >
          Reject Proposal
        </CustomButton>
      </div>
    </div>
  );
};

export default RejectProposalModal;

