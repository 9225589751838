import React, { useState, useEffect } from "react";

const StagingAuthPage = ({ onAuthenticated }) => {
  const [password, setPassword] = useState("");
  const savedPassword = localStorage.getItem("authPassword");

  const correctPassword = "UmojaLinn2024";

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (password === correctPassword) {
      localStorage.setItem("authPassword", password);
      onAuthenticated();
    } else {
      alert("Incorrect password. Please try again.");
    }
  };

  useEffect(() => {
    if (savedPassword) {
      onAuthenticated();
    }
  }, [savedPassword, onAuthenticated]);

  return (
    <div className="container">
      {savedPassword ? (
        <p>You are authenticated.</p>
      ) : (
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <form onSubmit={handlePasswordSubmit} className="mt-5">
              <label>Password:</label>
              <input
                type="password"
                value={password}
                className="form-control"
                onChange={handlePasswordChange}
              />
              <button className="mt-3 btn btn-warning" type="submit">
                Submit
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default StagingAuthPage;
