/* eslint-disable no-script-url */
import { getCurrencySymbol } from "constants/Utils";
import * as React from "react";
import styled from "styled-components";
import "./../Project/Styles/ListProjects.css";

const ProjectEscrow = (props) => {
  const acceptedBid = props.bids.filter(
    (bid) => bid.is_accepted && bid.project.id === props.project.id
  )[0];
  return (
    <Div>
      <Div2>
        <Div3>
          <A href="javascript:void()">Project Escrow</A>
          <Img
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/b4b2b88c-f9b7-49ac-bd5e-903cb3af1a40?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/b4b2b88c-f9b7-49ac-bd5e-903cb3af1a40?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b4b2b88c-f9b7-49ac-bd5e-903cb3af1a40?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/b4b2b88c-f9b7-49ac-bd5e-903cb3af1a40?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/b4b2b88c-f9b7-49ac-bd5e-903cb3af1a40?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b4b2b88c-f9b7-49ac-bd5e-903cb3af1a40?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/b4b2b88c-f9b7-49ac-bd5e-903cb3af1a40?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/b4b2b88c-f9b7-49ac-bd5e-903cb3af1a40?"
          />
        </Div3>
        <Div4>
          {acceptedBid.milestones
            .slice()
            .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
            .map((milestone, index) => (
              <>
                {milestone.status === "completed" ? (
                  <Div5 key={index} />
                ) : (
                  <Div8 />
                )}
              </>
            ))}
        </Div4>
        <Div9>
          <Div10>
            <Div11>
              <A2 href="javascript:void()">Paid Out</A2>
            </Div11>
            <A3 href="javascript:void()">
              {`${getCurrencySymbol(
                JSON.parse(props.project?.budget)?.currency
              )}`}
              0
            </A3>
          </Div10>
          {acceptedBid.milestones
            .slice()
            .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
            .map((milestone, index) => (
              <Div12 key={index}>
                <A4 href="javascript:void()">{milestone.name}</A4>
                <A5
                  href="javascript:void()"
                  className={
                    milestone.status === "completed" ? "crossed-out-text" : ""
                  }
                >
                  {`${getCurrencySymbol(
                    JSON.parse(props.project?.budget)?.currency
                  )}`}
                  {milestone.amount}
                </A5>
              </Div12>
            ))}
          <Div12>
            <A4 href="javascript:void()">Delivery Milestone</A4>
            <A5 href="javascript:void()">
              {`${getCurrencySymbol(
                JSON.parse(props.project?.budget)?.currency
              )}`}
              0
            </A5>
          </Div12>
        </Div9>
        <Div13>
          <Div14>
            <A6 href="javascript:void()">Escrow Balance</A6>
          </Div14>
          <A7 href="javascript:void()">_ _ _</A7>
        </Div13>
        <Div16>
          <Div17>
            <A8 href="javascript:void()">Project Price</A8>
          </Div17>
          <A3 href="javascript:void()">
            {`${getCurrencySymbol(
              JSON.parse(props.project?.budget)?.currency
            )}`}
            {`${getCurrencySymbol(JSON.parse(props.project?.budget)?.amount)}`}
          </A3>
        </Div16>
        <A10 href="javascript:void()">Invoice</A10>
        <Div19>
          <Img3
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/1fe119e7-8d62-42e8-a924-ad7d3bd72d7d?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/1fe119e7-8d62-42e8-a924-ad7d3bd72d7d?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1fe119e7-8d62-42e8-a924-ad7d3bd72d7d?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/1fe119e7-8d62-42e8-a924-ad7d3bd72d7d?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/1fe119e7-8d62-42e8-a924-ad7d3bd72d7d?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1fe119e7-8d62-42e8-a924-ad7d3bd72d7d?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/1fe119e7-8d62-42e8-a924-ad7d3bd72d7d?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/1fe119e7-8d62-42e8-a924-ad7d3bd72d7d?"
          />
          <Div20>
            "Upon milestone approval, funds are deposited into the designer's
            wallet."
          </Div20>
        </Div19>
      </Div2>
    </Div>
  );
};

export default ProjectEscrow;

const Div = styled.div`
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  border-radius: 12px;
  background: var(--gray-50, #f9fafb);
  width: 100%;
  max-width: 368px;
  padding-top: 24px;
  padding-left: 18px;
  padding-right: 18px;
  padding-bottom: 24px;
  align-self: end;
  display: flex;
  flex-direction: column;
`;

const Div2 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
`;

const Div3 = styled.div`
  margin-left: 1px;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 331px;
  @media (max-width: 991px) {
    margin-left: 1px;
  }
`;

const A = styled.a`
  color: var(--gray-900, #101828);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  margin-top: 2px;
  align-self: center;
  text-align: center;
  min-width: 114px;
`;

const Img = styled.img`
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 24px;
  align-self: stretch;
  min-width: 24px;
`;

const Div4 = styled.div`
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-top: 24px;
  padding-right: 20px;
  align-self: start;
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
  @media (max-width: 991px) {
    justify-content: center;
  }
`;

const Div5 = styled.div`
  flex: 1 0 0;
  border-radius: 3px;
  background: var(--success-400, #32d583);
  align-self: stretch;
  display: flex;
  flex-direction: column;
  min-height: 6px;
`;

const Div8 = styled.div`
  flex: 1 0 0;
  border-radius: 3px;
  background: var(--gray-100, #f2f4f7);
  mix-blend-mode: multiply;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  min-height: 6px;
`;

const Div9 = styled.div`
  align-items: flex-end;
  width: 100%;
  max-width: 326px;
  margin-top: 24px;
  align-self: start;
  display: flex;
  flex-direction: column;
`;

const Div10 = styled.div`
  justify-content: space-between;
  align-items: flex-start;
  padding-right: 20px;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
  max-width: 326px;
`;

const Div11 = styled.div`
  align-items: flex-start;
  padding-right: 20px;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: 12.234375px;
  flex-wrap: wrap;
  min-width: 88px;
`;



const A2 = styled.a`
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  margin-top: -0px;
  align-self: center;
  text-align: center;
`;

const A3 = styled.a`
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: 1px;
  align-self: center;
  text-align: center;
  min-width: 55px;
`;

const Div12 = styled.div`
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 295px;
  padding-top: 3px;
  padding-left: 1px;
  padding-right: 20px;
  padding-bottom: 3px;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
`;

const A4 = styled.a`
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  margin-top: 1px;
  align-self: stretch;
  min-width: 82px;
`;

const A5 = styled.a`
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  margin-top: 0px;
  align-self: stretch;
  min-width: 10%;
`;

const Div13 = styled.div`
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 326px;
  margin-top: 24px;
  padding-right: 20px;
  align-self: start;
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
`;

const Div14 = styled.div`
  align-items: flex-start;
  padding-right: 20px;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: 12.234375px;
  flex-wrap: wrap;
  min-width: 136px;
`;

const A6 = styled.a`
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  margin-top: -0px;
  align-self: center;
  text-align: center;
`;

const A7 = styled.a`
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: 15px;
  align-self: start;
  min-width: 27px;
`;

const Div16 = styled.div`
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 326px;
  margin-top: 24px;
  margin-bottom: 10px;
  padding-right: 20px;
  align-self: start;
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
`;

const Div17 = styled.div`
  align-items: flex-start;
  padding-right: 20px;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: 12.234375px;
  flex-wrap: wrap;
  min-width: 115px;
`;

const A8 = styled.a`
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  margin-top: 1px;
  align-self: center;
  text-align: center;
`;


const A10 = styled.a`
  justify-content: center;
  align-items: center;
  border: 1px solid var(--gray-300, #d0d5dd);
  background: var(--base-white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  width: 100%;
  max-width: 326px;
  margin-top: -1px;
  padding-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 15px;
  align-self: center;
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  margin-left: -0px;
  margin-bottom: 10px;
  text-align: center;
`;



const Div19 = styled.div`
  align-items: flex-start;
  flex: 1 0 0;
  margin-top: 24px;
  padding-top: 3px;
  padding-right: 20px;
  padding-bottom: 3px;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: 4.375px;
  flex-wrap: wrap;
`;

const Img3 = styled.img`
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 24px;
  align-self: center;
`;

const Div20 = styled.div`
  flex: 1 0 0;
  color: var(--gray-500, #667085);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  margin-top: 1px;
  align-self: stretch;
  flex-grow: 12.061360677083334;
`;
