import React from 'react';

const Rating = ({ rating }) => {
  const fullStars = Math.floor(rating);
  const starArray = Array(5).fill(0);

  return (
    <div className="tw-flex tw-items-center">
      {starArray.map((_, index) => (
        <span 
          key={index} 
          className={`tw-text-2xl ${index < fullStars ? 'tw-text-yellow-400' : 'tw-text-gray-300'}`}
        >
          ★
        </span>
      ))}
      <span className="tw-ml-2 tw-text-2xl tw-font-bold">{rating.toFixed(1)}</span>
    </div>
  );
};

export default Rating;