import * as React from "react";
import styled from "styled-components";

const DraftsTab = ({projects}) => {
  return (
    <Div>
      {projects.length > 0 && projects.map((project, index)=> (
        <Div2 key={index}>
        <Div3>
          <Column>
            <img src={project.cover_image_url} alt="" className="tw-h-[120px] tw-w-full tw-object-cover" />
          </Column>
          <Column2>
            <Div4>
              <Img
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/7b93b764-798c-4358-a610-f3abb57bc373?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/7b93b764-798c-4358-a610-f3abb57bc373?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/7b93b764-798c-4358-a610-f3abb57bc373?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/7b93b764-798c-4358-a610-f3abb57bc373?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/7b93b764-798c-4358-a610-f3abb57bc373?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/7b93b764-798c-4358-a610-f3abb57bc373?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/7b93b764-798c-4358-a610-f3abb57bc373?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/7b93b764-798c-4358-a610-f3abb57bc373?"
              />
              <Div5>
                <Div6>
                  <Div7 className="tw-mb-[16px]">{project.title}</Div7>
                    <A2 href="#">{project.created_at}</A2>
                  <Div8>
                  <Div7>{project.about}</Div7>
                  </Div8>
                </Div6>
                <Div9>
                  <Div10>
                    <Div11 />
                  </Div10>
                  <A4 href="#">40%</A4>
                </Div9>
              </Div5>
            </Div4>
          </Column2>
        </Div3>
      </Div2>
      ))}
    </Div>
  );
}
export default DraftsTab;

const Div = styled.div`
  align-items: flex-start;
  width: 100%;
  padding-top: 6px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 6px;
  align-self: end;
  display: flex;
  flex-direction: column;
`;

const Div2 = styled.div`
  align-self: stretch;
  border: 1px solid var(--gray-200, #eaecf0);
  background: var(--color-neutrals-00, #fff);
  margin-top: 2px;
  padding-top: 8px;
  padding-left: 16px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
`;

const Div3 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 22%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

// const A = styled.a`
//   justify-content: center;
//   align-items: center;
//   background: var(--gray-100, #f2f4f7);
//   margin-top: 14px;
//   padding-top: 40px;
//   padding-left: 20px;
//   padding-right: 20px;
//   padding-bottom: 40px;
//   color: var(--gray-900, #101828);
//   font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
//   font-style: normal;
//   font-weight: 600;
//   font-size: 13px;
//   margin-left: -0px;
//   margin-bottom: 12px;
//   align-self: center;
//   text-align: center;
//   @media (max-width: 991px) {
//     margin-top: 32px;
//   }
// `;

const Column2 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 78%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div4 = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 991px) {
    margin-top: 24px;
  }
`;

const Img = styled.img`
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 100%;
  max-width: 36px;
  margin-bottom: -28px;
  z-index: 1;
  align-self: end;
`;

const Div5 = styled.div`
  align-items: flex-start;
  flex: 1 0 0;
  width: 100%;
  max-width: 809px;
  align-self: start;
  display: flex;
  flex-direction: column;
`;

const Div6 = styled.div`
  padding-right: 20px;
  align-items: flex-start;
  align-self: stretch;
  padding-top: 2px;
  padding-bottom: 2px;
  display: flex;
  flex-direction: column;
`;

const Div7 = styled.div`
  align-self: start;
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: 2px;
  margin-left: 1px;
  @media (max-width: 991px) {
    margin-left: 1px;
  }
`;

const Div8 = styled.div`
  align-items: flex-start;
  align-self: start;
  width: 100%;
  max-width: 777px;
  margin-top: 18px;
  margin-bottom: -2px;
  padding-top: 3px;
  padding-left: 1px;
  padding-right: 20px;
  padding-bottom: 3px;
  display: flex;
  flex-direction: column;
`;

const A2 = styled.a`
  align-self: start;
  color: var(--gray-600, #475467);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: 2px;
`;



const Div9 = styled.div`
  align-items: flex-start;
  align-self: stretch;
  ${'' /* margin-top: 32px; */}
  padding-top: 5px;
  padding-right: 20px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  gap: 12.765625px;
  flex-wrap: wrap;
`;

const Div10 = styled.div`
  border-radius: 4px;
  background: var(--gray-200, #eaecf0);
  margin-top: 1px;
  padding-right: 20px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  flex-grow: 27.5291973361374;
`;

const Div11 = styled.div`
  border-radius: 4px;
  background: var(--primary-500, #eaaa08);
  width: 100%;
  max-width: 309px;
  align-self: start;
  display: flex;
  flex-direction: column;
  min-height: 8px;
`;

const A4 = styled.a`
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: -0px;
  align-self: stretch;
`;
