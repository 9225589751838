import React from "react";
import { Link } from "react-router-dom";

export default function SettingsCard(props) {
  const { path, icon, name, description } = props;
  return (
    <Link
      to={path}
      className="tw-border-2 tw-border-[#D0D5DD] tw-h-[302px] tw-py-[60px] tw-pr-[64px] tw-pl-[32px]"
    >
      {icon}
      <h2 className="tw-text-xl-bold tw-mt-[32px] tw-mb-[16px] tw-text-[#101828]">{name}</h2>
      <p className="tw-text-sm-regular">{description}</p>
    </Link>
  );
}
