import { fireRequest, HttpMethod } from "./axios";

export const listLanguages = async () => {
  try {
    const response = await fireRequest(
      {
        url: `/project/language/list/`,
        method: HttpMethod.GET,
      },
      true
    );

    return response.data;
  } catch (error) {
    throw error.data;
  }
};
