import React from "react";
import JobListCard from "components/JobListCard";
import { useNavigate } from "react-router-dom";

const SavedJobsTab = ({ saved_jobs, toggleSaveJob }) => {
  const navigate = useNavigate();

  const handleJobClick = (project) => {
    console.log(project);
    navigate(`/workspace/jobs/${project?.id}`);
  };

  return (
    <div className="tw-px-[32px]">
      {saved_jobs.length > 0 ? (
        <>
          {saved_jobs.map((item, index) => (
            <JobListCard
              project={item.project}
              key={index}
              onClick={handleJobClick}
              onToggleSave={toggleSaveJob}
              isSaved={true}
              saved={item}
            />
          ))}
        </>
      ) : (
        <>No saved jobs</>
      )}
    </div>
  );
};

export default SavedJobsTab;
