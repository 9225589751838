import { format, parse } from "date-fns";
import * as React from "react";
import styled from "styled-components";
import User from "../../../../../assets/images//user.svg";
import Accordion from "components/Accordion";

const GroupReviewDetails = ({ project }) => {
  let formattedDueDate;
  if (project.end_date) {
    const originalDate = parse(project?.end_date, "yyyy-MM-dd", new Date());
    formattedDueDate = format(originalDate, "MMM d, yyyy");
  } else {
    formattedDueDate = "No due date";
  }
  return (
    <Div6>
      <Div7>
        <Div8>
          <Div9>
            <h4 className="tw-text-sm-medium tw-text-gray-700 ">
              {project.title}
            </h4>
            <Div10>{project.about}</Div10>
          </Div9>
          <Img2
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/96e7b7ec-2dc5-4507-bf59-4bc10eb61457?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/96e7b7ec-2dc5-4507-bf59-4bc10eb61457?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/96e7b7ec-2dc5-4507-bf59-4bc10eb61457?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/96e7b7ec-2dc5-4507-bf59-4bc10eb61457?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/96e7b7ec-2dc5-4507-bf59-4bc10eb61457?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/96e7b7ec-2dc5-4507-bf59-4bc10eb61457?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/96e7b7ec-2dc5-4507-bf59-4bc10eb61457?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/96e7b7ec-2dc5-4507-bf59-4bc10eb61457?"
          />
        </Div8>
        <Div11>
          <div className="tw-flex tw-items-center tw-gap-[8px]">
            <h3 className="tw-text-sm-medium tw-text-gray-500 ">Due date</h3>
            <h4 className="tw-text-md-semi-bold tw-text-gray-800 ">
              {formattedDueDate}{" "}
            </h4>
          </div>
        </Div11>
      </Div7>
      <Div14>
        <Accordion title="Group Description" closable={false}>
          <Div16>
            <Div17>
              {project.project_gallery.map((image, index) => (
                <Column>
                  <Div18
                    style={{
                      backgroundImage: `url(${image.imageUrl})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                    }}
                  >
                    <Div19>
                      <Div20>{image.img_title}</Div20>
                    </Div19>
                  </Div18>
                </Column>
              ))}
            </Div17>
          </Div16>
        </Accordion>

        {/*  */}
        <Accordion title={"  Other Details"}>
          <div className="tw-flex tw-flex-col tw-gap-[16px] tw-w-full">
            <div className="tw-flex tw-justify-between tw-items-center tw-px-[8px] tw-w-full">
              <h4 className=" tw-text-sm-regular tw-text-gray-600">
                Clothing type
              </h4>
              <div className="tw-flex tw-gap-[18px]">
                {project.clothing_type.map((type) => (
                  <h6 className=" tw-text-sm-medium tw-text-gray-700  tw-border tw-border-gray-100 tw-rounded-md tw-px-[10px] tw-py-[4px] tw-w-fit">
                    {type.name}
                  </h6>
                ))}
              </div>
            </div>
            <div className="tw-flex tw-justify-between tw-items-center tw-px-[8px] tw-w-full">
              <h4 className=" tw-text-md-semi-bold tw-text-gray-600 !tw-text-[16px]">
                Collaborators
              </h4>
              <div className="tw-flex tw-gap-[18px]">
                {project.collaborators.map((collaborator) => (
                  <div className=" tw-flex tw-items-center tw-gap-4 tw-bg-[#F2F4F7]] tw-rounded-md tw-px-[10px] tw-py-[4px] tw-w-fit">
                    {collaborator?.image_url ? (
                      <img
                        src={collaborator?.image_url}
                        alt=""
                        className="tw-w-[24px] tw-h-[24px] tw-rounded-full"
                      />
                    ) : (
                      <img
                        src={User}
                        alt=""
                        className="tw-w-[24px] tw-h-[24px] tw-rounded-full"
                      />
                    )}
                    <h5 className="tw-text-sm-medium">
                      {collaborator.first_name} {collaborator.last_name}
                    </h5>
                  </div>
                ))}
              </div>
            </div>
            <div className="tw-flex tw-justify-between tw-items-center tw-px-[8px] tw-w-full">
              <h4 className=" tw-text-md-semi-bold tw-text-gray-600 !tw-text-[16px]">
                Recommended Designers
              </h4>
              <div className="tw-flex tw-gap-[18px]">
                {project.recommended_designers.map((designer) => (
                  <div className=" tw-flex tw-items-center tw-gap-4  tw-bg-[#F2F4F7] tw-rounded-md tw-px-[10px] tw-py-[4px] tw-w-fit">
                    {designer?.image_url ? (
                      <img
                        src={designer?.image_url}
                        alt=""
                        className="tw-w-[24px] tw-h-[24px] tw-rounded-full"
                      />
                    ) : (
                      <img
                        src={User}
                        alt=""
                        className="tw-w-[24px] tw-h-[24px] tw-rounded-full"
                      />
                    )}
                    <h5 className="tw-text-sm-medium ">
                      {designer.first_name} {designer.last_name}
                    </h5>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Accordion>
      </Div14>
    </Div6>
  );
};

export default GroupReviewDetails;

const Div6 = styled.div`
  align-items: flex-start;
  align-self: center;
  width: 100%;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
`;

const Div7 = styled.div`
  align-items: flex-start;
  align-self: stretch;
  background: var(--gray-100, #f2f4f7);
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
`;

const Div8 = styled.div`
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
`;

const Div9 = styled.div`
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  padding-top: 6px;
  padding-left: 0px;
  padding-right: 4px;
  padding-bottom: 6px;
  display: flex;
  flex-direction: column;
`;

const Div10 = styled.div`
  align-self: stretch;
  color: var(--gray-500, #667085);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-top: 12px;
  margin-bottom: -0px;
`;

const Img2 = styled.img`
  aspect-ratio: 1064;
  object-fit: cover;
  object-position: center;
  width: 100%;
  margin-top: 20px;
  align-self: stretch;
`;

const Div11 = styled.div`
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-top: 16px;
  padding-right: 20px;
  align-self: start;
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
`;

const Div14 = styled.div`
  align-items: flex-start;
  width: 100%;
  margin-top: 16px;
  align-self: start;
  display: flex;
  flex-direction: column;
`;



const Div16 = styled.div`
  align-self: stretch;
  margin-top: 19px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
`;

const Div17 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 31%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div18 = styled.div`
  align-items: flex-end;
  background: linear-gradient(180deg, #f9fafb 0%, #f1f1f1 100%);
  padding-top: 40px;
  padding-left: 19px;
  padding-right: 17px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  @media (max-width: 991px) {
    margin-top: 16px;
  }
`;

const Div19 = styled.div`
  margin-top: 43px;
  margin-bottom: 0px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
`;

const Div20 = styled.div`
  overflow: hidden;
  color: var(--gray-700, #344054);
  text-overflow: ellipsis;
  whitespace: nowrap;
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  margin-top: 31px;
  align-self: stretch;
`;
