import React from "react";
import { useNavigate } from "react-router-dom";
import StarImg from "assets/gif/successful.gif";
import CustomButton from "components/Buttons/Button";

const SuccessModal = (props) => {
  const navigate = useNavigate();

  return (
    <div>
      <div>
        <div>
          <img
            src={StarImg}
            alt="successful"
            className="tw-w-[227px] tw-h-[227px] tw-object-cover tw-mx-auto"
          />
          {props.project_type === "solo" ? (
            <div className="tw-text-center tw-mb-[64px]">
              <h2 className="tw-text-xs-semi-bold !tw-text-[24px] tw-text-[#101828]">
                Project Ad posted successfully{" "}
              </h2>
              <h6 className="tw-text-sm-regular  tw-text-[#475467] tw-mt-[4px]">
                We know you will find the perfect designer
              </h6>
            </div>
          ) : (
            <div className="tw-text-center tw-mb-[64px]">
              <h2 className="tw-text-xs-semi-bold !tw-text-[24px] tw-text-[#101828]">
                Group project posted successfully{" "}
              </h2>
              <h6 className="tw-text-sm-regular tw-text-[#475467] tw-mt-[4px]">
                Go to project dashboard to create your child’s project for *
                {props.project_name}*
              </h6>
            </div>
          )}
        </div>
      </div>
      <div>
        <CustomButton
          onClick={() => {
            props.hide();
            navigate("/workspace/projects");
          }}
          buttonVariant={"tertiary"}
          type={"button"}
          cls={`hover:!tw-bg-transparent tw-w-full`}
        >
          Close
        </CustomButton>
      </div>
    </div>
  );
};

export default SuccessModal;
