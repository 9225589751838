import { format, parse } from "date-fns";
import styled from "styled-components";
import GroupReviewDetails from "./GroupReviewDetails";
import { useEffect, useState, useCallback } from "react";
import Loader from "common/Loader";
import { getExperienceLevel } from "services/experienceLevelService";
import { listLanguages } from "services/languageService";
import Accordion from "components/Accordion";
import { listKeywordById } from "services/keywordService";
import { useNavigate } from "react-router-dom";
import { formatMoney } from "constants/Utils";
import { create } from "services/projectService";
import { createRecommendedDesigner } from "services/recommendedDesignerService";
import { useDispatch } from "react-redux";
import { projectFailure, projectSuccess } from "store/actions/projectActions";
import {  Modal } from "react-bootstrap";
import SuccessModal from "./SuccessModal";

const ReviewStepper = ({ data, prev, next, loading, isLoading, onSave }) => {
  const [isFetching, setIsFetching] = useState(false);
  const [experienceLevel, setExperienceLevel] = useState();
  const [language, setLanguage] = useState();
  const [specialist, setSpecialist] = useState();
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();


  const handleCloseModal = () => {
    setShow(false);
  };


  let formattedDueDate = data.end_date
    ? format(parse(data.end_date, "yyyy-MM-dd", new Date()), "MMM d, yyyy")
    : "No due date";

  const fetchExperienceLevel = useCallback(async () => {
    try {
      const response = await getExperienceLevel(data.experience_level);
      setExperienceLevel(response);
    } catch (err) {
      console.error(err);
    }
  }, [data.experience_level]);
  // TODO: use callback example
  const fetchLanguage = useCallback(async () => {
    try {
      const response = await listLanguages();
      const language = response.find((r) => r.id === data.language);
      setLanguage(language);
    } catch (err) {
      console.error(err);
    }
  }, [data.language]);
  const fetchSpecialist = useCallback(async () => {
    try {
      const response = await listKeywordById(data.specialist);
      console.log(response);
      setSpecialist(response);
    } catch (err) {
      console.error(err);
    }
  }, [data.specialist]);

  useEffect(() => {
    if (data.project_type === "solo" || data.project_type === "child") {
      Promise.allSettled([
        fetchExperienceLevel(),
        fetchLanguage(),
        fetchSpecialist(),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    try {
      const clothing_type_formatted = data.clothing_type.map(
        (type) => type.id
      );
      console.log(data);
      let updatedData = data;
      updatedData.specialist = [data.specialist];
      updatedData.clothing_type = clothing_type_formatted;

      const resp = await create(updatedData);
      if (resp) {
        console.log(resp);
        // if (collaborator_array.length > 0) {
        //   const collaborator_data = {
        //     project: resp.id,
        //     collaborators: collaborator_array,
        //   };
        //   const response = await createCollaborator(collaborator_data);
        //   return response;
        // }
        console.log(data.recommended_designers, "POS 2");
        if (data?.recommended_designers) {
          const designers_data = {
            project: resp.id,
            recommended_designers: data.recommended_designers,
          };
          const response = await createRecommendedDesigner(designers_data);
          return response;
        }
        console.log(resp, "POS 3");

        setShow(true);
        dispatch(projectSuccess(resp));
        return resp;
      }
    } catch (err) {
      dispatch(projectFailure(err.message));
    }
  }

  if (isFetching) <Loader />;
  return (
    <div className="lg:tw-px-[32px] tw-py-[32px]">
      <Div3 className="!tw-w-full">
        <Div4>
          <h4 className="tw-text-lg-semi-bold tw-text-gray-900 ">
            Confirm details
          </h4>
          <p className="tw-text-sm-regular tw-text-gray-600 ">
          Please check and confirm that the information you added about this project is correct
          </p>
        </Div4>
        <Img
          loading="lazy"
          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/80e34774-d755-4adc-837e-408b08793a4b?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/80e34774-d755-4adc-837e-408b08793a4b?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/80e34774-d755-4adc-837e-408b08793a4b?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/80e34774-d755-4adc-837e-408b08793a4b?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/80e34774-d755-4adc-837e-408b08793a4b?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/80e34774-d755-4adc-837e-408b08793a4b?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/80e34774-d755-4adc-837e-408b08793a4b?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/80e34774-d755-4adc-837e-408b08793a4b?"
        />
      </Div3>
      {data.project_type === "solo" || data.project_type === "child" ? (
        <Div6>
          <Div7 className="tw-mb-[16px]">
            <Div8>
              <Div9>
                <h4 className="tw-text-md-semi-bold tw-text-gray-700 ">
                  {data.title}
                </h4>
                <Div10>{data.about}</Div10>
              </Div9>
              <Img2
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/96e7b7ec-2dc5-4507-bf59-4bc10eb61457?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/96e7b7ec-2dc5-4507-bf59-4bc10eb61457?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/96e7b7ec-2dc5-4507-bf59-4bc10eb61457?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/96e7b7ec-2dc5-4507-bf59-4bc10eb61457?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/96e7b7ec-2dc5-4507-bf59-4bc10eb61457?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/96e7b7ec-2dc5-4507-bf59-4bc10eb61457?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/96e7b7ec-2dc5-4507-bf59-4bc10eb61457?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/96e7b7ec-2dc5-4507-bf59-4bc10eb61457?"
              />
            </Div8>
            <Div11>
              <div className="tw-flex tw-items-center tw-gap-[8px]">
                <h3 className="tw-text-sm-medium tw-text-gray-500 ">
                  Project due date
                </h3>
                <h4 className="tw-text-md-semi-bold tw-text-gray-800 ">
                  {formattedDueDate}{" "}
                </h4>
              </div>
              <div className="tw-flex tw-items-center  tw-gap-[8px]">
                <h3 className="tw-text-sm-medium tw-text-gray-500 ">
                  Project Budget
                </h3>
                <h4 className="tw-text-md-semi-bold tw-text-gray-800 ">
                  {formatMoney(
                    Number(data.budget.amount),
                    data.budget.currency
                  )}{" "}
                </h4>
              </div>
            </Div11>
          </Div7>

          <Accordion title="   Project Gallery" closable={false}>
            <Div17 className="tw-overflow-x-auto tw-mb-[16px]">
              {data.project_gallery.map((image, index) => (
                <Column key={index}>
                  <Div18
                    style={{
                      backgroundImage: `url(${image.imageUrl})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      maxWidth: '314px',
                      height: '198px'
                    }}
                  >
                    <Div19>
                      <Div20>{image.img_title}</Div20>
                    </Div19>
                  </Div18>
                </Column>
              ))}
            </Div17>
          </Accordion>
          <Accordion title={"  Delivery Details"}>
            <div className="tw-flex-col tw-gap-[16px]">
              <div className="tw-flex tw-justify-between tw-p-[8px]">
                <h4 className="tw-text-sm-regular tw-text-gray-600  ">
                  Country
                </h4>
                <div className=" tw-text-sm-medium tw-text-gray-700  tw-border tw-border-gray-100 tw-rounded-md tw-px-[10px] tw-py-[4px] tw-w-fit">
                  {data?.country}
                </div>
              </div>
              <div className="tw-flex tw-justify-between tw-p-[8px]">
                <h4 className="tw-text-sm-regular tw-text-gray-600  ">City</h4>
                <div className=" tw-text-sm-medium tw-text-gray-700  tw-border tw-border-gray-100 tw-rounded-md tw-px-[10px] tw-py-[4px] tw-w-fit">
                  {data?.city}
                </div>
              </div>
              <div className="tw-flex tw-justify-between tw-p-[8px]">
                <h4 className="tw-text-sm-regular tw-text-gray-600 ">
                  Province / State/ Zip code
                </h4>
                <div className="tw-flex tw-justify-end tw-gap-[18px]">
                  <div className="tw-text-sm-medium tw-text-gray-700  tw-border tw-border-gray-100 tw-rounded-md tw-px-[10px] tw-py-[4px] tw-w-fit">
                    {data?.state}
                  </div>
                  <div className="   tw-text-sm-medium tw-text-gray-700  tw-border tw-border-gray-100 tw-rounded-md tw-px-[10px] tw-py-[4px] tw-w-fit">
                    {data.zip_code}
                  </div>
                </div>
              </div>
              <div className="tw-flex tw-justify-between tw-p-[8px]">
                <h4 className="tw-text-sm-regular tw-text-gray-600 ">
                  House Number
                </h4>
                <div className=" tw-text-sm-medium tw-text-gray-700  tw-border tw-border-gray-100 tw-rounded-md tw-px-[10px] tw-py-[4px] tw-w-fit tw-h-[60px]">
                  {data.address}
                </div>
              </div>
            </div>
          </Accordion>

          <Accordion title={"    Other Details"}>
            <div>
              <Div42>
                <Div43 className="!tw-items-center">
                  <h4 className=" tw-text-sm-regular tw-text-gray-600">
                    Clothing type
                  </h4>
                  <div className="tw-flex tw-gap-[18px]">
                    {data.clothing_type.map((type) => (
                      <h6 className=" tw-text-sm-medium tw-text-gray-700  tw-border tw-border-gray-100 tw-rounded-md tw-px-[10px] tw-py-[4px] tw-w-fit">
                      {console.log(type)}
                        {type?.name}
                      </h6>
                    ))}
                    {/* <A20 className=" tw-text-sm-regular tw-text-gray-600">Shirt</A20>
                      <A21 className=" tw-text-sm-regular tw-text-gray-600">Trousers</A21> */}
                  </div>
                </Div43>
              </Div42>
              <Div45>
                <Div46 className="!tw-items-center">
                  <h4 className=" tw-text-sm-regular tw-text-gray-600">
                    Specialist
                  </h4>
                  <div className="tw-flex tw-gap-[18px]">
                    <h6 className=" tw-text-sm-medium tw-text-gray-700  tw-border tw-border-gray-100 tw-rounded-md tw-px-[10px] tw-py-[4px] tw-w-fit">
                      {specialist?.value}
                    </h6>
                    {/* <A20 className=" tw-text-sm-regular tw-text-gray-600">Shirt</A20>
                      <A21 className=" tw-text-sm-regular tw-text-gray-600">Trousers</A21> */}
                  </div>
                </Div46>
              </Div45>
              <div className="tw-flex tw-justify-between tw-p-[8px] tw-w-full tw-items-center">
                <h4 className=" tw-text-sm-regular tw-text-gray-600">
                  Experience level
                </h4>
                <h6 className=" tw-text-sm-regular tw-text-gray-600 tw-justify-end">
                  {experienceLevel?.name}
                </h6>
              </div>
              <Div50>
                <Div51 className="!tw-items-center">
                  <h4 className=" tw-text-sm-regular tw-text-gray-600">
                    {language?.name}
                  </h4>
                  <h6 className=" tw-text-sm-medium tw-text-gray-700  tw-border tw-border-gray-100 tw-rounded-md tw-px-[10px] tw-py-[4px] tw-w-fit">
                    {data.language_level}
                  </h6>
                </Div51>
              </Div50>
            </div>
          </Accordion>
        </Div6>
      ) : (
        <GroupReviewDetails project={data} />
      )}
      {/* Back & Next Button */}
      <div className="tw-px-1 tw-w-full">
        <hr className="tw-mt-10 tw-text-[#EAECF0]" />
        <div className="tw-flex tw-justify-between tw-my-[20px]">
          <button
            className="tw-text-sm-semi-bold"
            onClick={() => prev(data)}
            type="button"
          >
            Back
          </button>
          <div className="tw-flex tw-gap-[12px]">
            <button
            type="button"
              className="tw-text-sm-semi-bold tw-border tw-border-[#EAECF0] tw-py-[10px] tw-px-[16px] tw-text-[#D0D5DD] tw-hidden lg:tw-block"
              onClick={() =>
                onSave(data)
              }
            >
           {loading ? 'Saving' :'   Save & exit'}
            </button>
            <button
              className="tw-text-sm-semi-bold tw-py-[10px] tw-px-[16px] tw-bg-yellow-500 tw-text-white disabled:tw-opacity-50"
              type="submit"
              disabled={loading}
              onClick={async () => {
                try {
                  isLoading(true);
                  await handleSubmit(data);
                  
                } catch (err) {
                } finally {
                  isLoading(false);
                }
              }}
            >
              {loading ? "Posting" : "Post"}
            </button>
          </div>
        </div>
      </div>
      <Modal show={show} handleClose={handleCloseModal}>
        <Modal.Body>
          <SuccessModal
            project_type={data.project_type}
            project_name={data.project_name}
            hide={() => setShow(false)}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ReviewStepper;

const Div3 = styled.div`
  align-items: flex-start;
  align-self: center;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Div4 = styled.div`
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  padding-top: 7px;
  padding-left: 1px;
  padding-bottom: 2px;
  display: flex;
  flex-direction: column;
`;

const Img = styled.img`
  aspect-ratio: 1096;
  object-fit: cover;
  object-position: center;
  width: 100%;
  margin-top: 20px;
  align-self: stretch;
`;

const Div6 = styled.div`
  align-items: flex-start;
  align-self: center;
  width: 100%;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
`;

const Div7 = styled.div`
  align-items: flex-start;
  align-self: stretch;
  background: var(--gray-100, #f2f4f7);
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
`;

const Div8 = styled.div`
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
`;

const Div9 = styled.div`
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  padding-top: 6px;
  padding-left: 0px;
  padding-right: 4px;
  padding-bottom: 6px;
  display: flex;
  flex-direction: column;
`;

const Div10 = styled.div`
  align-self: stretch;
  color: var(--gray-500, #667085);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-top: 12px;
  margin-bottom: -0px;
`;

const Img2 = styled.img`
  aspect-ratio: 1064;
  object-fit: cover;
  object-position: center;
  width: 100%;
  margin-top: 20px;
  align-self: stretch;
`;

const Div11 = styled.div`
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-top: 16px;
  align-self: start;
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
`;

const Div17 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 31%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div18 = styled.div`
  align-items: flex-end;
  background: linear-gradient(180deg, #f9fafb 0%, #f1f1f1 100%);
  padding-top: 40px;
  padding-left: 19px;
  padding-right: 17px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  @media (max-width: 991px) {
    margin-top: 16px;
  }
`;

const Div19 = styled.div`
  margin-top: 43px;
  margin-bottom: 0px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
`;

const Div20 = styled.div`
  overflow: hidden;
  color: var(--gray-700, #344054);
  text-overflow: ellipsis;
  whitespace: nowrap;
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  margin-top: 31px;
  align-self: stretch;
`;

const Div42 = styled.div`
  align-items: center;
  border-top: 1px solid var(--gray-200, #eaecf0);
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 8px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
`;

const Div43 = styled.div`
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  padding-left: 1px;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
`;

const Div45 = styled.div`
  align-items: center;
  border: 0px solid var(--gray-200, #eaecf0);
  margin-top: 8px;
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 8px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
`;

const Div46 = styled.div`
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  padding-left: 1px;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
`;

const Div50 = styled.div`
  align-items: center;
  border-bottom: 1px solid var(--gray-200, #eaecf0);
  margin-top: 8px;
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 8px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
`;

const Div51 = styled.div`
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  padding-left: 1px;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
`;
