import React, { useState } from "react";

export default function Input(props) {
  const {
    label,
    type = "text",
    id,
    name,
    iconLeft,
    iconRight,
    onChange,
    value,
    onBlur,
    touched,
    placeholder,
    error,
    cls,
    helper,
    containerCls ='',
    ...rest
  } = props;
  const [localTouched, setLocalTouched] = useState(false);

  const hasError = (localTouched || touched) && (value === "" || error);
  const handleBlur = (e) => {
    setLocalTouched(true);
    if (onBlur) {
      onBlur(e);
    }
  };

  return (
    <div className={`tw-mb-[24px] ${containerCls}`}>
      {label && (
        <label htmlFor={id || name} className="tw-text-sm-medium tw-mb-[6px]">
          {label}
        </label>
      )}
      <div
        className={`${cls} tw-relative tw-w-fit tw-h-fit tw-border-gray-300 tw-border ${
          hasError
            ? "tw-border-red-500"
            : "tw-border-gray-300 focus-within:tw-border-yellow-500"
        }`}
      >
        {iconLeft && (
          <span className="tw-absolute tw-inset-y-0  tw-flex tw-items-center tw-cursor-pointer tw-left-[10px]">
            {iconLeft}
          </span>
        )}
        {iconRight && (
          <span className="tw-absolute tw-inset-y-0  tw-flex tw-items-center tw-cursor-pointer  tw-right-[10px]">
            {iconRight}
          </span>
        )}
        <input
          type={type}
          id={id}
          name={name}
          onChange={onChange}
          value={value}
          onBlur={handleBlur}
          placeholder={placeholder}
          {...rest}
          className={`tw-placeholder-gray-500 tw-w-full tw-text-md-regular tw-outline-none tw-h-[44px]   tw-py-[10px] tw-border-0 ${
            !iconLeft ? "tw-pl-[1rem]" : "tw-pl-[3rem]"
          } ${!iconRight ? "tw-pr-[1rem]" : "tw-pr-[3rem]"}`}
        />
      </div>
      {helper && helper}
      {(localTouched || touched) && (value === "" || error) && (
        <div style={{ color: "red" }} className="tw-mt-1">{error || "This field is required"}</div>
      )}
    </div>
  );
}
