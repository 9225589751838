import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { Autocomplete, Chip, TextField } from "@mui/material";
import PublishedModal from "./PublishedModal";
import Input from "components/Input";
import Upload from "components/Upload";
import { Formik, Form as FormikForm } from "formik";
import { create, listKeywords } from "services/keywordService";
import * as Yup from "yup";
import CustomButton from "components/Buttons/Button";
import { useNavigate } from "react-router-dom";
import { createDesignerPortfolio } from "services/designerService";
import { useSelector } from "react-redux";

const validationSchema = Yup.object().shape({
  image_url: Yup.string(),
  is_cover_image: Yup.boolean(),
  description: Yup.string().required(),
  // .min(250),
  // tags: Yup.array()
  //   .max(8, "selected value can't be more than 8")
  //   .required("Clothing Type is required"),
});

const ShareYourWork = (props) => {
  const [loading, isLoading] = useState(false);
  const { active } = useSelector((state) => state.account);
  const navigate = useNavigate();
  const [specialties, setSpecialties] = useState([]);
  const [show, setShow] = useState(false);

  const handleShowModal = () => {
    setShow(true);
  };
  const handleCloseModal = () => setShow(false);

  const loadSpecialties = async () => {
    try {
      const resp = await listKeywords();
      setSpecialties(resp);
    } catch (err) {
      console.log(err);
    }
  };
  // const removeImage = (index, setFieldValue, values) => {
  //   const newImages = values.portfolio_gallery.filter((_, i) => i !== index);
  //   setFieldValue("portfolio_gallery", newImages);
  // };

  const removeImage = (setFieldValue) => {
    setFieldValue("image_url", "");
  };

  // TODO: see why cover photo isn't updating
  // const handleIsCoverImage = (index, setFieldValue, values) => {
  //   const newImages = [...values.portfolio_gallery];
  //   const updatedImages = newImages.map((image, i) => {
  //     if (i === index) {
  //       return { ...image, is_cover_image: true };
  //     } else {
  //       return { ...image, is_cover_image: false };
  //     }
  //   });
  //   newImages[index].is_cover_image = true;
  //   setFieldValue("cover_image_url", newImages[index].image_url);
  //   setFieldValue("portfolio_gallery", updatedImages);
  // };

  const handleSpecialtiesChange = async (_, value, reason, setFieldValue) => {
    try {
      if (value.length > 8) return value.pop();
      if (_.key === "Enter" && reason === "createOption") {
        if (
          !specialties.some(
            (selectedType) => selectedType.name === _.target.value.trim()
          )
        ) {
          const response = await create(_.target.value.trim());
          console.log(response);
          setSpecialties([...specialties, response]);
          value.pop();
          value.push(response);
        } else {
          const found = specialties.find(
            (type) => type.name === _.target.value
          );
          value.pop();
          value.push(found);
        }
      }

      setFieldValue("tags", value);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    loadSpecialties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (values, { resetForm }) => {
    try {
      isLoading(true);
      const tags_formatted = values.tags.map((type) => type.id);
      const response = await createDesignerPortfolio({
        ...values,
        tags: tags_formatted,
      });
      console.log(response);
      isLoading(false);
      resetForm()
      handleShowModal();
    } catch (err) {
      console.log(err);
      isLoading(false);
    }
  };

  const handleChipDelete = () => {};

  return (
    <div className="tw-relative ">
      <div className="tw-bg-dashboard-banner tw-w-full tw-h-[120px] tw-bg-no-repeat tw-bg-cover"></div>

      <div className="tw-absolute tw-top-0 tw-p-[32px]">
        <h3 className="tw-text-xs-bold !tw-text-[24px]">Share your work</h3>
      </div>

      <div>
        <Formik
          initialValues={{
            image_url: "",
            description: "",
            tags: [],
            is_cover_image: false,
            designer: active?.id,
          }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({
            values,
            errors,
            touched,
            dirty,
            isValid,
            setFieldValue,
            handleChange,
            handleBlur,
          }) => (
            <FormikForm>
              <div className="tw-flex tw-gap-[32px] tw-px-[32px] flex-wrap flex-col lg:flex-row">
                <div>
                  <h5 className="tw-text-sm-medium">Build your portfolio</h5>
                  <h6 className="tw-text-sm-regular">
                    Upload styling images of previous jobs
                  </h6>
                </div>
                <div className="flex-1">
                  <Upload
                    gallery={values.image_url}
                    multiple={false}
                    handleInputChange={(images) => {
                      console.log(images);
                      setFieldValue("image_url", images.image_url);
                    }}
                  />
                  <div className="tw-w-full tw-mt-[20px]">
                    <Input
                      cls="tw-w-full"
                      name="description"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.description}
                      placeholder="Image Descriptions"
                      error={errors["description"]}
                      helper={
                        <div className="tw-mt-[6px]">
                          {values.description.length}/250 characters
                        </div>
                      }
                    />
                  </div>
                  {values.image_url && (
                    <div>
                      <div
                        className="tw-h-[461px] tw-relative tw-bg-no-repeat"
                        style={{
                          backgroundImage: `url(${values.image_url})`,
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                        }}
                      >
                        <button
                          className="tw-z-999 tw-absolute tw-right-3 tw-top-3 tw-cursor-pointer"
                          type="button"
                          onClick={() => {
                            removeImage(setFieldValue);
                          }}
                        >
                          <svg
                            width="35"
                            height="35"
                            viewBox="0 0 35 35"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g style={{ mixBlendMode: "multiply" }}>
                              <rect
                                width="34.2857"
                                height="34.2857"
                                rx="17.1429"
                                fill="#FEFBE8"
                              />
                              <path
                                d="M20.5714 11.9989V11.3132C20.5714 10.3531 20.5714 9.87303 20.3846 9.50633C20.2202 9.18377 19.958 8.92151 19.6354 8.75716C19.2687 8.57031 18.7887 8.57031 17.8286 8.57031H16.4571C15.4971 8.57031 15.017 8.57031 14.6503 8.75716C14.3277 8.92151 14.0655 9.18377 13.9011 9.50633C13.7143 9.87303 13.7143 10.3531 13.7143 11.3132V11.9989M15.4286 16.7132V20.9989M18.8571 16.7132V20.9989M9.42857 11.9989H24.8571M23.1429 11.9989V21.5989C23.1429 23.039 23.1429 23.7591 22.8626 24.3091C22.6161 24.793 22.2227 25.1864 21.7388 25.4329C21.1888 25.7132 20.4687 25.7132 19.0286 25.7132H15.2571C13.817 25.7132 13.0969 25.7132 12.5469 25.4329C12.063 25.1864 11.6697 24.793 11.4231 24.3091C11.1429 23.7591 11.1429 23.039 11.1429 21.5989V11.9989"
                                stroke="#EAAA08"
                                strokeWidth="2"
                                strokeLineCap="round"
                                strokeLineJoin="round"
                              />
                            </g>
                          </svg>
                        </button>
                      </div>
                      <div className="tw-my-[8px] tw-flex tw-items-end tw-justify-end tw-w-full">
                        <div className="tw-flex tw-gap-[8px] tw-items-center tw-cursor-pointer">
                          <p className="tw-text-sm-regular">
                            Use as cover image
                          </p>
                          <Form.Check
                            type="radio"
                            // name={`portfolio_gallery[${index}].is_cover_image`}
                            onChange={() =>
                              setFieldValue(
                                "is_cover_image",
                                !values.is_cover_image
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  <div>
                    <label className="tw-text-sm-medium tw-mb-[6px] tw-text-[#344054]">
                      Clothing Type
                    </label>
                    <Autocomplete
                      multiple
                      id="specialties"
                      freeSolo
                      value={values.clothing_type}
                      isOptionEqualToValue={(option, value) =>
                        (option.name || option) === value.value
                      }
                      options={specialties}
                      getOptionLabel={(option) => option.value || option}
                      onChange={(e, value, reason) =>
                        handleSpecialtiesChange(e, value, reason, setFieldValue)
                      }
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            key={index}
                            label={option.value || option}
                            onDelete={() =>
                              handleChipDelete(option, setFieldValue)
                            }
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          rows={4}
                          placeholder="add other"
                          variant="outlined"
                        />
                      )}
                    />
                    <div>{values.tags.length}/8 tags</div>
                  </div>
                </div>
              </div>
              <hr className="tw-text-[#EAECF0] tw-mt-[20px]" />
              <div
                className={`tw-mt-[20px] tw-flex tw-justify-between tw-gap-[8px] tw-px-[32px]`}
              >
                <CustomButton
                  click={() => navigate(-1)}
                  buttonVariant={"tertiary"}
                  type={"button"}
                  cls={`!tw-border-0 hover:!tw-bg-transparent `}
                >
                  Back
                </CustomButton>
                <CustomButton
                  click={() => {}}
                  buttonVariant={"primary"}
                  type={"submit"}
                  cls={`tw-py-[10px] tw-px-[16px]`}
                  disabled={!dirty || !isValid || loading}
                >
                  Publish
                </CustomButton>
              </div>
            </FormikForm>
          )}
        </Formik>
        <Modal
          show={show}
          onHide={handleCloseModal}
          className="rounded-0"
          centered
        >
          <Modal.Body className="!tw-p-0">
            <PublishedModal onHide={handleCloseModal} />
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default ShareYourWork;
