import React, { useState } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import User from "../../../../../assets/images//user.svg";

const RecommendDesignersModal = ({ designers, hide , setSelected}) => {

  const [show, setShow] = useState(null);

  const [searchInput, setSearchInput] = useState("");

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const filteredDesigners = designers.filter((designer) => {
    const fullName =
      `${designer.first_name} ${designer.last_name}`.toLowerCase();
    const countryName = designer?.country?.name?.toLowerCase() || "";
    const cityName = designer?.city?.name?.toLowerCase() || "";
    const searchValue = searchInput.toLowerCase();
    return (
      fullName.includes(searchValue) ||
      countryName.includes(searchValue) ||
      cityName.includes(searchValue)
    );
  });

  const handleSelect = (value) => {
    const updatedDesigners = designers.map((designer) =>
      designer.user.id === value.user.id
        ? { ...designer, selected: !designer.selected }
        : designer
    );
      setSelected(updatedDesigners);
  };

  return (
    <div>
      <div className="tw-px-[24px]">
        <div className="tw-flex tw-justify-between tw-items-center tw-mb-[16px]">
          <div>Recommend designers</div>
          <img
            style={{ cursor: "pointer" }}
            onClick={hide}
            loading="lazy"
            alt=""
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/55ef8dc5-44f2-4cc4-8cd1-3fdd9677d339?"
          />
        </div>
        <Form.Group controlId="designerSearch">
          <div style={{ position: "relative" }}>
            <span
              style={{
                position: "absolute",
                top: "50%",
                left: "10px",
                cursor: "pointer",
                transform: "translateY(-50%)",
              }}
            >
              <Img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/3fddd009ff5792fce86ef31defd8ace7e48ca792e51dd23fd6ce9f957a6c6815?"
              />
            </span>
            <Form.Control
              type="text"
              placeholder="Search"
              value={searchInput}
              onChange={handleSearchInputChange}
              className="rounded-0 form-control-lg px-5 tag-input-field"
            />
          </div>
        </Form.Group>
      </div>
      <Div8 />
      {/*  */}
      <div className="tw-mt-[8px] tw-px-[10px]">
        <div className="tw-px-[30px] tw-text-md-regular">Favorites</div>
        <div className="">
          {filteredDesigners.map((designer, i) => {
            return (
              <div
                className="tw-relative"
                onMouseEnter={() => setShow(i)}
                onMouseLeave={() => setShow(null)}
                onClick={() => handleSelect(designer)}
              >
                <div
                  className={`tw-flex tw-py-[16px] tw-items-center tw-gap-4 tw-cursor-pointer hover:tw-bg-[#F2F4F7] ${
                    designer.selected && "tw-bg-[#F2F4F7]"
                  }`}
                  key={i}
                >
                  {designer.image_url ? (
                    <img
                      src={designer.image_url}
                      alt=""
                      className="tw-w-[40px] tw-h-[40px] tw-rounded-full"
                    />
                  ) : (
                    <div className="tw-bg-[#F2F4F7] tw-w-[40px] tw-h-[40px] tw-rounded-full tw-p-[8px] tw-flex tw-items-center tw-justify-center ">
                      <img src={User} alt="" />
                    </div>
                  )}
                  <div>
                    <h4 className="tw-text-sm-semi-bold">
                      {designer.first_name} {designer.last_name}
                    </h4>
                    <p className="tw-text-sm-regular">
                      {designer?.user?.email}
                    </p>
                  </div>
                </div>
                {show === i && (
                  <div className="tw-absolute tw-left-20  tw-z-10 tw-bg-[#fff]  tw-p-[16px] tw-shadow-md">
                    <div className="tw-flex tw-gap-[32px]">
                      <div className="tw-flex tw-flex-col tw-gap-[32px]">
                        <div className="flex-1">
                          <h5>Location</h5>
                          <h5>
                            {designer.city.name}, {designer?.country?.name}
                          </h5>
                        </div>
                        <div className="flex-1">
                          <h5>Success rate</h5>
                          <h5>100%</h5>
                        </div>
                      </div>
                      <div className="tw-flex tw-flex-col tw-gap-[32px]">
                        <div className="flex-1">
                          <h5>Total Earnings</h5>
                          <h5>$5,000</h5>
                        </div>

                        <div className="flex-1">
                          <h5>Years of Experience</h5>
                          <h5>5</h5>
                        </div>
                      </div>
                      <div className="tw-flex tw-flex-col tw-gap-[32px]">
                        <div className="flex-1">
                          <h5>Total jobs</h5>
                          <h5>16</h5>
                        </div>
                        <div className="flex-1">
                          <h5>Reviews</h5>
                          <h5>16</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div className="tw-p-[24px] tw-w-full">
      <button   className="tw-text-sm-semi-bold tw-py-[10px] tw-px-[16px] tw-bg-yellow-500 tw-text-white tw-w-full" onClick={()=> hide()}>Recommend</button>
      </div>
    </div>
  );
};

export default RecommendDesignersModal;

const Img = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
  overflow: hidden;
  max-width: 100%;
  margin: auto 0;
`;

const Div8 = styled.div`
  background-color: rgba(
    234.11549985408783,
    236.13851130008698,
    240.18450379371643,
    1
  );
  display: flex;
  min-height: 1px;
  margin-top: 20px;
  width: 100%;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
