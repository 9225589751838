import { formatDistanceToNow } from "date-fns";
import { cloudUpload } from "services/cloudinary";
import { showToast } from "services/toastService";
import React from "react";
import { differenceInDays, format, isPast } from "date-fns";

export const getCurrencySymbol = (currencyCode) => {
  const upperCaseCurrencyCode = currencyCode.toUpperCase();
  switch (upperCaseCurrencyCode) {
    case "USD":
      return "$";
    case "EUR":
      return "€";
    case "NGN":
      return "₦";
    default:
      return upperCaseCurrencyCode;
  }
};

export const timeAgo = (date) => {
  const timeofDay = formatDistanceToNow(new Date(date), { addSuffix: true });
  return timeofDay;
};

export const setTokenInLocalStorage = (token) => {
  localStorage.setItem("auth_token", token);
};

export const setUserIdInLocalStorage = (userId) => {
  localStorage.setItem("userId", userId);
};

export const getToken = (key) => {
  return localStorage.getItem(key);
};

// Function to convert invalid JSON string to valid JSON string
const convertToValidJSONString = (errorString) => {
  console.log(errorString);
  // Replace single quotes with double quotes
  let validJSONString = errorString.replace(/'/g, '"');

  // Replace ErrorDetail instances with plain objects
  validJSONString = validJSONString.replace(/ErrorDetail/g, "");
  validJSONString = validJSONString.replace(
    /\(string="([^"]+)", code="([^"]+)"\)/,
    '{"string": "$1", "code": "$2"}'
  );

  return validJSONString;
};

const parseErrorString = (errorString) => {
  try {
    // Regular expression to match and extract string and code values
    const match = errorString.match(
      /ErrorDetail\(string='([^']*)', code='([^']*)'\)/
    );

    if (match) {
      const string = match[1]; // Extracted error string
      const code = match[2]; // Extracted error code
      return new ErrorDetail(string, code); // Instantiate ErrorDetail class
    } else {
      return errorString; // Indicate no match found
    }
  } catch (error) {
    console.error("Error parsing error string:", error);
    return null; // Handle parsing errors gracefully
  }
};

class ErrorDetail {
  constructor(string, code) {
    this.string = string;
    this.code = code;
  }
}

export function showErrors(error, defaultMessage) {
  // check errors array and display right error
  if (error) {
    if (error.errors) {
      // Extract phone number and format the error message
      let data;
      try {
        const errorString = error.errors[0]; // Assuming there's only one error
        data = parseErrorString(errorString); // Remove quotes from the string
      } catch (error) {
        console.error("Error parsing error details:", error);
        data = null;
      }
      if (data) {
        showToast("error", data.string || data);
      }
      // let formattedMessage;
      // if (phoneNumber) {
      //   formattedMessage = `Phone number error: ${phoneNumber.args[0]}`; // Access property within ErrorDetail object (assuming structure)
      // } else {
      //   formattedMessage = error.message; // Use original message if phone number extraction fails
      // }

      // console.log(formattedMessage);
    } else {
      showToast(
        "error",
        defaultMessage
          ? defaultMessage
          : error.message
          ? error.message
          : error.detail
      );
    }
  }
}

export const handleUploadImage = async (file) => {
  const preset_key = "ghx4dcwk";
  const formData = new FormData();
  formData.append("file", file);
  formData.append("upload_preset", preset_key);

  const response = await cloudUpload(formData);
  return response;
};

export const isElement = (element) => {
  return React.isValidElement(element);
};

export const formatMoney = (n, currency = "EUR") => {
  return new Intl.NumberFormat(currency === 'EUR' ? 'en-EU' : 'en-NG', {
    style: "currency",
    currency: currency ?? 'EUR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(Number(n));
};

export const debounce =
  (...args) =>
  (fn, ms = 2000) => {
    let timeoutId;

    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => fn.apply(this, args), ms);
    };
  };

export function reducerMultiplexer(o) {
  return function (state, action) {
    if (!o[action.type]) return state;
    return o[action.type](state, action);
  };
}

export const getFromStorage = (key) => {
  return localStorage.getItem(key)
    ? JSON.parse(localStorage.getItem(key) || "")
    : "";
};

export const removeFromStorage = (key) => {
  return localStorage.getItem(key) ? localStorage.removeItem(key) : false;
};

export const saveToStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const formatDate = (dateString) => {
  if (typeof dateString !== "string") return "XXX";
  const options = { year: "numeric", month: "short", day: "numeric" };
  const date = new Intl.DateTimeFormat("en-US", options).format(
    new Date(dateString)
  );
  return date;
};


export function capitalizeFirstLetter(string) {
  if (typeof string !== 'string' || string.length === 0) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
}




export const formatDueDate = (end_date) => {
  const dueDate = new Date(end_date);
  const currentDate = new Date();

  const dueMonthDayFormat = "MMMM d";

  const formattedDueDate = isPast(dueDate)
    ? `Due on ${format(dueDate, dueMonthDayFormat)}`
    : `Due in ${differenceInDays(dueDate, currentDate)} days`;

  return formattedDueDate;
};