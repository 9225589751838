import * as React from "react";
import styled from "styled-components";
import DesignerListCard from "components/DesignerListCard";
const MyHiresTab = ({ myHires }) => {
  return (
    <>
    {myHires.map((designer, i) => {
      return <DesignerListCard designer={designer} key={i} />;
    })}

   
  </>
  );
}
export default MyHiresTab;
