import React from "react";

export default function Wrapper({ children, header }) {
  return (
    <div className="relative">
      <div className="tw-bg-dashboard-banner tw-w-full tw-h-[120px] tw-bg-no-repeat tw-bg-cover">
        {header}
      </div>
      {children}
    </div>
  );
}
