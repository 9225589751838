import React, { useState, useEffect, useCallback } from "react";
import { Card, Col, Dropdown } from "react-bootstrap";
import { Chip, Tab, Tabs, Typography } from "@mui/material";
import CalendarPlusIcon from "assets/images/icons/calendar-plus-01.svg";
import CircleCheckIcon from "assets/images/icons/check-circle.svg";
import VerticalDots from "assets/images/icons/dots-vertical.svg";
import ImagePlaceholder from "assets/images/workspace/image-placeholder.png";
import "./Styles/ListProjects.css";
import ActivitiesTab from "./ActivitiesTab";
import ProjectEscrow from "./ProjectEscrow";
import ChatTab from "./ChatTab";
import MediaTab from "./MediaTab";
import DetailsTab from "./DetailsTab";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { designerDetails } from "services/designerService";
import profileHolderImg from "assets/images/icons/user-01.svg";
import { listCollaborators } from "services/collaboratorService";
import ProjectThumbnail from "components/ProjectThumbnail";
import { ReactComponent as VerticalDotIcon } from "assets/svg/vertical-dot.svg";
import ProjectMiniDetailsCard from "components/ProjectMiniDetailsCard";
import Accordion from "components/Accordion";
import { ReactComponent as NoProject } from "../../../assets/svg/no_project.svg";
import CustomButton from "components/Buttons/Button";
import { ReactComponent as PlusIcon } from "../../../assets/svg/plus-icon.svg";

const ActiveTab = ({ projects, onSelectedProject }) => {
  const [selectedProject, setSelectedProject] = useState(null);
  const [designerInfo, setDesignerInfo] = useState();
  const auth_token = localStorage.getItem("auth_token");
  const [actValue, setActValue] = useState(0);
  const navigate = useNavigate();
  const [collaborators, setCollaborators] = useState([]);

  const activitiesTabChange = (event, newValue) => {
    setActValue(newValue);
  };

  useEffect(() => {
    setSelectedProject(projects.length ? projects[0] : null);
  }, [projects]);

  // const options = { year: "numeric", month: "short", day: "numeric" };
  // const startDate = new Date(selectedProject.created_at);
  // const start_date = new Intl.DateTimeFormat("en-US", options).format(
  //   startDate
  // );
  // const endDate = new Date(selectedProject.end_date);
  // const end_date = new Intl.DateTimeFormat("en-US", options).format(endDate);

  const handleProjectChange = (value) => {
    setSelectedProject(value);
    onSelectedProject(value);
  };

  const handleCreateChildProject = () => {
    const dataToPass = {
      group_project: selectedProject,
      projecttype: "child",
    };

    navigate({
      pathname: "/workspace/create-project",
      state: dataToPass,
    });
  };

  const handleCreateProject = (e) => {
    e.preventDefault();
    navigate("/workspace/create-project");
  };

  const loadCollaborators = async () => {
    const resp = await listCollaborators(selectedProject.id, auth_token);
    setCollaborators(resp);
  };

  // useEffect(() => {
  //   const fetchDesignerDetails = async (designerId) => {
  //     try {
  //       const resp = await designerDetails(designerId, auth_token);
  //       setDesignerInfo(resp);
  //     } catch (error) {
  //       console.error('Error fetching designer information:', error);
  //     }
  //   };
  //   if (selectedProject.bids.length > 0 && selectedProject.bids[0].milestones.length > 0) {
  //     fetchDesignerDetails(selectedProject.bids[0].milestones[0].created_by);
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedProject.bids]);

  // useEffect(() => {
  //   // loadCollaborators();
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [collaborators]);
  console.log(selectedProject);
  const handleClick = useCallback(async (project) => {
    setSelectedProject(project);
  }, []);

  return (
    <div className="">
      <div className="tw-flex tw-flex-row tw-mt-5 tw-gap-[30px]">
        {projects.map((project, i) => {
          return (
            <ProjectThumbnail project={project} key={i} click={handleClick} />
          );
        })}
      </div>

      {projects.length <= 0 && (
        <div className=" tw-mt-[87px] tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full tw-overflow-y-scroll">
          <NoProject />

          <div className="tw-mt-[32px]">
            <h3 className="tw-text-center tw-text-md-semi-bold !tw-text-[36px] tw-text-[#D0D5DD] tw-mb-[32px]">
              No active project yet
            </h3>
            <CustomButton
              click={handleCreateProject}
              leftIcon={<PlusIcon />}
              cls={`tw-w-full`}
            >
              Create New Project
            </CustomButton>
          </div>
        </div>
      )}

      {selectedProject && (
        <div>
          <div className="tw-border-b tw-border-[#EAECF0] tw-py-[8px] tw-flex tw-justify-between tw-items-center tw-my-[16px]">
            <h3 className="tw-text-xl-bold">{selectedProject?.title}</h3>
          </div>

          <div></div>
        </div>
      )}
    </div>
  );
};

export default ActiveTab;
