/* eslint-disable react-hooks/exhaustive-deps */
import {
  CardElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import { Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { listCountries } from "services/commonService";
import { addPaymentMethod } from "services/paymentService";
import styled from "styled-components";
import "./Wallet.css";

const initialFormData = {
  card_name: '',
  email: '',
  street_address: '',
  city: '',
  state: '',
  country: '',
};

const CardComponent = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const auth_token = localStorage.getItem("auth_token");
  const buyer = useSelector((state) => state.buyer.buyer);
  const designer = useSelector((state) => state.designer.designer);
  const selectedAccount = buyer ? buyer : designer;
  const [selectedCountry, setCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAddPaymentMethod = async (payment_method) => {
    const data = {
      card_name: formData.card_name,
      stripe_pm_id: payment_method.id,
      method_type: payment_method.card.brand,
      exp_month: payment_method.card.exp_month,
      exp_year: payment_method.card.exp_year,
      last4: payment_method.card.last4,
      zip_code: payment_method.billing_details.address.postal_code,
      email: formData.email,
      country: selectedCountry,
      state: formData.state,
      city: formData.city,
      street_address: formData.street_address,
      created_by: selectedAccount.id
    };
    try {
      const resp = await addPaymentMethod(data, auth_token);
      if(resp) {
        setFormData(initialFormData);
      }
    } catch (error) {
      console.error("Error adding payment method:", error);
    }
  };

  const getCountries = async () => {
    const response = await listCountries(auth_token);
    setCountries(response);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (error) {
      setError(error.message);
    } else {
      console.log("payment method:", paymentMethod);
      handleAddPaymentMethod(paymentMethod);
    }
  };

  useEffect(() => {
    getCountries();
  }, [countries]);

  const cardElementOptions = {
    style: {
      base: {
        fontSize: "16px",
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      border: "1px solid #ced4da",
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  return (
    <>
      <Row style={{ marginTop: "-30px" }}>
        <Form onSubmit={handleSubmit}>
          <Div>
            <Div2>Add new card</Div2>
            <Div3>Update your billing details</Div3>
            <Img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/497c493a-3560-4723-981f-3304d1ed3e23?"
            />
            <Div4>
              <Div5>
                <Column>
                  <Div6>Card details</Div6>
                </Column>
                <Column2>
                  <Div9 className="mb-3">
                    <Div10>Name on card</Div10>
                    <Form.Control
                      type="text"
                      name="card_name"
                      value={formData.card_name}
                      onChange={handleChange}
                      className="form-control form-control-lg rounded-0"
                    />
                  </Div9>
                  <CardElement options={cardElementOptions} />
                  {error && <div>{error}</div>}
                </Column2>
              </Div5>
            </Div4>
            <Div24 />
            <Div4>
              <Div5>
                <Column>
                  <Div27>Email address</Div27>
                  <Div28>Invoices will be sent to this email address.</Div28>
                </Column>
                <Column2>
                  <Div7>
                    <Div8>
                      <Div9>
                        <Form.Control
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          className="form-control form-control-lg rounded-0"
                        />
                      </Div9>
                    </Div8>
                  </Div7>
                </Column2>
              </Div5>
            </Div4>
            <Div24 />
            <Div4>
              <Div5>
                <Column>
                  <Div27>Street address</Div27>
                </Column>
                <Column2>
                  <Div7>
                    <Div8>
                      <Div9>
                        <Form.Control
                          type="text"
                          name="street_address"
                          value={formData.street_address}
                          onChange={handleChange}
                          className="form-control form-control-lg rounded-0"
                        />
                      </Div9>
                    </Div8>
                  </Div7>
                </Column2>
              </Div5>
            </Div4>
            <Div24 />
            <Div4>
              <Div5>
                <Column>
                  <Div27>City</Div27>
                </Column>
                <Column2>
                  <Div7>
                    <Div8>
                      <Div9>
                        <Form.Control
                          type="text"
                          name="city"
                          value={formData.city}
                          onChange={handleChange}
                          className="form-control form-control-lg rounded-0"
                        />
                      </Div9>
                    </Div8>
                  </Div7>
                </Column2>
              </Div5>
            </Div4>
            <Div24 />
            <Div4>
              <Div5>
                <Column>
                  <Div27>State / Province</Div27>
                </Column>
                <Column2>
                  <Div7>
                    <Div8>
                      <Div9>
                        <Form.Control
                          type="text"
                          name="state"
                          value={formData.state}
                          onChange={handleChange}
                          className="form-control form-control-lg rounded-0"
                        />
                      </Div9>
                    </Div8>
                  </Div7>
                </Column2>
              </Div5>
            </Div4>
            <Div24 />
            <Div4>
              <Div5>
                <Column>
                  <Div27>Country</Div27>
                </Column>
                <Column2>
                  <Div7>
                    <Div8>
                      <Div9>
                        <select
                          name="country"
                          onChange={(e) => setCountry(e.target.value)}
                          className="form-select form-select-lg rounded-0"
                        >
                          <option>Select country</option>
                          {countries.map((country, index) => (
                            <option key={index} value={country.id}>
                              {country.name}
                            </option>
                          ))}
                        </select>
                      </Div9>
                    </Div8>
                  </Div7>
                </Column2>
              </Div5>
            </Div4>
            <Div24 />
            <Div55>
              <Div56>Back</Div56>
              <button type="submit" className="btn-add-card" disabled={!stripe}>
                Add card
              </button>
            </Div55>
          </Div>
        </Form>
      </Row>
    </>
  );
};

export default CardComponent;

const Div = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
`;

const Div2 = styled.div`
  align-self: stretch;
  color: var(--gray-900, #101828);
  width: 100%;
  font: 700 24px/32px Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div3 = styled.div`
  align-self: stretch;
  color: var(--gray-600, #475467);
  margin-top: 4px;
  width: 100%;
  font: 400 14px/20px Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Img = styled.img`
  aspect-ratio: 1042;
  object-fit: contain;
  object-position: center;
  width: 100%;
  align-self: stretch;
  fill: var(--gray-200, #eaecf0);
  overflow: hidden;
  margin-top: 20px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div4 = styled.div`
  align-self: stretch;
  margin-top: 24px;
  width: 100%;
  padding: 0 20px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div5 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 35%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div6 = styled.div`
  color: var(--gray-700, #344054);
  font: 500 14px/20px Inter, sans-serif;
  @media (max-width: 991px) {
    margin-top: 32px;
  }
`;

const Column2 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 65%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div7 = styled.div`
  align-self: stretch;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 32px;
  }
`;

const Div8 = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Div9 = styled.div`
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div10 = styled.div`
  color: var(--gray-700, #344054);
  white-space: nowrap;
  font: 500 14px/20px Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    white-space: initial;
  }
`;


const Div24 = styled.div`
  align-self: stretch;
  background-color: var(--gray-200, #eaecf0);
  display: flex;
  min-height: 1px;
  margin-top: 20px;
  width: 100%;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div27 = styled.div`
  color: var(--gray-700, #344054);
  margin-right: -20px;
  font: 500 14px/20px Inter, sans-serif;
`;

const Div28 = styled.div`
  color: var(--gray-600, #475467);
  margin-right: -20px;
  white-space: nowrap;
  font: 400 14px/20px Inter, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;


const Div55 = styled.div`
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  display: flex;
  margin-top: 20px;
  width: 100%;
  gap: 20px;
  padding: 0 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Div56 = styled.div`
  color: var(--gray-600, #475467);
  margin: auto 0;
  font: 600 14px/20px Inter, sans-serif;
`;
