import { reducerMultiplexer } from "constants/Utils";
import {
  AccountState,
  INIT_ACCOUNT,
  INIT_ACCOUNT_FAILED,
  INIT_ACCOUNT_SUCCESS,
  LIST_ACCOUNT,
  LIST_ACCOUNT_FAILED,
  LIST_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT,
} from "./actions";

export default function accountReducer(state = AccountState, action) {
  return reducerMultiplexer({
    [INIT_ACCOUNT]: (state, action) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
    [INIT_ACCOUNT_FAILED]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    [INIT_ACCOUNT_SUCCESS]: (state, action) => {
      return {
        ...state,
        loading: false,
        active: action.payload,
      };
    },
    [LIST_ACCOUNT]: (state, action) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
    [LIST_ACCOUNT_FAILED]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    [LIST_ACCOUNT_SUCCESS]: (state, action) => {
      console.log(action.payload);
      return {
        ...state,
        loading: false,
        ...action.payload,
      };
    },
    [UPDATE_ACCOUNT]: (state, action) => {
      return {
        ...state,
        loading: false,
        active: { ...state.active, ...action.payload },
      };
    },
  })(state, action);
}
