import React, { useEffect, useState } from "react";
import Footer from "./components/Footer";
import RenderRoutes from "routes/RenderRoutes";
import ROUTES from "routes/ROUTES";
import { ToastContainer } from "react-toastify";
import "react-tooltip/dist/react-tooltip.css";
import "./common/css/style.css";
import "./common/css/custom.css";
import "simplebar/dist/simplebar.min.css";
import "./index.scss";
import "react-toastify/dist/ReactToastify.css";
import StagingAuthPage from "StagingAuthPage";

// import { ThemeProvider } from "@mui/material/styles";
// import MaterialTheme from "./MaterialTheme";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleAuthenticated = () => {
    setIsAuthenticated(true);
  };
  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      setIsAuthenticated(true);
    }
  }, []);
  return (
    <>
      {isAuthenticated ? (
        <>
          <ToastContainer />
          {/* <ThemeProvider theme={MaterialTheme}> */}
          <RenderRoutes routes={ROUTES} />
          {/* <Footer /> */}
          {/* </ThemeProvider> */}
        </>
      ) : (
        <StagingAuthPage onAuthenticated={handleAuthenticated} />
      )}
    </>
  );
};

export default App;
