import React, { useRef } from "react";

export default function AvatarProfilePhoto({
  url,
  icon = (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M66.6693 70C66.6693 65.3481 66.6693 63.0222 66.0951 61.1295C64.8025 56.8682 61.4677 53.5334 57.2064 52.2408C55.3138 51.6666 52.9878 51.6666 48.3359 51.6666H31.6693C27.0174 51.6666 24.6915 51.6666 22.7988 52.2408C18.5375 53.5334 15.2027 56.8682 13.9101 61.1295C13.3359 63.0222 13.3359 65.3481 13.3359 70M55.0026 25C55.0026 33.2843 48.2869 40 40.0026 40C31.7183 40 25.0026 33.2843 25.0026 25C25.0026 16.7157 31.7183 10 40.0026 10C48.2869 10 55.0026 16.7157 55.0026 25Z"
        stroke="#475467"
        stroke-width="6.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  uploadIcon = true,
  handleUpload,
  info,
  loading,
  cls = "tw-rounded-full tw-w-[96px] tw-h-[96px] lg:tw-h-[160px] lg:tw-w-[160px] ",
}) {
  const inputRef = useRef(null);
  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-relative">
      <div className="tw-relative">
        {uploadIcon && (
          <>
            <input
              type="file"
              accept="image/*"
              className="tw-hidden"
              ref={inputRef}
              onChange={(e) => {
                handleUpload(e);
                if (inputRef.current) {
                  inputRef.current.value = null;
                }
              }}
            />
            <button
              className="tw-absolute lg:tw-right-2 lg:tw-bottom-10 tw-bottom-0 tw-right-0 "
              onClick={() => inputRef.current.click()}
            >
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="tw-w-[20px] tw-h-[20px] lg:tw-w-[30px] lg:tw-h-[30px]"
              >
                <path
                  d="M13.6641 4.33624H8.06406C5.82385 4.33624 4.70375 4.33624 3.8481 4.77222C3.09545 5.15571 2.48353 5.76763 2.10004 6.52028C1.66406 7.37593 1.66406 8.49603 1.66406 10.7362V21.9362C1.66406 24.1765 1.66406 25.2966 2.10004 26.1522C2.48353 26.9049 3.09545 27.5168 3.8481 27.9003C4.70375 28.3362 5.82385 28.3362 8.06406 28.3362H19.2641C21.5043 28.3362 22.6244 28.3362 23.48 27.9003C24.2327 27.5168 24.8446 26.9049 25.2281 26.1522C25.6641 25.2966 25.6641 24.1765 25.6641 21.9362V16.3362M9.66403 20.3362H11.8968C12.549 20.3362 12.8751 20.3362 13.182 20.2626C13.4541 20.1972 13.7142 20.0895 13.9528 19.9433C14.2219 19.7784 14.4525 19.5478 14.9137 19.0866L27.6641 6.33624C28.7686 5.23167 28.7686 3.44081 27.6641 2.33624C26.5595 1.23167 24.7686 1.23167 23.6641 2.33624L10.9137 15.0866C10.4525 15.5478 10.2219 15.7784 10.057 16.0475C9.91078 16.2861 9.80303 16.5462 9.73771 16.8183C9.66403 17.1252 9.66403 17.4513 9.66403 18.1036V20.3362Z"
                  stroke="#EAAA08"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </>
        )}
        <div
          className={`tw-flex tw-items-center ${cls} tw-justify-center  tw-border-[4px] tw-bg-[#F2F4F7] tw-border-[#FFFFFF] tw-shadow-md`}
        >
          {loading ? (
            <div className="tw-flex tw-items-center tw-justify-center tw-w-full tw-h-full">
              <svg
                className="tw-animate-spin tw-h-8 tw-w-8 tw-text-gray-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="tw-opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="tw-opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8v8z"
                ></path>
              </svg>
            </div>
          ) : url ? (
            <img
              src={url}
              alt=""
              className="tw-w-full tw-h-full tw-object-cover tw-rounded-full"
            />
          ) : (
            icon
          )}
        </div>
      </div>
      <h6 className="tw-text-xs-semi-bold tw-mt-[8px] tw-text-[#1D2939] !tw-font-[600]">
        {info}
      </h6>
    </div>
  );
}
