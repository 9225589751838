import { HttpMethod, fireRequest } from "./axios";

export const listExperienceLevels = async () => {
  try {
    const response = await fireRequest(
      {
        url: `/project/experience-level/list/`,
        method: HttpMethod.GET,
      },
      true
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};


export const getExperienceLevel = async (id) => {
  try {
    const response = await fireRequest(
      {
        url: `/project/experience-level/details/${id}`,
        method: HttpMethod.GET,
      },
      true
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};
