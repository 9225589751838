import React from 'react';

// import { Container } from './styles';

function AuthLayout({children}) {
   return  <div className="">
      <div className="tw-bg-top-banner tw-w-full tw-h-[231px] tw-absolute tw-top-0"></div>
      <div className="tw-z-9999 tw-fixed tw-flex tw-justify-center tw-w-full tw-items-center tw-h-full tw-overflow-auto">
        {children}
      </div>
      <div className="tw-hidden md:tw-block tw-bg-bottom-banner tw-w-full tw-h-[231px] tw-absolute tw-bottom-0 tw-z-[-2]"></div>
    </div>
}

export default AuthLayout;