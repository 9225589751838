import React, { useState } from "react";
import { timeAgo, formatMoney, capitalizeFirstLetter } from "constants/Utils";
import ImagePlaceholder from "assets/images/workspace/image-placeholder.png";
import CustomButton from "components/Buttons/Button";

export default function JobListCard({
  project,
  onClick,
  onToggleSave,
  isSaved,
  saved,
}) {
  const [hover, isHover] = useState(false);
  return (
    <div
      onMouseEnter={() => isHover(true)}
      onMouseLeave={() => isHover(false)}
      onClick={(e) => {
        e.preventDefault();
        onClick(project);
      }}
      className=" tw-cursor-pointer tw-relative tw-mb-[16px] lg:tw-border-[2px] tw-border-gray-200 tw-bg-gray-25 lg:tw-py-[20px] tw-p-[16px] lg:tw-px-[30px] tw-flex tw-gap-[13px] tw-flex-wrap"
    >
      <>
        <img
          src={
            project?.cover_image_url
              ? project.cover_image_url
              : ImagePlaceholder
          }
          alt="cover"
          className="tw-w-full lg:tw-w-[160px] tw-h-[160px] tw-object-cover tw-hidden lg:tw-block"
        />
        <div className="tw-flex-1 tw-flex tw-flex-col tw-justify-between">
          <div className="tw-flex tw-items-center tw-justify-between tw-w-full tw-mb-[8px]">
            <div className="tw-flex tw-flex-col">
              <div>
                <div className="tw-mb-[6px] tw-flex tw-gap-[8px] tw-items-center">
                  <img
                    src={
                      project?.created_by?.profile_image_url
                        ? project.created_by.profile_image_url
                        : `https://ui-avatars.com/api/?background=EAAA08&color=ffffff&name=NA`
                    }
                    alt=""
                    className="tw-w-[32px] tw-h-[32px] tw-rounded-full tw-object-cover"
                  />
                  <h4 className="tw-text-lg-semi-bold"> {project?.title}</h4>

                  {/* chip */}
                  <div className="tw-h-[24px] tw-py-[2px] tw-px-[10px] tw-bg-[#F2F4F7] tw-text-sm-medium tw-text-[#344054] tw-rounded-[16px]">
                    {capitalizeFirstLetter(project.project_type)}
                  </div>
                </div>
              </div>
              <div className="tw-flex tw-gap-[8px] tw-items-center">
                <h4 className="tw-h-[24px] tw-py-[2px] tw-px-[10px] tw-bg-[#F2F4F7] tw-text-sm-medium tw-text-[#344054] tw-rounded-[16px]">{`${project?.city}, ${project?.country}`}</h4>
                <div className="tw-w-[4px] tw-h-[4px] tw-bg-gray-500 tw-rounded-full"></div>
                <h6 className="!tw-leading-none  tw-text-xs-regular lg:tw-text-sm-semi-bold tw-text-gray-500">
                  {formatMoney(
                    project?.budget?.amount,
                    project?.budget?.currency
                  )}
                </h6>
                <div className="tw-w-[4px] tw-h-[4px] tw-bg-gray-500 tw-rounded-full"></div>
                <h4 className="tw-text-md-regular">
                  {project?.bids.length} Applicants
                </h4>
              </div>
            </div>
            <button
              className=""
              onClick={(e) => {
                e.stopPropagation();
                console.log('here', project, saved)
                onToggleSave(project, saved);
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill={`${isSaved ? "#FAC515" : "none"}`}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 7.8C5 6.11984 5 5.27976 5.32698 4.63803C5.6146 4.07354 6.07354 3.6146 6.63803 3.32698C7.27976 3 8.11984 3 9.8 3H14.2C15.8802 3 16.7202 3 17.362 3.32698C17.9265 3.6146 18.3854 4.07354 18.673 4.63803C19 5.27976 19 6.11984 19 7.8V21L12 17L5 21V7.8Z"
                  stroke="#FAC515"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>
          <p className="tw-text-sm-regular tw-text-gray-500">
            {project.about.substring(0, 400)}
          </p>
          <div className="tw-mt-[24px] tw-flex tw-justify-between">
            <div className="tw-flex tw-flex-1 tw-justify-between lg:tw-justify-normal lg:tw-gap-[31.5px] tw-items-center">
              <h4 className="!tw-text-[12px] tw-text-sm-medium tw-flex lg:!tw-text-[16px] tw-items-center">
                {project?.gender}
                <span className="tw-mx-2 tw-text-[12px] tw-font-[400] lg:!tw-text-[16px]  tw-text-gray-400">
                  Wear
                </span>
              </h4>

              <h5 className="!tw-text-[12px] tw-text-sm-medium tw-flex lg:!tw-text-[16px] tw-items-center">
                {project?.experience_level?.name}{" "}
                <span className="tw-mx-2 tw-text-[12px] tw-font-[400] lg:!tw-text-[16px] tw-text-gray-400">
                  Experience
                </span>{" "}
              </h5>

              <h5 className="!tw-text-[12px] tw-text-sm-medium tw-flex lg:!tw-text-[16px] tw-items-center">
                {project?.language?.name}
                <span className="tw-mx-2 tw-text-[12px] tw-font-[400] lg:!tw-text-[16px] tw-text-gray-400">
                  Language
                </span>
              </h5>
            </div>
            <h4 className="tw-hidden lg:tw-block tw-text-md-regular tw-text-gray-400">
              Posted {`${timeAgo(project?.created_at)}`}
            </h4>
          </div>
        </div>
      </>
      {hover && (
        <div
          className={`tw-flex tw-items-center tw-py-[8px] tw-h-[52px] tw-bottom-0 tw-absolute tw-w-full tw-z-999 tw-bg-white/50 card-blur tw-backdrop-filter tw-backdrop-blur-sm tw-bg-opacity-5 tw-left-0`}
        >
          <CustomButton cls=" tw-px-[16px]  lg:tw-ml-[200px]">
            Apply for Job
          </CustomButton>
        </div>
      )}
    </div>
  );
}
