/* eslint-disable no-script-url */
import { getCurrencySymbol } from "constants/Utils";
import * as React from "react";
import styled from "styled-components";

const DetailsTab = ({project}) => {
  const options = { year: "numeric", month: "short", day: "numeric" };
  const startDate = new Date(project.created_at);
  const start_date = new Intl.DateTimeFormat("en-US", options).format(
    startDate
  );
  const endDate = new Date(project.end_date);
  const end_date = new Intl.DateTimeFormat("en-US", options).format(endDate);

  const acceptedBid = project.bids.filter((bid) => bid.is_accepted).map((acceptedBid) => ({ ...acceptedBid, milestones: acceptedBid.milestones.slice().sort((a, b) => new Date(a.created_at) - new Date(b.created_at)), }));

  return (
    <Div>
      <Div2>
        <Div3>
          <Div4>
            <A href="javascript:void(0)">{project?.title}</A>
            <Div5>
              {project?.about}
            </Div5>
          </Div4>
          <Img
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/90c0f131-4a66-4116-99a1-a8515b64c76c?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/90c0f131-4a66-4116-99a1-a8515b64c76c?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/90c0f131-4a66-4116-99a1-a8515b64c76c?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/90c0f131-4a66-4116-99a1-a8515b64c76c?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/90c0f131-4a66-4116-99a1-a8515b64c76c?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/90c0f131-4a66-4116-99a1-a8515b64c76c?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/90c0f131-4a66-4116-99a1-a8515b64c76c?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/90c0f131-4a66-4116-99a1-a8515b64c76c?"
          />
        </Div3>
        <Div6>
          <Div7>
            <Div8>
              <A2 href="javascript:void(0)">Start date:</A2>
              <A3 href="javascript:void(0)">{start_date}</A3>
            </Div8>
            <Img2
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/6b4e6a2f-e314-4595-8ad2-cdb49e3d2b9a?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/6b4e6a2f-e314-4595-8ad2-cdb49e3d2b9a?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/6b4e6a2f-e314-4595-8ad2-cdb49e3d2b9a?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/6b4e6a2f-e314-4595-8ad2-cdb49e3d2b9a?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/6b4e6a2f-e314-4595-8ad2-cdb49e3d2b9a?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/6b4e6a2f-e314-4595-8ad2-cdb49e3d2b9a?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/6b4e6a2f-e314-4595-8ad2-cdb49e3d2b9a?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/6b4e6a2f-e314-4595-8ad2-cdb49e3d2b9a?"
            />
            <Div9>
              <A4 href="javascript:void(0)">End date</A4>
              <A5 href="javascript:void(0)">{end_date}</A5>
            </Div9>
          </Div7>
          <Div10>
            <A6 href="javascript:void(0)">Project Budget</A6>
            <A7 href="javascript:void(0)">{`${getCurrencySymbol(JSON.parse(project?.budget)?.currency)}${JSON.parse(project?.budget)?.amount}`}</A7>
          </Div10>
        </Div6>
      </Div2>
      <Div11>
        <Div12>
          <A8 href="javascript:void(0)">Project Gallery</A8>
          <Div13>
            <Div14>
              {project.project_gallery.map((image, index) => (
                <Column key={index}>
                <Div15 style={{backgroundImage: `url(${image.image})`, backgroundPosition: "center", backgroundSize: "cover"}}>
                  <Div16>
                    <Img3
                      loading="lazy"
                      srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/8a5a8b06-43e5-4df5-99e1-36a3cad88ceb?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/8a5a8b06-43e5-4df5-99e1-36a3cad88ceb?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/8a5a8b06-43e5-4df5-99e1-36a3cad88ceb?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/8a5a8b06-43e5-4df5-99e1-36a3cad88ceb?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/8a5a8b06-43e5-4df5-99e1-36a3cad88ceb?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/8a5a8b06-43e5-4df5-99e1-36a3cad88ceb?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/8a5a8b06-43e5-4df5-99e1-36a3cad88ceb?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/8a5a8b06-43e5-4df5-99e1-36a3cad88ceb?"
                    />
                    <Div17>
                      {image.image_title}
                    </Div17>
                  </Div16>
                </Div15>
              </Column>
              ))}
              <Column4>
                <Div23 />
              </Column4>
            </Div14>
          </Div13>
        </Div12>
        <Div24>
          <Div25>
            <A10 href="javascript:void(0)">Delivery Details</A10>
            <Img6
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/dc0b0bbf-9bfa-4c5d-8f38-b86c0a8893da?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/dc0b0bbf-9bfa-4c5d-8f38-b86c0a8893da?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/dc0b0bbf-9bfa-4c5d-8f38-b86c0a8893da?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/dc0b0bbf-9bfa-4c5d-8f38-b86c0a8893da?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/dc0b0bbf-9bfa-4c5d-8f38-b86c0a8893da?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/dc0b0bbf-9bfa-4c5d-8f38-b86c0a8893da?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/dc0b0bbf-9bfa-4c5d-8f38-b86c0a8893da?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/dc0b0bbf-9bfa-4c5d-8f38-b86c0a8893da?"
            />
          </Div25>
          <Div26>
            <Div27>
              <Div28>
                <A11 href="javascript:void(0)">Country</A11>
                <A12 href="javascript:void(0)">{project.country?.name}</A12>
              </Div28>
            </Div27>
            <Div29>
              <Div30>
                <A13 href="javascript:void(0)">City</A13>
                <A14 href="javascript:void(0)">{project.city?.name}</A14>
              </Div30>
            </Div29>
            <Div31>
              <Div32>
                <Div33>Province / State/ Zip code</Div33>
                <Div34>
                  <A15 href="javascript:void(0)">Rivers Sate</A15>
                  <A16 href="javascript:void(0)">{project?.zip_code}</A16>
                </Div34>
              </Div32>
            </Div31>
            <Div35>
              <A17 href="javascript:void(0)">Address</A17>
              <A18 href="javascript:void(0)">{project?.address}</A18>
            </Div35>
          </Div26>
        </Div24>
        <Div36>
          <Div37>
            <A19 href="javascript:void(0)">Escrow</A19>
            <Img7
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/02f3f838-dee4-44fb-858b-b8706e46c132?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/02f3f838-dee4-44fb-858b-b8706e46c132?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/02f3f838-dee4-44fb-858b-b8706e46c132?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/02f3f838-dee4-44fb-858b-b8706e46c132?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/02f3f838-dee4-44fb-858b-b8706e46c132?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/02f3f838-dee4-44fb-858b-b8706e46c132?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/02f3f838-dee4-44fb-858b-b8706e46c132?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/02f3f838-dee4-44fb-858b-b8706e46c132?"
            />
          </Div37>
          <Div38>
            {acceptedBid[0].milestones.map((milestone, index) => (
              <Div39 key={index}>
              <Div40>
                <A20 href="javascript:void(0)">{milestone.name}</A20>
                <Div41>
                  <Div42>{milestone.description}</Div42>
                  {milestone.is_approved && (
                    <Img8
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/dace9ede-439e-470e-9098-2fd1e12f1899?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/dace9ede-439e-470e-9098-2fd1e12f1899?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/dace9ede-439e-470e-9098-2fd1e12f1899?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/dace9ede-439e-470e-9098-2fd1e12f1899?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/dace9ede-439e-470e-9098-2fd1e12f1899?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/dace9ede-439e-470e-9098-2fd1e12f1899?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/dace9ede-439e-470e-9098-2fd1e12f1899?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/dace9ede-439e-470e-9098-2fd1e12f1899?"
                  />
                  )}
                </Div41>
              </Div40>
            </Div39>
            ))}
          </Div38>
        </Div36>
        <Div52>
          <Div53>
            <A24 href="javascript:void(0)">Other Details</A24>
            <Img9
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/5581e97e-0e5e-4adf-9759-6c2128f56518?&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/5581e97e-0e5e-4adf-9759-6c2128f56518?&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/5581e97e-0e5e-4adf-9759-6c2128f56518?&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/5581e97e-0e5e-4adf-9759-6c2128f56518?&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/5581e97e-0e5e-4adf-9759-6c2128f56518?&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/5581e97e-0e5e-4adf-9759-6c2128f56518?&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/5581e97e-0e5e-4adf-9759-6c2128f56518?&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/5581e97e-0e5e-4adf-9759-6c2128f56518?"
            />
          </Div53>
          <Div54>
            <Div55>
              <Div56>
                <A25 href="javascript:void(0)">Clothing type</A25>
                <Div57>
                  {project.clothing_type.map((item, index) => (
                  <A26 key={index} href="javascript:void(0)">{item.name}</A26>
                  ))}
                </Div57>
              </Div56>
            </Div55>
            <Div58>
              <Div59>
                <A28 href="javascript:void(0)">Specialist</A28>
                <Div60>
                {project.specialist.map((item, index) => (
                  <A26 key={index} href="javascript:void(0)">{item.value}</A26>
                  ))}
                </Div60>
              </Div59>
            </Div58>
            <Div61>
              <Div62>
                <A33 href="javascript:void(0)">Experience level</A33>
                <A34 href="javascript:void(0)">{project.experience_level.name}</A34>
              </Div62>
            </Div61>
            <Div63>
              <Div64>
                <A35 className="text-capitalize" href="javascript:void(0)">{project.language.name}</A35>
                <A36 className="text-capitalize" href="javascript:void(0)">{project.language_level}</A36>
              </Div64>
            </Div63>
          </Div54>
        </Div52>
      </Div11>
    </Div>
  );
}

export default DetailsTab;

const Div = styled.div`
  align-items: flex-start;
  align-self: end;
  width: 100%;
  // max-width: 1024px;
  display: flex;
  flex-direction: column;
`;

const Div2 = styled.div`
  align-items: flex-start;
  align-self: stretch;
  background: var(--gray-100, #f2f4f7);
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
`;

const Div3 = styled.div`
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
`;

const Div4 = styled.div`
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  padding-top: 4px;
  padding-left: 0px;
  padding-right: 12px;
  padding-bottom: 4px;
  display: flex;
  flex-direction: column;
`;

const A = styled.a`
  align-self: start;
  color: var(--gray-600, #475467);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  margin-top: 2px;
  margin-left: 1px;
  @media (max-width: 991px) {
    margin-left: 1px;
  }
`;

const Div5 = styled.div`
  align-self: stretch;
  color: var(--gray-500, #667085);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-top: 12px;
  margin-bottom: -1px;
`;

const Img = styled.img`
  aspect-ratio: 992;
  object-fit: cover;
  object-position: center;
  width: 100%;
  margin-top: 20px;
  align-self: stretch;
`;

const Div6 = styled.div`
  align-items: flex-start;
  align-self: stretch;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
`;

const Div7 = styled.div`
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
  max-width: 992px;
  @media (max-width: 991px) {
    justify-content: center;
  }
`;

const Div8 = styled.div`
  align-items: flex-start;
  padding-top: 5px;
  padding-left: 1px;
  padding-right: 20px;
  padding-bottom: 5px;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: 10.41015625px;
  flex-wrap: wrap;
  min-width: 181px;
`;

const A2 = styled.a`
  color: var(--gray-500, #667085);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-top: 1px;
  align-self: stretch;
`;

const A3 = styled.a`
  color: var(--gray-800, #1d2939);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  margin-top: 1px;
  align-self: stretch;
`;

const Img2 = styled.img`
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 4px;
  align-self: center;
  min-width: 4px;
`;

const Div9 = styled.div`
  align-items: flex-start;
  padding-top: 5px;
  padding-left: 1px;
  padding-right: 20px;
  padding-bottom: 5px;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: 9.93359375px;
  flex-wrap: wrap;
  min-width: 168px;
`;

const A4 = styled.a`
  color: var(--gray-500, #667085);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-top: 1px;
  align-self: stretch;
`;

const A5 = styled.a`
  color: var(--gray-800, #1d2939);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  margin-top: 1px;
  align-self: stretch;
`;

const Div10 = styled.div`
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-top: 16px;
  padding-top: 8px;
  padding-left: 1px;
  padding-right: 20px;
  padding-bottom: 8px;
  align-self: start;
  display: flex;
  flex-direction: row;
  gap: 10.08984375px;
  flex-wrap: wrap;
`;

const A6 = styled.a`
  color: var(--gray-500, #667085);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-top: 2px;
  align-self: start;
`;

const A7 = styled.a`
  color: var(--gray-800, #1d2939);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  margin-top: 1px;
  align-self: stretch;
`;

const Div11 = styled.div`
  align-items: flex-start;
  align-self: stretch;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
`;

const Div12 = styled.div`
  align-items: flex-start;
  align-self: stretch;
  padding-top: 14px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
`;

const A8 = styled.a`
  align-self: start;
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  margin-left: 1px;
  @media (max-width: 991px) {
    margin-left: 1px;
  }
`;

const Div13 = styled.div`
  align-self: stretch;
  margin-top: 19px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
`;

const Div14 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 33%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div15 = styled.div`
  align-items: flex-end;
  background: linear-gradient(180deg, #f9fafb 0%, #f1f1f1 100%);
  padding-top: 40px;
  padding-left: 19px;
  padding-right: 17px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  @media (max-width: 991px) {
    margin-top: 16px;
  }
`;

const Div16 = styled.div`
  margin-top: 43px;
  margin-bottom: 0px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
`;

const Img3 = styled.img`
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 100%;
  max-width: 32px;
  margin-left: -1px;
  align-self: center;
`;

const Div17 = styled.div`
  overflow: hidden;
  color: var(--gray-700, #344054);
  text-overflow: ellipsis;
  whitespace: nowrap;
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  margin-top: 31px;
  align-self: stretch;
`;


//TODO: clean

// const Column2 = styled.div`
//   display: flex;
//   flex-direction: column;
//   line-height: normal;
//   width: 33%;
//   margin-left: 20px;
//   @media (max-width: 991px) {
//     width: 100%;
//   }
// `;

// const Div18 = styled.div`
//   align-items: flex-end;
//   background: linear-gradient(180deg, #f9fafb 0%, #f1f1f1 100%);
//   padding-top: 39px;
//   padding-left: 20px;
//   padding-right: 19px;
//   padding-bottom: 39px;
//   display: flex;
//   flex-direction: column;
//   @media (max-width: 991px) {
//     margin-top: 16px;
//   }
// `;

// const Div19 = styled.div`
//   width: 100%;
//   max-width: 272px;
//   margin-top: 44px;
//   margin-left: 3px;
//   margin-bottom: -0px;
//   align-self: start;
//   display: flex;
//   flex-direction: column;
//   @media (max-width: 991px) {
//     margin-left: 3px;
//   }
// `;

// const Img4 = styled.img`
//   aspect-ratio: 1;
//   object-fit: cover;
//   object-position: center;
//   width: 100%;
//   max-width: 32px;
//   margin-left: -2px;
//   align-self: center;
// `;

// const Div20 = styled.div`
//   right: 14px;
//   bottom: 19px;
//   overflow: hidden;
//   color: var(--gray-700, #344054);
//   text-overflow: ellipsis;
//   whitespace: nowrap;
//   font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
//   font-style: normal;
//   font-weight: 400;
//   font-size: 11px;
//   margin-top: 35px;
//   align-self: stretch;
// `;

// const Column3 = styled.div`
//   display: flex;
//   flex-direction: column;
//   line-height: normal;
//   width: 33%;
//   margin-left: 20px;
//   @media (max-width: 991px) {
//     width: 100%;
//   }
// `;

// const Div21 = styled.div`
//   align-items: flex-end;
//   background: linear-gradient(180deg, #f9fafb 0%, #f1f1f1 100%);
//   padding-top: 39px;
//   padding-left: 18px;
//   padding-right: 20px;
//   padding-bottom: 39px;
//   display: flex;
//   flex-direction: column;
//   @media (max-width: 991px) {
//     margin-top: 16px;
//   }
// `;

// const Div22 = styled.div`
//   width: 100%;
//   max-width: 155px;
//   margin-top: 44px;
//   margin-bottom: -0px;
//   align-self: start;
//   display: flex;
//   flex-direction: column;
// `;

// const Img5 = styled.img`
//   aspect-ratio: 1;
//   object-fit: cover;
//   object-position: center;
//   width: 100%;
//   max-width: 32px;
//   align-self: end;
// `;

// const A9 = styled.a`
//   right: 17px;
//   bottom: 21px;
//   overflow: hidden;
//   color: var(--gray-700, #344054);
//   text-overflow: ellipsis;
//   whitespace: nowrap;
//   font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
//   font-style: normal;
//   font-weight: 400;
//   font-size: 11px;
//   margin-top: 33px;
//   align-self: start;
// `;

const Column4 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 2%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div23 = styled.div`
  align-items: flex-end;
  background: linear-gradient(180deg, #f9fafb 0%, #f1f1f1 100%);
  display: flex;
  flex-direction: column;
  min-height: 198px;
  @media (max-width: 991px) {
    margin-top: 16px;
  }
`;

const Div24 = styled.div`
  align-items: flex-start;
  align-self: stretch;
  margin-top: 8px;
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
`;

const Div25 = styled.div`
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  padding-left: 1px;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1008px;
`;

const A10 = styled.a`
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  margin-top: 2px;
  align-self: center;
  text-align: center;
  min-width: 123px;
`;

const Img6 = styled.img`
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 24px;
  align-self: stretch;
  min-width: 24px;
`;

const Div26 = styled.div`
  align-items: flex-start;
  align-self: stretch;
  margin-top: 16px;
  padding-top: 3px;
  padding-bottom: 3px;
  display: flex;
  flex-direction: column;
`;

const Div27 = styled.div`
  align-items: center;
  align-self: stretch;
  border-top: 1px solid var(--gray-200, #eaecf0);
  margin-top: -3px;
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
`;

const Div28 = styled.div`
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  padding-left: 1px;
  padding-right: 20px;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
  max-width: 992px;
`;

const A11 = styled.a`
  color: var(--gray-500, #667085);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-top: 2px;
  align-self: center;
  text-align: center;
  min-width: 61px;
`;

const A12 = styled.a`
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid var(--gray-300, #d0d5dd);
  background: var(--base-white, #fff);
  padding-top: 7px;
  padding-left: 11px;
  padding-right: 11px;
  padding-bottom: 7px;
  align-self: stretch;
  min-width: 68px;
  color: var(--gray-700, #344054);
  text-align: center;
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: 1px;
  margin-bottom: -1px;
`;

const Div29 = styled.div`
  align-items: center;
  align-self: stretch;
  border: 0px solid var(--gray-200, #eaecf0);
  margin-top: 8px;
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
`;

const Div30 = styled.div`
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  padding-left: 1px;
  padding-right: 20px;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
  max-width: 992px;
`;

const A13 = styled.a`
  color: var(--gray-500, #667085);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-top: 2px;
  align-self: center;
  text-align: center;
  min-width: 29px;
`;

const A14 = styled.a`
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid var(--gray-300, #d0d5dd);
  background: var(--base-white, #fff);
  padding-top: 7px;
  padding-left: 11px;
  padding-right: 10px;
  padding-bottom: 7px;
  align-self: stretch;
  min-width: 142px;
  color: var(--gray-700, #344054);
  text-align: center;
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: 1px;
  margin-bottom: -1px;
`;

const Div31 = styled.div`
  align-items: center;
  align-self: stretch;
  border: 0px solid var(--gray-200, #eaecf0);
  margin-top: 8px;
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
`;

const Div32 = styled.div`
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  padding-left: 1px;
  padding-right: 20px;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
  max-width: 992px;
`;

const Div33 = styled.div`
  color: var(--gray-500, #667085);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-top: 2px;
  align-self: center;
  text-align: center;
  min-width: 196px;
`;

const Div34 = styled.div`
  align-items: flex-start;
  padding-right: 20px;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: 18px;
  flex-wrap: wrap;
  min-width: 176px;
`;

const A15 = styled.a`
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid var(--gray-300, #d0d5dd);
  background: var(--base-white, #fff);
  padding-top: 9px;
  padding-left: 11px;
  padding-right: 11px;
  padding-bottom: 9px;
  align-self: stretch;
  color: var(--gray-700, #344054);
  text-align: center;
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: -1px;
  margin-bottom: -0px;
`;

const A16 = styled.a`
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid var(--gray-300, #d0d5dd);
  background: var(--base-white, #fff);
  padding-top: 9px;
  padding-left: 11px;
  padding-right: 11px;
  padding-bottom: 9px;
  align-self: stretch;
  color: var(--gray-700, #344054);
  text-align: center;
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: -0px;
  margin-bottom: -0px;
`;

const Div35 = styled.div`
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  width: 100%;
  max-width: 994px;
  margin-top: 15px;
  margin-left: 1px;
  margin-bottom: 2px;
  padding-top: 2px;
  padding-left: 0px;
  padding-right: 20px;
  padding-bottom: 2px;
  align-self: center;
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
`;

const A17 = styled.a`
  color: var(--gray-500, #667085);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-top: 5px;
  align-self: start;
  min-width: 62px;
`;

const A18 = styled.a`
  align-items: flex-start;
  flex: 1 0 0;
  align-self: start;
  border-radius: 8px;
  border: 1px solid var(--gray-300, #d0d5dd);
  background: var(--color-neutrals-00, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  margin-top: -1px;
  padding-top: 18px;
  padding-left: 14px;
  padding-right: 20px;
  padding-bottom: 38px;
  min-width: 300px;
  color: var(--gray-500, #667085);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
`;

const Div36 = styled.div`
  align-items: flex-start;
  align-self: stretch;
  margin-top: 8px;
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
`;

const Div37 = styled.div`
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  padding-left: 1px;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1008px;
`;

const A19 = styled.a`
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  margin-top: 0px;
  align-self: center;
  text-align: center;
  min-width: 55px;
`;

const Img7 = styled.img`
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 24px;
  align-self: stretch;
  min-width: 24px;
`;

const Div38 = styled.div`
  align-items: flex-start;
  align-self: stretch;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
`;

const Div39 = styled.div`
  align-items: center;
  align-self: stretch;
  border-top: 1px solid var(--gray-200, #eaecf0);
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
`;

const Div40 = styled.div`
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  padding-left: 1px;
  padding-right: 20px;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
  max-width: 992px;
`;

const A20 = styled.a`
  color: var(--gray-500, #667085);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-top: 0px;
  align-self: center;
  text-align: center;
  min-width: 99px;
`;

const Div41 = styled.div`
  justify-content: center;
  align-items: flex-start;
  border-radius: 6px;
  border: 1px solid var(--gray-300, #d0d5dd);
  background: var(--base-white, #fff);
  padding-top: 4px;
  padding-left: 11px;
  padding-right: 20px;
  padding-bottom: 4px;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: 9.2265625px;
  flex-wrap: wrap;
  min-width: 187px;
`;

const Div42 = styled.div`
  color: var(--gray-700, #344054);
  text-align: center;
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: 1px;
  align-self: center;
`;

const Img8 = styled.img`
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 20px;
  align-self: stretch;
`;

// const Div43 = styled.div`
//   align-items: center;
//   align-self: stretch;
//   border: 0px solid var(--gray-200, #eaecf0);
//   margin-top: 8px;
//   padding-top: 8px;
//   padding-left: 8px;
//   padding-right: 8px;
//   padding-bottom: 8px;
//   display: flex;
//   flex-direction: column;
// `;

// const Div44 = styled.div`
//   justify-content: space-between;
//   align-items: flex-start;
//   flex: 1 0 0;
//   padding-left: 1px;
//   padding-right: 20px;
//   align-self: stretch;
//   display: flex;
//   flex-direction: row;
//   gap: 20px;
//   flex-wrap: wrap;
//   width: 100%;
//   max-width: 992px;
// `;

// const A21 = styled.a`
//   color: var(--gray-500, #667085);
//   font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
//   font-style: normal;
//   font-weight: 500;
//   font-size: 14px;
//   margin-top: 0px;
//   align-self: center;
//   text-align: center;
//   min-width: 107px;
// `;

// const Div45 = styled.div`
//   justify-content: center;
//   align-items: center;
//   border-radius: 6px;
//   border: 1px solid var(--gray-300, #d0d5dd);
//   background: var(--base-white, #fff);
//   padding-top: 7px;
//   padding-left: 11px;
//   padding-right: 11px;
//   padding-bottom: 7px;
//   align-self: stretch;
//   min-width: 165px;
//   color: var(--gray-700, #344054);
//   text-align: center;
//   font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
//   font-style: normal;
//   font-weight: 500;
//   font-size: 13px;
//   margin-top: 1px;
//   margin-bottom: -1px;
// `;

// const Div46 = styled.div`
//   align-items: center;
//   align-self: stretch;
//   border: 0px solid var(--gray-200, #eaecf0);
//   margin-top: 8px;
//   padding-top: 8px;
//   padding-left: 8px;
//   padding-right: 8px;
//   padding-bottom: 8px;
//   display: flex;
//   flex-direction: column;
// `;

// const Div47 = styled.div`
//   justify-content: space-between;
//   align-items: flex-start;
//   flex: 1 0 0;
//   padding-left: 1px;
//   padding-right: 20px;
//   align-self: stretch;
//   display: flex;
//   flex-direction: row;
//   gap: 20px;
//   flex-wrap: wrap;
//   width: 100%;
//   max-width: 992px;
// `;

// const A22 = styled.a`
//   color: var(--gray-500, #667085);
//   font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
//   font-style: normal;
//   font-weight: 500;
//   font-size: 14px;
//   margin-top: 0px;
//   align-self: center;
//   text-align: center;
//   min-width: 103px;
// `;

// const Div48 = styled.div`
//   justify-content: center;
//   align-items: center;
//   border-radius: 6px;
//   border: 1px solid var(--gray-300, #d0d5dd);
//   background: var(--base-white, #fff);
//   padding-top: 7px;
//   padding-left: 11px;
//   padding-right: 11px;
//   padding-bottom: 7px;
//   align-self: stretch;
//   min-width: 165px;
//   color: var(--gray-700, #344054);
//   text-align: center;
//   font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
//   font-style: normal;
//   font-weight: 500;
//   font-size: 13px;
//   margin-top: 1px;
//   margin-bottom: -1px;
// `;

// const Div49 = styled.div`
//   align-items: center;
//   align-self: stretch;
//   border-bottom: 1px solid var(--gray-200, #eaecf0);
//   margin-top: 8px;
//   padding-top: 8px;
//   padding-left: 8px;
//   padding-right: 8px;
//   padding-bottom: 8px;
//   display: flex;
//   flex-direction: column;
// `;

// const Div50 = styled.div`
//   justify-content: space-between;
//   align-items: flex-start;
//   flex: 1 0 0;
//   padding-left: 1px;
//   padding-right: 20px;
//   align-self: stretch;
//   display: flex;
//   flex-direction: row;
//   gap: 20px;
//   flex-wrap: wrap;
//   width: 100%;
//   max-width: 992px;
// `;

// const A23 = styled.a`
//   color: var(--gray-500, #667085);
//   font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
//   font-style: normal;
//   font-weight: 500;
//   font-size: 14px;
//   margin-top: 2px;
//   align-self: center;
//   text-align: center;
//   min-width: 139px;
// `;

// const Div51 = styled.div`
//   justify-content: center;
//   align-items: center;
//   border-radius: 6px;
//   border: 1px solid var(--gray-300, #d0d5dd);
//   background: var(--base-white, #fff);
//   padding-top: 7px;
//   padding-left: 11px;
//   padding-right: 11px;
//   padding-bottom: 7px;
//   align-self: stretch;
//   min-width: 165px;
//   color: var(--gray-700, #344054);
//   text-align: center;
//   font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
//   font-style: normal;
//   font-weight: 500;
//   font-size: 13px;
//   margin-top: 1px;
//   margin-bottom: -1px;
// `;

const Div52 = styled.div`
  align-items: flex-start;
  align-self: stretch;
  margin-top: 8px;
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
`;

const Div53 = styled.div`
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  padding-left: 1px;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1008px;
`;

const A24 = styled.a`
  color: var(--gray-700, #344054);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  margin-top: -0px;
  align-self: center;
  text-align: center;
  min-width: 100px;
`;

const Img9 = styled.img`
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 24px;
  align-self: stretch;
  min-width: 24px;
`;

const Div54 = styled.div`
  align-items: flex-start;
  align-self: stretch;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
`;

const Div55 = styled.div`
  align-items: center;
  align-self: stretch;
  border-top: 1px solid var(--gray-200, #eaecf0);
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
`;

const Div56 = styled.div`
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  padding-left: 1px;
  padding-right: 20px;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
  max-width: 992px;
`;

const A25 = styled.a`
  color: var(--gray-500, #667085);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-top: 2px;
  align-self: center;
  text-align: center;
  min-width: 101px;
`;

const Div57 = styled.div`
  align-items: flex-start;
  padding-right: 20px;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: 18px;
  flex-wrap: wrap;
  min-width: 149px;
`;

const A26 = styled.a`
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid var(--gray-300, #d0d5dd);
  background: var(--base-white, #fff);
  padding-top: 9px;
  padding-left: 11px;
  padding-right: 11px;
  padding-bottom: 9px;
  align-self: stretch;
  color: var(--gray-700, #344054);
  text-align: center;
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: -1px;
  margin-bottom: -0px;
`;

// const A27 = styled.a`
//   justify-content: center;
//   align-items: center;
//   border-radius: 6px;
//   border: 1px solid var(--gray-300, #d0d5dd);
//   background: var(--base-white, #fff);
//   padding-top: 9px;
//   padding-left: 11px;
//   padding-right: 11px;
//   padding-bottom: 9px;
//   align-self: stretch;
//   color: var(--gray-700, #344054);
//   text-align: center;
//   font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
//   font-style: normal;
//   font-weight: 500;
//   font-size: 13px;
//   margin-top: -0px;
//   margin-bottom: -0px;
// `;

const Div58 = styled.div`
  align-items: center;
  align-self: stretch;
  border: 0px solid var(--gray-200, #eaecf0);
  margin-top: 8px;
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
`;

const Div59 = styled.div`
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  padding-left: 1px;
  padding-right: 20px;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
  max-width: 992px;
`;

const A28 = styled.a`
  color: var(--gray-500, #667085);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-top: 2px;
  align-self: center;
  text-align: center;
  min-width: 73px;
`;

const Div60 = styled.div`
  align-items: flex-start;
  padding-right: 20px;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: 18px;
  flex-wrap: wrap;
  min-width: 293px;
  @media (max-width: 991px) {
    justify-content: center;
  }
`;

// const A29 = styled.a`
//   justify-content: center;
//   align-items: center;
//   border-radius: 6px;
//   border: 1px solid var(--gray-300, #d0d5dd);
//   background: var(--base-white, #fff);
//   padding-top: 9px;
//   padding-left: 11px;
//   padding-right: 11px;
//   padding-bottom: 9px;
//   align-self: stretch;
//   color: var(--gray-700, #344054);
//   text-align: center;
//   font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
//   font-style: normal;
//   font-weight: 500;
//   font-size: 13px;
//   margin-top: -1px;
//   margin-bottom: -0px;
// `;

// const A30 = styled.a`
//   justify-content: center;
//   align-items: center;
//   border-radius: 6px;
//   border: 1px solid var(--gray-300, #d0d5dd);
//   background: var(--base-white, #fff);
//   padding-top: 9px;
//   padding-left: 11px;
//   padding-right: 11px;
//   padding-bottom: 9px;
//   align-self: stretch;
//   color: var(--gray-700, #344054);
//   text-align: center;
//   font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
//   font-style: normal;
//   font-weight: 500;
//   font-size: 13px;
//   margin-top: -0px;
//   margin-bottom: -0px;
// `;

// const A31 = styled.a`
//   justify-content: center;
//   align-items: center;
//   border-radius: 6px;
//   border: 1px solid var(--gray-300, #d0d5dd);
//   background: var(--base-white, #fff);
//   padding-top: 9px;
//   padding-left: 11px;
//   padding-right: 11px;
//   padding-bottom: 9px;
//   align-self: stretch;
//   color: var(--gray-700, #344054);
//   text-align: center;
//   font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
//   font-style: normal;
//   font-weight: 500;
//   font-size: 13px;
//   margin-top: -1px;
//   margin-bottom: -0px;
// `;

// const A32 = styled.a`
//   justify-content: center;
//   align-items: center;
//   border-radius: 6px;
//   border: 1px solid var(--gray-300, #d0d5dd);
//   background: var(--base-white, #fff);
//   padding-top: 7px;
//   padding-left: 10px;
//   padding-right: 11px;
//   padding-bottom: 7px;
//   align-self: stretch;
//   color: var(--gray-700, #344054);
//   text-align: center;
//   font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
//   font-style: normal;
//   font-weight: 500;
//   font-size: 13px;
//   margin-top: 2px;
//   margin-bottom: -1px;
// `;

const Div61 = styled.div`
  align-items: center;
  align-self: stretch;
  border: 0px solid var(--gray-200, #eaecf0);
  margin-top: 8px;
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
`;

const Div62 = styled.div`
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  padding-left: 1px;
  padding-right: 20px;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
  max-width: 992px;
`;

const A33 = styled.a`
  color: var(--gray-500, #667085);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-top: 2px;
  align-self: center;
  text-align: center;
  min-width: 122px;
`;

const A34 = styled.a`
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid var(--gray-300, #d0d5dd);
  background: var(--base-white, #fff);
  padding-top: 7px;
  padding-left: 11px;
  padding-right: 11px;
  padding-bottom: 7px;
  align-self: stretch;
  min-width: 85px;
  color: var(--gray-700, #344054);
  text-align: center;
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: 2px;
  margin-bottom: -1px;
`;

const Div63 = styled.div`
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid var(--gray-200, #eaecf0);
  margin-top: 8px;
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
`;

const Div64 = styled.div`
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  padding-left: 1px;
  padding-right: 20px;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
  max-width: 992px;
`;

const A35 = styled.a`
  color: var(--gray-500, #667085);
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-top: 2px;
  align-self: center;
  text-align: center;
  min-width: 53px;
`;

const A36 = styled.a`
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid var(--gray-300, #d0d5dd);
  background: var(--base-white, #fff);
  padding-top: 9px;
  padding-left: 11px;
  padding-right: 11px;
  padding-bottom: 9px;
  align-self: stretch;
  min-width: 62px;
  color: var(--gray-700, #344054);
  text-align: center;
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: -0px;
  margin-bottom: -0px;
`;
