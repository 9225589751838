import { HttpMethod, fireRequest } from "./axios";

export const listTeamMates = async (userId) => {
  try {
    const response = await fireRequest(
      {
        url: `/team-mate/list/${userId}/`,
        method: HttpMethod.GET,
      },
      true
    );
    return response;
  } catch (error) {
    throw error.data;
  }
};

export const createTeamMate = async (values) => {
  try {
    const response = await fireRequest(
      {
        url: `/team-mate/create/`,
        method: HttpMethod.POST,
        body: values,
      },
      true
    );

    return response.data;
  } catch (error) {
    throw error.data;
  }
};

export const updateTeamMate = async (values) => {
  try {
    const response = await fireRequest(
      {
        url: `/team-mate/update`,
        method: HttpMethod.PUT,
        body: values,
      },
      true
    );

    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const teamMateDetails = async (id) => {
  try {
    const response = await fireRequest(
      {
        url: `/team-mate/details/${id}`,
        method: HttpMethod.GET,
      },
      true
    );
    return response.data;
  } catch (error) {
    throw error.data;
  }
};

export const deleteTeamMate = async (id) => {
  try {
    const response = await fireRequest(
      {
        url: `/team-mate/${id}/delete/`,
        method: HttpMethod.DELETE,
      },
      true
    );

    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};
