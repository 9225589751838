import React, { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import DescriptionStepper from "./DescriptionStepper";
import GalleryStepper from "./GalleryStepper";
import ReviewStepper from "./ReviewStepper";
import "./wizardStyle.css";
import BudgetSteper from "./BudgetStepper";
import { useDispatch, useSelector } from "react-redux";
import { create } from "services/projectService";
import { projectFailure, projectSuccess } from "store/actions/projectActions";
import SuccessModal from "./SuccessModal";
import CollaboratorsStepper from "./CollaboratorsStepper";
// import { createCollaborator } from "store/services/collaboratorService";
import { createRecommendedDesigner } from "services/recommendedDesignerService";
import Wrapper from "../wrapper";
import { useNavigate } from "react-router-dom";

const WizardCreate = (props) => {
  const [loading, isLoading] = useState(false);
  const [steps] = useState([
    {
      key: 1,
      label: "Project Description",
    },
    {
      key: 2,
      label: "Gallery",
    },
    {
      key: 3,
      label:
        props.project_type !== "group"
          ? "Requirement & budgets"
          : "Recommendations & Collaborators",
    },
    {
      key: 4,
      label: "Review",
    },
  ]);

  const [activeStep, setActiveStep] = useState(0);
  const { active } = useSelector((state) => state.account);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const [data, setData] = useState({
    project_type: props.project_type,
    title: "",
    gender: "Male",
    clothing_type: [],
    about: "",
    country: "",
    city: "",
    state: "",
    address: "",
    project_gallery: [],
    specialist: [],
    experience_level: "",
    language: "",
    language_level: "",
    budget: null,
    negotiable: true,
    created_by: active.id,
    end_date: "",
    additional_note: "",
    sizing_template: "",
    zip_code: "",
    cover_image_url: "",
    collaborators: [],
    recommended_designers: null,
    parent_project: props.parent_project?.id ?? null,
    status: "pending",
  });

  const handleCloseModal = () => {
    setShow(false);
  };

  const handleNextStep = async (newData, final = false, cb) => {
    setData((prev) => ({ ...prev, ...newData }));
    console.log(newData);
    if (final) {
    } else {
      setActiveStep(activeStep + 1);
    }
  };
  const filterData = (data) => {
    return Object.fromEntries(
      Object.entries(data).filter(([key, value]) => {
        if (
          value === null ||
          value === undefined ||
          value === "" ||
          value.length <= 0
        )
          return false;

        return true;
      })
    );
  };

  const saveDraft = async (newData) => {
    try {
      isLoading(true);
      const clothing_type_formatted = newData.clothing_type.map(
        (type) => type.id
      );
      let updatedData = newData;
      console.log(clothing_type_formatted);
      updatedData.specialist = newData.specialist.length
        ? [newData.specialist]
        : [];
      updatedData.clothing_type = clothing_type_formatted.length
        ? clothing_type_formatted
        : [];
      updatedData.status = "draft";
      const filteredData = filterData(updatedData);
      if (Object.keys(filteredData).length < 0) return isLoading(false);
      const resp = await create(filteredData);
      if (resp) {
        if (filteredData.recommended_designers && filteredData.recommended_designers.length > 0) {
          const designers_data = {
            project: resp.id,
            recommended_designers: filteredData.recommended_designers,
          };
          const response = await createRecommendedDesigner(designers_data);
          return response;
        }
        isLoading(false);
        dispatch(projectSuccess(resp));
        navigate("/workspace/projects");
      }
    } catch (err) {
      isLoading(false);
      dispatch(projectFailure(err.message));
    }
  };
  const handlePrevStep = (newData, last = false) => {
    setData((prev) => ({ ...prev, ...newData }));
    if (last) {
      props.setIsTypeSelected(false);
    } else {
      setActiveStep((prev) => prev - 1);
    }
  };

  return (
    <Wrapper
      header={
        <div className="lg:tw-p-[32px]">
          <h6 className="tw-mt-5 tw-text-xs-bold !tw-text-[24px]">
            {props.project_type === "solo"
              ? "Create Project"
              : props.project_type === "child"
              ? `Create child project for ${props.parent_project.title}`
              : "Create Group Project"}
          </h6>
          <Row className="tw-mt-[24px]">
            {steps.map((step, i) => {
              return (
                <Col
                  key={i}
                  md={3}
                  xs={3}
                  className={`${activeStep + 1 >= i + 1 ? "active" : ""} ${
                    step.isDone ? "done" : ""
                  } text-dark`}
                >
                  <Row className="gx-2">
                    <Col>
                      <div>
                        <hr
                          className={`${
                            activeStep + 1 >= step.key
                              ? "active-stepper-line"
                              : "inactive-stepper-line"
                          }`}
                        />
                      </div>
                      <div className="tw-hidden lg:tw-flex tw-mt-[12px]">
                        <div
                          className={`${
                            activeStep + 1 === step.key
                              ? "active-stepper-label"
                              : "inactive-stepper-label"
                          }`}
                        >
                          {step.label}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              );
            })}
          </Row>
        </div>
      }
    >
      {/* Start Steppers */}
      <div className="tw-flex lg:tw-justify-start">
        <div className="account-step-components  tw-w-full">
          {/* Components Render */}
          {activeStep === 0 && (
            <DescriptionStepper
              next={handleNextStep}
              prev={handlePrevStep}
              data={data}
              onSave={saveDraft}
              loading={loading}
              isLoading={isLoading}
            />
          )}

          {activeStep === 1 && (
            <GalleryStepper
              next={handleNextStep}
              prev={handlePrevStep}
              data={data}
              onSave={saveDraft}
              loading={loading}
              isLoading={isLoading}
            />
          )}

          {activeStep === 2 && (
            <>
              {(props.project_type === "solo" ||
                props.project_type === "child") && (
                <BudgetSteper
                  next={handleNextStep}
                  prev={handlePrevStep}
                  data={data}
                  onSave={saveDraft}
                  loading={loading}
                  isLoading={isLoading}
                />
              )}

              {props.project_type === "group" && (
                <CollaboratorsStepper
                  next={handleNextStep}
                  prev={handlePrevStep}
                  data={data}
                  onSave={saveDraft}
                  loading={loading}
                  isLoading={isLoading}
                />
              )}
            </>
          )}

          {activeStep === 3 && (
            <ReviewStepper
              next={handleNextStep}
              prev={handlePrevStep}
              data={data}
              loading={loading}
              isLoading={isLoading}
              onSave={saveDraft}
            />
          )}
        </div>
      </div>
      <Modal show={show} handleClose={handleCloseModal}>
        <Modal.Body>
          <SuccessModal
            project_type={props.project_type}
            project_name={data.project_name}
            hide={() => setShow(false)}
          />
        </Modal.Body>
      </Modal>
    </Wrapper>
  );
};
export default WizardCreate;
