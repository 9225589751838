import { listCollaborators } from "services/collaboratorService";
import { listAllProjects, listProjects } from "services/projectService";
import { storageService } from "services/";
import { listBids } from "services/bidService";

export const ProjectState = {
  projects: [],
  active_projects: [],
  ads_projects: [],
  bids_project: [],
  draft_projects: [],
  completed_projects: [],
  loading: true,
  all_active_projects: []
};

export const INIT_PROJECT = "@umojalinn/project/init";
export const INIT_PROJECT_SUCCESS = "@umojalinn/project/init-success";
export const INIT_PROJECT_FAILED = "@umojalinn/project/init-failed";

export const initProject = () => {
  return {
    type: INIT_PROJECT,
    payload: true,
  };
};

export const initProjectSuccess = (data) => {
  return {
    type: INIT_PROJECT_SUCCESS,
    payload: data,
  };
};

export const initProjectFailed = (data) => {
  return {
    type: INIT_PROJECT_FAILED,
    payload: data,
  };
};

export const initProjectAsync = (active) => {
  return async (dispatch) => {
    try {
      dispatch(initProject());
      let account_id = storageService.get("account_id");
      const projects = await listAllProjects();

      //   const projects = await listAllProjects();

      // const collaboratorsPromises = projects.map(async (project) => {
      //   const projectCollaborators = await listCollaborators(project?.id);
      //   return { projectId: project?.id, collaborators: projectCollaborators };
      // });
      // const collaboratorsData = await Promise.all(collaboratorsPromises);

      const buyerProjects = projects.filter(
        (project) => project.created_by.id === account_id 
      );

      const adverts = buyerProjects.filter(
        (project) => project.project_type !== "group" && project.status === "pending"
      );
      const draftProjects =  buyerProjects.filter((project) => project.status === "draft");

      const activeProjects = buyerProjects.filter(
        (project) => project.status === "ongoing"
      );

      const completedProjects = buyerProjects.filter(
        (project) => project.status === "completed"
      );
      const groupProjects = projects.filter((project) => {
        // const projectCollaborators =
        //   collaboratorsData.find((data) => data.projectId === project.id)
        //     ?.collaborators || [];

        // if (project.project_type === "group") {
        //   const isCreatedByCurrentUser = project.created_by.id === account_id;

        //   const isCollaborator = projectCollaborators.some(
        //     (collaborator) => collaborator.buyer.id === account_id
        //   );
        //   return isCreatedByCurrentUser || isCollaborator;
        // }
        return false;
      });

      const bids = [];
      for (const project of buyerProjects) {
        const resp = await listBids(project?.id);
        bids.push(...resp);
      }
      console.log(bids);
      const combinedProjects = [...activeProjects, ...groupProjects];
      const sortedActiveProjects = combinedProjects.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
      dispatch(
        initProjectSuccess({
          projects: buyerProjects,
          bids_projects: bids,
          draft_projects: draftProjects,
          completed_projects: completedProjects,
          all_active_projects: sortedActiveProjects,
          ads_projects: adverts
        })
      );
    } catch (err) {
      dispatch(initProjectFailed(err));
    }
  };
};

export const CREATE_PROJECT = "@umojalinn/project/create";
export const CREATE_PROJECT_SUCCESS = "@umojalinn/project/create-success";
export const CREATE_PROJECT_FAILED = "@umojalinn/project/create-failed";

export const createProject = () => {
  return {
    type: INIT_PROJECT,
    payload: true,
  };
};

export const createProjectSuccess = (data) => {
  return {
    type: INIT_PROJECT_SUCCESS,
    payload: data,
  };
};

export const createProjectFailed = (data) => {
  return {
    type: INIT_PROJECT_FAILED,
    payload: data,
  };
};

export const createProjectAsync = () => {
  return async (dispatch) => {
    try {
      dispatch(createProject());
      const data = await listAllProjects();
      console.log(data);
      dispatch(createProjectSuccess({ data }));
    } catch (err) {
      dispatch(createProjectFailed(err));
    }
  };
};
