import { HttpMethod, fireRequest } from "./axios";

export const listSizingTemplates = async (userId) => {
  try {
    const response = await fireRequest({
      url: `/sizing-template/list/${userId}/`,
      method: HttpMethod.GET
    }, true)

    return response.data;
  } catch (error) {
    throw error.data;
  }
};

export const create = async (values) => {
  try {
    const response = await fireRequest({
      url: `/sizing-template/create/`,
      method: HttpMethod.POST,
      body: values
    }, true)

    return response;
  } catch (error) {
    throw error.data;
  }
};

export const update = async (values) => {
  try {
    const response = await fireRequest({
      url: `/sizing-template/update`,
      body: values,
      method: HttpMethod.PUT
    }, true)

    return response;
  } catch (error) {
    throw error.data;
  }
};

export const details = async (id, authToken) => {
  try {
    const response = await fireRequest({
      url: `/sizing-template/details/${id}`,
      method: HttpMethod.GET
    }, true)

    return response;
  } catch (error) {
    throw error.data;
  }
};
