import React, { useState, useEffect } from "react";
import DiscoverTab from "./DiscoverTab";
import SavedJobsTab from "./SavedJobsTab";
import ClientWatchTab from "./ClientWatchTab";
import { filterJobs, listAllProjects } from "services/projectService";
import HorizontalMain from "components/Tabs/HorizontalMain";
import Loader from "common/Loader";
import { listSavedJobs } from "services/projectService";
import { useSelector } from "react-redux";
import { useCallback } from "react";
import { saveJob } from "services/projectService";
import { capitalizeFirstLetter, showErrors } from "constants/Utils";
import { showToast } from "services/toastService";
import { unSaveJob } from "services/projectService";
import FilterComponent from "components/Filter/Filter";
import Input from "components/Input";
import { Form } from "react-bootstrap";
import CustomButton from "components/Buttons/Button";
import { listExperienceLevels } from "services/experienceLevelService";
import { listLanguages } from "services/languageService";
import { listKeywords } from "services/keywordService";
import { list } from "services/clothingTypeService";
import Radio from "components/Input/Radio";
import { language_levels } from "../Project/Create/Wizard/BudgetStepper";

const jobTabs = [
  { name: "Discover", key: "discover" },
  { name: "Saved Jobs", key: "saved-jobs" },
  { name: "Client Watchlist", key: "client-watch" },
];
const Jobs = () => {
  const [actValue, setActValue] = useState("discover");
  const [projects, setAllProjects] = useState([]);
  const [bids, setBids] = useState([]);
  const [loading, isLoading] = useState(false);
  const [saved_jobs, setSavedJobs] = useState([]);
  const { active } = useSelector((state) => state.account);
  const [show, setShow] = useState(false);
  const [filter, setFilter] = useState(null);
  const [clothing, setClothingTypes] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [experienceLevel, setExperienceLevels] = useState([]);
  const [resultPending, isFetchingResult] = useState(false);
  const [filterResult, setResults] = useState([]);
  const [specialist, setSpecialist] = useState([]);

  useEffect(() => {
    async function fetchClothingType() {
      const response = await list();
      console.log(response, "specialist");
      setClothingTypes(response);
    }
    fetchClothingType();
  }, []);

  useEffect(() => {
    async function fetchLanguages() {
      const response = await listLanguages();
      console.log(response, "languages");
      setLanguages(
        response.sort((a, b) => {
          var textA = a.name?.toUpperCase();
          var textB = b.name?.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        })
      );
    }
    fetchLanguages();
  }, []);

  useEffect(() => {
    async function fetchExperienceLevel() {
      const response = await listExperienceLevels();
      console.log(response, "experience level");
      setExperienceLevels(response);
    }
    fetchExperienceLevel();
  }, []);

  useEffect(() => {
    async function listSpecialist() {
      const response = await listKeywords();
      console.log(response);
      setSpecialist(response);
    }
    listSpecialist();
  }, []);
  // {
  //   title: "string",
  //   date_posted_after: "2024-08-13",
  //   date_posted_before: "2024-08-13",
  //   clothing_type: ["3fa85f64-5717-4562-b3fc-2c963f66afa6"],
  //   location: "string",
  //   currency: "string",
  //   budget_min: "9.78",
  //   budget_max: "8",
  //   project_type: "child",
  //   experience_level: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  //   language: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  //   language_level: null,
  // }
  // const loadBids = async () => {
  //   try {
  //     const resp = await listBids("", designer ? designer.id);
  //     setBids(resp);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  useEffect(() => {
    const loadAllProjects = async () => {
      try {
        isLoading(true);
        const resp = await listAllProjects();
        setAllProjects(resp);
        isLoading(false);
      } catch (err) {
        isLoading(false);
        console.log(err);
      } finally {
      }
    };
    loadAllProjects();
    // loadBids()
  }, []);

  useEffect(() => {
    const loadSavedJobs = async (designerId) => {
      try {
        const resp = await listSavedJobs(designerId);
        setSavedJobs(resp);
      } catch (err) {
        console.log(err);
      }
    };
    loadSavedJobs(active.id);
  }, []);

  const save = useCallback(
    async (project) => {
      const tempId = `${project.id}-temp`; // Unique temporary ID for the optimistic update
      const newJob = { project: { ...project }, id: tempId };

      try {
        // Step 1: Add the job optimistically
        setSavedJobs((prev) => [...prev, newJob]);

        // Step 2: Call the saveJob API
        const data = {
          designer: active.id,
          project: project.id,
        };
        const resp = await saveJob(data);
        console.log(resp, "saved response");

        // Step 3: Update the saved job with the response from the API
        setSavedJobs((prev) =>
          prev.map((job) =>
            job.id === tempId ? { ...resp, project: { ...project } } : job
          )
        );

        showToast("success", "Job Successful Saved");
        return resp;
      } catch (err) {
        // Step 4: Handle errors by removing the optimistically added job
        setSavedJobs((prev) => prev.filter((job) => job.id !== tempId));
        showErrors(err);
      }
    },
    [active.id]
  );

  const unSave = useCallback(async (job) => {
    console.log(job, "Job");
    const tempId = job.id;
    let previousJobs;

    try {
      // Step 1: Optimistically remove the job
      setSavedJobs((prev) => {
        previousJobs = [...prev]; // Save the previous state
        return prev.filter((job) => job.id !== tempId);
      });

      // Step 2: Call the unSaveJob API
      const resp = await unSaveJob(job.id);
      console.log(resp, "unsaved response");

      // Step 3: Confirm the job is removed with the server response
      showToast("success", "Job successfully removed");
      return resp;
    } catch (err) {
      // Step 4: Handle errors by reverting the optimistic update
      setSavedJobs(previousJobs); // Revert to the previous state
      console.log(err);
      showErrors(err);
    }
  }, []);

  const toggleSaveJob = async (projectId, itemId) => {
    if (itemId !== undefined) {
      // If it's saved, unsave it using itemId
      unSave(itemId);
    } else {
      // If it's not saved, save it using projectId
      save(projectId);
    }
  };
  const handleShowResult = async () => {
    try {
      isFetchingResult(true);
      const response = await filterJobs(filter);
      console.log(response, "filter result");
      setShow(false);
      setResults(response.results);
    } catch (err) {
    } finally {
      isFetchingResult(false);
    }
  };
  if (loading || resultPending) return <Loader />;

  const results = filterResult.length ? filterResult : projects;

  return (
    <div className="tw-relative ">
      <div className="tw-bg-job-banner tw-w-full sm:tw-h-[285px] tw-bg-no-repeat tw-bg-cover tw-relative">
        <div className="tw-p-[32px] tw-h-full tw-flex tw-flex-col tw-justify-center">
          <h3 className="tw-text-xs-bold !tw-text-[24px]">Discover Jobs</h3>
          <p className="tw-text-[16px] tw-font-[400] lg:tw-w-[360px] tw-text-gray-500 tw-mt-[4px]">
            Find the perfect job that lets you fully unleash your creative
            potential
          </p>
        </div>
      </div>

      <div className="lg:tw-px-[32px]">
        <HorizontalMain
          tabs={jobTabs}
          activeTab={actValue}
          setActiveTab={setActValue}
        />
        {/* show filter icon */}
      </div>
      {filterResult.length > 0 && (
        <div className="tw-px-[32px] tw-mt-[24px]">
          <h4 className="tw-text-sm-semi-bold">
            {filterResult.length}{" "}
            {filterResult.length > 1 ? "Results" : "Result"}
          </h4>
        </div>
      )}
      <div className="mt-5 tw-relative">
        {actValue === "discover" && (
          <DiscoverTab
            projects={results || projects}
            toggleSaveJob={toggleSaveJob}
            saved_jobs={saved_jobs}
          />
        )}
        {actValue === "saved-jobs" && (
          <SavedJobsTab saved_jobs={saved_jobs} toggleSaveJob={toggleSaveJob} />
        )}
        {actValue === "client-watch" && <ClientWatchTab bids={bids} />}

        {!show && (
          <div
            className="tw-fixed tw-bg-[#EAAA08] tw-w-[36px] tw-h-[86px] tw-px-[6px] tw-py-[8px] tw-right-0 tw-flex tw-flex-col tw-justify-center tw-items-center tw-translate-y-1/2 tw-top-1/2 tw-shadow-sm tw-cursor-pointer"
            onClick={() => setShow(!show)}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 18L9 12L15 6"
                stroke="#EAECF0"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span
              style={{ writingMode: "vertical-rl" }}
              className="-tw-rotate-180 tw-text-yellow-100"
            >
              Filter
            </span>
          </div>
        )}

        {show && (
          <FilterComponent setShow={setShow} show={show}>
            <>
              <div
                className="tw-absolute tw-bg-[#EAAA08] tw-w-[36px] tw-h-[86px] tw-px-[6px] tw-py-[8px] -tw-left-[36px] tw-flex tw-flex-col tw-justify-center tw-items-center tw-translate-y-1/2 tw-top-1/2 tw-shadow-sm tw-cursor-pointer"
                onClick={() => setShow(!show)}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15 18L9 12L15 6"
                    stroke="#EAECF0"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <span
                  style={{ writingMode: "vertical-rl" }}
                  className="-tw-rotate-180 tw-text-yellow-100"
                >
                  Filter
                </span>
              </div>
              {filter && (
                <div
                  className="tw-w-full tw-text-center tw-text-yellow-500 tw-text-sm-regular tw-my-5"
                  onClick={() => {
                    setFilter(null);
                    setResults([]);
                  }}
                >
                  Reset Filter
                </div>
              )}
              {/* <Input
                required={false}
                type="text"
                placeholder="search"
                cls="tw-w-full"
                value={filter?.title}
                name="title"
                id="title"
                onChange={(e) => {
                  setFilter((prev) => ({
                    ...prev,
                    [e.target.name]: e.target.value,
                  }));
                }}
              /> */}
              {/* <div className="tw-flex-1 tw-w-full">
                <Form.Group>
                  <Form.Label className="tw-text-lg-semi-bold">
                    Specialist
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="specialist"
                    onChange={(e) => {
                      setFilter((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.value,
                      }));
                    }}
                    value={filter?.specialist}
                    className="form-control-lg rounded-0"
                  >
                    <option value="">Select</option>
                  </Form.Control>
                </Form.Group>
              </div> */}

              <div className="tw-flex-1 tw-w-full tw-mt-[24px]">
                <Form.Group>
                  <Form.Label className="tw-text-lg-semi-bold">
                    Clothing Category
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="clothing_type"
                    onChange={(e) => {
                      setFilter((prev) => ({
                        ...prev,
                        [e.target.name]: [e.target.value],
                      }));
                    }}
                    value={filter?.clothing_type?.[0] || ""}
                    className="form-control-lg rounded-0"
                  >
                    <option value="">Select</option>
                    {clothing.map((type, index) => {
                      return (
                        <option value={type.id} key={index}>
                          {type.name}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>
              </div>

              <div className="tw-flex-1 tw-w-full tw-mt-[24px]">
                <Form.Group>
                  <Form.Label className="tw-text-lg-semi-bold">
                    Currency
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="currency"
                    id="currency"
                    onChange={(e) => {
                      setFilter((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.value,
                      }));
                    }}
                    value={filter?.currency}
                    className="form-control-lg rounded-0"
                  >
                    <option value="">Select</option>
                    <option value="NGN">NGN</option>
                    <option value="EUR">EUR</option>
                  </Form.Control>
                </Form.Group>
              </div>

              <div className="tw-mt-[24px]">
                <label className="tw-text-lg-semi-bold">Budget Rates</label>
              </div>

              <div className="tw-mt-[24px]">
                <label className="tw-text-lg-semi-bold" htmlFor="project_type">
                  Project Type
                </label>
                <div className="tw-grid tw-grid-cols-2 tw-gap-y-[10px]">
                  {[
                    { value: "", name: "both" },
                    { value: "solo", name: "solo" },
                    { value: "group", name: "group" },
                  ].map((type, index) => {
                    return (
                      <Radio
                        key={index}
                        id={`${type.name.toLowerCase()}-type`}
                        name="project_type"
                        onChange={(e) => {
                          setFilter((prev) => ({
                            ...prev,
                            [e.target.name]: e.target.value,
                          }));
                        }}
                        value={type.value}
                        label={capitalizeFirstLetter(type.name)}
                        checked={filter?.project_type === type.value}
                      />
                    );
                  })}
                </div>
              </div>
              <div className="tw-mt-[24px]">
                <label
                  className="tw-text-lg-semi-bold"
                  htmlFor="experience_level"
                >
                  Experience level
                </label>

                <div className="tw-grid tw-grid-cols-2 tw-gap-[8px]">
                  {experienceLevel.map((type, index) => {
                    return (
                      <Radio
                        key={index}
                        id={`${type.name.toLowerCase()}-type`}
                        name="experience_level"
                        onChange={(e) => {
                          setFilter((prev) => ({
                            ...prev,
                            [e.target.name]: e.target.value,
                          }));
                        }}
                        value={type.id}
                        label={capitalizeFirstLetter(type.name)}
                        checked={filter?.experience_level === type.id}
                      />
                    );
                  })}
                </div>
              </div>
              <div className="tw-flex-1 tw-w-full tw-mt-[24px]">
                <Form.Group>
                  <Form.Label className="tw-text-lg-semi-bold">
                    Language
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="language"
                    onChange={() => {}}
                    value={filter?.language}
                    className="form-control-lg rounded-0"
                  >
                    <option value="">Select</option>
                    {languages.map((language, index) => {
                      return (
                        <option value={language.id} key={index}>
                          {language.name}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>
              </div>
              <div className="tw-mt-[24px]">
                <label className="tw-text-lg-semi-bold">Language Level</label>
                <div className="tw-grid tw-grid-cols-2 tw-gap-[8px]">
                  {language_levels.map((type, index) => {
                    return (
                      <Radio
                        key={index}
                        id={`${type.name.toLowerCase()}-type`}
                        name="language_level"
                        onChange={(e) => {
                          setFilter((prev) => ({
                            ...prev,
                            [e.target.name]: e.target.value,
                          }));
                        }}
                        value={type.name}
                        label={capitalizeFirstLetter(type.name)}
                        checked={filter?.language_level === type.name}
                      />
                    );
                  })}
                </div>
              </div>
              <CustomButton
                cls=" tw-px-[16px] tw-py-[10px] tw-w-full tw-mt-[24px]"
                onClick={handleShowResult}
                disabled={resultPending}
              >
                Show Result
              </CustomButton>
            </>
          </FilterComponent>
        )}
      </div>
    </div>
  );
};

export default Jobs;
