import { fireRequest, HttpMethod } from "./axios";

// TODO: endpoint has fault
export const listProjects = async (buyerId) => {
  try {
    const response = await fireRequest(
      {
        url: `/project/list/?buyer_id=${buyerId}`,
        method: HttpMethod.GET,
      },
      true
    );

    return response.data;
  } catch (error) {
    throw error.data;
  }
};

export const listAllProjects = async () => {
  try {
    const response = await fireRequest(
      {
        url: `/project/list/`,
        method: HttpMethod.GET,
      },
      true
    );

    return response.data;
  } catch (error) {
    throw error.data;
  }
};

export const getProjectDetails = async () => {
  try {
    const response = await fireRequest(
      {
        url: `/project/details/`,
        method: HttpMethod.GET,
      },
      true
    );

    return response.data;
  } catch (error) {
    throw error.data;
  }
};

export const create = async (values) => {
  try {
    const response = await fireRequest(
      {
        url: `/project/create/`,
        method: HttpMethod.POST,
        body: values,
      },
      true
    );

    return response.data;
  } catch (error) {
    throw error.data;
  }
};

export const update = async (values) => {
  try {
    const response = await fireRequest(
      {
        url: `/project/update`,
        method: HttpMethod.PUT,
        body: values,
      },
      true
    );

    return response.data;
  } catch (error) {
    throw error.data;
  }
};

export const details = async (id) => {
  try {
    const response = await fireRequest(
      {
        url: `/project/details/${id}`,
        method: HttpMethod.GET,
      },
      true
    );

    return response.data;
  } catch (error) {
    throw error.data;
  }
};

export const listSavedJobs = async (designerId) => {
  try {
    const response = await fireRequest(
      {
        url: `/project/saved-job/list/${designerId}`,
        method: HttpMethod.GET,
      },
      true
    );

    return response.data;
  } catch (error) {
    throw error.data;
  }
};

export const saveJob = async (values) => {
  try {
    const response = await fireRequest(
      {
        url: `/project/saved-job/create/`,
        method: HttpMethod.POST,
        body: values,
      },
      true
    );

    return response.data;
  } catch (error) {
    throw error.data;
  }
};


export const unSaveJob = async (id) => {
  try {
    const response = await fireRequest(
      {
        url: `/project/saved-job/${id}/delete`,
        method: HttpMethod.DELETE,
      },
      true
    );

    return response.data;
  } catch (error) {
    throw error.data;
  }
};


export const filterJobs = async (payload) => {
  try {
    const response = await fireRequest(
      {
        url: `project/filter/`,
        method: HttpMethod.POST,
        body: payload
      },
      true
    );

    return response;
  } catch (error) {
    throw error.data;
  }
};
