import React from 'react';

const AvatarGroup = ({ avatars , cls}) => {
  return (
    <div className='avatar-group relative'>
      {avatars.map((avatar, index) => (
        <img key={index} src={avatar.imageSrc} alt={avatar.name} className={`${cls}`}
            style={{
            marginLeft: index > 0 ? `-${index * 10}px` : '0px', // Dynamic margin-left
            zIndex: index + 1, // Increase z-index for stacking
          }}
        />
      ))}
    </div>
  );
};

export default AvatarGroup;
