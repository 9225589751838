import CustomButton from "components/Buttons/Button";
import * as React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as NoProject } from "../../../assets/svg/no_project.svg";
import { ReactComponent as PlusIcon } from "../../../assets/svg/plus-icon.svg";
import { ReactComponent as Arrow } from "../../../assets/svg/arrow.svg";

const EmptyProject = () => {
  const navigate = useNavigate();
  const {active} = useSelector((state) => state.account);

  const handleCreateProject = (e) => {
    e.preventDefault();
    navigate("/workspace/create-project");
  };

  return (
    <div className="tw-relative ">
      <div className="tw-bg-dashboard-banner tw-w-full tw-h-[120px] tw-bg-no-repeat tw-bg-cover"></div>

      <div className="tw-absolute tw-top-0 tw-px-[4px] left-0 tw-w-full">
        <Arrow />
        <p className="tw-mx-[60px] tw-w-[240px] tw-font-[400] tw-text-[16px] tw-text-[#667085]">
          <span className="!tw-font-[600]"> Hi {active?.first_name}!</span> you
          can also click here to create a new project anytime
        </p>
      </div>

      <div className=" tw-mt-[87px] tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full tw-overflow-y-scroll">
        <NoProject />

        <div className="tw-mt-[32px]">
          <h3 className="tw-text-center tw-text-md-semi-bold !tw-text-[36px] tw-text-[#D0D5DD] tw-mb-[32px]">
            No active project yet
          </h3>
          <CustomButton
            click={handleCreateProject}
            leftIcon={<PlusIcon />}
            cls={`tw-w-full`}
          >
            Create New Project
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

export default EmptyProject;
