import { toast } from "react-toastify";

export const showToast = (type, msg) => {
  switch (type) {
    case "success":
      return toast.success(msg, {
        position: "top-right",
        autoClose: 3000
      });

    case "error":
      return toast.error(msg, {
        position: "top-right",
        autoClose: 3000
      });

    default:
      return toast(msg, {
        position: "top-right",
        autoClose: 3000
      });
  }
};
