import React, { useState, useEffect } from "react";
import { Row, Card, Col, Modal } from "react-bootstrap";
import { Typography, Grid, Paper } from "@mui/material";
import EyeIcon from "assets/images/icons/eye-open-yellow-icon.svg";
import CardMockupImg from "assets/images/card-mockup.jpg";
import RecentTransactions from "./RecentTransactions";
import styled from "styled-components";
import VerifyWalletModal from "./VerifyWalletModal";
import { useSelector } from "react-redux";
import Deposit from "./Deposit";
import AddCard from "./AddCard";
import { listPaymentMethod, listWallets } from "services/paymentService";
import { getCurrencySymbol } from "constants/Utils";
import "./Wallet.css";
// import { useNavigate } from "react-router-dom";

const Wallet = () => {
  const [show, setShow] = useState(true);
  const token = localStorage.getItem("auth_token");
  const [componentIndex, setComponentIndex] = useState(-1);
  const { active } = useSelector((state) => state.account);

  const selectedAccount = active;
  const [payment_cards, setPaymentCards] = useState([]);
  const [otherPaymentMethods, setOtherPaymentMethods] = useState([]);
  const [wallets, setUserWallets] = useState([]);
  const [selectedWallet, setSelectedWallet] = useState(wallets[0]);
  // const navigate = useNavigate();

  const handleCloseModal = () => {
    setComponentIndex(0);
    setShow(false);
  };

  const handleComponentIndex = (indexValue, walletobject) => {
    setComponentIndex(indexValue);
    setSelectedWallet(walletobject);
  };

  const loadPaymentMethods = async () => {
    const resp = await listPaymentMethod(selectedAccount.id, token);
    const cards = resp.filter((item) => item.stripe_pm_id);
    const other_methods = resp.filter((item) => item.account_id);
    setPaymentCards(cards);
    setOtherPaymentMethods(other_methods);
  };

  const loadWallets = async () => {
    const resp = await listWallets(selectedAccount.id, token);
    setUserWallets(resp);
  };

  const handleRedirectToDashboard = () => {
    if (active.accountType === "buyer") {
      // navigate("/workspace/projects");
    } else {
      // navigate("/workspace/dashboard");
    }
  };

  // useEffect(() => {
  //   loadPaymentMethods();
  //   loadWallets();
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <div className="tw-relative">
      <div className="tw-bg-dashboard-banner tw-w-full tw-h-[120px] tw-bg-no-repeat tw-bg-cover tw-relative">
        <div className="tw-p-[32px] tw-h-full tw-flex tw-flex-col tw-justify-center">
          <h3 className="tw-text-xs-bold !tw-text-[24px]">Wallet</h3>
        </div>
      </div>
      {/* {componentIndex === 0 && (
        <>
          <Row>
            <Card className="projects-tab-bg rounded-0 p-0 mx-0">
              <Card.Body className="p-0">
                <Typography className="mt-5">
                  <h3>
                    <strong>Wallet</strong>
                  </h3>
                </Typography>
              </Card.Body>
            </Card>
          </Row>
          <Row>
            <Col md={7}>
              <Grid container spacing={3}>
                {wallets.map((wallet, index) => (
                  <Grid item md={12} key={index}>
                    <Paper
                      elevation={0}
                      style={{
                        padding: "20px",
                        borderRadius: 0,
                        border: "2px solid #F2F4F7",
                      }}
                    >
                      <Row>
                        <Typography variant="h6">
                          {wallet.currency === "eur"
                            ? "Euro Wallet"
                            : wallet.currency === "usd"
                            ? "Dallar Wallet"
                            : wallet.currency === "ngn"
                            ? "Naira Wallet"
                            : "No wallet"}
                        </Typography>
                      </Row>
                     
                        <Row className="mt-4">
                          <Col>
                            <Typography>Current balance </Typography>
                          </Col>
                          {buyer && (
                          <Col className="d-flex justify-content-end">
                            <button
                            className="withdraw-btn"
                            type="button"
                            onClick={() => handleComponentIndex(1, wallet)}
                          >
                            Deposit{" "}
                            <Img7
                              loading="lazy"
                              src="https://cdn.builder.io/api/v1/image/assets/TEMP/ca3e931479d09700ac0b7a64c6cafd8f1ea59875a5e81e606987d961a91b05f5?"
                            />
                          </button>
                          </Col>
                          )}
                        </Row>
                      <Row className="mt-3">
                        <Col>
                          <Typography fontWeight={600} variant="h4">
                            {`${getCurrencySymbol(wallet.currency)}`}
                            {wallet.balance}{" "}
                          </Typography>
                        </Col>
                        {designer && (
                          <Col className="d-flex justify-content-end">
                          <img src={EyeIcon} alt="icon" />
                          <button
                            className="withdraw-btn"
                            type="button"
                            onClick={() => handleComponentIndex(1, wallet)}
                          >
                            Withdraw{" "}
                            <Img7
                              loading="lazy"
                              src="https://cdn.builder.io/api/v1/image/assets/TEMP/ca3e931479d09700ac0b7a64c6cafd8f1ea59875a5e81e606987d961a91b05f5?"
                            />
                          </button>
                        </Col>
                        )}
                      </Row>
                    </Paper>
                  </Grid>
                ))} */}

      {/* Money in Escrows */}
      {/* <Grid item md={12}>
                  <Paper
                    elevation={0}
                    style={{
                      padding: "20px",
                      borderRadius: 0,
                      border: "2px solid #F2F4F7",
                    }}
                  >
                    <Row>
                      <Col>
                        <Typography>Money in Escrows </Typography>
                      </Col>
                      <Col className="d-flex justify-content-end">
                        <img src={DropDownIcon} alt="icon" />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <Typography fontWeight={600} variant="h5">
                          $0.00{" "}
                        </Typography>
                      </Col>
                    </Row>
                  </Paper>
                </Grid> */}
      {/* {buyer && (
                  <Grid item md={12} spacing={0}>
                    <Paper
                      elevation={0}
                      style={{
                        padding: "20px",
                        borderRadius: 0,
                        border: "2px solid #F2F4F7",
                      }}
                    >
                      <Div>
                        <Div2>
                          <Div3>Your cards</Div3>
                          <Img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/42601be0-484b-42ee-9080-8c74a2fc0d90?"
                          />
                        </Div2>
                        <Div4>
                          <Div5>
                            {payment_cards.map((item, index) => (
                              <Column key={index}>
                                <Div6>
                                  <Img2 loading="lazy" srcSet={CardMockupImg} />
                                  <Div7>
                                    <Div8>Umoja linn</Div8>
                                    <Img3
                                      loading="lazy"
                                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/6b189c01-e77a-4c8f-bca3-6d6af6956beb?"
                                    />
                                  </Div7>
                                  <Div9>
                                    <Div10>{item?.card_name}</Div10>
                                    <Div11>
                                      {item.exp_month}/{item.exp_year}
                                    </Div11>
                                  </Div9>
                                  <Div12>
                                    <Div13>XXXX XXXX XXXX {item.last4}</Div13>
                                    {item.method_type === "visa" && (
                                      <Img4
                                        loading="lazy"
                                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/d6e6504a-daf3-41f4-8bb0-076e87eb585b?"
                                        alt="icon"
                                      />
                                    )}
                                    {item.method_type === "mastercard" && (
                                      <Img4
                                        loading="lazy"
                                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/b0ac2a04-2594-405c-bd42-1eb06d12f5c2?"
                                        alt="icon"
                                      />
                                    )}
                                  </Div12>
                                </Div6>
                              </Column>
                            ))}
                            <Column2
                              style={{ cursor: "pointer" }}
                              onClick={() => handleComponentIndex(2)}
                            >
                              <Div14>
                                <Div15>Add New Card</Div15>
                                <Img5
                                  loading="lazy"
                                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/22feaec6-2d54-43ed-8ade-cc78f333f669?"
                                />
                              </Div14>
                            </Column2>
                          </Div5>
                        </Div4>
                        <Img6
                          loading="lazy"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/3e02294a-4034-4394-a6f4-bc7b8a1c7828?"
                        />
                        <Div16>Manage cards</Div16>
                      </Div>
                    </Paper>
                  </Grid>
                )}
              </Grid>
            </Col>
            <Col md={4}>
              <RecentTransactions />
            </Col>
          </Row>
        </>
      )} */}
      {/* {componentIndex === 1 && (
        <Deposit
          payment_cards={payment_cards}
          selectedWallet={selectedWallet}
          other_payment_methods={otherPaymentMethods}
        />
      )}
      {componentIndex === 2 && <AddCard />} */}
      {/* <Modal
        show={show}
        onHide={handleCloseModal}
        className="rounded-0"
        centered
        backdrop="static"
        style={{
          boxShadow:
            "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
        }}
      >
        <Modal.Body>
          <VerifyWalletModal
            onHide={handleCloseModal}
            onRedirectToDash={handleRedirectToDashboard}
          />
        </Modal.Body>
      </Modal> */}
    </div>
  );
};

export default Wallet;

const Div = styled.div`
  align-items: flex-start;
  border: 1px solid var(--gray-200, #eaecf0);
  background-color: var(--color-neutrals-00, #fff);
  display: flex;
  margin-top: 20px;
  width: 100%;
  flex-direction: column;
  padding: 18px 0;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div2 = styled.div`
  align-self: center;
  display: flex;
  width: 100%;
  gap: 16px;
  padding: 0 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Div3 = styled.div`
  color: var(--gray-900, #101828);
  font: 600 18px/28px Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Img = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
  overflow: hidden;
  align-self: start;
  max-width: 100%;
`;

const Div4 = styled.div`
  justify-content: space-between;
  align-self: center;
  margin-top: 20px;
  width: 100%;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div5 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 50%;
  margin-left: 0px;
  border-radius: 50px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div6 = styled.div`
  disply: flex;
  flex-direction: column;
  box-shadow: 7.64557px 9.55696px 15.29114px 0px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  position: relative;
  display: flex;
  aspect-ratio: 1.7;
  flex-grow: 1;
  padding: 18px 15px;
  @media (max-width: 991px) {
    margin-top: 10px;
  }
`;

const Img2 = styled.img`
  aspect-ratio: 16 / 9;
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 5%;
  object-position: center;
`;

const Div7 = styled.div`
  position: relative;
  display: flex;
  padding-right: 8px;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`;

const Div8 = styled.div`
  color: var(--base-white, var(--color-neutrals-00, #fff));
  margin: auto 0;
  font: 600 15px/19px Inter, sans-serif;
`;

const Img3 = styled.img`
  aspect-ratio: 0.83;
  object-fit: contain;
  object-position: center;
  width: 19px;
  overflow: hidden;
  align-self: stretch;
  max-width: 100%;
`;

const Div9 = styled.div`
  position: relative;
  display: flex;
  margin-top: 84px;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const Div10 = styled.div`
  color: var(--base-white, var(--color-neutrals-00, #fff));
  letter-spacing: 0.5734177589416504px;
  text-transform: uppercase;
  font: 600 11px/16px Manrope, sans-serif;
`;

const Div11 = styled.div`
  color: var(--base-white, var(--color-neutrals-00, #fff));
  text-align: right;
  letter-spacing: 0.5734177589416504px;
  white-space: nowrap;
  font: 600 11px/16px Manrope, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div12 = styled.div`
  position: relative;
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 20px;
`;

const Div13 = styled.div`
  color: var(--base-white, var(--color-neutrals-00, #fff));
  letter-spacing: 2.2936709403991697px;
  margin-top: 14px;
  font: 600 15px/21px Manrope, sans-serif;
`;

const Img4 = styled.img`
  aspect-ratio: 1.43;
  object-fit: contain;
  object-position: center;
  width: 43px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  align-self: stretch;
  max-width: 100%;
`;

const Column2 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 50%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div14 = styled.div`
  border-radius: 19.11px;
  background-color: var(--gray-100, #f2f4f7);
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: start;
  width: 100%;
  margin: 0 auto;
  padding: 28px 80px 50px 24px;
  @media (max-width: 991px) {
    margin-top: 10px;
    padding: 0 20px;
  }
`;

const Div15 = styled.div`
  color: var(--gray-700, #344054);
  white-space: nowrap;
  font: 600 15px/19px Inter, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Img5 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 41px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: 37px 0 15px;
`;

const Img6 = styled.img`
  aspect-ratio: 662;
  object-fit: contain;
  object-position: center;
  width: 100%;
  align-self: stretch;
  fill: var(--gray-200, #eaecf0);
  overflow: hidden;
  margin-top: 20px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div16 = styled.div`
  color: var(--gray-700, #344054);
  white-space: nowrap;
  justify-content: center;
  border: 1px solid var(--gray-300, #d0d5dd);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  background-color: var(--base-white, #fff);
  align-self: end;
  width: 128px;
  max-width: 100%;
  margin: 16px 24px 0 0;
  padding: 10px 16px;
  font: 600 14px/20px Inter, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
    margin-right: 10px;
  }
`;
const Img7 = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 20px;
`;
