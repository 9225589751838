import * as React from "react";
import DesignerListCard from "components/DesignerListCard";

const DiscoverTab = ({ designers }) => {
  return (
    <>
      {designers.map((designer, i) => {
        return <DesignerListCard designer={designer} key={i} />;
      })}

     
    </>
  );
};
export default DiscoverTab;

