import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as ArrowLeft } from "assets/svg/arrow-left.svg";
import { ReactComponent as BookMark } from "assets/svg/bookmark.svg";
import WorkHistoryCard from "components/WorkHistoryCard";
import { details } from "services/buyerService";
import { formatDate } from "constants/Utils";
import Loader from "common/Loader";

const ClientProfile = (props) => {
  const navigate = useNavigate();
  const { slug, clientId } = useParams();
  const [profile, setProfile] = React.useState(null);
  const [loading, isLoading] = React.useState(true);
  console.log(slug, clientId);
  const handleBack = () => {
    navigate(`/workspace/jobs/${slug}`);
  };

  React.useEffect(() => {
    async function fetch() {
      try {
        isLoading(true);
        const resp = await details(clientId);
        console.log(resp);
        setProfile(resp);
      } catch (err) {
      } finally {
        isLoading(false);
      }
    }
    fetch();
  }, [clientId]);

  if (loading) <Loader />;

  return (
    <div className="tw-relative">
      <button
        className="tw-flex tw-gap-[4px] tw-px-[16px] tw-py-[10px] tw-mb-[24px]"
        type="button"
        onClick={handleBack}
      >
        <ArrowLeft /> Back{" "}
      </button>

      <div className="tw-flex tw-justify-between tw-px-[32px] tw-items-end">
        <div className="tw-flex tw-gap-[24px] tw-items-end">
          <img
            src={`${profile?.profile_image_url}`}
            alt=""
            className="tw-w-[160px] tw-h-[160px]  tw-border-[4px] tw-border-gray-25 tw-object-cover"
          />
          <div>
            <h5 className="tw-text-sm-semi-bold !tw-text-[30px]">
              {profile?.last_name} {profile?.first_name}
            </h5>
            <span className="tw-text-[16px] tw-text-[#475467]">
              Member since {formatDate(profile?.created_at)}{" "}
            </span>
          </div>
        </div>
        <div>
          <button className="tw-px-[14px] tw-py-[12px] tw-shadow-sm tw-border tw-border-[#D0D5DD]">
            <BookMark />
          </button>
        </div>
      </div>
      <div className="tw-px-[32px]">
        <p className="tw-text-md-medium tw-my-[24px]">About Client</p>

        <div className="tw-grid tw-grid-cols-4 tw-justify-end tw-bg-[#F9FAFB] tw-gap-y-[24px] tw-px-[24px] tw-py-[20px] ">
          <div>
            <p className="tw-text-sm-medium tw-text-[#667085]">Location</p>
            <span className="tw-text-md-semi-bold tw-text-[#344054]">
              {profile?.country}
            </span>
          </div>
          <div>
            <p className="tw-text-sm-medium tw-text-[#667085]">Total Spent</p>
            <span className="tw-text-md-semi-bold tw-text-[#344054]">0</span>
          </div>
          <div>
            <p className="tw-text-sm-medium tw-text-[#667085]">Total Jobs</p>
            <span className="tw-text-md-semi-bold tw-text-[#344054]">
              {
                profile?.projects?.filter(
                  (project) =>
                    project.status === "draft" || project.status === "pending"
                ).length
              }{" "}
              Jobs
            </span>
          </div>
          <div>
            <p className="tw-text-sm-medium tw-text-[#667085]">Reviews</p>
            <span className="tw-text-md-semi-bold tw-text-[#344054]">0</span>
          </div>
          <div>
            <p className="tw-text-sm-medium tw-text-[#667085]">Language</p>
            <span className="tw-text-md-semi-bold tw-text-[#344054]">
              English, Fluent
            </span>
          </div>
        </div>
      </div>
      <div className="tw-px-[32px] tw-flex tw-flex-col tw-gap-y-[24px] tw-my-[24px]">
        {/* <WorkHistoryCard /> */}
      </div>
    </div>
  );
};

export default ClientProfile;
