const initialState = {
    messages: null,
    error: null,
  };
  
  const messageReducer = (state = initialState, action) => {
    switch (action.type) {
      case "MESSAGE_SUCCESS":
        return {
          ...state,
          messages: action.payload,
          error: null,
        };
      case "MESSAGE_FAILURE":
        return {
          ...state,
          messages: null,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default messageReducer;
  