import CustomButton from 'components/Buttons/Button'
import React from 'react'


export default function Pagination() {
  return (
    <div className="tw-flex tw-px-[24px] tw-py-[12px] tw-justify-between tw-items-center  ">
        <div className='tw-text-sm-medium tw-text-[#344054]'>Page 1 of 1 </div>
        <div className='tw-flex tw-gap-[12px]'>
        <CustomButton
                  click={() =>{} }
                  buttonVariant={"tertiary"}
                  type={"button"}
                  cls={` hover:!tw-bg-transparent tw-px-[14px] tw-py-[8px] `}
                >
                  Previous
                </CustomButton>
                <CustomButton
                  click={() => {}}
                  buttonVariant={"tertiary"}
                  type={"button"}
                  cls={` hover:!tw-bg-transparent tw-px-[14px] tw-py-[8px] `}
                >
                  Next
                </CustomButton>
        </div>
    </div>
  )
}
