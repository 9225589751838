import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import MaterialTheme from 'MaterialTheme';

const withMaterialUITheme = () => (WrappedComponent) => {
  return (props) => (
    <ThemeProvider theme={MaterialTheme}>
      <WrappedComponent {...props} />
    </ThemeProvider>
  );
};

export default withMaterialUITheme;