import * as React from "react";
import styled from "styled-components";

const RecentTransactions = (props) => {
  return (
    <Div>
      <Div2>
        <Div3>Recent transactions</Div3>
        <Img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/3dbb8ba0-d568-4fc7-9d8d-7e7e98a1441b?"
        />
      </Div2>
      {/* <Div4>
        <Div5>
          <Div6>
            <Div7>
              <Img2
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/980828c0-8a08-4916-91a8-45e6a9ddc38e?"
              />
              <Div8>
                <Div9>Fund Escrow</Div9>
                <Div10>Chioma’s Wedding </Div10>
              </Div8>
            </Div7>
            <Div11>
              <Img3
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/690873de-34dc-4909-984d-71ff4eb9a288?"
              />
              <Div12>
                <Div13>Fund Escrow</Div13>
                <Div14>My Agbada</Div14>
              </Div12>
            </Div11>
            <Div15>
              <Img4
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/63cda802-cf1e-48c3-8dc9-034462378b55?"
              />
              <Div16>
                <Div17>Stripe deposit</Div17>
                <Div18>samuel@gmail.com.com</Div18>
              </Div16>
            </Div15>
            <Div19>
              <Img5
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/05247ac7-2820-4c56-a35e-d00ccf5fddf0?"
              />
              <Div20>
                <Div21>Visa deposit</Div21>
                <Div22>Charles Alexander</Div22>
              </Div20>
            </Div19>
            <Div23>
              <Img6
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/4675dcae-0273-4937-9e00-64204ed5bb80?"
              />
              <Div24>
                <Div25>PayPal deposit</Div25>
                <Div26>samuel@gmail.com.com</Div26>
              </Div24>
            </Div23>
            <Div27>
              <Img7
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/4e27e2c4-4677-40e3-a850-3782becef6e0?"
              />
              <Div28>
                <Div29>Apple Pay deposit</Div29>
                <Div30>samuelalex@gmail.com</Div30>
              </Div28>
            </Div27>
            <Div31>
              <Img8
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/bfda39d0-fa79-4b65-911a-10714326d10c?"
              />
              <Div32>
                <Div33>Visa Deposit</Div33>
                <Div34>Charles Alexander</Div34>
              </Div32>
            </Div31>
            <Div35>
              <Img9
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/866572bd-ea3b-4d84-9cec-c1d3cca48a17?"
              />
              <Div36>
                <Div37>Fund Escrow</Div37>
                <Div38>My Agbada</Div38>
              </Div36>
            </Div35>
            <Div39>
              <Img10
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/82a8c786-248b-46f8-bc84-027f6b8cdf13?"
              />
              <Div40>
                <Div41>Stripe deposit</Div41>
                <Div42>samuel@gmail.com.com</Div42>
              </Div40>
            </Div39>
            <Div43>
              <Img11
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/2e2d80f3-78dc-471f-9757-78cb36601e3d?"
              />
              <Div44>
                <Div45>Stripe deposit</Div45>
                <Div46>samuel@gmail.com.com</Div46>
              </Div44>
            </Div43>
          </Div6>
          <Div47>
            <Div48>
              <Div49>+ $244.00</Div49>
              <Div50>26/02/23</Div50>
            </Div48>
            <Div51>
              <Div52>+ $326.00</Div52>
              <Div53>26/02/23</Div53>
            </Div51>
            <Div54>
              <Div55>+ $408.00</Div55>
              <Div56>26/02/23</Div56>
            </Div54>
            <Div57>
              <Div58>+ $628.00</Div58>
              <Div59>26/02/23</Div59>
            </Div57>
            <Div60>
              <Div61>+ $166.00</Div61>
              <Div62>26/02/23</Div62>
            </Div60>
            <Div63>
              <Div64>+ $250.00</Div64>
              <Div65>26/02/23</Div65>
            </Div63>
            <Div66>
              <Div67>+ $144.00</Div67>
              <Div68>26/02/23</Div68>
            </Div66>
            <Div69>
              <Div70>+ $408.00</Div70>
              <Div71>26/02/23</Div71>
            </Div69>
            <Div72>
              <Div73>+ $408.00</Div73>
              <Div74>26/02/23</Div74>
            </Div72>
            <Div75>
              <Div76>+ $408.00</Div76>
              <Div77>26/02/23</Div77>
            </Div75>
          </Div47>
        </Div5>
        <Div78>Show more</Div78>
      </Div4> */}
    </Div>
  );
};

export default RecentTransactions;

const Div = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
`;

const Div2 = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 16px;
  padding: 0 20px;
`;

const Div3 = styled.div`
  color: var(--gray-900, #101828);
  font: 600 18px/28px Inter, sans-serif;
`;

const Img = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
  overflow: hidden;
  align-self: start;
  max-width: 100%;
`;

// const Div4 = styled.div`
//   border-radius: 8px;
//   background-color: var(--color-neutrals-00, #fff);
//   display: flex;
//   margin-top: 20px;
//   width: 100%;
//   flex-direction: column;
//   padding: 0 20px;
// `;

// const Div5 = styled.div`
//   border-top: 1px solid var(--gray-200, #eaecf0);
//   background-color: var(--color-neutrals-00, #fff);
//   display: flex;
//   margin-right: -20px;
//   width: 100%;
//   justify-content: space-between;
//   gap: 0px;
// `;

// const Div6 = styled.div`
//   display: flex;
//   flex-grow: 1;
//   flex-basis: 0%;
//   flex-direction: column;
// `;

// const Div7 = styled.div`
//   align-items: center;
//   border-bottom: 1px solid var(--gray-200, #eaecf0);
//   display: flex;
//   justify-content: space-between;
//   gap: 12px;
//   padding: 16px 0;
// `;

// const Img2 = styled.img`
//   aspect-ratio: 1.44;
//   object-fit: contain;
//   object-position: center;
//   width: 46px;
//   justify-content: center;
//   align-items: center;
//   overflow: hidden;
//   max-width: 100%;
//   margin: auto 0;
// `;

// const Div8 = styled.div`
//   align-self: stretch;
//   display: flex;
//   flex-grow: 1;
//   flex-basis: 0%;
//   flex-direction: column;
// `;

// const Div9 = styled.div`
//   color: var(--gray-900, #101828);
//   white-space: nowrap;
//   font: 500 14px/20px Inter, sans-serif;
//   @media (max-width: 991px) {
//     white-space: initial;
//   }
// `;

// const Div10 = styled.div`
//   color: var(--gray-600, #475467);
//   white-space: nowrap;
//   font: 400 14px/20px Inter, sans-serif;
//   @media (max-width: 991px) {
//     white-space: initial;
//   }
// `;

// const Div11 = styled.div`
//   align-items: center;
//   border-bottom: 1px solid var(--gray-200, #eaecf0);
//   display: flex;
//   justify-content: space-between;
//   gap: 12px;
//   padding: 16px 0;
// `;

// const Img3 = styled.img`
//   aspect-ratio: 1.44;
//   object-fit: contain;
//   object-position: center;
//   width: 46px;
//   justify-content: center;
//   align-items: center;
//   overflow: hidden;
//   max-width: 100%;
//   margin: auto 0;
// `;

// const Div12 = styled.div`
//   align-self: stretch;
//   display: flex;
//   flex-grow: 1;
//   flex-basis: 0%;
//   flex-direction: column;
// `;

// const Div13 = styled.div`
//   color: var(--gray-900, #101828);
//   white-space: nowrap;
//   font: 500 14px/20px Inter, sans-serif;
//   @media (max-width: 991px) {
//     white-space: initial;
//   }
// `;

// const Div14 = styled.div`
//   color: var(--gray-600, #475467);
//   white-space: nowrap;
//   font: 400 14px/20px Inter, sans-serif;
//   @media (max-width: 991px) {
//     white-space: initial;
//   }
// `;

// const Div15 = styled.div`
//   align-items: center;
//   border-bottom: 1px solid var(--gray-200, #eaecf0);
//   display: flex;
//   justify-content: space-between;
//   gap: 12px;
//   padding: 16px 0;
// `;

// const Img4 = styled.img`
//   aspect-ratio: 1.44;
//   object-fit: contain;
//   object-position: center;
//   width: 46px;
//   fill: var(--color-neutrals-00, #fff);
//   stroke-width: 1px;
//   stroke: var(--gray-100, #f2f4f7);
//   overflow: hidden;
//   max-width: 100%;
//   margin: auto 0;
// `;

// const Div16 = styled.div`
//   align-self: stretch;
//   display: flex;
//   flex-grow: 1;
//   flex-basis: 0%;
//   flex-direction: column;
// `;

// const Div17 = styled.div`
//   color: var(--gray-900, #101828);
//   white-space: nowrap;
//   font: 500 14px/20px Inter, sans-serif;
//   @media (max-width: 991px) {
//     white-space: initial;
//   }
// `;

// const Div18 = styled.div`
//   color: var(--gray-600, #475467);
//   white-space: nowrap;
//   font: 400 14px/20px Inter, sans-serif;
//   @media (max-width: 991px) {
//     white-space: initial;
//   }
// `;

// const Div19 = styled.div`
//   align-items: center;
//   border-bottom: 1px solid var(--gray-200, #eaecf0);
//   display: flex;
//   justify-content: space-between;
//   gap: 12px;
//   padding: 16px 0;
// `;

// const Img5 = styled.img`
//   aspect-ratio: 1.44;
//   object-fit: contain;
//   object-position: center;
//   width: 46px;
//   fill: var(--color-neutrals-00, #fff);
//   stroke-width: 1px;
//   stroke: var(--gray-100, #f2f4f7);
//   overflow: hidden;
//   max-width: 100%;
//   margin: auto 0;
// `;

// const Div20 = styled.div`
//   align-self: stretch;
//   display: flex;
//   flex-grow: 1;
//   flex-basis: 0%;
//   flex-direction: column;
// `;

// const Div21 = styled.div`
//   color: var(--gray-900, #101828);
//   white-space: nowrap;
//   font: 500 14px/20px Inter, sans-serif;
//   @media (max-width: 991px) {
//     white-space: initial;
//   }
// `;

// const Div22 = styled.div`
//   color: var(--gray-600, #475467);
//   white-space: nowrap;
//   font: 400 14px/20px Inter, sans-serif;
//   @media (max-width: 991px) {
//     white-space: initial;
//   }
// `;

// const Div23 = styled.div`
//   align-items: center;
//   border-bottom: 1px solid var(--gray-200, #eaecf0);
//   display: flex;
//   justify-content: space-between;
//   gap: 12px;
//   padding: 16px 0;
// `;

// const Img6 = styled.img`
//   aspect-ratio: 1.44;
//   object-fit: contain;
//   object-position: center;
//   width: 46px;
//   justify-content: center;
//   align-items: center;
//   overflow: hidden;
//   max-width: 100%;
//   margin: auto 0;
// `;

// const Div24 = styled.div`
//   align-self: stretch;
//   display: flex;
//   flex-grow: 1;
//   flex-basis: 0%;
//   flex-direction: column;
// `;

// const Div25 = styled.div`
//   color: var(--gray-900, #101828);
//   white-space: nowrap;
//   font: 500 14px/20px Inter, sans-serif;
//   @media (max-width: 991px) {
//     white-space: initial;
//   }
// `;

// const Div26 = styled.div`
//   color: var(--gray-600, #475467);
//   white-space: nowrap;
//   font: 400 14px/20px Inter, sans-serif;
//   @media (max-width: 991px) {
//     white-space: initial;
//   }
// `;

// const Div27 = styled.div`
//   align-items: center;
//   border-bottom: 1px solid var(--gray-200, #eaecf0);
//   display: flex;
//   justify-content: space-between;
//   gap: 12px;
//   padding: 16px 0;
// `;

// const Img7 = styled.img`
//   aspect-ratio: 1.44;
//   object-fit: contain;
//   object-position: center;
//   width: 46px;
//   fill: var(--color-neutrals-00, #fff);
//   stroke-width: 1px;
//   stroke: var(--gray-100, #f2f4f7);
//   overflow: hidden;
//   max-width: 100%;
//   margin: auto 0;
// `;

// const Div28 = styled.div`
//   align-self: stretch;
//   display: flex;
//   flex-grow: 1;
//   flex-basis: 0%;
//   flex-direction: column;
// `;

// const Div29 = styled.div`
//   color: var(--gray-900, #101828);
//   white-space: nowrap;
//   font: 500 14px/20px Inter, sans-serif;
//   @media (max-width: 991px) {
//     white-space: initial;
//   }
// `;

// const Div30 = styled.div`
//   color: var(--gray-600, #475467);
//   white-space: nowrap;
//   font: 400 14px/20px Inter, sans-serif;
//   @media (max-width: 991px) {
//     white-space: initial;
//   }
// `;

// const Div31 = styled.div`
//   align-items: center;
//   border-bottom: 1px solid var(--gray-200, #eaecf0);
//   display: flex;
//   justify-content: space-between;
//   gap: 12px;
//   padding: 16px 0;
// `;

// const Img8 = styled.img`
//   aspect-ratio: 1.44;
//   object-fit: contain;
//   object-position: center;
//   width: 46px;
//   fill: var(--color-neutrals-00, #fff);
//   stroke-width: 1px;
//   stroke: var(--gray-100, #f2f4f7);
//   overflow: hidden;
//   max-width: 100%;
//   margin: auto 0;
// `;

// const Div32 = styled.div`
//   align-self: stretch;
//   display: flex;
//   flex-grow: 1;
//   flex-basis: 0%;
//   flex-direction: column;
// `;

// const Div33 = styled.div`
//   color: var(--gray-900, #101828);
//   white-space: nowrap;
//   font: 500 14px/20px Inter, sans-serif;
//   @media (max-width: 991px) {
//     white-space: initial;
//   }
// `;

// const Div34 = styled.div`
//   color: var(--gray-600, #475467);
//   white-space: nowrap;
//   font: 400 14px/20px Inter, sans-serif;
//   @media (max-width: 991px) {
//     white-space: initial;
//   }
// `;

// const Div35 = styled.div`
//   align-items: center;
//   border-bottom: 1px solid var(--gray-200, #eaecf0);
//   display: flex;
//   justify-content: space-between;
//   gap: 12px;
//   padding: 16px 0;
// `;

// const Img9 = styled.img`
//   aspect-ratio: 1.44;
//   object-fit: contain;
//   object-position: center;
//   width: 46px;
//   justify-content: center;
//   align-items: center;
//   overflow: hidden;
//   max-width: 100%;
//   margin: auto 0;
// `;

// const Div36 = styled.div`
//   align-self: stretch;
//   display: flex;
//   flex-grow: 1;
//   flex-basis: 0%;
//   flex-direction: column;
// `;

// const Div37 = styled.div`
//   color: var(--gray-900, #101828);
//   white-space: nowrap;
//   font: 500 14px/20px Inter, sans-serif;
//   @media (max-width: 991px) {
//     white-space: initial;
//   }
// `;

// const Div38 = styled.div`
//   color: var(--gray-600, #475467);
//   white-space: nowrap;
//   font: 400 14px/20px Inter, sans-serif;
//   @media (max-width: 991px) {
//     white-space: initial;
//   }
// `;

// const Div39 = styled.div`
//   align-items: center;
//   border-bottom: 1px solid var(--gray-200, #eaecf0);
//   display: flex;
//   justify-content: space-between;
//   gap: 12px;
//   padding: 16px 0;
// `;

// const Img10 = styled.img`
//   aspect-ratio: 1.44;
//   object-fit: contain;
//   object-position: center;
//   width: 46px;
//   fill: var(--color-neutrals-00, #fff);
//   stroke-width: 1px;
//   stroke: var(--gray-100, #f2f4f7);
//   overflow: hidden;
//   max-width: 100%;
//   margin: auto 0;
// `;

// const Div40 = styled.div`
//   align-self: stretch;
//   display: flex;
//   flex-grow: 1;
//   flex-basis: 0%;
//   flex-direction: column;
// `;

// const Div41 = styled.div`
//   color: var(--gray-900, #101828);
//   white-space: nowrap;
//   font: 500 14px/20px Inter, sans-serif;
//   @media (max-width: 991px) {
//     white-space: initial;
//   }
// `;

// const Div42 = styled.div`
//   color: var(--gray-600, #475467);
//   white-space: nowrap;
//   font: 400 14px/20px Inter, sans-serif;
//   @media (max-width: 991px) {
//     white-space: initial;
//   }
// `;

// const Div43 = styled.div`
//   align-items: center;
//   border-bottom: 1px solid var(--gray-200, #eaecf0);
//   display: flex;
//   justify-content: space-between;
//   gap: 12px;
//   padding: 16px 0;
// `;

// const Img11 = styled.img`
//   aspect-ratio: 1.44;
//   object-fit: contain;
//   object-position: center;
//   width: 46px;
//   fill: var(--color-neutrals-00, #fff);
//   stroke-width: 1px;
//   stroke: var(--gray-100, #f2f4f7);
//   overflow: hidden;
//   max-width: 100%;
//   margin: auto 0;
// `;

// const Div44 = styled.div`
//   align-self: stretch;
//   display: flex;
//   flex-grow: 1;
//   flex-basis: 0%;
//   flex-direction: column;
// `;

// const Div45 = styled.div`
//   color: var(--gray-900, #101828);
//   white-space: nowrap;
//   font: 500 14px/20px Inter, sans-serif;
//   @media (max-width: 991px) {
//     white-space: initial;
//   }
// `;

// const Div46 = styled.div`
//   color: var(--gray-600, #475467);
//   white-space: nowrap;
//   font: 400 14px/20px Inter, sans-serif;
//   @media (max-width: 991px) {
//     white-space: initial;
//   }
// `;

// const Div47 = styled.div`
//   display: flex;
//   flex-basis: 0%;
//   flex-direction: column;
// `;

// const Div48 = styled.div`
//   border-bottom: 1px solid var(--gray-200, #eaecf0);
//   display: flex;
//   flex-direction: column;
//   padding: 17px 1px 17px 24px;
//   @media (max-width: 991px) {
//     padding-left: 20px;
//   }
// `;

// const Div49 = styled.div`
//   color: var(--error-500, #f04438);
//   white-space: nowrap;
//   font: 400 14px/20px Inter, sans-serif;
//   @media (max-width: 991px) {
//     white-space: initial;
//   }
// `;

// const Div50 = styled.div`
//   color: var(--gray-600, #475467);
//   align-self: start;
//   margin-left: 16px;
//   white-space: nowrap;
//   font: 400 12px/18px Inter, sans-serif;
//   @media (max-width: 991px) {
//     margin-left: 10px;
//     white-space: initial;
//   }
// `;

// const Div51 = styled.div`
//   justify-content: flex-end;
//   border-bottom: 1px solid var(--gray-200, #eaecf0);
//   display: flex;
//   flex-direction: column;
//   padding: 17px 0 17px 25px;
//   @media (max-width: 991px) {
//     padding-left: 20px;
//   }
// `;

// const Div52 = styled.div`
//   color: var(--error-500, #f04438);
//   white-space: nowrap;
//   font: 400 14px/20px Inter, sans-serif;
//   @media (max-width: 991px) {
//     white-space: initial;
//   }
// `;

// const Div53 = styled.div`
//   color: var(--gray-600, #475467);
//   align-self: start;
//   margin-left: 16px;
//   white-space: nowrap;
//   font: 400 12px/18px Inter, sans-serif;
//   @media (max-width: 991px) {
//     margin-left: 10px;
//     white-space: initial;
//   }
// `;

// const Div54 = styled.div`
//   border-bottom: 1px solid var(--gray-200, #eaecf0);
//   display: flex;
//   flex-direction: column;
//   padding: 17px 0 17px 24px;
//   @media (max-width: 991px) {
//     padding-left: 20px;
//   }
// `;

// const Div55 = styled.div`
//   color: var(--success-500, #12b76a);
//   white-space: nowrap;
//   font: 400 14px/20px Inter, sans-serif;
//   @media (max-width: 991px) {
//     white-space: initial;
//   }
// `;

// const Div56 = styled.div`
//   color: var(--gray-600, #475467);
//   align-self: start;
//   margin-left: 17px;
//   white-space: nowrap;
//   font: 400 12px/18px Inter, sans-serif;
//   @media (max-width: 991px) {
//     margin-left: 10px;
//     white-space: initial;
//   }
// `;

// const Div57 = styled.div`
//   border-bottom: 1px solid var(--gray-200, #eaecf0);
//   display: flex;
//   flex-direction: column;
//   padding: 17px 1px 17px 24px;
//   @media (max-width: 991px) {
//     padding-left: 20px;
//   }
// `;

// const Div58 = styled.div`
//   color: var(--success-500, #12b76a);
//   white-space: nowrap;
//   font: 400 14px/20px Inter, sans-serif;
//   @media (max-width: 991px) {
//     white-space: initial;
//   }
// `;

// const Div59 = styled.div`
//   color: var(--gray-600, #475467);
//   align-self: start;
//   margin-left: 16px;
//   white-space: nowrap;
//   font: 400 12px/18px Inter, sans-serif;
//   @media (max-width: 991px) {
//     margin-left: 10px;
//     white-space: initial;
//   }
// `;

// const Div60 = styled.div`
//   border-bottom: 1px solid var(--gray-200, #eaecf0);
//   display: flex;
//   flex-direction: column;
//   padding: 17px 3px 17px 24px;
//   @media (max-width: 991px) {
//     padding-left: 20px;
//   }
// `;

// const Div61 = styled.div`
//   color: var(--success-500, #12b76a);
//   white-space: nowrap;
//   font: 400 14px/20px Inter, sans-serif;
//   @media (max-width: 991px) {
//     white-space: initial;
//   }
// `;

// const Div62 = styled.div`
//   color: var(--gray-600, #475467);
//   align-self: start;
//   margin-left: 14px;
//   white-space: nowrap;
//   font: 400 12px/18px Inter, sans-serif;
//   @media (max-width: 991px) {
//     margin-left: 10px;
//     white-space: initial;
//   }
// `;

// const Div63 = styled.div`
//   border-bottom: 1px solid var(--gray-200, #eaecf0);
//   display: flex;
//   flex-direction: column;
//   padding: 17px 1px 17px 24px;
//   @media (max-width: 991px) {
//     padding-left: 20px;
//   }
// `;

// const Div64 = styled.div`
//   color: var(--success-500, #12b76a);
//   white-space: nowrap;
//   font: 400 14px/20px Inter, sans-serif;
//   @media (max-width: 991px) {
//     white-space: initial;
//   }
// `;

// const Div65 = styled.div`
//   color: var(--gray-600, #475467);
//   align-self: start;
//   margin-left: 16px;
//   white-space: nowrap;
//   font: 400 12px/18px Inter, sans-serif;
//   @media (max-width: 991px) {
//     margin-left: 10px;
//     white-space: initial;
//   }
// `;

// const Div66 = styled.div`
//   border-bottom: 1px solid var(--gray-200, #eaecf0);
//   display: flex;
//   flex-direction: column;
//   padding: 17px 2px 17px 24px;
//   @media (max-width: 991px) {
//     padding-left: 20px;
//   }
// `;

// const Div67 = styled.div`
//   color: var(--success-500, #12b76a);
//   white-space: nowrap;
//   font: 400 14px/20px Inter, sans-serif;
//   @media (max-width: 991px) {
//     white-space: initial;
//   }
// `;

// const Div68 = styled.div`
//   color: var(--gray-600, #475467);
//   align-self: start;
//   margin-left: 15px;
//   white-space: nowrap;
//   font: 400 12px/18px Inter, sans-serif;
//   @media (max-width: 991px) {
//     margin-left: 10px;
//     white-space: initial;
//   }
// `;

// const Div69 = styled.div`
//   border-bottom: 1px solid var(--gray-200, #eaecf0);
//   display: flex;
//   flex-direction: column;
//   padding: 17px 0 17px 24px;
//   @media (max-width: 991px) {
//     padding-left: 20px;
//   }
// `;

// const Div70 = styled.div`
//   color: var(--error-500, #f04438);
//   white-space: nowrap;
//   font: 400 14px/20px Inter, sans-serif;
//   @media (max-width: 991px) {
//     white-space: initial;
//   }
// `;

// const Div71 = styled.div`
//   color: var(--success-500, #12b76a);
//   align-self: start;
//   margin-left: 17px;
//   white-space: nowrap;
//   font: 400 12px/18px Inter, sans-serif;
//   @media (max-width: 991px) {
//     margin-left: 10px;
//     white-space: initial;
//   }
// `;

// const Div72 = styled.div`
//   border-bottom: 1px solid var(--gray-200, #eaecf0);
//   display: flex;
//   flex-direction: column;
//   padding: 17px 0 17px 24px;
//   @media (max-width: 991px) {
//     padding-left: 20px;
//   }
// `;

// const Div73 = styled.div`
//   color: var(--success-500, #12b76a);
//   white-space: nowrap;
//   font: 400 14px/20px Inter, sans-serif;
//   @media (max-width: 991px) {
//     white-space: initial;
//   }
// `;

// const Div74 = styled.div`
//   color: var(--gray-600, #475467);
//   align-self: start;
//   margin-left: 17px;
//   white-space: nowrap;
//   font: 400 12px/18px Inter, sans-serif;
//   @media (max-width: 991px) {
//     margin-left: 10px;
//     white-space: initial;
//   }
// `;

// const Div75 = styled.div`
//   border-bottom: 1px solid var(--gray-200, #eaecf0);
//   display: flex;
//   flex-direction: column;
//   padding: 17px 0 17px 24px;
//   @media (max-width: 991px) {
//     padding-left: 20px;
//   }
// `;

// const Div76 = styled.div`
//   color: var(--success-500, #12b76a);
//   white-space: nowrap;
//   font: 400 14px/20px Inter, sans-serif;
//   @media (max-width: 991px) {
//     white-space: initial;
//   }
// `;

// const Div77 = styled.div`
//   color: var(--gray-600, #475467);
//   align-self: start;
//   margin-left: 17px;
//   white-space: nowrap;
//   font: 400 12px/18px Inter, sans-serif;
//   @media (max-width: 991px) {
//     margin-left: 10px;
//     white-space: initial;
//   }
// `;

// const Div78 = styled.div`
//   color: var(--primary-500, var(--primary-500, #eaaa08));
//   align-self: end;
//   white-space: nowrap;
//   margin: 16px -20px 0 0;
//   font: 600 14px/20px Inter, sans-serif;
//   @media (max-width: 991px) {
//     white-space: initial;
//   }
// `;
