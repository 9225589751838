import { storageService } from "services";
import { listUserAccounts } from "services/authService";
import { details } from "services/buyerService";
import { designerDetails } from "services/designerService";
import { signOutAsync } from "store/signout/actions";

export const AccountState = {
  designer: null,
  buyer: null,
  // designer | buyer
  active: null,
  account_id: null,
  account_type: null,
  loading: true,
};

export const LIST_ACCOUNT = "@umojalinn/account/list";
export const LIST_ACCOUNT_SUCCESS = "@umojalinn/account/list-success";
export const LIST_ACCOUNT_FAILED = "@umojalinn/account/list-failed";

export const listAccount = () => {
  return {
    type: LIST_ACCOUNT,
    payload: true,
  };
};

export const listAccountSuccess = (data) => {
  return {
    type: LIST_ACCOUNT_SUCCESS,
    payload: data,
  };
};

export const listAccountFailed = (data) => {
  return {
    type: LIST_ACCOUNT_FAILED,
    payload: data,
  };
};

export const listAccountAsync = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(listAccount());
      const { data } = await listUserAccounts(payload);
      dispatch(listAccountSuccess(data));
    } catch (err) {
      dispatch(listAccountFailed(err));
    }
  };
};
//

export const INIT_ACCOUNT = "@umojalinn/account/init";
export const INIT_ACCOUNT_SUCCESS = "@umojalinn/account/init-success";
export const INIT_ACCOUNT_FAILED = "@umojalinn/account/init-failed";

export const initAccount = () => {
  return {
    type: INIT_ACCOUNT,
    payload: true,
  };
};

export const initAccountSuccess = (data) => {
  return {
    type: INIT_ACCOUNT_SUCCESS,
    payload: data,
  };
};

export const initAccountFailed = (data) => {
  return {
    type: INIT_ACCOUNT_FAILED,
    payload: data,
  };
};

export const initAccountAsync = () => {
  return async (dispatch) => {
    try {
      dispatch(initAccount());
      const account_type = storageService.get("account_type");
      const account_id = storageService.get("account_id");
      const data =
        account_type.toLowerCase() === "buyer"
          ? await details(account_id)
          : await designerDetails(account_id);
      dispatch(initAccountSuccess({...data, account_type}));
    } catch (err) {
      dispatch(signOutAsync())
      dispatch(initAccountFailed(err));
      window.location.href = '/signin'
    }
  };
};


export const UPDATE_ACCOUNT = "@umojalinn/account/update";

export const updateAccount = (data) => {
  return {
    type: UPDATE_ACCOUNT,
    payload: data,
  };
};
