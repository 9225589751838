/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Form, Row, Modal } from "react-bootstrap";
import styled from "styled-components";
import WalletIcon from "assets/images/icons/wallet-icon.svg";
import "./Wallet.css";
import {
  addOtherPaymentDetails,
  topUpWallet,
  withdraw,
} from "services/paymentService";
import { listCountries } from "services/commonService";
import AddCard from "./AddCard";
import { fundEscrow } from "services/escrowService";
import { useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
import StripeIcon from "assets/images/icons/stripe-icon.png";
import PaypalIcon from "assets/images/icons/paypal-icon.png";
import BankIcon from "assets/images/icons/bank-icon.svg";
import VisaIcon from "assets/images/icons/visa-icon.png";
import MastercardIcon from "assets/images/icons/mastercard-icon.png";
import DisconnectAccountModal from "./DisconnectAccountModal";

const otherPaymentOptions = ["stripe", "paystack", "paypal", "direct_transfer"];

const Deposit = (props) => {
  const [selectedCard, setSelectedCard] = useState(props.payment_cards[0]);
  const [selectedOtherPaymentMethod, setSelectedOtherPaymentMethod] =
    useState();
  const token = localStorage.getItem("auth_token");
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setCountry] = useState("");
  const [selectedMethod, setSelectedMethod] = useState();
  const [isDepositSuccess, setIsDepositSuccess] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [depositedAmount, setDepositedAmount] = useState(0);
  const [cardComponentIndex, setCardComponentIndex] = useState(0);
  const buyer = useSelector((state) => state.buyer.buyer);
  const designer = useSelector((state) => state.designer.designer);
  const selectedAccount = buyer ? buyer : designer;
  const [amount, setAmount] = useState();
  // const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const getCountries = async () => {
    const response = await listCountries(token);
    setCountries(response);
  };

  const handleCloseModal = () => setShow(false);
  const handleShowModal = () => setShow(true);

  const handleCardChange = (card) => {
    setSelectedCard(card);
  };

  const handleWithdrawOrDepositfromOtherMethods = (method) => {
    setSelectedOtherPaymentMethod(method);
  };

  const handleChange = (e) => {
    setSelectedMethod(e.target.value);
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handleCardDeposit = async () => {
    setIsButtonDisabled(true);
    if (props.paymentMode && typeof props.paymentMode === "string") {
      const data = {
        milestone: props.nextMilestone.id,
        project: props.nextMilestone.project,
        buyer: selectedAccount.id,
        payment_method: selectedCard.id,
      };
      const resp = await fundEscrow(data, token);
      if (resp) {
        // navigate("/workspace/projects");
      }
    } else {
      const data = {
        payment_method: selectedCard.id,
        wallet: props.selectedWallet?.id,
        amount: amount,
        currency: "eur",
      };
      console.log('data', data);
      const resp = await topUpWallet(data, token);
      if (resp) {
        setIsDepositSuccess(true);
        setDepositedAmount(data.amount);
      }
      return resp;
    }
  };

  const handleWithdrawal = async () => {
    setIsButtonDisabled(true);
    const walletBalance = parseFloat(props.selectedWallet.balance);
    if (!isNaN(walletBalance) && walletBalance > amount) {
      const data = {
        created_by: selectedAccount.id,
        payment_method: selectedOtherPaymentMethod.id,
        wallet: props.selectedWallet?.id,
        amount: amount,
      };
      const resp = await withdraw(data, token);
      if (resp.success) {
        window.href = "/workspace/wallet";
      }
    } else {
      window.alert("Amount to withdraw must be lower than wallet balance");
    }
  };

  const handleAddOtherPaymentMethod = async () => {
    setIsButtonDisabled(true);
    const data = {
      method_type: selectedMethod,
      country_code: selectedCountry,
      email: selectedAccount.user.email,
      created_by: selectedAccount.id,
    };

    try {
      const resp = await addOtherPaymentDetails(data, token);
      if (resp.account_link_url) {
        window.open(resp.account_link_url, "_blank");
      }
    } catch (error) {
      console.error("Error adding payment method:", error);
    }
  };

  useEffect(() => {
    getCountries();
  }, [countries]);

  const handlehideSuccessBox = () => {
    setIsDepositSuccess(false);
  };

  const handleAddCardComponent = (value) => {
    setCardComponentIndex(value);
  };

  const handleWalletRedirect = () => {
    window.href = "/workspace/wallet";
  };

  const filteredPaymentOptions = otherPaymentOptions.filter(
    (option) =>
      !props.other_payment_methods.some(
        (method) => method.method_type === option
      )
  );

  const handleRedirectToDashboard = () => {
    handleCloseModal();
  }

  return (
    <>
      {cardComponentIndex === 0 && (
        <>
          <Row className="projects-tab-bg mb-5">
            <BDiv>
              <BImg loading="lazy" src={WalletIcon} />
              <img
                loading="lazy"
                alt="umojalin"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/2c31c096-bf86-40ad-b8ae-e06932cb3085?"
              />
              <BDiv2
                style={{ cursor: "pointer" }}
                onClick={handleWalletRedirect}
              >
                Wallet
              </BDiv2>
              <img
                loading="lazy"
                alt="umojalin"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/d1b49d32-fae7-4129-960a-bda1de650b39?"
              />
              <BDiv2>Deposit</BDiv2>
            </BDiv>
            <p className="mt-3">
              <strong>{buyer ? "Add new card" : "Withdrawal method"}</strong>
              <br />{" "}
              <small>
                {buyer
                  ? "Update your billing details"
                  : "Update your withdrawal details"}
              </small>
            </p>
          </Row>
          <Row>
            <Div>
              <Div2>Location</Div2>
              <Div3>
                <select
                  name="country"
                  onChange={(e) => setCountry(e.target.value)}
                  className="form-select form-select-lg rounded-0"
                >
                  <option>Select country</option>
                  {countries.map((country, index) => (
                    <option key={index} value={country.code2}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </Div3>
              <Div5>
                {buyer
                  ? "Our payment options are customized to suit your location, ensuring effortless deposits."
                  : "Our withdrawals options are customized to suit your location, ensuring effortless withdrawals."}
              </Div5>
              {(props.payment_cards.length > 0 ||
                props.other_payment_methods.length > 0) && (
                <div className="mt-3">
                  <Div2>{buyer ? "Deposit Amount" : "Withdrawal Amount"}</Div2>
                  <Div3>
                    <Form.Control
                      type="number"
                      placeholder={
                        buyer ? "Amount to fund" : "Amount to withdraw"
                      }
                      className="form-control-lg rounded-0"
                      name="amount"
                      value={amount}
                      onChange={handleAmountChange}
                    />
                  </Div3>
                </div>
              )}
              <Div6>
                {buyer && (
                  <Div7>
                    <Column>
                      <Div8>
                        <Div9>Card details</Div9>
                        <Div10>Select deposit method.</Div10>
                      </Div8>
                    </Column>
                    <Column2>
                      <Div11>
                        {props.payment_cards.map((item, index) => (
                          <Div12
                            key={index}
                            style={{ cursor: "pointer" }}
                            onClick={() => handleCardChange(item)}
                            className={`${
                              selectedCard === item
                                ? "selected-card"
                                : "unselected-card"
                            } mb-3`}
                          >
                            <Div13>
                              {item.method_type === "visa" && (
                                <Img2
                                  loading="lazy"
                                  src={VisaIcon}
                                  alt="icon"
                                />
                              )}
                              {item.method_type === "mastercard" && (
                                <Img2
                                  loading="lazy"
                                  src={MastercardIcon}
                                  alt="icon"
                                />
                              )}
                              {selectedCard === item ? (
                                <Div14>
                                  <Div15>{item.card_name}</Div15>
                                  <Div16>
                                    Expiry 0{item.exp_month}/{item.exp_year}
                                  </Div16>
                                  <Div17>
                                    <Div18>Set as default</Div18>
                                    <Div19>Edit</Div19>
                                  </Div17>
                                </Div14>
                              ) : (
                                <Div22>
                                  <Div23>{item.card_name}</Div23>
                                  <Div24>
                                    Expiry 0{item.exp_month}/{item.exp_year}
                                  </Div24>
                                  <Div25>
                                    <Div26>Set as default</Div26>
                                    <Div27>Edit</Div27>
                                  </Div25>
                                </Div22>
                              )}
                            </Div13>
                            {selectedCard === item ? (
                              <Img3
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/3eb40c84-6036-4795-9396-ef24a1e38309?"
                                alt="icon"
                              />
                            ) : (
                              <Div28 />
                            )}
                          </Div12>
                        ))}
                      </Div11>
                      <DivMainCreditDebit>
                        <Span2
                          style={{ cursor: "pointer" }}
                          onClick={() => handleAddCardComponent(1)}
                        >
                          <ImgCreditDebit
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/8ec5c94ed118859d1f7490e8230b22701b002971db0f4bf40393cca19c15a4fe?"
                          />
                          <DivCreditDebit>
                            Add a credit or debit card
                          </DivCreditDebit>
                        </Span2>
                      </DivMainCreditDebit>
                    </Column2>
                  </Div7>
                )}
              </Div6>
              {props.paymentMode ? (
                <></>
              ) : (
                <Div6>
                  <Div7>
                    <Column>
                      <Div8>
                        <Div9>
                          {buyer
                            ? "Other payment details"
                            : "Other withdrawals details"}
                        </Div9>
                        <Div10>
                          {buyer
                            ? "Select deposit method."
                            : "Select withdrawals method"}
                        </Div10>
                      </Div8>
                    </Column>
                    <Column2>
                      <Div11>
                        {props.other_payment_methods.map((method, index) => (
                          <Div20
                            key={index}
                            className={`${
                              selectedOtherPaymentMethod === method
                                ? "selected-card"
                                : "unselected-card"
                            }`}
                          >
                            <Div21>
                              {method.method_type === "stripe" && (
                                <Img4
                                  loading="lazy"
                                  src={StripeIcon}
                                  alt="icon"
                                />
                              )}
                              {method.method_type === "paypal" && (
                                <Img4
                                  loading="lazy"
                                  src={PaypalIcon}
                                  alt="icon"
                                />
                              )}
                              {method.method_type === "paystack" && (
                                <Img4
                                  loading="lazy"
                                  src={StripeIcon}
                                  alt="icon"
                                />
                              )}
                              {method.method_type === "direct_transfer" && (
                                <Img4
                                  loading="lazy"
                                  src={BankIcon}
                                  alt="icon"
                                />
                              )}
                              {selectedOtherPaymentMethod === method ? (
                                <Div14>
                                  <Div15>{method?.card_name}</Div15>
                                  <Div16>
                                    Expiry 0{method?.exp_month}/
                                    {method?.exp_year}
                                  </Div16>
                                  <Div17>
                                    <Div18>Set as default</Div18>
                                    <Div19 style={{ cursor: "pointer" }}
                                      onClick={handleShowModal}>Edit</Div19>
                                  </Div17>
                                </Div14>
                              ) : (
                                <Div22>
                                  <Div23>{method?.card_name}</Div23>
                                  <Div24>
                                    Expiry 0{method?.exp_month}/
                                    {method?.exp_year}
                                  </Div24>
                                  <Div25>
                                    <Div26>Set as default</Div26>
                                    <Div27
                                      style={{ cursor: "pointer" }}
                                      onClick={handleShowModal}
                                    >
                                      Edit
                                    </Div27>
                                  </Div25>
                                </Div22>
                              )}
                            </Div21>
                            {selectedOtherPaymentMethod === method ? (
                              <Img3
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/3eb40c84-6036-4795-9396-ef24a1e38309?"
                                alt="icon"
                              />
                            ) : (
                              <Div28
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleWithdrawOrDepositfromOtherMethods(
                                    method
                                  )
                                }
                              />
                            )}
                          </Div20>
                        ))}
                        <Div44 className="mb-3">
                          <select
                            name="payment_method"
                            onChange={handleChange}
                            className="form-select form-select-lg rounded-0"
                          >
                            <option>Add other payment options</option>
                            {filteredPaymentOptions.map((option, index) => (
                              <option key={index} value={option}>
                                <span className="text-capitalize">
                                  {option} <small>(Recommended)</small>
                                </span>
                              </option>
                            ))}
                          </select>
                        </Div44>
                        {isDepositSuccess && (
                          <Div45>
                            <Div3Depo>
                              <Span>
                                <Div4Depo>${depositedAmount}</Div4Depo>
                                <Div5Depo>Deposited in wallet</Div5Depo>
                              </Span>
                            </Div3Depo>
                            <Img
                              loading="lazy"
                              onClick={handlehideSuccessBox}
                              style={{ cursor: "pointer" }}
                              src="https://cdn.builder.io/api/v1/image/assets/TEMP/a4b2ebe0753abfab38a4f8776a40d38e2198101630415ad2152e497d36a67376?"
                            />
                          </Div45>
                        )}
                        <Div47>
                          <span
                            style={{
                              fontFamily: "Inter, sans-serif",
                              fontWeight: "400",
                            }}
                          >
                            Got any other payment suggestions?{" "}
                          </span>
                          <span
                            style={{
                              fontFamily: "Inter, sans-serif",
                              fontWeight: "600",
                            }}
                          >
                            Submit feedback
                          </span>
                        </Div47>
                      </Div11>
                    </Column2>
                  </Div7>
                </Div6>
              )}
              <Img7
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/7ba91b1b-2a5d-444a-b069-b5e7ef5abffb?"
                alt="icon"
              />
              <Div48>
                <Div49>Back</Div49>
                {buyer && !selectedMethod && (
                  <button
                    type="button"
                    onClick={handleCardDeposit}
                    className="btn-deposit"
                    disabled={!amount || isButtonDisabled}
                  >
                    {props.paymentMode ? "Fund Escrow" : "Procced to deposit"}
                  </button>
                )}
                {buyer && selectedMethod && (
                  <button
                    type="button"
                    onClick={handleAddOtherPaymentMethod}
                    className="btn-deposit"
                    disabled={!selectedCountry || isButtonDisabled}
                  >
                    {props.paymentMode ? "Fund Escrow" : "Proceed to Connect"}
                  </button>
                )}
                {designer && !selectedOtherPaymentMethod && selectedMethod && (
                  <button
                    type="button"
                    onClick={handleAddOtherPaymentMethod}
                    className="btn-deposit"
                    disabled={
                      (!selectedCountry && !selectedMethod) || isButtonDisabled
                    }
                  >
                    Proceed to Connect
                  </button>
                )}
                {designer &&
                  props.other_payment_methods.length > 0 &&
                  !selectedMethod && (
                    <button
                      type="button"
                      onClick={handleWithdrawal}
                      className="btn-deposit"
                      disabled={!amount || isButtonDisabled}
                    >
                      Proceed to Withdrawal
                    </button>
                  )}
              </Div48>
            </Div>
          </Row>
          <Modal
            show={show}
            onHide={handleCloseModal}
            className="rounded-0"
            centered
            backdrop="static"
            style={{
              boxShadow:
                "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
            }}
          >
            <Modal.Body>
              <DisconnectAccountModal
                onHide={handleCloseModal}
                onRedirectToDash={handleRedirectToDashboard}
              />
            </Modal.Body>
          </Modal>
        </>
      )}
      {cardComponentIndex === 1 && <AddCard />}
    </>
  );
};

export default Deposit;

const BDiv = styled.div`
  align-items: flex-start;
  display: flex;
  gap: 8px;
  height: 20px;
  @media (max-width: 991px) {
    justify-content: center;
  }
`;

const BImg = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
`;

const BDiv2 = styled.div`
  color: var(--gray-600, #475467);
  align-self: center;
  margin: auto 0;
  font: 500 14px/20px Inter, sans-serif;
`;

const Div = styled.div`
  display: flex;
  flex-direction: column;
`;

const Div2 = styled.div`
  color: var(--gray-700, #344054);
  width: 100%;
  font: 500 14px/20px Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div3 = styled.div`
  display: flex;
  margin-top: 6px;
  width: 100%;
  justify-content: space-between;
  gap: 8px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;


const Img = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 16px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
`;

const Div5 = styled.div`
  color: var(--gray-600, #475467);
  margin-top: 6px;
  width: 100%;
  font: 400 14px/20px Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div6 = styled.div`
  margin-top: 20px;
  width: 100%;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div7 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 31%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div8 = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  @media (max-width: 991px) {
    margin-top: 32px;
  }
`;

const Div9 = styled.div`
  color: var(--gray-700, #344054);
  margin-right: -20px;
  font: 500 14px/20px Inter, sans-serif;
`;

const Div10 = styled.div`
  color: var(--gray-600, #475467);
  margin-right: -20px;
  font: 400 14px/20px Inter, sans-serif;
`;

const Column2 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 69%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div11 = styled.div`
  align-self: stretch;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 32px;
  }
`;

const Div12 = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 4px;
  padding: 16px 11px 16px 16px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Div13 = styled.div`
  align-items: start;
  display: flex;
  justify-content: space-between;
  gap: 12px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Img2 = styled.img`
  aspect-ratio: 1.44;
  object-fit: contain;
  object-position: center;
  width: 46px;
  fill: var(--color-neutrals-00, #fff);
  stroke-width: 1px;
  stroke: var(--gray-100, #f2f4f7);
  overflow: hidden;
  max-width: 100%;
`;

const Div14 = styled.div`
  align-items: start;
  align-self: stretch;
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div15 = styled.div`
  align-self: stretch;
  color: var(--primary-800, #854a0e);
  font: 500 14px/20px Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div16 = styled.div`
  align-self: stretch;
  color: var(--primary-500, var(--primary-500, #eaaa08));
  font: 400 14px/20px Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div17 = styled.div`
  display: flex;
  margin-top: 8px;
  width: 133px;
  max-width: 100%;
  gap: 12px;
`;

const Div18 = styled.div`
  color: var(--primary-500, var(--primary-500, #eaaa08));
  font: 600 14px/20px Inter, sans-serif;
`;

const Div19 = styled.div`
  color: var(--primary-700, #a15c07);
  white-space: nowrap;
  font: 600 14px/20px Inter, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Img3 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 16px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  align-self: start;
  max-width: 100%;
`;

const Div20 = styled.div`
  display: flex;
  margin-top: 12px;
  justify-content: space-between;
  gap: 4px;
  padding: 16px 11px 16px 16px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Div21 = styled.div`
  align-items: start;
  display: flex;
  justify-content: space-between;
  gap: 12px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Img4 = styled.img`
  aspect-ratio: 1.44;
  object-fit: contain;
  object-position: center;
  width: 46px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
`;

const Div22 = styled.div`
  align-items: start;
  align-self: stretch;
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div23 = styled.div`
  align-self: stretch;
  color: var(--gray-700, #344054);
  font: 500 14px/20px Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div24 = styled.div`
  align-self: stretch;
  color: var(--gray-600, #475467);
  font: 400 14px/20px Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div25 = styled.div`
  display: flex;
  margin-top: 8px;
  width: 133px;
  max-width: 100%;
  gap: 12px;
`;

const Div26 = styled.div`
  color: var(--gray-600, #475467);
  font: 600 14px/20px Inter, sans-serif;
`;

const Div27 = styled.div`
  color: var(--primary-500, var(--primary-500, #eaaa08));
  white-space: nowrap;
  font: 600 14px/20px Inter, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div28 = styled.div`
  border-radius: 8px;
  border: 1px solid var(--gray-300, #d0d5dd);
  background-color: var(--base-white, #fff);
  align-self: start;
  display: flex;
  width: 16px;
  height: 16px;
  flex-direction: column;
`;

const Div44 = styled.div`
  align-items: center;
  align-self: stretch;
  background-color: var(--color-neutrals-00, #fff);
  display: flex;
  height: 44px;
  flex-direction: column;
  margin: 16px 0 0;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div45 = styled.div`
  border: 1px solid var(--success-300, #6ce9a6);
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  background-color: var(--success-25, #f6fef9);
  align-self: stretch;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin: 0;
  padding: 7px 0 33px 80px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
    padding-left: 20px;
  }
`;



const Div47 = styled.div`
  align-self: stretch;
  color: var(--gray-600, #475467);
  margin: -17px 0 0 0;
  font: 400 14px/20px Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Img7 = styled.img`
  aspect-ratio: 952;
  object-fit: contain;
  object-position: center;
  width: 100%;
  fill: var(--gray-200, #eaecf0);
  overflow: hidden;
  margin-top: 20px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div48 = styled.div`
  justify-content: space-between;
  align-items: center;
  display: flex;
  margin-top: 20px;
  width: 100%;
  gap: 20px;
  padding: 0 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Div49 = styled.div`
  color: var(--gray-600, #475467);
  margin: auto 0;
  font: 600 14px/20px Inter, sans-serif;
`;

const Span = styled.span`
  display: flex;
  gap: 6px;
`;

const Div3Depo = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
  padding: 0 60px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`;

const Div4Depo = styled.div`
  color: var(--Success-700, #027a48);
  white-space: nowrap;
  font: 600 16px/150% Inter, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div5Depo = styled.div`
  color: var(--Success-500, #12b76a);
  flex-grow: 1;
  white-space: nowrap;
  font: 400 16px/150% Inter, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const DivMainCreditDebit = styled.div`
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--Gray-200, #eaecf0);
  background-color: var(--Base-White, #fff);
  display: flex;
  gap: 4px;
  padding: 16px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const Span2 = styled.span`
  display: flex;
  padding-right: 80px;
  justify-content: space-between;
  gap: 12px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
    padding-right: 20px;
  }
`;

const ImgCreditDebit = styled.img`
  aspect-ratio: 1.44;
  object-fit: contain;
  object-position: center;
  width: 46px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
`;

const DivCreditDebit = styled.div`
  color: var(--Gray-600, #475467);
  align-self: start;
  flex-grow: 1;
  white-space: nowrap;
  font: 600 14px/143% Inter, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;
