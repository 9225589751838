import React from "react";
import { Link } from "react-router-dom";

export default function BreadCrumb({
  path,
  icon,
  current = <p className="tw-text-sm-medium tw-text-[#475467]">Profile</p>,
}) {
  return (
    <div className="tw-flex tw-gap-[8px] tw-items-center">
      <Link to={path}>{icon}</Link>

      <div>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.83337 18.3337L14.1667 1.66699"
            stroke="#D0D5DD"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div className="tw-px-[8px] tw-py-[4px] tw-flex tw-items-center tw-justify-center">{current} </div>
      <div>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.83337 18.3337L14.1667 1.66699"
            stroke="#D0D5DD"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
  );
}
